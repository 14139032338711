import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_LINK_API}/`,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const apiCep = axios.create({
  baseURL: 'https://viacep.com.br/',
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});
