import { postPurchase } from 'api/services/ApiCalls';
import {
  BottomNavigation,
  Button,
  Card,
  Input,
  Layout,
  Modal,
  Row,
  Spacing,
  Text,
  Title
} from 'components';
import { DataContext } from 'context/DataContext';
import { useContext, useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { maskCPF, maskCep } from 'utils/mask';
import error from '../../assets/icons/error-unenabled-location-icon.png';
import styles from './styles.module.scss';

function Registration() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [cep, setCep] = useState('');
  const [form, setForm] = useState({
    document: '',
    phone: '',
    email: ''
  });
  const { updateOrderID }: any = useContext(DataContext);

  async function handleRegistration() {
    const formData = new FormData();
    formData.append('cpf', form.document);
    formData.append('phone', form.phone);
    formData.append('email', form.email);
    formData.append('total', '150');

    await postPurchase(formData)
      .then((response) => {
        updateOrderID({ orderId: response.data.purchase.id });
      })
      .catch((error) => {
        console.error(error.response.data.error);
      })
      .finally(() => {
        navigate('/autenticacao');
      });
  }

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Modal
        isOpen={modalIsOpen}
        title="Informe o CEP da sua região"
        description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
        onClickClose={() => setModalIsOpen(false)}
      >
        <Spacing size="large" />
        <Input
          name="cep"
          type="text"
          width="100%"
          maxLength={10}
          value={cep}
          onChange={(event) => {
            setCep(maskCep(event.target.value));
          }}
        />
        <Spacing size="large" />
        <BottomNavigation
          width="74px"
          labelLeft="Cancelar"
          appearanceLeft="ghost"
          actionLeft={() => setModalIsOpen(false)}
          labelRight="Continuar"
          appearanceRight="primary"
          actionRight={() => {
            setModalIsOpen(false);
            setModalErrorIsOpen(true);
          }}
        />
      </Modal>
      <Modal
        isOpen={modalErrorIsOpen}
        image={error}
        imageWidth="70px"
        title="Serviço indisponível em sua região"
        description="O pacote Saúde da Mulher Premium não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
        onClickClose={() => setModalErrorIsOpen(false)}
        footerButtonFirst="Ver serviços disponíveis"
        onClickFooterFirst={() => setModalErrorIsOpen(false)}
        footerButtonSecond="Mudar localização"
        footerButtonSecondIcon={<FaMapMarkerAlt />}
        footerButtonSecondIconPosition="left"
        onClickFooterSecond={() => setModalErrorIsOpen(false)}
      />
      <Card appearance="ghost">
        <div className={styles.header}>
          <Row>
            <BsXLg className={styles.close} onClick={() => navigate('/autenticacao')} />
          </Row>
          <Spacing size="medium" />
          <Title bolder={true} appearance="primary">
            Olá, bem-vindo(a)!
          </Title>
          <Spacing size="x-small" />
          <Text>Preencha os campos abaixo, para que possamos dar continuidade ao pagamento</Text>
        </div>
        <Spacing size="xxx-large" />
        <div className={styles.content}>
          <div className={styles.contentRow}>
            <div className={styles.contentColumn}>
              <Input
                name="document"
                type="text"
                width="100%"
                placeholder="CPF"
                value={maskCPF(form.document)}
                onChange={(event) => {
                  setForm({ ...form, document: event.target.value });
                }}
              />
            </div>
            <div className={styles.contentColumn}>
              <Input
                name="phone"
                type="text"
                width="100%"
                placeholder="Telefone"
                value={form.phone}
                onChange={(event) => {
                  setForm({ ...form, phone: event.target.value });
                }}
              />
            </div>
          </div>
          <Spacing size="medium" />
          <Row width="100%" position="start">
            <Input
              name="email"
              type="text"
              width="100%"
              placeholder="E-mail"
              value={form.email}
              onChange={(event) => {
                setForm({ ...form, email: event.target.value });
              }}
            />
          </Row>
        </div>
        <Spacing size="xxx-large" />
        <div className={styles.cardFooter}>
          <Button
            text="Cancelar"
            width="90px"
            appearance="ghost"
            onClick={() => navigate('/detalhe')}
          />
          <Spacing size="large" />
          <Button text="Continuar" width="90px" appearance="primary" onClick={handleRegistration} />
        </div>
      </Card>
    </Layout>
  );
}

export default Registration;
