import React from 'react';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-onboarding-bro.svg';

function ContractUploadSuccess() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Upload do contrato realizado com sucesso!"
        description="Foi encaminhado para a empresa um e-mail para o cadastro dos beneficiários."
        image={feedback}
      />
    </Layout>
  );
}

export default ContractUploadSuccess;
