import { FaCheck } from 'react-icons/fa';
import { Step } from 'types/step';
import styles from './styles.module.scss';

type StepsProps = {
  steps: Step[];
};

function Steps({ steps }: StepsProps) {
  return (
    <div className={styles.container}>
      {steps.map((step, index) => (
        <div className={styles.step} key={index}>
          <div className={styles.wrapper}>
            <hr className={`${styles.line} ${index > 0 && styles[steps[index - 1].status]}`} />
            <div className={`${styles.circle} ${styles[step.status]}`}>
              {step.status === 'concluded' && <FaCheck />}
            </div>
            <hr className={`${styles.line} ${styles[step.status]}`} />
          </div>
          <p className={`${styles.title} ${styles[step.status]}`}>{step.title}</p>
        </div>
      ))}
    </div>
  );
}

export default Steps;
