/* eslint-disable react/jsx-no-constructed-context-values  */ // <-- ARRUMAR O CONTEXT
import axios from 'axios';
import React, { createContext, useEffect, useMemo, useState, useContext } from 'react';
import Product from 'views/Summary/components/Product';

export const DataContext = createContext({} as any);

export function DataProvider({ children }) {
  const [context, setContext] = useState({
    location: {
      postCode: '',
      city: ''
    },
    packages: [],
    products: [],
    info: {
      orderId: 0
    }
  });

  function addProduct(data: any) {
    const ifExists = context.products.some((product) => product.id === data.id);
    if (ifExists) {
      const item = context.products.find((product) => product.id === data.id);
      item.amount += 1;
      const index = context.products.findIndex((product) => product.id === data.id);
      context.products[index] = item;
      setContext({ ...context });
    } else {
      // eslint-disable-next-line no-param-reassign
      data.amount = 1;
      context.products.push(data);
    }
    setContext({ ...context });
    sessionStorage.setItem('@data', JSON.stringify(context));
  }

  function deleteProduct(id: number) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products.splice(index, 1);
    setContext({ ...context });
    sessionStorage.setItem('@data', JSON.stringify({ ...context }));
  }

  function handleData(data: any) {
    setContext({ ...context, ...data });
    sessionStorage.clear();
    sessionStorage.setItem('@data', JSON.stringify({ ...context, ...data }));
  }

  function updatePackages(data: any) {
    setContext({ ...context, packages: [...data] });
    sessionStorage.clear();
    sessionStorage.setItem('@data', JSON.stringify({ ...context, packages: [...data] }));
  }

  function updateLocation(data: any) {
    setContext({ ...context, location: { ...data } });
    sessionStorage.clear();
    sessionStorage.setItem('@data', JSON.stringify({ ...context, location: { ...data } }));
  }

  function updateOrderID(data: any) {
    setContext({ ...context, info: { ...data } });
    sessionStorage.clear();
    sessionStorage.setItem('@data', JSON.stringify({ ...context, info: { ...data } }));
  }

  useEffect(() => {
    const storage = JSON.parse(sessionStorage.getItem('@data'));
    if (storage && storage.products.length > 0) {
      setContext({ ...storage });
      sessionStorage.setItem('@data', JSON.stringify(storage));
    }
  }, []);

  return (
    <DataContext.Provider
      value={{
        context,
        setContext,
        handleData,
        updateLocation,
        addProduct,
        updatePackages,
        updateOrderID,
        deleteProduct
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useDataContext() {
  const t = useContext(DataContext);

  return t;
}
