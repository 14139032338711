import { useEffect, useState } from 'react';
import axios from 'axios';
import { FaTrashAlt } from 'react-icons/fa';
import Column from 'components/Column';
import styles from './styles.module.scss';
import fileIcon from '../../assets/icons/file.png';
import pdfLogo from '../../assets/logos/pdf_logo_black.png';

export default function FileUpload({ file, setFile, setImage }) {
  const [isAPDF, setIsAPDF] = useState(false);

  function loadImg(event: any) {
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const readerTarget = e.target;
      setImage(readerTarget.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  }

  useEffect(() => {
    if (file && typeof file === 'string') {
      axios
        .get(file)
        .then((response) => {
          if (response.headers['content-type'] === 'application/pdf') {
            setIsAPDF(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [file]);

  return (
    <>
      <label className={styles.fileBox}>
        {file !== '' ? (
          <>
            <img src={isAPDF ? pdfLogo : file} draggable="false" className={styles.displayImg} />
            <FaTrashAlt
              className={styles.trash}
              onClick={(e: any) => {
                setFile('');
              }}
            />
          </>
        ) : (
          <Column position="centered" width="100%" htmlFor="arquivo">
            <img src={fileIcon} />
            <input
              type="file"
              name="arquivo"
              id="arquivo"
              value={file}
              onChange={(event) => {
                setFile(event);
                loadImg(event);
              }}
              className={styles.fileInput}
            />
            <p className={styles.text}>
              <span>Clique aqui</span> ou arraste seu arquivo
            </p>
            <p className={`${styles.text} ${styles.grey}`}>.png ou .jpg</p>
          </Column>
        )}
      </label>
      {/* <i>
        <b>Motivo:</b> O documento apresenta avarias
      </i> */}
    </>
  );
}
