import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Undertitle } from '..';
import styles from './styles.module.scss';

function Dropdown({ title, children }: any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Undertitle bolder={true}>{title}</Undertitle>
        <div className={styles.icon} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {isOpen && <div className={styles.body}>{children}</div>}
    </div>
  );
}

export default Dropdown;
