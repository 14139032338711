import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProposalContext } from 'context/ProposalContext';
import { maskMoneyPlans, maskReset } from 'utils/mask';
import { FaDownload } from 'react-icons/fa';
import {
  editOrder,
  getCommercialApresentation,
  getPlanById,
  postOrder
} from 'api/services/ApiCalls';
import dayjs from 'dayjs';
import axios from 'axios';
import { useNavigationContext } from 'context/NavigationContext';
import styles from './styles.module.scss';
import {
  Layout,
  Row,
  Spacing,
  Title,
  Text,
  Card,
  Column,
  Description,
  Undertitle,
  BottomNavigation,
  Button
} from '../../components';
import check from '../../assets/icons/check-icon.png';
import error from '../../assets/icons/error-icon.png';
import { CoupomDivider } from './components';
import PlanMobile from './components/PlanMobile';

function ProposalSummary() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    cnpj: '',
    name: '',
    telephone: '',
    email: '',
    emailExtra1: '',
    emailExtra2: '',
    enterprise: '',
    agent: '',
    office: '',
    city: '',
    street: '',
    number: '',
    complement: '',
    state: '',
    zip_code: '',
    district: '',
    proposalExpiration: '',
    coupomName: '',
    coupomDescription: '',
    totalBeneficiaries: '',
    subtotal: '',
    coupomDiscount: ''
  });

  dayjs().format();
  dayjs.locale('pt-br');
  const currentDate = dayjs();
  const validDate = currentDate.add(20, 'day');
  const [planAtributes, setPlanAtributes] = useState(null);
  const { setOrder } = useProposalContext();
  const { Broker } = useNavigationContext();
  const [beneficiaries, setBeneficiaries] = useState(0);
  const [table, setTable] = useState([]);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function getPdf() {
    getCommercialApresentation()
      .then((response) => {
        const pdf = response.data.documentation[0];
        setUrl(pdf.documentation_path);
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  async function postCreateOrder() {
    const table = JSON.parse(sessionStorage.getItem('@table'));

    if (!loading) {
      setLoading(true);
      getPlanById(table[0].id).then(async (response) => {
        const dependents = [];
        table.map((plan: any) => {
          plan.dependents.map((dependent) => {
            dependents.push({ ...dependent, value: Number(dependent.plan_value) });
          });
        });

        if (isEditing) {
          const editingToken = sessionStorage.getItem('@editToken');
          const orderId = sessionStorage.getItem('@orderId');
          const orderData = JSON.parse(sessionStorage.getItem('@userData'));
          const body = {
            id: orderId,
            cnpj: maskReset(userData.cnpj),
            phone: maskReset(userData.telephone),
            email: userData.email,
            validity: orderData.validity,
            secondary_emails: [userData.emailExtra1, userData.emailExtra2],
            dependents,
            city: userData.city,
            responsible_office: userData.office,
            street: userData.street,
            zip_code: userData.zip_code && maskReset(userData.zip_code),
            district: userData.district,
            number: userData.number,
            invoice_due_date: userData.proposalExpiration,
            complement: userData.complement,
            state: userData.state,
            total: Number(userData.subtotal) - Number(userData.coupomDiscount),
            company_name: userData.enterprise,
            responsible_name: userData.agent,
            partner_slug: Broker.broker ? Broker.broker.slug : 'autoatendimento',
            coupom_name: userData.coupomName ? userData.coupomName : null,
            coupon_discount: userData.coupomDiscount ? userData.coupomDiscount : null,
            plan_type: response.data.plan[0].type,
            total_beneficiaries: userData.totalBeneficiaries
              ? userData.totalBeneficiaries
              : beneficiaries,
            subtotal: userData.subtotal,
            edit_token: editingToken,
            responsible_cpf: null,

            // TROCAR DEPOIS QUE FOR IMPLEMENTADO OS CAMPOS DE RESPONSAVEL FINANCEIRO
            financial_responsible_email: userData.email
          };
          try {
            const response = await editOrder(body);
            if (response.data.success) {
              navigate('/proposta/editar/sucesso');
            }
          } catch (error) {
            console.error(error.response.data.error);
            setLoading(false);
          }
        } else {
          const orderData = JSON.parse(sessionStorage.getItem('@userData'));
          const body = {
            cnpj: maskReset(userData.cnpj),
            phone: maskReset(userData.telephone),
            email: userData.email,
            secondary_emails: [userData.emailExtra1, userData.emailExtra2],
            dependents,
            city: userData.city,
            street: userData.street,
            validity: orderData.validity,
            zip_code: userData.zip_code && maskReset(userData.zip_code),
            district: userData.district,
            responsible_office: userData.office,
            number: userData.number,
            invoice_due_date: userData.proposalExpiration,
            complement: userData.complement,
            state: userData.state,
            total: Number(userData.subtotal) - Number(userData.coupomDiscount),
            company_name: userData.enterprise,
            responsible_name: userData.agent,
            partner_slug: Broker.broker ? Broker.broker.slug : 'autoatendimento',
            coupom_name: userData.coupomName ? userData.coupomName : null,
            coupon_discount: userData.coupomDiscount ? userData.coupomDiscount : null,
            plan_type: response.data.plan[0].type,
            total_beneficiaries: userData.totalBeneficiaries
              ? userData.totalBeneficiaries
              : beneficiaries,
            subtotal: userData.subtotal
          };

          try {
            const response = await postOrder(body);
            setOrder(response.data.order);

            if (response.data.success) {
              sessionStorage.setItem('@orderData', JSON.stringify(response.data.order));
              const broker = sessionStorage.getItem('@brokerParams');
              const brokerRoute = sessionStorage.getItem('@brokerRoute');
              if (broker && brokerRoute === 'criar pedido') {
                navigate('/proposta/enviada/consultor');
                setLoading(false);
              } else {
                navigate('/proposta/enviada');
                setLoading(false);
              }
            }
          } catch (error) {
            console.error(error.response.data.error);
            setLoading(false);
          }
        }
      });
    }
  }

  function InstitutionalInformationDownload() {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Informativo Institucional.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }

  useEffect(() => {
    const data = sessionStorage.getItem('@userData');
    const isEditing = sessionStorage.getItem('@editToken');
    if (isEditing) {
      setIsEditing(true);
    }
    const storageTotal = JSON.parse(sessionStorage.getItem('@totalValue'));
    const plans = JSON.parse(sessionStorage.getItem('@table'));
    setTable(plans);
    getPdf();
    let lives = 0;
    plans.map((plan: any) => {
      plan.dependents.map((dependent: any) => {
        lives = lives + Number(dependent.dependents) + Number(dependent.owners);
      });
    });
    if (data) {
      const parsedData = JSON.parse(data);
      setUserData({
        cnpj: parsedData.cnpj,
        name: parsedData.name,
        telephone: parsedData.telephone,
        email: parsedData.email,
        emailExtra1: parsedData.emailExtra1,
        emailExtra2: parsedData.emailExtra2,
        enterprise: parsedData.enterprise,
        agent: parsedData.agent,
        office: parsedData.office,
        city: parsedData.city,
        street: parsedData.street,
        number: parsedData.number,
        complement: parsedData.complement,
        state: parsedData.state,
        zip_code: parsedData.zip_code,
        district: parsedData.district,
        proposalExpiration: parsedData.proposalExpiration,
        coupomName: parsedData.coupomName,
        coupomDescription: parsedData.coupomDescription,
        coupomDiscount: parsedData.coupomDiscount,
        totalBeneficiaries: lives.toString(),
        subtotal: storageTotal
      });
    }
    const atributes = sessionStorage.getItem('@plan_attributes');
    if (atributes) {
      const parsedAtributes = JSON.parse(atributes);
      setPlanAtributes(parsedAtributes);
    }
    setBeneficiaries(lives);
  }, []);

  return (
    <Layout navbar={true} goBackButton={false}>
      <Spacing size="medium" />
      <Title appearance="primary" bolder={true}>
        Análise de informações da empresa
      </Title>
      <Spacing size="xx-small" />
      <Text>Confirme se todos os dados estão corretos antes de prosseguir</Text>
      <Spacing size="x-large" />
      <Card appearance="ghost" width="94%">
        <Row>
          <Column>
            <Title bolder={true}>Proposta Comercial</Title>
            <Spacing size="xxx-small" />
          </Column>
          <Column position="end">
            <Description>Data da proposta: {dayjs(currentDate).format('DD/MM/YYYY')}</Description>
            <Spacing size="xxx-small" />
            <Description>Proposta válida até: {dayjs(validDate).format('DD/MM/YYYY')}</Description>
          </Column>
        </Row>
        <Spacing size="medium" />
        <Card appearance="ghost">
          <Row position="start">
            <Undertitle appearance="grey3" bolder={true}>
              Informações da empresa
            </Undertitle>
          </Row>
          <Spacing size="medium" />
          <Row>
            <Column width="50%">
              <Description appearance="grey2">CNPJ</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.cnpj}
              </Text>
            </Column>
            <Column>
              <Description appearance="grey2">Nome da Empresa</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.enterprise}
              </Text>
            </Column>
          </Row>
          <Spacing size="large" />
          <Row>
            <Column>
              <Description appearance="grey2">Município</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.city}
              </Text>
            </Column>
            <Column width="99%">
              <Description appearance="grey2">Bairro</Description>
              <Spacing size="xxx-small" />
              <Text textAlign="left" appearance="grey3" bolder={true}>
                {userData.district}
              </Text>
            </Column>
            <Column>
              <Description appearance="grey2">CEP</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.zip_code}
              </Text>
            </Column>
          </Row>
          <Spacing size="large" />
          <Row>
            <Column width="102%">
              <Description appearance="grey2">Representante</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.agent}
              </Text>
            </Column>
            <Column>
              <Description appearance="grey2">Telefone</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.telephone}
              </Text>
            </Column>
            <Column width="102%">
              <Description appearance="grey2">E-mail</Description>
              <Spacing size="xxx-small" />
              <Text appearance="grey3" bolder={true}>
                {userData.email}
              </Text>
            </Column>
          </Row>
        </Card>

        <Spacing size="medium" />

        <Card appearance="ghost">
          {width >= 1000 ? (
            <Row position="start">
              <Undertitle appearance="grey3" bolder={true}>
                Dados da Simulação
              </Undertitle>
            </Row>
          ) : (
            <Title appearance="grey3" bolder={true} textAlign="start" width="100%">
              Dados da Simulação
            </Title>
          )}
          <Spacing size="medium" />

          {width <= 1000 ? (
            <PlanMobile plans={table} />
          ) : (
            table &&
            table.map((elem: any, key: number) => (
              <div key={key} style={{ marginBottom: '50px' }}>
                <Spacing size="xxx-large" />
                <Row>
                  <Column width="120%">
                    <Description appearance="grey" bolder={true}>
                      Plano
                    </Description>
                  </Column>
                  <Column width="70%" position="centered">
                    <Description appearance="grey" bolder={true}>
                      Faixa etária
                    </Description>
                  </Column>
                  <Column width="60%" position="centered">
                    <Description appearance="grey" bolder={true}>
                      Qnt. Titular
                    </Description>
                  </Column>
                  <Column position="centered">
                    <Description appearance="grey" bolder={true}>
                      Qnt. Dependente
                    </Description>
                  </Column>
                  <Column position="centered">
                    <Description appearance="grey" bolder={true}>
                      Valor do plano
                    </Description>
                  </Column>
                  <Column position="end">
                    <Description appearance="grey" bolder={true}>
                      Valor total
                    </Description>
                  </Column>
                </Row>
                <Spacing size="small" />
                {elem.dependents.map((dependent, key) => (
                  <>
                    <Row key={key}>
                      <Column width="120%">
                        <Text textAlign="start">{elem.plan}</Text>
                      </Column>
                      <Column width="70%" position="centered">
                        <Text>{dependent.age}</Text>
                      </Column>
                      <Column width="60%" position="centered">
                        <Text>{dependent.owners}</Text>
                      </Column>
                      <Column position="centered">
                        <Text>{dependent.dependents}</Text>
                      </Column>
                      <Column position="centered">
                        <Text>R${maskMoneyPlans(Number(dependent.plan_value))}</Text>
                      </Column>
                      <Column position="end">
                        <Text>R${maskMoneyPlans(Number(dependent.totalValue))}</Text>
                      </Column>
                    </Row>
                    <Spacing size="medium" />
                  </>
                ))}
              </div>
            ))
          )}

          {/* <Spacing size="large" />
          <Card appearance="warning" padding="12px">
            <Row position="start">
              <Description appearance="grey">
                Cupom UNIMEDMAIS5 adicionado!
              </Description>
            </Row>
          </Card> */}
          <Spacing size="x-large" />
          {userData.coupomName ? (
            <>
              <Row>
                <CoupomDivider
                  name={userData.coupomName}
                  description={userData.coupomDescription}
                />
              </Row>
              <Spacing size="large" />
            </>
          ) : (
            ''
          )}

          <Card appearance="card" padding={width <= 1000 ? '18px 12px' : '24px'}>
            {width <= 1000 ? (
              <>
                <div className={styles.row} style={{ justifyContent: 'space-between' }}>
                  <Column
                    width="50%"
                    justifyContent="flex-start"
                    position="flex-start"
                    paddingRight="0px"
                  >
                    <Description appearance="grey" textAlign="start">
                      Subtotal
                    </Description>
                  </Column>
                  <Column
                    width="50%"
                    justifyContent="flex-end"
                    position="flex-end"
                    paddingRight="0px"
                  >
                    <Description appearance="grey" textAlign="end">
                      R$ {maskMoneyPlans(Number(userData.subtotal))}
                    </Description>
                  </Column>
                </div>
                <Spacing size="xx-small" />
                <div className={styles.row} style={{ justifyContent: 'space-between' }}>
                  <Column width="100%" justifyContent="flex-start" position="flex-start">
                    <Description appearance="grey" textAlign="start">
                      Desconto
                    </Description>
                  </Column>
                  <Column
                    width="100%"
                    justifyContent="flex-end"
                    position="flex-end"
                    paddingRight="0px"
                  >
                    <Description appearance="grey" textAlign="end">
                      - R$ {maskMoneyPlans(Number(userData.coupomDiscount))}
                    </Description>
                  </Column>
                </div>
                <Spacing size="xx-small" />
                <div className={styles.row} style={{ justifyContent: 'space-between' }}>
                  <Column width="100%" justifyContent="flex-start" position="flex-start">
                    <Text appearance="grey" textAlign="start" bolder={true}>
                      Valor Total
                    </Text>
                  </Column>
                  <Column
                    width="100%"
                    justifyContent="flex-end"
                    position="flex-end"
                    paddingRight="0px"
                  >
                    <Description appearance="grey" textAlign="end" bolder={true}>
                      R$
                      {maskMoneyPlans(
                        Number(userData.subtotal) - Number(userData.coupomDiscount) > 0
                          ? Number(userData.subtotal) - Number(userData.coupomDiscount)
                          : 0
                      )}
                    </Description>
                  </Column>
                </div>
              </>
            ) : (
              <>
                <Row position="end">
                  <Column width="60px" position="end">
                    <Description appearance="grey">Subtotal</Description>
                  </Column>
                  <Column width="90px" position="end">
                    <Description appearance="grey">
                      R$ {maskMoneyPlans(Number(userData.subtotal))}
                    </Description>
                  </Column>
                </Row>
                <Spacing size="small" />
                {userData.coupomDiscount !== '' && (
                  <Row position="end">
                    <Column width="60px" position="end">
                      <Description appearance="grey">Desconto</Description>
                    </Column>
                    <Column width="90px" position="end">
                      <Description appearance="grey">
                        - R$ {maskMoneyPlans(Number(userData.coupomDiscount))}
                      </Description>
                    </Column>
                  </Row>
                )}
                <Spacing size="small" />
                <Row position="end">
                  <Column width="70px" position="end">
                    <Description appearance="grey">Valor Total</Description>
                  </Column>
                  <Column width="90px" position="end">
                    <Description bolder={true}>
                      R$
                      {maskMoneyPlans(
                        Number(userData.subtotal) - Number(userData.coupomDiscount) > 0
                          ? Number(userData.subtotal) - Number(userData.coupomDiscount)
                          : 0
                      )}
                    </Description>
                  </Column>
                </Row>
              </>
            )}

            <Spacing size="large" />
            <Row position="end">
              <Description>
                Esta proposta possui um número mínimo de <b>{beneficiaries} vidas</b>para serem
                cobradas.
              </Description>
            </Row>
          </Card>
        </Card>

        <Spacing size="medium" />

        <Card appearance="ghost">
          <Row position="start">
            <Undertitle appearance="grey3" bolder={true}>
              Plano(s) Selecionado(s)
            </Undertitle>
          </Row>
          <Spacing size="x-large" />
          <div className={styles.container}>
            {table &&
              table.map((item, key) => (
                <>
                  <Card width="100%" appearance="ghost" key={key}>
                    <div className={styles.plansAtributes}>
                      <Undertitle appearance="primary" padding="10px 0px" bolder={true}>
                        {item.plan}
                      </Undertitle>
                      <Spacing size="medium" />
                      <Row position="start" align="start">
                        <Column justifyContent="start">
                          <Description bolder={true}>Coberturas do Plano</Description>
                          <Spacing size="x-small" />
                          {item.coparticipation.map(
                            (coparticipation, key) =>
                              coparticipation.status === 1 &&
                              coparticipation.type === 'cobertura' && (
                                <div className={styles.atributes} key={key}>
                                  <Spacing size="x-small" />
                                  <Text appearance="grey" textAlign="left">
                                    <img
                                      src={coparticipation.status === 1 ? check : error}
                                      style={{
                                        width: '15px',
                                        marginRight: '8px',
                                        color: 'green',
                                        marginTop: '3px',
                                        alignSelf: 'flex-start'
                                      }}
                                    />

                                    {coparticipation.co_participation_name}
                                  </Text>
                                  <Spacing size="xx-small" />
                                </div>
                              )
                          )}
                        </Column>

                        <Spacing size="x-large" />

                        <Column justifyContent="start">
                          <Description bolder={true}>Coparticipações</Description>
                          <Spacing size="x-small" />
                          {item.coparticipation &&
                            item.coparticipation.map((coparticipation, key) => {
                              if (
                                coparticipation.status === 1 &&
                                coparticipation.type === 'coparticipacao'
                              ) {
                                return (
                                  <div className={styles.atributes} key={key}>
                                    <Row width="100%" position="start" align="start">
                                      <Text
                                        appearance="grey"
                                        textAlign="start"
                                        position="start"
                                        padding="0px"
                                      >
                                        <img
                                          src={check}
                                          style={{
                                            width: '15px',
                                            marginRight: '8px',
                                            color: 'green',
                                            marginTop: '3px',
                                            alignSelf: 'flex-start'
                                          }}
                                        />
                                        {coparticipation.co_participation_name}
                                      </Text>
                                    </Row>
                                    <Spacing size="xx-small" />
                                  </div>
                                );
                              }
                            })}
                        </Column>
                      </Row>
                    </div>
                  </Card>
                  <Spacing size="medium" />
                </>
              ))}
          </div>
        </Card>

        <Spacing size="medium" />

        {/* <Card appearance="ghost">
          <Row position="start">
            <Undertitle appearance="grey3" bolder={true}>
              Termos de carência
            </Undertitle>
          </Row>
          <Spacing size="x-large" />
          <Row position="start">
            <Text position="start" maxWidth="100%" appearance="grey" textAlign="left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor etiam nisi, egestas
              libero. A sem eu at et mauris. Aliquam risus at nunc nunc, neque, quis sed vulputate
              nulla. Tincidunt faucibus nunc malesuada tincidunt sit feugiat. Nulla faucibus tortor
              ipsum facilisis erat amet. Molestie mattis senectus blandit tellus semper nulla sociis
              fermentum, risus. Pulvinar lectus et eget risus, morbi consequat vitae. Consequat
              mauris congue ut augue. Bibendum a consectetur cras urna. Eu facilisis condimentum ut
              odio rhoncus sed. Amet sed fames nulla at amet. Nunc proin pellentesque sagittis
              lectus et. Orci pharetra, in nunc hac. Lorem maecenas tellus tellus morbi auctor
              ullamcorper sit. Massa lacinia in in nec sit aliquam aliquet quisque. Quisque in neque
              leo sed vitae purus phasellus lectus. Sem adipiscing in ac maecenas odio auctor. Quam
              sit risus tempor diam pretium laoreet eu pulvinar. Sed eget vitae mi eu volutpat, urna
              morbi ac volutpat. A sed velit cursus nunc, id porttitor. Aliquam, tempus viverra
              morbi ultricies. Est ac diam lorem ut et in ullamcorper. Mauris at leo faucibus
              aliquam fringilla. Nisl, lectus urna, elit libero, sapien enim, lorem. Lectus aliquam
              faucibus lorem metus at leo ac elementum. Vel non, sapien cras est at morbi nisi. Eget
              sodales tellus auctor dui. Rhoncus sapien faucibus vel bibendum enim laoreet at
              facilisis. Hac feugiat euismod aliquam non in. Nisl venenatis fames vel ultrices
              mauris, vulputate. Sit quis eu leo tortor, elit nulla semper etiam. Et aliquam auctor
              aliquam mi elit amet mauris sit volutpat. Pulvinar vitae eget proin mattis vulputate
              laoreet ante porta hendrerit.
            </Text>
          </Row>
        </Card> */}
      </Card>

      <Spacing size="large" />
      <Row position="end" width="95%">
        <Button
          text="Informativo Institucional"
          appearance="ghost"
          iconPosition="left"
          width="240px"
          onClick={() => InstitutionalInformationDownload()}
        >
          <FaDownload />
        </Button>
      </Row>
      <Spacing size="xxx-large" />

      <Row width="95%">
        <BottomNavigation
          labelLeft="Voltar para Editar Proposta"
          appearanceLeft="ghost"
          actionLeft={() => navigate(-1)}
          labelRight={isEditing ? 'Reenviar Proposta' : 'Enviar Proposta'}
          appearanceRight={!loading ? 'primary' : 'disabled'}
          actionRight={() => {
            if (!loading) {
              postCreateOrder();
            }
          }}
          loadingRight={loading}
        />
      </Row>
    </Layout>
  );
}

export default ProposalSummary;
