import React from 'react';
import styles from './styles.module.scss';

function Column({
  width = '100%',
  position = 'start',
  justifyContent = 'center',
  paddingRight = '10px',
  children,
  borderRadius = '0'
}: any) {
  return (
    <div
      className={`
        ${styles.container} 
        ${position === 'start' && styles.start} 
        ${position === 'centered' && styles.centered} 
        ${position === 'end' && styles.end} 
        `}
      style={{
        width: width && width,
        borderRadius: borderRadius && borderRadius,

        paddingRight: paddingRight && paddingRight,
        justifyContent: justifyContent && justifyContent
      }}
    >
      {children}
    </div>
  );
}

export default Column;
