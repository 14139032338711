import error from '../../assets/icons/excplamation-icon.png';
import success from '../../assets/icons/success-icon.png';
import warning from '../../assets/icons/warning-icon.png';
import styles from './styles.module.scss';

export type AlertProps = {
  type: 'success' | 'error' | 'warning';
  title: string;
  text: string;
};

export type AlertState = {
  show: boolean;
} & AlertProps;

function Alert({ type, title, text }: AlertProps) {
  return (
    <div className={`${styles.alertComponent} ${styles[type]} `}>
      {type === 'success' && (
        <img src={success} style={{ width: '18px', height: '18px' }} alt="" draggable="false" />
      )}
      {type === 'warning' && (
        <img src={warning} style={{ width: '18px', height: '18px' }} alt="" draggable="false" />
      )}
      {type === 'error' && (
        <img src={error} style={{ width: '18px', height: '18px' }} alt="" draggable="false" />
      )}
      <div className={styles.column}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Alert;
