/* eslint-disable import/export */
import { Column, Description, Input, Spacing, Text } from 'components';
import { useProposalContext } from 'context/ProposalContext';
import { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { maskMoneyPlans } from 'utils/mask';
import styles from './styles.module.scss';

function TableLine({
  width,
  index,
  plans,
  plan,
  owners,
  content,
  dependents,
  age,
  totalValue,
  planValue,
  onChangeOwners,
  onChangeDependents
}) {
  const { formPlans } = useProposalContext();

  function correctPlanId() {
    if (plans) {
      const result = plans.find(({ value }) => value === plan);
      return result;
    } else {
      return 0;
    }
  }

  function maskMoneyPlansLocal(value) {
    if (value) {
      return `R$ ${value}`;
    }
  }

  useEffect(() => {
    const newValue = [...formPlans];
    const planId = correctPlanId();
    newValue[index].plan_id = planId?.id;
  }, [owners, age, plan, dependents, totalValue]);

  return (
    <div className={styles.row}>
      <Column width={width >= 900 ? '50%' : '55%'}>
        <Text>{age}</Text>
        <Spacing size="xxx-small" />
        <Description appearance="grey">{maskMoneyPlansLocal(planValue)}</Description>
      </Column>
      <Column width="50%" paddingRight={width >= 900 ? '10px' : '0px'}>
        <Input
          name="plan"
          type="text"
          position="center"
          width="60%"
          value={owners}
          onChange={(event) => {
            if (event.target.value === '') {
              onChangeOwners(0, plan, content.id, content.plan_value, content.dependents);
            }
            if (Number(event.target.value) >= 0 && Number(event.target.value) <= 1000) {
              onChangeOwners(
                parseInt(event.target.value, 10),
                plan,
                content.id,
                content.plan_value,
                content.dependents
              );
            }
          }}
        />
      </Column>
      <Column width="50%" paddingRight={width >= 900 ? '10px' : '0px'}>
        <Input
          name="plan"
          type="text"
          position="center"
          width="60%"
          value={dependents}
          onChange={(event) => {
            if (event.target.value === '') {
              onChangeDependents(0, plan, content.id, content.plan_value, content.owners);
            }
            if (Number(event.target.value) >= 0 && Number(event.target.value) <= 1000) {
              onChangeDependents(
                parseInt(event.target.value, 10),
                plan,
                content.id,
                content.plan_value,
                content.owners
              );
            }
          }}
        />
      </Column>
      <Column width={width >= 900 ? '100%' : '80%'} paddingRight="0px" justifyContent="center">
        <div className={styles.totalValueField}>
          {maskMoneyPlansLocal(maskMoneyPlans(Number(totalValue)))}
        </div>
      </Column>
    </div>
  );
}

function TableMobile({
  width,
  content,
  plan,
  plans,
  onChangeOwners,
  onChangeDependents,
  onClickDelete
}) {
  return (
    <div className={styles.container}>
      <div className={styles.row} style={{ width: '100%' }}>
        <Description appearance="grey">Selecione o Plano</Description>
        <FaTrashAlt onClick={onClickDelete} className={styles.trash} />
      </div>
      <Spacing size="small" />
      <div className={styles.row}>
        <Input name="plan" type="text" width="100%" value={plan} disabled={true} />
      </div>
      <div className={styles.row}>
        <Column width="55%" justifyContent="flex-start" position="flex-start">
          <Description appearance="grey" textAlign="start">
            Faixa Etária/R$
          </Description>
        </Column>
        <Column width="60%" justifyContent="center" position="center">
          <Description appearance="grey">Qnt. Tit.</Description>
        </Column>
        <Column width="50%" justifyContent="flex-start" position="flex-start">
          <Description appearance="grey">Qnt. Dep.</Description>
        </Column>
        <Column width="100%" justifyContent="flex-start" position="flex-start">
          <Description appearance="grey">Valor Total</Description>
        </Column>
      </div>
      <Spacing size="xx-small" />
      {plans.map((plan, key) => (
        <TableLine
          width={width}
          key={key}
          content={plan}
          onChangeOwners={onChangeOwners}
          onChangeDependents={onChangeDependents}
          owners={plan.owners}
          dependents={plan.dependents}
          index={key}
          age={plan.age}
          plan={plan.plan}
          plans={content}
          planValue={plan.plan_value}
          totalValue={plan.totalValue}
        />
      ))}
    </div>
  );
}

export default TableMobile;
