import { useNavigate } from 'react-router-dom';
import feedback from '../../assets/sulmed-feedback-concluded-payment.png';
import { Feedback, Layout } from '../../components';

function ConcludedPayment() {
  const navigate = useNavigate();

  return (
    <Layout
      navbar={true}
      verticalCentered={true}
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Contrato', status: 'concluded' },
        { title: 'Cadastro Beneficiários', status: 'concluded' },
        { title: 'Análise Beneficiários', status: 'concluded' },
        { title: 'Implantação', status: 'inProgress' }
      ]}
    >
      <Feedback
        title="Pagamento Realizado!"
        description="Sua empresa agora faz parte de uma das maiores redes de Saúde do Brasil"
        image={feedback}
        buttonText="Voltar para Home"
        onClickButton={() => navigate('/')}
        underbuttonText="Ir para Minha Vendas"
        onClickUnderbutton={() => navigate('/')}
      />
    </Layout>
  );
}

export default ConcludedPayment;
