import {
  concludeReviewBeneficiaries,
  getOrderData,
  updateBeneficiariesStatus
} from 'api/services/ApiCalls';
import { AlertState } from 'components/Alert';
import { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { maskCPF, maskCnpj } from 'utils/mask';
import {
  Alert,
  BottomNavigation,
  Button,
  Card,
  Column,
  Description,
  Layout,
  Modal,
  Row,
  Spacing,
  Spinner,
  Text,
  Title
} from '../../components';
import styles from './styles.module.scss';

import checkicon from '../../assets/icons/check-icon.png';
import clockicon from '../../assets/icons/clock-icon.png';
import warningicon from '../../assets/icons/warning-icon.png';
import erroricon from '../../assets/icons/error-icon.png';
import mail from '../../assets/sulmed-feedback-mail.png';

function ReviewBeneficiaries() {
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalConcludedIsOpen, setModalConcludedIsOpen] = useState(false);
  const [order, setOrder] = useState<any>();
  const [holders, setHolders] = useState([]);
  const [holdersWaiting, setHoldersWaiting] = useState([]);
  const [alertData, setAlertData] = useState<AlertState | null>(null);

  function reviewBeneficiaries(holders) {
    const status = [];
    holders.map((holder: any) => {
      if (holder.status === 6 || holder.status === 0) {
        status.push(true);

        if (holder.status === 0) {
          const formData = new FormData();
          formData.append('holder_id', holder.id);
          formData.append('beneficiaries_status', '7');

          updateBeneficiariesStatus(formData)
            .then(() => {})
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        status.push(false);
      }
    });

    if (status.includes(false)) {
      return false;
    } else {
      return true;
    }
  }

  function concludeReview() {
    if (reviewBeneficiaries(order.holders)) {
      const formData = new FormData();
      formData.append('order_id', order.id);
      concludeReviewBeneficiaries(formData).then(() => {
        navigate('/revisao/beneficiarios/sucesso');
      });
    } else {
      setAlertData({
        title: 'Atenção!',
        type: 'warning',
        text: 'Todos os cadastros precisam estar aprovados para prosseguir',
        show: true
      });
      setTimeout(() => {
        setAlertData(null);
      }, 5500);
    }
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getData(jwtParsed) {
    getOrderData(jwtParsed.id)
      .then((response) => {
        setOrder(response.data.order_data.order);
        const holdersWaiting = [];
        const holdersProcessing = [];
        response.data.order_data.order?.holders.map((holder) => {
          if (holder.status === 0 || holder.status === 1) {
            holdersWaiting.push(holder);
          } else {
            holdersProcessing.push(holder);
          }
        });
        setHoldersWaiting(holdersWaiting);
        setHolders(holdersProcessing);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      setId(jwtParsed.id);
      getData(jwtParsed);
    }
  }

  useEffect(() => {
    getParams();
  }, []);

  return (
    <Layout
      navbar={true}
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Contrato', status: 'concluded' },
        { title: 'Cadastro Beneficiários', status: 'concluded' },
        { title: 'Análise Beneficiários', status: 'inProgress' },
        { title: 'Implantação', status: 'pending' }
      ]}
    >
      {alertData && <Alert type={alertData.type} title={alertData.title} text={alertData.text} />}

      <Modal
        isOpen={modalIsOpen}
        image={mail}
        title="Analisar os documentos"
        description="Enviamos para o e-mail dos beneficiários cadastrados, um link para que eles possam adicionar os documentos restantes"
        onClickClose={() => setModalIsOpen(false)}
        footerButtonFirst="Analisar os documentos"
        onClickFooterFirst={() => {}}
        footerButtonSecond="Ir para a Minhas Vendas"
        onClickFooterSecond={() => {}}
      />

      <Modal
        height="200px"
        isOpen={modalConcludedIsOpen}
        title="Analise concluída"
        description=""
        onClickClose={() => setModalConcludedIsOpen(false)}
        footerButtonFirst="Ok"
        onClickFooterFirst={() => setModalConcludedIsOpen(false)}
        // footerButtonSecond="Ir para a Minhas Vendas"
        // onClickFooterSecond={() => {}}
      />

      {order ? (
        <>
          <Spacing size="medium" />
          <Title appearance="primary" bolder={true}>
            Analise as informações recebidas
          </Title>
          <Spacing size="x-small" />
          <Text>
            Acompanhe aqui o envio de documentação dos beneficiários. Para analisar a documentação,
            basta clicar em “visualizar informações”.
          </Text>
          <Spacing size="xx-large" />
          <Card appearance="ghost" width="97%">
            <Row position="start">
              <Text bolder={true}>Informações da empresa</Text>
            </Row>
            <Spacing size="medium" />
            <Row width="100%">
              <Column>
                <Description textAlign="start">Razão Social</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true} textAlign="start">
                  {order.company_name}
                </Text>
              </Column>
              <Column>
                <Description textAlign="start">CNPJ</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true} textAlign="start">
                  {maskCnpj(order.cnpj)}
                </Text>
              </Column>
            </Row>
          </Card>

          <Spacing size="xx-large" />
          <Row width="97%" position="start">
            <Text bolder={true} padding="0px 6px">
              Cadastros em Análise
            </Text>
          </Row>
          <Spacing size="x-small" />
          <Card appearance="ghost" width="97%" padding="0px">
            <div className={styles.header}>
              <Column width="140px">
                <Description appearance="primaryDark" textAlign="start">
                  Documentação
                </Description>
              </Column>
              <Column width="150px">
                <Description appearance="primaryDark" textAlign="start">
                  CPF
                </Description>
              </Column>
              <Column width="160px">
                <Description appearance="primaryDark" textAlign="start">
                  Nome
                </Description>
              </Column>
              <Column width="230px">
                <Description appearance="primaryDark" textAlign="start">
                  Contato
                </Description>
              </Column>
              <Column width="200px">
                <Description appearance="primaryDark" textAlign="start">
                  Ações
                </Description>
              </Column>
            </div>
            {holders.length > 0 ? (
              holders.map((elem, key) => (
                <div className={styles.body} key={key}>
                  <Column width="140px">
                    <div className={styles.documentation}>
                      {elem.status === 0 && (
                        <>
                          <img src={warningicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Aguardando</Description>
                        </>
                      )}
                      {elem.status === 1 && (
                        <>
                          <img src={clockicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">DPS</Description>
                        </>
                      )}
                      {elem.status === 2 && (
                        <>
                          <img src={clockicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Envio da Documentação</Description>
                        </>
                      )}
                      {elem.status === 3 && (
                        <>
                          <img src={warningicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Em Análise</Description>
                        </>
                      )}
                      {elem.status === 4 && (
                        <>
                          <img src={clockicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Aguardando Reenvio</Description>
                        </>
                      )}
                      {elem.status === 5 && (
                        <>
                          <img src={clockicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Reenviado</Description>
                        </>
                      )}
                      {elem.status === 6 && (
                        <>
                          <img src={checkicon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Aprovado</Description>
                        </>
                      )}
                      {elem.status === 7 && (
                        <>
                          <img src={erroricon} />
                          <Spacing size="xxx-small" />
                          <Description textAlign="start">Cancelado</Description>
                        </>
                      )}
                    </div>
                  </Column>
                  <Column width="150px">
                    <Text appearance="grey" textAlign="start">
                      {maskCPF(elem.cpf)}
                    </Text>
                  </Column>
                  <Column width="160px" paddingRight="0px">
                    <Text textAlign="start">{elem.name}</Text>
                  </Column>
                  <Column width="230px" justifyContent="start" position="start">
                    <p className={styles.beneficiarieEmail}>{elem.email}</p>
                  </Column>
                  <Column width="200px">
                    <Text
                      textAlign="start"
                      bolder={true}
                      pointer={true}
                      appearance="primary"
                      onClick={() => {
                        sessionStorage.setItem('@holder', elem.id);
                        navigate('/revisao/titular');
                      }}
                    >
                      Visualizar Informações <FaChevronRight style={{ marginLeft: '7px' }} />
                    </Text>
                  </Column>
                </div>
              ))
            ) : (
              <Card appearance="ghost" width="100%">
                <Row position="centered">
                  <Text maxWidth="240px" textAlign="center">
                    Você ainda não possui documentos para análise
                  </Text>
                </Row>
              </Card>
            )}
          </Card>
          <Spacing size="xx-large" />

          <Row width="97%" position="start">
            <Text bolder={true} padding="0px 6px">
              Cadastros Pendentes
            </Text>
          </Row>
          <Spacing size="x-small" />
          <Card appearance="ghost" width="97%" padding="0px">
            <div className={styles.header}>
              <Column width="190px">
                <Description textAlign="start" appearance="primaryDark">
                  CPF
                </Description>
              </Column>
              <Column width="150px">
                <Description textAlign="start" appearance="primaryDark">
                  Nome
                </Description>
              </Column>
              <Column width="130px" justifyContent="center" position="centered">
                <Description textAlign="start" appearance="primaryDark">
                  Dependentes
                </Description>
              </Column>
              <Column width="260px">
                <Description textAlign="start" appearance="primaryDark">
                  Contato
                </Description>
              </Column>
              <Column width="300px">
                <Description textAlign="start" appearance="primaryDark">
                  Documentação
                </Description>
              </Column>
            </div>
            {holdersWaiting.length > 0 ? (
              holdersWaiting.map((elem, key) => (
                <div className={styles.body} key={key}>
                  <Column width="190px">
                    <Text textAlign="start" bolder={true}>
                      {maskCPF(elem.cpf)}
                    </Text>
                  </Column>
                  <Column width="150px">
                    <p className={styles.beneficiarieEmail}>{elem.name}</p>
                  </Column>
                  <Column width="130px" justifyContent="center" position="centered">
                    <Text textAlign="center">
                      {elem?.dependents ? elem?.dependents?.length : 0}
                    </Text>
                  </Column>
                  <Column width="260px" justifyContent="start" position="start">
                    <p className={styles.beneficiarieEmail}>{elem.email}</p>
                  </Column>
                  <Column width="300px">
                    <div className={styles.documentation}>
                      <img src={warningicon} alt="warning icon" />
                      <Description>Aguardando envio de documentação</Description>
                    </div>
                  </Column>
                </div>
              ))
            ) : (
              <Card appearance="ghost" width="100%" textAlign="center">
                <Row position="centered" width="100%">
                  <Text maxWidth="240px" textAlign="center">
                    Você não possui cadastros pendentes
                  </Text>
                </Row>
              </Card>
            )}
          </Card>

          <Spacing size="xxx-large" />

          <Row width="97%">
            <BottomNavigation
              labelRight="Concluir Análise"
              appearanceRight={order.holders.length > 0 ? 'primary' : 'disabled'}
              actionRight={() => {
                if (order.holders.length > 0) {
                  concludeReview();
                }
              }}
            />
          </Row>
        </>
      ) : (
        <Row width="97%" align="center">
          <Spacing size="xxx-large" />
          <Column width="100%" position="centered">
            <Spacing size="xxx-large" />
            <Spinner show={true} />
            <Spacing size="medium" />
            <Description appearance="primary" bolder={true}>
              Carregando os dados
            </Description>
          </Column>
        </Row>
      )}
    </Layout>
  );
}

export default ReviewBeneficiaries;
