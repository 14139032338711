import { maskReset } from 'utils/mask';
import { api, apiCep } from './index';

export function getPackagesCategories() {
  return api.get('package-categories');
}

export function getPackagesById(id: string) {
  return api.get(`packages/${id}`);
}

export function getActivePackagesById(id: string) {
  return api.get(`packages-id/${id}`);
}

export function getPackagesByRegions(cep: string) {
  return api.get(`packages-by-regions/${cep}`);
}

export function getActiveExams() {
  return api.get('exams');
}

export function getExamsById(id: string) {
  return api.get(`exam/${id}`);
}

export function getAddressByCep(cep: string) {
  return api.get(`address/${cep}`);
}

export function getStates() {
  return api.get('states');
}

export function getCities() {
  return api.get('cities');
}

export function getCitiesByStateId(id: string) {
  return api.get(`cities/${id}`);
}

export function postBillet(body: any) {
  return api.post('payment/create/billet', body);
}

export function postPix(body: any) {
  return api.post('payment/create/pix', body);
}

export function postPurchase(body: any) {
  return api.post('packages/purchase', body);
}

export function getActiveCoupons() {
  return api.get('coupons');
}

export function getActivesPlans() {
  return api.get('plans');
}

export function getCep(cep: string) {
  return apiCep.get(`ws/${maskReset(cep)}/json/`);
}

export function getTableCommercial() {
  return api.get('tablecommercial');
}

export function getTableCommercialByPlanId(id: string) {
  return api.get(`tablecommercial/${id}`);
}

export function getCnpj(cnpj: string) {
  return api.get(`order/getcnpjdata/${cnpj}`);
}

export function postSuccessfulOrder(body: any) {
  return api.post('order/successfulorder', body);
}

export function postOrder(body: any) {
  return api.post('order/create', body);
}

export function editOrder(body: any) {
  return api.post('/order/update', body);
}

export function getOrderData(id: string) {
  return api.get(`order/get-order-data/${id}`);
}

export function postOrderSpecialDiscount(body: any) {
  return api.post('order/create-special-discount', body);
}

export function postHolder(body: any) {
  return api.post('order/beneficiaries/create', body);
}

export function getOrdersPlan(body: any) {
  return api.post('order/get-order-plans', body);
}

export function postDependent(body: any) {
  return api.post('dependent/create', body);
}

export function postCPFData(body: any) {
  return api.post('order/getcpfdata', body);
}

export function getReletionships() {
  return api.get('client-relationships');
}

export function getBroker(name: string) {
  return api.get(`broker/slug/${name}`);
}

export function verifyToken(body: any) {
  return api.post('order/verifytoken', body);
}

export function resendToken(body: any) {
  return api.post('order/generatetoken', body);
}

export function resendTokenPurchase(body: any) {
  return api.post('packages/purchase/generatetoken', body);
}

export function getPlanById(id: any) {
  return api.get(`plan/${id}`);
}

export function getLinkedCNPJ(cnpj: any) {
  return api.get(`order/getlinkedcnpj/${cnpj}`);
}

export function getCommercialApresentation() {
  return api.get('documentation');
}

export function getQuestionsDPS() {
  return api.get('dps/questions-answers');
}
export function sendBeneficariesXls(body: any) {
  return api.post('beneficiaries/upload', body);
}

export function getHoldDocuments() {
  return api.get('documents');
}

export function sendHoldDocuments(data: any) {
  return api.post('holder/document/create', data);
}

export function saveDpsHolder(data: any) {
  return api.post('holder/dps/create', data);
}
export function saveDpsDependent(data: any) {
  return api.post('dependent/dps/create', data);
}

export function createCptHolder(data: any) {
  return api.post('holder/cpt/create', data);
}

export function createCptDepedent(data: any) {
  return api.post('dependent/cpt/create', data);
}

export function getHoldersCPT(id: number) {
  return api.get(`holder-answers/${id}`);
}

export function getDependentsCPT(id: number) {
  return api.get(`dependent-answers/${id}`);
}

export function getDepedentByID(id: any) {
  return api.get(`/holder-dependents/${id}`);
}

export function createContract(data: any) {
  return api.post('/document/contract/create', data);
}

export function getContractData(planID: number) {
  return api.get(`/document/contract/${planID}`);
}

export function approveContract(data: any) {
  return api.post('/order/sulmed/document/approve', data);
}

export function saveHolderDocuments(data: any) {
  return api.post('/holder/document/upload', data);
}

export function saveDependentsDocuments(data: any) {
  return api.post('/dependent/document/upload', data);
}

export function reproveHolderDocument(data: any) {
  return api.post('/holder/document/reprove', data);
}

export function approveHolderDocument(data: any) {
  return api.post('/holder/document/aprove', data);
}

export function reproveDependentDocument(data: any) {
  return api.post('/dependent/document/reprove', data);
}

export function approveDependentDocument(data: any) {
  return api.post('/dependent/document/aprove', data);
}

export function uploadCompanyDocument(data: any) {
  return api.post('/order/upload-company-documents', data);
}

export function resendCompanyDocument(data: any) {
  return api.post('/order/resend-company-documents', data);
}

export function updateBeneficiariesStatus(data: any) {
  return api.post('/beneficiaries/updatestatus', data);
}

export function senTokenDPS(data: any) {
  return api.post('/holder/send-token-auth', data);
}

export function verifyTokenDPS(data: any) {
  return api.post('/holder/verify-token-auth', data);
}

export function saveInitAnswersHolder(data: any) {
  return api.post('/holder/dps-init/create', data);
}

export function saveInitAnswersDependent(data: any) {
  return api.post('/dependent/dps-init/create', data);
}

export function aproveEnterpriseDocument(data: any) {
  return api.post('/order/approve-company-documents', data);
}

export function reproveEnterpriseDocument(data: any) {
  return api.post('/order/reprove-company-documents', data);
}

export function getAllEnterpriseDocuments() {
  return api.get('/company-documentation');
}

export function getAllUploadedEnterpriseDocuments(id: any) {
  return api.get(`/company-documents/${id}`);
}

export function uploadDocumentContract(data: any) {
  return api.post('/order/sulmed/document/upload', data);
}

export function sendDocumentContract(data: any) {
  return api.post('/order/sulmed/document/approve', data);
}

export function concludeReviewBeneficiaries(data: any) {
  return api.post('/order/conclude-document-analysis', data);
}

export function getDocumentations() {
  return api.get('/documentation');
}

export function sendContractAuthToken(data: any) {
  return api.post('/document/contract/generate-token', data);
}

export function verifyTokenContract(data: any) {
  return api.post('/document/contract/verify-token', data);
}

export function updateProposalResposible(data: any) {
  return api.post('order/update-responsible-data', data);
}

export function setValidity(data: any) {
  return api.post('/document/contract/set-validity', data);
}

export function getContractByOrderID(id: number) {
  return api.get(`/document/contract-by-order-id/${id}`);
}

export function createSimulationData(data: any) {
  return api.post('/ordersimulator/create', data);
}

export function sendSimulationData(data: any) {
  return api.post('/ordersimulator/send-email', data);
}

export function getSimulationPDF(data: any) {
  return api.post('/ordersimulator/recover-pdf', data);
}

export function getCPTDescriptions() {
  return api.get('/dps/cpt-descriptions');
}

export function acceptCompanyPolicy(data: any) {
  return api.post('/order/contract/accept-company-privacy-terms', data);
}

export function acceptBeneficiaryPolicy(data: any) {
  return api.post('/order/contract/accept-beneficiary-privacy-terms', data);
}

export function getSpecialDiscount(data: any) {
  return api.post('/order/special-discount', data);
}

export function updateCPTHolder(data: any) {
  return api.post('/holder/cpt/update', data);
}

export function updateCPTDependent(data: any) {
  return api.post('/dependent/cpt/update', data);
}

export function deleteBeneficiarie(data: any) {
  return api.delete('/beneficiaries/delete', data);
}

export function deleteBeneficiaries(data: any) {
  return api.post('/beneficiaries/delete', data);
}

export function finishBeneficiariesRegister(data: any) {
  return api.post('/order/beneficiaries/finish', data);
}

export function updateBeneficiariesData(data: any) {
  return api.post('/order/beneficiaries/update', data);
}
