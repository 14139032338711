/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  getActiveCoupons,
  getActivesPlans,
  getCnpj,
  getLinkedCNPJ,
  getTableCommercialByPlanId,
  postOrderSpecialDiscount
} from 'api/services/ApiCalls';
import { AlertState } from 'components/Alert';
import { useNavigationContext } from 'context/NavigationContext';
import { useProposalContext } from 'context/ProposalContext';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import TableMobile from 'views/Proposal/components/TableMobile';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import check from '../../assets/icons/check-icon.png';
import error from '../../assets/icons/error-icon.png';
import infoIcon from '../../assets/icons/info-icon.svg';
import {
  Accordion,
  Alert,
  BottomNavigation,
  Button,
  Card,
  Column,
  Description,
  Input,
  Layout,
  Modal,
  Row,
  Spacing,
  Spinner,
  Text,
  Textarea,
  Title,
  Undertitle
} from '../../components';
import { maskCnpj, maskMoneyPlans, maskPhone, maskReset } from '../../utils/mask';
import Table from './components/Table';
import styles from './components/Table/styles.module.scss';

function ProposalInitialHome() {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [att, setAtt] = useState(0);
  const [checkEInputCNPJ, setCheckInputCNPJ] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [emailExtra1, setEmailExtra1] = useState<string>('');
  const [emailExtra2, setEmailExtra2] = useState<string>('');
  const [checkEInputEmail, setCheckInputEmail] = useState(false);
  const [enterprise, setEnterprise] = useState<string>('');
  const [agent, setAgent] = useState<string>('');
  const [office, setOffice] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [proposalExpiration, setProposalExpiration] = useState<string>('');
  const [checkProposalExpiration, setCheckProposalExpiration] = useState(false);
  const [checkEInputTelephone, setCheckInputTelephone] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkInputAgent, setCheckInputAgent] = useState(false);
  const [totalBeneficiaries, seTotalBeneficiaries] = useState<number>(0);
  const [modalInfoIsOpen, setModalInfoIsOpen] = useState(false);
  const [showExtraEmails, setShowExtraEmails] = useState(false);
  const [coupomInvalid, setCoupomInvalid] = useState(false);
  const [discountInvalid, setDiscountInvalid] = useState(false);
  const [subtotal, setSubtotal] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [modalProposeExists, setModalProposeExists] = useState(false);
  const [loadingSpecialDiscount, setLoadingSpecialDiscount] = useState(false);
  const [proposalValidity, setProposalValidity] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [discountRequest, setDiscountRequest] = useState({
    discount: '',
    justification: ''
  });
  const [plans, setPlans] = useState([]);
  const [coupomName, setCoupomName] = useState('');
  const [coupomDiscount, setCoupomDiscount] = useState('');
  const [table, setTable] = useState<any>([]);
  const [selectPlans, setSelectPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertState | null>(null);
  const [coupomDescription, setCoupomDescription] = useState('');
  const { setParams, Broker, setOrder } = useNavigationContext();
  const {
    planValues,
    handleRemovePlan,
    handleSetDiscount,
    formPlans,
    setFormPlans,
    setPlanValues,
    orderByPlan
  } = useProposalContext();

  async function getCoupons() {
    try {
      const response = await getActiveCoupons();
      const { coupons } = response.data;
      setCoupons(coupons);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function getPlans() {
    getActivesPlans()
      .then((response) => {
        const formattedPlan = response.data.plans.map((plan) => {
          const formatPlan = {
            id: plan.id,
            title: plan.name,
            value: `${plan.name} ${plan.subtitle}`,
            coparticipation: plan.co_participation,
            status: false,
            attributes: plan.plan_attributes
          };
          return formatPlan;
        });
        setPlans(formattedPlan);
        const table = JSON.parse(sessionStorage.getItem('@table'));
        if (table) {
          table.map((item) => {
            const index = formattedPlan.map((e) => e.value).indexOf(item.plan);
            formattedPlan[index].status = true;
          });
        }
        setSelectPlans(formattedPlan);
        formPlans.map((formPlan: any) => {
          formPlan.plan = formattedPlan[0]?.title;
        });
        setFormPlans(formPlans);
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  async function getCnpjData(cnpj: string) {
    const response = await getCnpj(cnpj);
    setEnterprise(response.data.cnpj_data.name);
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    const userData = {
      city: response.data.cnpj_data.city,
      complement: response.data.cnpj_data.complement,
      district: response.data.cnpj_data.district,
      number: response.data.cnpj_data.number,
      state: response.data.cnpj_data.state,
      street: response.data.cnpj_data.street,
      zip_code: response.data.cnpj_data.zip_code,
      enterprise: response.data.cnpj_data.name
    };
    sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
  }

  function checkRequestedFields() {
    if (cnpj === '' || cnpj === undefined) {
      setCheckInputCNPJ(true);
    } else {
      setCheckInputCNPJ(false);
    }

    if (email === '' || email === undefined) {
      setCheckInputEmail(true);
    } else {
      setCheckInputEmail(false);
    }

    if (proposalExpiration === '' || proposalExpiration === undefined) {
      setCheckProposalExpiration(true);
    } else {
      setCheckProposalExpiration(false);
    }

    if (agent === '' || agent === undefined || agent.length < 4) {
      setCheckInputAgent(true);
    } else {
      setCheckInputAgent(false);
    }

    if (telephone === '' || telephone === undefined || telephone.length <= 13) {
      setCheckInputTelephone(true);
    } else {
      setCheckInputTelephone(false);
    }

    if (
      cnpj !== '' &&
      email !== '' &&
      proposalExpiration !== '' &&
      proposalExpiration !== undefined &&
      telephone !== '' &&
      telephone !== undefined &&
      telephone.length > 13 &&
      agent !== '' &&
      agent !== undefined &&
      agent.length > 3 &&
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function saveUserData() {
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    const userData = {
      cnpj,
      name,
      telephone,
      email,
      agent,
      office,
      enterprise,
      proposalExpiration,
      coupomName: user.coupomName,
      coupomDescription: user.coupomDescription,
      coupomDiscount,
      validity: proposalValidity,
      emailExtra1,
      emailExtra2,
      totalBeneficiaries,
      subtotal
    };
    sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
    sessionStorage.setItem('@plan_attributes', JSON.stringify(plans));
  }

  function savePlansData() {
    const plansData = JSON.parse(sessionStorage.getItem('@plansData'));
    sessionStorage.setItem('@plansData', JSON.stringify({ ...plansData, ...formPlans }));
  }

  function handleNextPage(): void {
    const result = JSON.parse(sessionStorage.getItem('@table'));
    const verify = [];
    if (result) {
      result.map((elem) => {
        let count = 0;
        elem.dependents.map((item) => {
          count += item.owners;
        });
        verify.push(count > 0);
      });
    }

    if (!verify.includes(false)) {
      setLoading(true);
      const checkRequestFields = checkRequestedFields();
      if (checkRequestFields) {
        saveUserData();
        const plansData = JSON.parse(sessionStorage.getItem('@plansData'));
        sessionStorage.setItem('@plansData', JSON.stringify({ ...plansData, ...formPlans }));
        navigate('/proposta/resumo');
      }
      document.documentElement.scrollTo({
        top: 0,
        left: 0
      });
      setLoading(false);
    } else {
      setAlert({
        show: true,
        title: 'Atenção!',
        type: 'warning',
        text: 'Necessário informar ao menos um titular por plano para prosseguir'
      });
      setTimeout(() => {
        setAlert({
          show: false,
          title: '',
          type: 'success',
          text: ''
        });
      }, 5500);
    }
  }

  function resetAlert() {
    setAlert(null);
  }

  function resetAllFields() {
    setCnpj('');
    setEmail('');
    setEmailExtra1('');
    setEmailExtra2('');
    setEnterprise('');
    setAgent('');
    setOffice('');
    setTelephone('');
    seTotalBeneficiaries(0);
    setPlanValues({
      underTotal: 0,
      discountPorcentage: 0,
      discountValue: 0,
      totalValue: 0
    });
  }

  function resetDiscountFields() {
    setDiscountRequest({
      discount: '',
      justification: ''
    });
  }

  async function handleDiscountRequest() {
    const checkRequestFields = checkRequestedFields();
    if (checkRequestFields) {
      setLoadingSpecialDiscount(true);
      const data = JSON.parse(sessionStorage.getItem('@userData'));
      const totalValue = JSON.parse(sessionStorage.getItem('@totalValue'));
      const dependents = [];
      let lives = 0;
      table.map((plan: any) => {
        plan.dependents.map((dependent) => {
          dependents.push({ ...dependent, value: Number(dependent.plan_value) });
          lives = lives + dependent.dependents + dependent.owners;
        });
      });
      const body = {
        cnpj: maskReset(data.cnpj),
        phone: maskReset(data.telephone),
        email: data.email,
        dependents,
        city: data.city,
        zip_code: data.zip_code,
        district: data.district,
        special_discount: {
          value: discountRequest.discount,
          type: 'R',
          description: discountRequest.justification
        },
        total: Number(totalValue) - Number(coupomDiscount),
        company_name: data.enterprise,
        responsible_name: data.agent,
        partner_slug: Broker.broker ? Broker.broker.slug : 'autoatendimento',
        coupom_name: data.coupomName ? data.coupomName : null,
        coupon_discount: data.coupomDiscount,
        total_beneficiaries: data.totalBeneficiaries ? data.totalBeneficiaries : lives,
        subtotal: Number(totalValue)
      };
      await postOrderSpecialDiscount(body)
        .then((response: any) => {
          setAlert({
            ...{
              show: true,
              type: 'success',
              title: 'Sucesso!',
              text: 'Solicitação de desconto especial gerada com sucesso.'
            }
          });
          setModalIsOpen(false);
          sessionStorage.clear();
          resetAllFields();
          resetDiscountFields();
          document.documentElement.scrollTo({
            top: 0,
            left: 0
          });
          setTable([]);
        })
        .catch((error: any) => {
          setAlert({
            ...{
              show: true,
              type: 'error',
              title: 'Atenção!',
              text: 'Ocorreu um erro ao gerar a solicitação de desconto especial.'
            }
          });
          setTimeout(() => {
            resetAlert();
          }, 5500);
        });
      setLoadingSpecialDiscount(false);
    } else {
      resetDiscountFields();
      setModalIsOpen(false);
      document.documentElement.scrollTo({
        top: 0,
        left: 0
      });
    }
  }

  function addEmailsForm() {
    setShowExtraEmails(true);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const brokerParamsName = urlParams.get('corretor');
      sessionStorage.setItem('@brokerParams', brokerParamsName);
      sessionStorage.setItem('@brokerRoute', 'criar pedido');
      setParams(brokerParamsName);
    }
    return '';
  }

  async function fillFields() {
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    if (user) {
      setCnpj(user.cnpj);
      setEmail(user.email);
      setEmailExtra1(user.emailExtra1);
      setEmailExtra2(user.emailExtra2);
      setProposalExpiration(user.proposalExpiration);
      setEnterprise(user.enterprise);
      setAgent(user.agent);
      setOffice(user.office);
      setTelephone(user.telephone);

      if (user.cnoj) {
        getCnpjData(user?.cnpj);
        const teste = await getLinkedCNPJ(maskReset(user?.cnpj));

        if (teste.data.total_orders > 0) {
          setModalProposeExists(true);
          setTotalOrders(teste.data.total_orders);
        }
      }
    }
  }

  function calcTotalMembers() {
    const result = JSON.parse(sessionStorage.getItem('@table'));
    if (result) {
      let count = 0;
      result.map((elem) => {
        elem.dependents.map((item) => {
          count = count + item.owners + item.dependents;
        });
      });
      seTotalBeneficiaries(count);
    }
  }

  function calcTotal() {
    const result = JSON.parse(sessionStorage.getItem('@table'));
    if (result) {
      let totalValues = 0;
      result.map((elem) => {
        elem.dependents.map((item) => {
          totalValues += item.totalValue;
        });
      });
      setPlanValues({
        ...planValues,
        underTotal: totalValues,
        totalValue: totalValues
      });
      sessionStorage.setItem('@totalValue', totalValues.toFixed(2).toString());
    }
  }

  function applySimulation(simulationPlans, simulationTable) {
    setLoadingPlans(true);
    const teste = [];
    for (let i = 0; i < simulationPlans.length; i++) {
      const commertial = [];
      getTableCommercialByPlanId(simulationPlans[i].plan_id).then((response) => {
        response.data.table_commercial.map((item: any) => {
          simulationTable.map((simulation) => {
            if (`${item.minimum_age} - ${item.maximum_age}` === simulation.title) {
              item.age = simulation.title;
              item.plan = simulationPlans[i].title;
              item.owners = simulation.holder;
              item.dependents = simulation.dependent;
              item.totalValue = item.plan_value * (simulation.holder + simulation.dependent);
              item.plan_id = simulationPlans[i].id;
            }
          });
        });
        commertial.push(response.data.table_commercial);
      });
      setTimeout(() => {
        setTable([
          ...table,
          {
            plan: simulationPlans[i].title,
            attributes: simulationPlans[i].attributes,
            coparticipation: simulationPlans[i].coparticipation,
            dependents: commertial
          }
        ]);
        const index = simulationPlans.map((e) => e.value).indexOf(simulationPlans[i].value);
        simulationPlans[index].status = true;
        simulationPlans[index].plan = simulationPlans[index].value;
        simulationPlans[index].dependents = commertial;
        teste.push({
          id: simulationPlans[index].id,
          plan: simulationPlans[index].title,
          title: simulationPlans[index].title,
          value: simulationPlans[index].title,
          coparticipation: simulationPlans[index].coparticipation,
          status: false,
          attributes: simulationPlans[index].attributes,
          dependents: commertial[0]
        });
        setTable(teste);
        getPlans();
        sessionStorage.removeItem('@table');
        sessionStorage.setItem('@table', JSON.stringify(teste));
        setLoadingPlans(false);
        calcTotalMembers();
        calcTotal();
      }, 2500);
    }
  }

  useEffect(() => {
    getPlans();
    getCoupons();
    fillFields();
    getParams();

    const storageTotal = sessionStorage.getItem('@totalValue');
    if (storageTotal) {
      setPlanValues({
        ...planValues,
        underTotal: storageTotal,
        totalValue: storageTotal
      });
    } else {
      setPlanValues({
        ...planValues,
        underTotal: 0,
        totalValue: 0
      });
    }

    const result = JSON.parse(sessionStorage.getItem('@table'));
    if (result) {
      setTable(result);
    }

    const simulationTable = JSON.parse(sessionStorage.getItem('@simulationTable'));
    const simulationPlans = JSON.parse(sessionStorage.getItem('@simulationPlans'));
    if (simulationPlans) {
      applySimulation(simulationPlans, simulationTable);
    }

    if (att < 2) {
      const pland_id = JSON.parse(sessionStorage.getItem('@plan_id'));
      orderByPlan(pland_id, plans);
      setAtt(att + 1);
    }

    const user = JSON.parse(sessionStorage.getItem('@userData'));
    if (user && user.coupomDiscount) {
      setEnterprise(user.enterprise);
      setCoupomDiscount(user.coupomDiscount);
    }
  }, [att]);

  return (
    <>
      {alert?.show && <Alert type={alert.type} title={alert.title} text={alert.text} />}

      <Modal
        isOpen={modalProposeExists}
        height="346px"
        title=""
        description=""
        onClickClose={() => {
          resetAllFields();
          setModalProposeExists(false);
        }}
        footerButtonSecond="Voltar"
        onClickFooterSecond={() => {
          resetAllFields();
          setModalProposeExists(false);
        }}
      >
        <div
          style={{
            height: '156px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <svg
            width="62"
            height="63"
            viewBox="0 0 62 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31 19V31.5M31 44H31.0313M59.125 31.5C59.125 47.033 46.533 59.625 31 59.625C15.467 59.625 2.875 47.033 2.875 31.5C2.875 15.967 15.467 3.375 31 3.375C46.533 3.375 59.125 15.967 59.125 31.5Z"
              stroke="#FCC332"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Spacing size="large" />
          <Title bolder={true} appearance="primary">
            Essa empresa já possui proposta vigente.
          </Title>
          <Spacing size="xx-small" />
          <Text>Para mais detalhes, entre em contato com a Sulmed.</Text>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        height="580px"
        title="Solicitação de desconto"
        description="Informe abaixo a porcentagem de desconto que deseja solicitar e a justificativa. Essas informações irão para análise."
        onClickClose={() => setModalIsOpen(false)}
        footerButtonFirst=""
        footerButtonFirstAppearance=""
        onClickFooterFirst=""
      >
        <div
          style={{
            height: '330px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Description textAlign="start">Informe a porcentagem de desconto</Description>
          <Spacing size="x-small" />
          <Input
            name="discount"
            type="text"
            width="360px"
            label="% de desconto"
            maxLength={3}
            value={discountRequest.discount}
            error={discountInvalid}
            errorMessage="A porcentagem de desconto aceita deve ser inferior a 100%"
            onChange={(event) => {
              if (Number(event.target.value) > 100) {
                setDiscountInvalid(true);
              } else {
                setDiscountInvalid(false);
              }
              setDiscountRequest({
                ...discountRequest,
                discount: event.target.value
              });
            }}
          />
          {/* <Spinner show={true} color="#054685" /> */}
          <Spacing size="small" />
          <Description textAlign="start">Justificativa</Description>
          <Spacing size="x-small" />
          <Textarea
            name="justification"
            type="text"
            width="360px"
            label="Descreva..."
            value={discountRequest.justification}
            onChange={(event) => {
              setDiscountRequest({
                ...discountRequest,
                justification: event.target.value
              });
            }}
          />
          <Spacing size="xxx-large" />

          <Row align="center">
            <Button
              appearance={discountInvalid && loadingSpecialDiscount ? 'disabled' : 'primary'}
              text="Enviar Solicitação"
              onClick={
                !discountInvalid && !loadingSpecialDiscount ? handleDiscountRequest : () => {}
              }
              loading={loadingSpecialDiscount}
            />
          </Row>
        </div>
      </Modal>

      <Modal
        width="900px"
        height="90%"
        isOpen={modalInfoIsOpen}
        title="Planos de Saúde Empresarial Sulmed"
        description="Veja abaixo as informacões das Coberturas e Coparticipações de cada plano."
        onClickClose={() => setModalInfoIsOpen(false)}
      >
        {plans &&
          plans.map((plan, key) => (
            <div key={key}>
              <Accordion width="100%" title={plan.value} padding="15px 24px" deleteButton={false}>
                <Row>
                  {/* <Card appearance="ghost" width="100%"> */}
                  <Column width="100%" paddingRight="0px">
                    <Undertitle bolder={true} appearance="primary">
                      {plan.value}
                    </Undertitle>
                    <Spacing size="x-small" />
                    <Card width="100%" appearance="ghost" padding="24px 15px">
                      <Row position="start" align="start">
                        <Text appearance="primary" textAlign="start" bolder={true}>
                          Coberturas do Plano
                        </Text>
                      </Row>
                      <Spacing size="x-large" />
                      {plan.attributes.map((attribute, key) => (
                        <>
                          <div className={`${styles.row} ${styles.start}`} key={key}>
                            <Column width="4%">
                              <img
                                src={attribute.status === 1 ? check : error}
                                style={{
                                  width: '15px',
                                  marginRight: '8px',
                                  color: attribute.status === 1 ? 'green' : 'red'
                                }}
                              />
                            </Column>
                            <Spacing size="xx-small" />
                            <Column>
                              <Description appearance="grey" textAlign="start">
                                {attribute.attribute_name}
                              </Description>
                            </Column>
                          </div>
                          <Spacing size="x-small" />
                        </>
                      ))}
                    </Card>

                    <Spacing size="x-small" />
                    <Card width="100%" appearance="ghost" padding="24px 15px">
                      <Row position="start" align="start">
                        <Text appearance="primary" bolder={true}>
                          Coparticipações
                        </Text>
                      </Row>
                      <Spacing size="x-large" />
                      <Column>
                        {plan.coparticipation.map((coop) => {
                          if (coop.status === 1) {
                            return (
                              <>
                                <div className={`${styles.row} ${styles.start}`}>
                                  <Column width="4%">
                                    <img
                                      src={check}
                                      style={{
                                        width: '15px',
                                        marginRight: '8px',
                                        color: 'green'
                                      }}
                                    />
                                  </Column>
                                  <Spacing size="xx-small" />
                                  <Column>
                                    <Description
                                      appearance="grey"
                                      textAlign="start"
                                      position="start"
                                      padding="0px"
                                    >
                                      {coop.co_participation_name}
                                    </Description>
                                  </Column>
                                </div>
                                <Spacing size="xx-small" />
                              </>
                            );
                          }
                        })}
                      </Column>
                    </Card>
                  </Column>
                  {/* </Card> */}
                </Row>
              </Accordion>
              <Spacing size="large" />
            </div>
          ))}
      </Modal>

      <Layout
        navbar={true}
        onClick={() => {
          sessionStorage.clear();
          navigate('/');
        }}
      >
        <Spacing size="medium" />
        {loadingPlans ? (
          <>
            <Row width="100%" align="center">
              <Column width="100%" position="centered">
                <Spinner show={true} />
                <Spacing size="xx-small" />
                <Description appearance="primary" bolder={true}>
                  Carregando os dados
                </Description>
              </Column>
            </Row>
            <Spacing size="x-large" />
          </>
        ) : (
          <>
            <Title appearance="primary" bolder={true}>
              Simulação do Plano Empresarial Sulmed
            </Title>
            <Spacing size="xx-small" />
            <Text>
              Você pode selecionar o plano, a faixa de idade e inserir a quantidade de titulares e
              dependentes.
            </Text>
            <Spacing size="xx-large" />
            <div className={`${styles.row} ${styles.end}`}>
              <img src={infoIcon} alt="" />
              <Spacing size="xx-small" />
              <Text
                bolder={true}
                appearance="primary"
                textAlign="start"
                pointer={true}
                onClick={() => setModalInfoIsOpen(true)}
              >
                Dúvida de qual planos escolher? Saiba mais aqui
              </Text>
            </div>
            <Spacing size="large" />
            <div style={{ width: '100%', height: '40px' }}>
              <Card appearance="fadedPrimary" padding="12px 24px" width="100%">
                <div className={`${styles.row} ${styles.start}`}>
                  <img src={infoIcon} alt="" className={styles.icon} style={{ margin: '0 5px' }} />
                  <p className={styles.informative}>
                    A <b>Solicitação de Desconto Especial</b>
                    estará disponível nas cotações acima de 30 vidas.
                  </p>
                </div>
              </Card>
            </div>
            <Spacing size="xxx-large" />
            <Row width="95%" position="start">
              <Text bolder={true} appearance="grey" textAlign="start" maxWidth="100%">
                Informações da empresa
              </Text>
            </Row>
            <Spacing size="small" />
            <Row width="95%" align="start">
              <Column position="start" width="100%">
                <Input
                  name="cnpj"
                  type="text"
                  width="100%"
                  label="CNPJ *"
                  value={maskCnpj(cnpj || '')}
                  error={checkEInputCNPJ}
                  onChange={async (event) => {
                    setCnpj(maskReset(event.target.value));
                    if (event.target.value.length > 1) {
                      setCheckInputCNPJ(false);
                    }
                    if (event.target.value.length >= 14) {
                      getCnpjData(maskReset(event.target.value));
                      const teste = await getLinkedCNPJ(maskReset(event.target.value));
                      if (teste.data.total_orders > 0) {
                        setModalProposeExists(true);
                        setTotalOrders(teste.data.total_orders);
                      }
                    }
                  }}
                  onBlur={() => {
                    const user = JSON.parse(sessionStorage.getItem('@userData'));
                    if (cnpj) {
                      sessionStorage.setItem('@userData', JSON.stringify({ ...user, cnpj }));
                    }
                  }}
                />
              </Column>
              <Spacing size="small" />
              <Column position="end">
                <Input
                  name="enterprise"
                  type="text"
                  width="100%"
                  value={enterprise}
                  label="Nome da empresa"
                  disabled
                />
              </Column>
            </Row>
            <Spacing size="xxx-small" />
            <Row width="95%">
              <Column>
                <Input
                  name="agent"
                  type="text"
                  width="100%"
                  label="Nome Representante Legal *"
                  error={checkInputAgent}
                  value={agent}
                  onChange={(event) => {
                    if (event.target.value.length > 1) {
                      setCheckInputAgent(false);
                    }
                    setAgent(event.target.value);
                  }}
                  onBlur={() => {
                    const user = JSON.parse(sessionStorage.getItem('@userData'));
                    sessionStorage.setItem('@userData', JSON.stringify({ ...user, agent }));
                  }}
                />
              </Column>
              <Spacing size="small" />
              <Column position="end">
                <Input
                  name="office"
                  type="text"
                  width="100%"
                  label="Cargo"
                  value={office}
                  onChange={(event) => {
                    setOffice(event.target.value);
                  }}
                  onBlur={() => {
                    const user = JSON.parse(sessionStorage.getItem('@userData'));
                    sessionStorage.setItem('@userData', JSON.stringify({ ...user, office }));
                  }}
                />
              </Column>
            </Row>
            <Spacing size="xxx-small" />
            <Row width="95%">
              <Column>
                <Input
                  name="email"
                  type="text"
                  width="100%"
                  label="E-mail *"
                  error={checkEInputEmail}
                  value={email}
                  onChange={(event) => {
                    if (event.target.value.length > 1) {
                      setCheckInputEmail(false);
                    }
                    setEmail(event.target.value);
                  }}
                  onBlur={() => {
                    const user = JSON.parse(sessionStorage.getItem('@userData'));
                    sessionStorage.setItem('@userData', JSON.stringify({ ...user, email }));
                  }}
                />
              </Column>
              <Spacing size="small" />
              <Column position="end">
                <Input
                  name="phone"
                  type="text"
                  width="100%"
                  label="Telefone *"
                  error={checkEInputTelephone}
                  value={telephone}
                  maxLength={15}
                  onChange={(event) => {
                    if (event.target.value.length > 1) {
                      setCheckInputTelephone(false);
                    }
                    setTelephone(maskPhone(event.target.value));
                  }}
                  onBlur={() => {
                    const user = JSON.parse(sessionStorage.getItem('@userData'));
                    sessionStorage.setItem('@userData', JSON.stringify({ ...user, telephone }));
                  }}
                />
              </Column>
            </Row>
            {showExtraEmails && (
              <>
                <Spacing size="xxx-small" />
                <Row width="95%">
                  <Column>
                    <Input
                      name="emailExtra1"
                      type="text"
                      width="100%"
                      label="E-mail (opcional)"
                      value={emailExtra1}
                      onChange={(event) => {
                        setEmailExtra1(event.target.value);
                      }}
                    />
                  </Column>
                  <Spacing size="small" />
                  <Column position="end">
                    <Input
                      name="emailExtra2"
                      type="text"
                      width="100%"
                      label="E-mail (opcional)"
                      value={emailExtra2}
                      onChange={(event) => {
                        setEmailExtra2(event.target.value);
                      }}
                    />
                  </Column>
                </Row>
              </>
            )}

            <Row width="95%" position="end">
              <Button
                text="Adicionar outros e-mails"
                width="240px"
                appearance={showExtraEmails ? 'disabled' : 'ghost'}
                onClick={addEmailsForm}
                iconPosition="left"
              >
                <FaPlus style={{ marginRight: '10px' }} />
              </Button>
            </Row>

            <Spacing size="xxx-large" />

            <Card appearance="ghost" width="94%">
              <Title appearance="primary" textAlign="start" bolder={true}>
                Adicionar Plano
              </Title>
              <Spacing size="xxx-large" />

              <Text bolder={true} appearance="grey">
                Selecione o Plano
              </Text>
              <Spacing size="medium" />
              <select
                name="plans"
                id="plans"
                className={styles.select}
                value={selectedPlan}
                onChange={(event: any) => {
                  setSelectedPlan(event.target.value);
                }}
              >
                <option value="" disabled>
                  Selecione um plano
                </option>
                {selectPlans.map((plan, key) => {
                  if (plan.status === false) {
                    return (
                      <option key={key} value={plan.value}>
                        {plan.value}
                      </option>
                    );
                  }
                })}
              </select>
              <Spacing size="xxx-large" />
              <Row position="end">
                <Button
                  text="Adicionar Plano"
                  width="190px"
                  loading={loadingTable}
                  appearance={selectedPlan === '' || loadingTable ? 'disabled' : 'primary'}
                  onClick={async () => {
                    if (selectedPlan !== '') {
                      setLoadingTable(true);
                      const plan = selectPlans.find((elem: any) => elem.value === selectedPlan);
                      const response = await getTableCommercialByPlanId(`${plan.id}`);
                      response.data.table_commercial.map((item: any) => {
                        let age = '';
                        if (item.minimum_age !== 59) {
                          age = `${item.minimum_age} - ${item.maximum_age}`;
                        } else {
                          age = `${item.minimum_age} +`;
                        }
                        item.age = age;
                        item.plan = selectedPlan;
                        item.owners = 0;
                        item.dependents = 0;
                        item.totalValue = 0;
                        item.plan_id = plan.id;
                      });
                      table.unshift({
                        plan: selectedPlan,
                        attributes: plan.attributes,
                        coparticipation: plan.coparticipation,
                        dependents: response.data.table_commercial
                      });
                      const index = selectPlans.map((e) => e.value).indexOf(selectedPlan);
                      selectPlans[index].status = true;
                      setSelectPlans([...selectPlans]);
                      setSelectedPlan('');
                      setTimeout(() => {
                        setTable([...table]);
                        console.log('table: ', table);
                        getPlans();
                        sessionStorage.removeItem('@table');
                        sessionStorage.setItem('@table', JSON.stringify(table));
                        setLoadingTable(false);
                      }, 2000);
                    }
                  }}
                />
              </Row>
            </Card>

            <Spacing size="x-large" />
            {!loadingTable &&
              table &&
              table.length > 0 &&
              table.map((elem, key) => (
                <>
                  <Accordion
                    key={key}
                    title={elem.plan}
                    deleteButton={true}
                    onClickDelete={() => {
                      // Delete the selected item
                      const index = selectPlans.map((e) => e.value).indexOf(elem.plan);
                      selectPlans[index].status = false;
                      setSelectPlans([...selectPlans]);

                      const index2 = table.map((e) => e.plan).indexOf(elem.plan);
                      table.splice(index2, 1);
                      setTable([...table]);
                      sessionStorage.setItem('@table', JSON.stringify(table));

                      calcTotalMembers();
                      calcTotal();
                    }}
                    opened={key === 0}
                  >
                    <TableMobile
                      width={width}
                      content={plans}
                      plans={elem.dependents}
                      plan={elem.plan}
                      onClickDelete={() => {
                        const index = selectPlans.map((e) => e.value).indexOf(elem.plan);
                        selectPlans[index].status = false;
                        setSelectPlans([...selectPlans]);

                        const index2 = table.map((e) => e.plan).indexOf(elem.plan);
                        table.splice(index2, 1);
                        setTable(table);
                        sessionStorage.setItem('@table', JSON.stringify(table));
                      }}
                      onChangeOwners={(data, plan, id, plan_value, dependents) => {
                        // Busca os dados à serem alterados
                        const index = table.map((e) => e.plan).indexOf(plan);
                        const indexDependents = table[index].dependents
                          .map((e) => e.id)
                          .indexOf(id);

                        // Altera os dados informados
                        table[index].dependents[indexDependents].owners =
                          data === 0 || Number.isNaN(data) ? 0 : data;
                        table[index].dependents[indexDependents].totalValue =
                          Number(plan_value) *
                          (Number(Number.isNaN(data) ? 0 : data) + Number(dependents));
                        table[index].id = table[index].dependents[indexDependents].plan_id;

                        // Seta os novos valores no state Table
                        setTable([...table]);
                        sessionStorage.setItem('@table', JSON.stringify(table));

                        // Recalcula a quantidade total de membros e o valor total da proposta
                        calcTotalMembers();
                        calcTotal();
                      }}
                      onChangeDependents={(data, plan, id, plan_value, owners) => {
                        // Busca os dados à serem alterados
                        const index = table.map((e) => e.plan).indexOf(plan);
                        const indexDependents = table[index].dependents
                          .map((e) => e.id)
                          .indexOf(id);

                        // Altera os dados informados
                        table[index].dependents[indexDependents].dependents =
                          data === 0 || Number.isNaN(data) ? 0 : data;
                        table[index].dependents[indexDependents].totalValue =
                          Number(plan_value) *
                          (Number(owners) + Number(Number.isNaN(data) ? 0 : data));
                        table[index].id = table[index].dependents[indexDependents].plan_id;

                        // Seta os novos valores no state Table
                        setTable([...table]);
                        sessionStorage.setItem('@table', JSON.stringify(table));

                        // Recalcula a quantidade total de membros e o valor total da proposta
                        calcTotalMembers();
                        calcTotal();
                      }}
                    />
                    {width >= 800 && (
                      <div className={`${styles.row} ${styles.header}`} style={{ width: '900px' }}>
                        <Column width="230%">
                          <Description appearance="grey">Plano Selecionado</Description>
                        </Column>
                        <Column width="75%">
                          <Description appearance="grey">Idade do titular</Description>
                        </Column>
                        <Column width="60%">
                          <Description appearance="grey">Qnt. Titular</Description>
                        </Column>
                        <Column width="78%">
                          <Description appearance="grey">Qnt. Dependente</Description>
                        </Column>
                        <Column width="100%">
                          <Description appearance="grey">Valor do plano</Description>
                        </Column>
                        <Column width="100%">
                          <Description appearance="grey">Valor total</Description>
                        </Column>
                      </div>
                    )}
                    {elem.dependents.map((formPlan, index) => (
                      <Table
                        content={plans}
                        key={index}
                        removePlan={() => handleRemovePlan(index)}
                        onChangeAge={() => {}}
                        onChangePlan={() => {}}
                        onChangeOwners={(data) => {
                          // Busca os dados à serem alterados
                          const index = table.map((e) => e.plan).indexOf(formPlan.plan);
                          const indexDependents = table[index].dependents
                            .map((e) => e.id)
                            .indexOf(formPlan.id);

                          // Altera os dados informados
                          table[index].dependents[indexDependents].owners = data;
                          table[index].dependents[indexDependents].totalValue =
                            Number(formPlan.plan_value) * (data + formPlan.dependents);
                          table[index].id = table[index].dependents[indexDependents].plan_id;

                          // Seta os novos valores no state Table
                          setTable(table);
                          sessionStorage.setItem('@table', JSON.stringify(table));

                          // Recalcula a quantidade total de membros e o valor total da proposta
                          calcTotalMembers();
                          calcTotal();
                        }}
                        onChangeDependents={(data) => {
                          // Busca os dados à serem alterados
                          const index = table.map((e) => e.plan).indexOf(formPlan.plan);
                          const indexDependents = table[index].dependents
                            .map((e) => e.id)
                            .indexOf(formPlan.id);

                          // Altera os dados informados
                          table[index].dependents[indexDependents].dependents = data;
                          table[index].dependents[indexDependents].totalValue =
                            Number(formPlan.plan_value) * (formPlan.owners + data);
                          table[index].id = table[index].dependents[indexDependents].plan_id;

                          // Seta os novos valores no state Table
                          setTable(table);
                          sessionStorage.setItem('@table', JSON.stringify(table));

                          // Recalcula a quantidade total de membros e o valor total da proposta
                          calcTotalMembers();
                          calcTotal();
                        }}
                        owners={formPlan.owners}
                        dependents={formPlan.dependents}
                        index={index}
                        age={formPlan.age}
                        plan={elem.plan}
                        planValue={formPlan.plan_value}
                        totalValue={formPlan.totalValue}
                      />
                    ))}
                  </Accordion>
                  <Spacing size="xx-small" />
                </>
              ))}

            <Spacing size="xxx-large" />

            <Row width="97%">
              <Column paddingRight="0px">
                <Card appearance="ghost" width="100%" textAlign="start">
                  <Title appearance="primary" textAlign="start" bolder={true}>
                    Vencimento da Fatura
                  </Title>
                  <Spacing size="xxx-large" />
                  <div className={`${styles.row} ${styles.start}`}>
                    <select
                      name="proposalExpiration"
                      id="proposalExpiration"
                      style={{ border: checkProposalExpiration ? '2px solid red' : 'none' }}
                      className={styles.select}
                      value={proposalExpiration}
                      onChange={(event: any) => {
                        setCheckProposalExpiration(false);
                        setProposalExpiration(event.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Selecione o dia para vencimento da fatura
                      </option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                    {checkProposalExpiration ? (
                      <i className={styles.error}>Campo Obrigatório!</i>
                    ) : (
                      ''
                    )}
                  </div>
                </Card>
              </Column>
              {width >= 1000 ? <Spacing size="x-small" /> : <Spacing size="large" />}
              <Column paddingRight="0px">
                <Card appearance="ghost" width="100%" textAlign="start">
                  <Title appearance="primary" textAlign="start" bolder={true}>
                    Data de Vigência
                  </Title>
                  <Spacing size="xxx-large" />
                  <div className={`${styles.row} ${styles.start}`}>
                    <div className={styles.datepicker}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            value={dayjs(proposalValidity)}
                            disablePast
                            views={['year', 'month', 'day']}
                            format="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setProposalValidity(dayjs(newValue).format('YYYY-MM-DD'));
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </Card>
              </Column>
            </Row>

            <Spacing size="xx-large" />
            <Card appearance="primary" width="95%">
              <Row>
                <Column position="start">
                  <Text bolder={true} appearance="grey">
                    Você possui um cupom de desconto? (opcional)
                  </Text>
                  <Row position="start">
                    <Column>
                      <Spacing size="xxx-small" />
                      <Spacing size="medium" />
                      <Input
                        name="discount"
                        type="text"
                        width="98%"
                        label="Cupom de desconto"
                        value={coupomName}
                        error={coupomInvalid}
                        errorMessage="Cumpom Inválido"
                        onChange={(event) => {
                          setCoupomName(event.target.value);
                        }}
                      />
                    </Column>
                    <Column>
                      <Button
                        text="Adicionar Cupom"
                        width="180px"
                        size="sm"
                        onClick={() => {
                          if (coupons.find((event) => event.name === coupomName)) {
                            const coupom = coupons.find((event) => event.name === coupomName);
                            setCoupomDescription(coupom.coupon_description);
                            handleSetDiscount(coupons, coupomName);

                            if (coupom.type === 'V') {
                              setCoupomDiscount(coupom.discount_value);
                            } else {
                              const discount =
                                Number(planValues.underTotal) *
                                (Number(coupom.discount_percentage) / 100);
                              setCoupomDiscount(discount.toFixed(2));
                            }
                            setCoupomInvalid(false);
                            const user = JSON.parse(sessionStorage.getItem('@userData'));
                            sessionStorage.setItem(
                              '@userData',
                              JSON.stringify({
                                ...user,
                                coupomName: coupom.name,
                                coupomDescription: coupom.coupon_description,
                                coupomDiscount
                              })
                            );
                          } else {
                            setCoupomInvalid(true);
                          }
                        }}
                      />
                    </Column>
                  </Row>
                  <Spacing size="x-small" />
                  {totalBeneficiaries >= 30 && (
                    <Text
                      appearance="primary"
                      bolder={true}
                      pointer={true}
                      onClick={() => setModalIsOpen(true)}
                    >
                      Solicitar Desconto Especial
                    </Text>
                  )}
                </Column>

                <Column position="end" paddingRight="0px">
                  <Spacing size="xxx-large" />
                  <div className={styles.row} style={{ width: width >= 1000 ? '290px' : '100%' }}>
                    <div
                      className={styles.column}
                      style={{
                        width: width >= 1000 ? '140px' : '50%',
                        alignItems: width >= 1000 ? 'end' : 'start'
                      }}
                    >
                      <Text appearance="grey" textAlign="end">
                        Subtotal
                      </Text>
                    </div>
                    <div
                      className={styles.column}
                      style={{
                        width: width >= 1000 ? '190px' : '50%',
                        alignItems: 'flex-end',
                        textAlign: 'end'
                      }}
                    >
                      <Undertitle appearance="grey">
                        R$ {maskMoneyPlans(Number(planValues.underTotal))}
                      </Undertitle>
                    </div>
                  </div>
                  <Spacing size="xx-small" />
                  {coupomDiscount && (
                    <>
                      <div
                        className={styles.row}
                        style={{ width: width >= 1000 ? '280px' : '100%' }}
                      >
                        <Column
                          position={width >= 1000 ? 'end' : 'start'}
                          width={width >= 1000 ? '80px' : '50%'}
                        >
                          <Text appearance="grey">Desconto</Text>
                        </Column>
                        <Column
                          position="end"
                          width={width >= 1000 ? '200px' : '50%'}
                          paddingRight="0px"
                        >
                          <Undertitle appearance="grey">
                            - R$ {maskMoneyPlans(Number(coupomDiscount))}
                          </Undertitle>
                        </Column>
                      </div>
                      <Spacing size="xx-small" />
                    </>
                  )}
                  <div className={styles.row} style={{ width: width >= 1000 ? '320px' : '100%' }}>
                    <div
                      className={styles.column}
                      style={{
                        width: width >= 1000 ? '140px' : '50%',
                        alignItems: width >= 1000 ? 'end' : 'start',
                        textAlign: width >= 1000 ? 'center' : 'start'
                      }}
                    >
                      <Undertitle appearance="grey" bolder={true}>
                        Valor Total
                      </Undertitle>
                    </div>
                    <div
                      className={styles.column}
                      style={{
                        width: width >= 1000 ? '190px' : '50%',
                        alignItems: 'end',
                        textAlign: 'end'
                      }}
                    >
                      <Title bolder={true} appearance="primary" textAlign="end">
                        R$
                        {Number(planValues.underTotal) - Number(coupomDiscount) < 0
                          ? ' 0,00'
                          : maskMoneyPlans(Number(planValues.underTotal) - Number(coupomDiscount))}
                      </Title>
                    </div>
                  </div>
                </Column>
              </Row>
            </Card>
            <Spacing size="xxx-large" />
            <Row width="95%">
              <BottomNavigation
                labelRight="Continuar"
                appearanceRight={totalBeneficiaries <= 0 ? 'disabled' : 'primary'}
                actionRight={() => {
                  if (totalBeneficiaries > 0) {
                    handleNextPage();
                  }
                }}
              />
            </Row>
          </>
        )}
      </Layout>
    </>
  );
}

export default ProposalInitialHome;
