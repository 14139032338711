import { NavigationProvider } from 'context/NavigationContext';
import { ProposalProvider } from 'context/ProposalContext';
import { PageSizeProvider } from 'context/PageSizeContext';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import AppEnvironment from './components/AppEnvironment';
import AppRoutes from './routes/index';

function App() {
  const enviroment: string = process.env.REACT_APP_ENV;
  return (
    <div className="App">
      <Router>
        <AppEnvironment environment={enviroment || 'production'} />
        <NavigationProvider>
          <PageSizeProvider>
            <ProposalProvider>
              <AppRoutes />
            </ProposalProvider>
          </PageSizeProvider>
        </NavigationProvider>
      </Router>
    </div>
  );
}

export default App;
