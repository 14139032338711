import Icon from '../../../../../assets/icons/check.svg';
import styles from '../styles.module.scss';

function Done() {
  return (
    <div className={styles.column}>
      <img src={Icon} alt="" width={24} />
      <p className={styles.status}>Concluído</p>
    </div>
  );
}

export default Done;
