import React from 'react';
import styles from './styles.module.scss';
import { Spacing } from '..';

import logo from '../../assets/logos/conexxa-logo.png';
import linkedin from '../../assets/footer/icon-linkedin-white-footer.png';
import twitter from '../../assets/footer/icon-twitter-white-footer.png';
import instagram from '../../assets/footer/icon-instagram-white-footer.png';
import facebook from '../../assets/footer/icon-facebook-white-footer.png';
import iconEmail from '../../assets/footer/icon-email-white-footer.png';
import iconPhone from '../../assets/footer/icon-phone-white-footer.png';

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={`${styles.footerColumn} ${styles.start}`}>
          <img src={logo} className={styles.logo} draggable="false" />
          <Spacing size="xx-large" />
          <p>2023 Conexx Saúde - Todos os direitos reservados</p>
          <p>
            Desenvolvido por{' '}
            <b onClick={() => window.open('https://startaideia.com.br/')}>Startaideia</b>
          </p>
        </div>
        <div className={`${styles.footerColumn} ${styles.end}`} />
        <div className={`${styles.footerColumn} ${styles.end}`}>
          <Spacing size="xx-large" />
          <div className={styles.footerContacts}>
            <p>
              <img src={iconEmail} alt="" /> contato@email.com.br
            </p>
            <Spacing size="medium" />
            <p>
              <img src={iconPhone} alt="" /> +55 (11) 998437182
            </p>
            <Spacing size="medium" />
          </div>
          <Spacing size="xx-large" />
          <div className={styles.row}>
            <img
              src={linkedin}
              alt="Linkedin Logo"
              className={styles.socialImage}
              onClick={() => window.open('https://www.google.com.br/').focus()}
            />
            <img
              src={twitter}
              alt="Twitter Logo"
              className={styles.socialImage}
              onClick={() => window.open('https://www.google.com.br/').focus()}
            />
            <img
              src={instagram}
              alt="Instagram Logo"
              className={styles.socialImage}
              onClick={() => window.open('https://www.google.com.br/').focus()}
            />
            <img
              src={facebook}
              alt="Facebook Logo"
              className={styles.socialImage}
              onClick={() => window.open('https://www.google.com.br/').focus()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
