/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { BsCheck, BsX, BsBoxArrowUpRight } from 'react-icons/bs';
import {
  approveHolderDocument,
  reproveHolderDocument,
  approveDependentDocument,
  reproveDependentDocument
} from 'api/services/ApiCalls';
import axios from 'axios';
import { Row, Column, Text, Button, Spacing, Alert, Input } from '..';
import styles from './styles.module.scss';
import pdfLogo from '../../assets/logos/pdf_logo_black.png';

function DocumentReviewer({
  id,
  width,
  orderData,
  status,
  beneficiarieID,
  beneficiarieType,
  title,
  image,
  updateData
}: any) {
  const [alert, setAlert] = useState<any>({
    show: false,
    title: '',
    type: '',
    message: ''
  });
  const [isAPDF, setIsAPDF] = useState<boolean>(false);
  const [reproved, setReproved] = useState<boolean>(false);
  const [reprovedMessage, setReprovedMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  function resetAlertParams() {
    setAlert({
      show: false,
      title: '',
      type: '',
      message: ''
    });
  }

  function approveBeneficiarieDoc() {
    const formData = new FormData();
    formData.append('document_id', id);

    if (beneficiarieType === 'holder') {
      formData.append('holder_id', beneficiarieID);
      approveHolderDocument(formData)
        .then(() => {
          setAlert({
            show: true,
            title: 'Sucesso',
            type: 'success',
            message: 'Documento Aprovado com sucesso!'
          });
          setTimeout(() => {
            resetAlertParams();
          }, 5500);
          updateData();
        })
        .catch((error) => console.error(error));
    } else {
      formData.append('dependent_id', beneficiarieID);
      approveDependentDocument(formData)
        .then(() => {
          setAlert({
            show: true,
            title: 'Sucesso',
            type: 'success',
            message: 'Documento Aprovado com sucesso!'
          });
          setTimeout(() => {
            resetAlertParams();
          }, 5500);
          updateData();
        })
        .catch((error) => console.error(error));
    }
  }

  function reproveBeneficiarieDoc() {
    setLoading(true);
    if (reprovedMessage !== '') {
      const formData = new FormData();
      formData.append('document_id', id);

      if (beneficiarieType === 'holder') {
        formData.append('holder_id', beneficiarieID);
        formData.append('order_id', orderData.order.id);
        formData.append('disapproval_justification', reprovedMessage);

        reproveHolderDocument(formData)
          .then(() => {
            setAlert({
              show: true,
              title: 'Sucesso',
              type: 'success',
              message: 'Documento Reprovado com sucesso!'
            });
            setTimeout(() => {
              resetAlertParams();
            }, 5500);
            updateData();
          })
          .catch((error) => console.error(error))
          .finally(() => {
            setLoading(false);
            setReproved(false);
            setReprovedMessage('');
          });
      } else {
        formData.append('dependent_id', beneficiarieID);
        formData.append('order_id', orderData.order.id);
        formData.append('disapproval_justification', reprovedMessage);

        reproveDependentDocument(formData)
          .then(() => {
            setAlert({
              show: true,
              title: 'Sucesso',
              type: 'success',
              message: 'Documento Reprovado com sucesso!'
            });
            setTimeout(() => {
              resetAlertParams();
            }, 5500);
            updateData();
          })
          .catch((error) => console.error(error))
          .finally(() => {
            setLoading(false);
            setReproved(false);
            setReprovedMessage('');
          });
      }
    } else {
      setAlert({
        show: true,
        title: 'Atenção!',
        type: 'error',
        message: 'Para reprovar um documento o campo de justificativa deve ser preenchido!'
      });
      setTimeout(() => {
        resetAlertParams();
        setLoading(false);
      }, 5500);
    }
  }

  useEffect(() => {
    if (image) {
      axios.get(image).then((response) => {
        if (response.headers['content-type'] === 'application/pdf') {
          setIsAPDF(true);
        }
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      {alert.show && <Alert type={alert.type} title={alert.title} text={alert.message} />}
      <Text bolder={true} textAlign="left">
        {title}
      </Text>
      <Spacing size="x-small" />
      <Row position="centered">
        <Column width="100%" paddingRight={width >= 1000 ? '10px' : '0px'}>
          <img
            src={isAPDF ? pdfLogo : image}
            style={{
              border: (status === 1 && '4px solid green') || (status === 2 && '4px solid red')
            }}
            className={styles.uploadedFile}
            alt="Imagem enviada pelo usuário"
          />
        </Column>
        <Column
          position="centered"
          width="100%"
          justifyContent="center"
          paddingRight={width >= 1000 ? '10px' : '0px'}
        >
          <Spacing size="xx-small" />
          <Button
            width={width >= 1000 ? '120px' : '100%'}
            text="Aprovar"
            appearance={orderData?.order?.status !== 15 ? 'primary' : 'disabled'}
            onClick={() => {
              if (orderData?.order?.status !== 15) {
                setReproved(false);
                approveBeneficiarieDoc();
              }
            }}
          >
            <BsCheck style={{ color: orderData?.order?.status !== 15 ? '#fff' : '#A8B7BF' }} />
          </Button>
          <Spacing size="xx-small" />
          <Button
            width={width >= 1000 ? '120px' : '100%'}
            text="Reprovar"
            appearance={orderData?.order?.status !== 15 ? 'error' : 'disabled'}
            onClick={() => {
              if (orderData?.order?.status !== 15) {
                setReproved(true);
              }
            }}
          >
            <BsX style={{ color: orderData?.order?.status !== 15 ? '#fff' : '#A8B7BF' }} />
          </Button>
          <Spacing size="xx-small" />
          <Button
            width={width >= 1000 ? '120px' : '100%'}
            text="Visualizar"
            appearance={orderData?.order?.status !== 15 ? 'ghost' : 'primary'}
            onClick={() => {
              setReproved(false);
              window.open(image, '_blank').focus();
            }}
          >
            <BsBoxArrowUpRight
              style={{ width: '13px', color: orderData?.order?.status !== 15 ? '#373272' : '#fff' }}
            />
          </Button>
        </Column>
      </Row>
      <Spacing size="medium" />
      {reproved && (
        <Row width="100%" position="centered" align="start">
          <Column width="100%" position="centered" justifyContent="start">
            <Input
              name="holderName"
              type="text"
              width="100%"
              placeholder="Motivo da reprovação"
              value={reprovedMessage}
              onChange={(event) => {
                setReprovedMessage(event.target.value);
              }}
            />
          </Column>

          <Column width="50%" position="end" justifyContent="start">
            <Button
              width="120px"
              text="Reprovar"
              appearance="error"
              loading={loading}
              onClick={() => {
                if (orderData !== 15) {
                  reproveBeneficiarieDoc();
                }
              }}
            />
          </Column>
        </Row>
      )}
    </div>
  );
}

export default DocumentReviewer;
