import { Route, Routes } from 'react-router-dom';

import Auth from 'views/Auth';
import Conclusion from 'views/Conclusion';
import ConfirmPersonalData from 'views/ConfirmPersonalData';
import ContractAuth from 'views/ContractAuth';
import ContractUpload from 'views/ContractUpload';
import ContractUploadSuccess from 'views/ContractUploadSuccess';
import DeclarationHealth from 'views/DeclarationHealth';
import DeclarationHealthAuth from 'views/DeclarationHealthAuth';
import DeclarationHealthSuccess from 'views/DeclarationHealthSuccess';
import Detail from 'views/Detail';
import EnterpriseDocument from 'views/EnterpriseDocumentCheck';
import EnterpriseDocumentCheckConsultant from 'views/EnterpriseDocumentCheckConsultant';
import EnterpriseDocumentFinal from 'views/EnterpriseDocumentFinal';
import EnterpriseDocumentSuccess from 'views/EnterpriseDocumentSuccess';
import Home from 'views/Home';
import Jornadas from 'views/Jornadas';
import Product from 'views/Product';
import ProposalInitialHome from 'views/ProposalInitialHome';
import Registration from 'views/Registration';
import SpecialDiscountResume from 'views/SpecialDiscountResume';
import StartBeneficiariesJourney from 'views/StartBeneficiariesJourney';
import Summary from 'views/Summary';
import UploadDocuments from 'views/UploadDocuments';
import UploadReprovedDocuments from 'views/UploadReprovedDocuments';
import ConcludedPayment from '../views/ConcludedPayment';
import Contract from '../views/Contract';
import ContractSuccess from '../views/ContractSuccess';
import EnterpriseAgreement from '../views/EnterpriseAgreement';
import Error404 from '../views/Error404';
import ErrorConnection from '../views/ErrorConnection';
import ErrorPayment from '../views/ErrorPayment';
import FormBeneficiaries from '../views/FormBeneficiaries';
import FormBeneficiariesConcluded from '../views/FormBeneficiariesConcluded';
import FormCompany from '../views/FormCompany';
import Payment from '../views/Payment';
import Portability from '../views/Portability';
import Proposal from '../views/Proposal';
import ProposalAuth from '../views/ProposalAuth';
import ProposalDownload from '../views/ProposalDownload';
import ProposalEditSent from '../views/ProposalEditSent';
import ProposalSent from '../views/ProposalSent';
import ProposalSentConsultant from '../views/ProposalSentConsultant';
import ProposalSuccess from '../views/ProposalSuccess';
import ProposalSummary from '../views/ProposalSummary';
import ReviewBeneficiaries from '../views/ReviewBeneficiaries';
import ReviewBeneficiariesConsultant from '../views/ReviewBeneficiariesConsultant';
import ReviewBeneficiariesSuccess from '../views/ReviewBeneficiariesSuccess';
import ReviewHolder from '../views/ReviewHolder';
import ReviewHolderConsultant from '../views/ReviewHolderConsultant';
import SimulationConsultant from '../views/SimulationConsultant';
import SimulationDownload from '../views/SimulationDownload';
import SitePlans from '../views/SitePlans';
import WaitingPayment from '../views/WaitingPayment';
import Welcome from '../views/Welcome';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/produto/:id" element={<Product />} />
      <Route path="/detalhe/:id" element={<Detail />} />
      <Route path="/cadastro" element={<Registration />} />
      <Route path="/resumo/desconto-especial" element={<SpecialDiscountResume />} />
      <Route path="/autenticacao" element={<Auth />} />
      <Route path="/resumo" element={<Summary />} />
      <Route path="/pagamento" element={<Payment />} />
      <Route path="/jornadas" element={<Jornadas />} />
      <Route path="/proposta" element={<Proposal />} />
      <Route path="/proposta/home" element={<ProposalInitialHome />} />
      <Route path="/formulario/cadastro" element={<StartBeneficiariesJourney />} />
      <Route path="/formulario/cadastro/beneficiario" element={<ConfirmPersonalData />} />
      <Route path="/portabilidade" element={<Portability />} />
      <Route path="/declaracao-saude" element={<DeclarationHealth />} />
      <Route path="/declaracao-saude/auth" element={<DeclarationHealthAuth />} />
      <Route path="/declaracao-saude/sucesso" element={<DeclarationHealthSuccess />} />
      <Route path="/envio-documentos" element={<UploadDocuments />} />
      <Route path="/reenvio-documentos" element={<UploadReprovedDocuments />} />
      <Route path="/conclusao" element={<Conclusion />} />
      <Route path="/proposta:" element={<Proposal />} />
      <Route path="/proposta/resumo" element={<ProposalSummary />} />
      <Route path="/proposta/enviada" element={<ProposalSent />} />
      <Route path="/proposta/enviada/consultor" element={<ProposalSentConsultant />} />
      <Route path="/proposta/download" element={<ProposalDownload />} />
      <Route path="/proposta/autenticacao" element={<ProposalAuth />} />
      <Route path="/proposta/concluido" element={<ProposalSuccess />} />
      <Route path="/proposta/editar/sucesso" element={<ProposalEditSent />} />
      <Route path="/proposta/criada" element={<Welcome />} />
      <Route path="/formulario/empresa" element={<FormCompany />} />
      <Route path="/contrato/upload" element={<ContractUpload />} />
      <Route path="/contrato/auth" element={<ContractAuth />} />
      <Route path="/contrato/upload/sucesso" element={<ContractUploadSuccess />} />
      <Route path="/contrato" element={<Contract />} />
      <Route path="/contrato/sucesso" element={<ContractSuccess />} />
      <Route path="/analise/documento/empresa" element={<EnterpriseDocument />} />
      <Route
        path="/analise/documento/empresa/consultor"
        element={<EnterpriseDocumentCheckConsultant />}
      />
      <Route path="/analise/documento/empresa/sucesso" element={<EnterpriseDocumentSuccess />} />
      <Route path="/formulario/contrato" element={<EnterpriseAgreement />} />
      <Route path="/formulario/beneficiarios" element={<FormBeneficiaries />} />
      <Route path="/formulario/beneficiarios/concluido" element={<FormBeneficiariesConcluded />} />
      <Route path="/formulario/empresa/concluido" element={<EnterpriseDocumentFinal />} />

      <Route path="/revisao/beneficiarios" element={<ReviewBeneficiaries />} />
      <Route path="/revisao/beneficiarios/sucesso" element={<ReviewBeneficiariesSuccess />} />
      <Route path="/revisao/beneficiarios/consultor" element={<ReviewBeneficiariesConsultant />} />
      <Route path="/revisao/titular" element={<ReviewHolder />} />
      <Route path="/revisao/titular/consultor" element={<ReviewHolderConsultant />} />
      <Route path="/pagamento" element={<Payment />} />
      <Route path="/pagamento/aguardando" element={<WaitingPayment />} />
      <Route path="/pagamento/concluido" element={<ConcludedPayment />} />
      <Route path="/pagamento/erro" element={<ErrorPayment />} />
      <Route path="/erro/404" element={<Error404 />} />
      <Route path="/erro/conexao" element={<ErrorConnection />} />
      <Route path="/site/planos" element={<SitePlans />} />
      <Route path="/simulacao" element={<SimulationConsultant />} />
      <Route path="/simulacao/download" element={<SimulationDownload />} />
    </Routes>
  );
}

export default AppRoutes;
