import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-bro.png';

function Welcome() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Bem-vindo a Sulmed!"
        image={feedback}
        description="Para visualizar a sua propósta comercial clique em Abrir Proposta"
        buttonText="Abrir Proposta"
        onClickButton={() => navigate('/formulario/download')}
      />
    </Layout>
  );
}

export default Welcome;
