import { Spacing } from 'components';
import styles from './styles.module.scss';
import logo from '../../../../assets/logos/sulmed-logo-white-simple.svg';

function Footer() {
  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.about}`}>
        <div className={styles.column}>
          <img src={logo} alt="" />
          <p>
            Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
            resolutividade e menos gastos para todos.
          </p>
        </div>
      </div>
      <Spacing size="large" />
      <div className={styles.row}>
        <p>Todos os direitos reservados - 2022. Desenvolvido por Starta Ideia</p>
      </div>
    </div>
  );
}

export default Footer;
