import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown, FaTrashAlt } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Title, Row, Spacing, Column } from '..';
import check from '../../assets/icons/chip-check-icon.svg';
import info from '../../assets/icons/chip-info-icon.svg';

export type ChipProps = {
  color?: 'green' | 'grey';
  text?: string;
};

export function Chip({ text, color = 'grey' }: ChipProps) {
  return (
    <div className={`${styles.chip} ${color === 'green' ? styles.green : styles.grey}`}>
      <p>
        {color === 'green' && <img src={check} alt="" />}
        {color === 'grey' && <img src={info} alt="" />}
        {text}
      </p>
    </div>
  );
}

export type Props = {
  title: string;
  width?: any;
  padding?: string;
  children?: any;
  chip?: boolean;
  chipText?: string;
  chipColor?: 'green' | 'grey';
  deleteButton?: boolean;
  onClickDelete?: any;
  opened?: any;
};

function Accordion({
  title,
  width,
  padding = '24px',
  children,
  chip = false,
  chipText = '',
  chipColor = 'grey',
  deleteButton = false,
  onClickDelete = null,
  opened
}: Props) {
  const [closed, setClosed] = useState(opened);
  return (
    <div className={styles.container} style={{ padding }}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Title appearance="primary" bolder={true} textAlign="start">
            {title}
          </Title>
        </div>
        {chip && <Chip text={chipText} color={chipColor} />}
        <div className={styles.row} style={{ justifyContent: 'end' }}>
          {deleteButton && <FaTrashAlt className={styles.trashIcon} onClick={onClickDelete} />}
          <Spacing size="large" />
          {closed && <FaChevronUp onClick={() => setClosed(false)} style={{ color: '#22096F' }} />}
          {!closed && (
            <FaChevronDown onClick={() => setClosed(true)} style={{ color: '#22096F' }} />
          )}
        </div>
      </div>
      {closed && (
        <>
          <Spacing size="xxx-large" />
          <div className={styles.body}>{children}</div>
        </>
      )}
    </div>
  );
}

export default Accordion;
