import * as RadixDialog from '@radix-ui/react-dialog';
import { Button, Checkbox } from 'components';
import Dialog from 'components/Dialog';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import DownloadIcon from '../../../../../assets/icons/download.svg';
import Icon from '../../../../../assets/icons/info-icon.svg';
import styles from '../styles.module.scss';

type PendingProps = {
  setDisabledNext: React.Dispatch<React.SetStateAction<boolean>>;
  setPending: React.Dispatch<React.SetStateAction<boolean>>;
  pdf: string;
};

function Pending({ setDisabledNext, setPending, pdf }: PendingProps) {
  const [checked, setChecked] = useState(false);

  const loading = pdf === '';

  useEffect(() => {
    setDisabledNext(!checked);
  }, [checked, setDisabledNext]);

  const dialog = loading ? (
    <div className={styles.loader}>
      <Loader />
    </div>
  ) : (
    <div className={styles.dialog}>
      <a href={pdf} download target="_blank" rel="noreferrer">
        <Button text="Download" appearance="ghost" width="fit-content">
          <img src={DownloadIcon} alt="" />
        </Button>
      </a>
      <iframe
        src={`${pdf}#toolbar=0&view=FitH`}
        className={styles.pdf}
        title="Cobertura Parcial Temporária"
      />
      <Checkbox
        id="temporaryCoverage"
        label="Li e estou ciente as condições de cobertura temporária aqui descritas"
        checked={checked}
        setChecked={setChecked}
      />
      <RadixDialog.Close asChild>
        <Button
          text="Continuar"
          width="fit-content"
          onClick={() => setPending(false)}
          disabled={!checked}
        />
      </RadixDialog.Close>
    </div>
  );

  return (
    <div className={styles.column}>
      <img src={Icon} alt="" width={24} />
      <p className={styles.status}>Pendente</p>
      <div>
        <p className={styles.warning}>
          Você possui uma
          <Dialog
            content={dialog}
            title="Aviso de Cumprimento de Cobertura Parcial Temporária"
            description="Leia atentamente o Aviso de Cumprimento de Cobertura Parcial Temporária antes de continuar"
          >
            Cobertura Parcial Temporária
          </Dialog>
          vinculada ao seu Plano. Aceite-a para prosseguir com o envio de documentos.
        </p>
      </div>
    </div>
  );
}

export default Pending;
