import { useState } from 'react';
import { Layout, Spacing, Title, Text, Row, Button, Divider, FileUpload, Alert } from 'components';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

import info from '../../assets/icons/info-icon.svg';
import check from '../../assets/icons/check.svg';
import close from '../../assets/icons/x-icon.svg';
import warning from '../../assets/icons/warning-blue-icon.svg';

function Portability() {
  const navigate = useNavigate();
  const [addDocument, setAddDcoument] = useState<any>();
  const [concluded, setConcluded] = useState(false);
  const [document, setDocument] = useState({
    document_path: '',
    document_image: ''
  });
  const [alertData, setAlertData] = useState<any>({
    display: false,
    title: '',
    type: '',
    message: ''
  });

  function handleNext() {
    if (addDocument === true) {
      if (document.document_path !== '') {
        setConcluded(true);
      } else {
        setAlertData({
          display: true,
          type: 'warning',
          title: 'Atenção!',
          message:
            'Para continuar com o processo de envio da Carta de Portabilidade é necessário realizar o upload do arquivo no campo sinalizado.'
        });
        setTimeout(() => {
          setAlertData({
            display: false,
            type: '',
            title: '',
            message: ''
          });
        }, 7500);
      }
    } else {
      setConcluded(true);
    }
  }

  return (
    <Layout
      navbar
      steps={[
        { title: 'Beneficiários', status: 'inProgress' },
        { title: 'Declaração de saúde', status: 'pending' },
        { title: 'Documentos', status: 'pending' },
        { title: 'Conclusão', status: 'pending' }
      ]}
    >
      {alertData.display && (
        <Alert type={alertData.type} title={alertData.title} text={alertData.message} />
      )}
      {!concluded ? (
        <>
          <Title appearance="primary" bolder={true}>
            Portabilidade de Plano de Saúde (opcional)
          </Title>
          <Spacing size="x-small" />
          <Text maxWidth="90%">
            Se você já possui um plano de saúde, você pode realizar a portabilidade e aproveitar as
            informações do plano anterior. Dessa forma, não será necessário cumprir carência no
            plano novo. Para fazer a portabilidade é necessário ter a Carta de Portabilidade.
          </Text>
          <Spacing size="large" />
          <Row position="end">
            <Button
              width="360px"
              appearance="ghost"
              text="Como adquirir carta Carta de Portabilidade"
              onClick={() => {}}
              iconPosition="left"
            >
              <img src={info} alt="info icon" />
            </Button>
          </Row>
          <Spacing size="xxx-large" />
          <Row>
            <Text>Gostaria de fazer portabilidade do seu Plano de Saúde?</Text>
          </Row>
          <Spacing size="medium" />
          <Row width="90%" position="centered">
            <Button
              size="sm"
              width="120px"
              appearance={addDocument === false ? 'disabled' : 'ghost-borded'}
              text="Sim"
              iconPosition="left"
              onClick={() => setAddDcoument(true)}
            >
              <img src={check} style={{ width: '14px' }} alt="" />
            </Button>
            <Spacing size="medium" />
            <Button
              size="sm"
              width="120px"
              appearance={addDocument === true ? 'disabled' : 'ghost-borded'}
              text="Não"
              iconPosition="left"
              onClick={() => setAddDcoument(false)}
            >
              <img src={close} style={{ width: '14px' }} alt="" />
            </Button>
          </Row>
          <Spacing size="xxx-large" />
          {addDocument === true && (
            <>
              <Row>
                <Text maxWidth="100%">
                  Para dar continuidade na portabilidade, realize o upload dos documentos abaixo:
                </Text>
              </Row>
              <Spacing size="medium" />
              <Row position="start">
                <Text bolder={true}>Carta de Portabilidade</Text>
              </Row>
              <Spacing size="medium" />
              <FileUpload
                file={document.document_path}
                setFile={(event: any) => {
                  setDocument({
                    ...document,
                    document_image: event ? event.target.files[0] : '',
                    document_path: event || ''
                  });
                }}
                setImage={(event: any) => {
                  setDocument({
                    ...document,
                    document_path: event
                  });
                }}
              />
            </>
          )}
          <Spacing size="xxx-large" />
          <Divider />
          <Spacing size="xxx-large" />
          <Row width="98%" position="spaceBetween">
            <Button width="200px" appearance="ghost" text="Voltar" onClick={() => {}} />
            <Button
              width="200px"
              appearance={addDocument === true || addDocument === false ? 'primary' : 'disabled'}
              text="Continuar"
              onClick={() => (addDocument === true || addDocument === false ? handleNext() : '')}
            />
          </Row>
        </>
      ) : (
        <div className={styles.column}>
          <img src={warning} alt="" />
          <Spacing size="x-large" />
          <Title appearance="primary" bolder={true}>
            Processo de portabilidade em análise!
          </Title>
          <Spacing size="x-small" />
          <Text maxWidth="90%">
            O pedido de portabilidade pode levar até 10 dias para conclusão.
          </Text>
          <Spacing size="x-large" />
          <Button
            width="200px"
            text="Continuar"
            onClick={() => {
              if (addDocument === true) {
                navigate('/envio-documentos');
              } else {
                navigate('/declaracao-saude');
              }
            }}
          />
        </div>
      )}
    </Layout>
  );
}

export default Portability;
