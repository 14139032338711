import {
  Layout,
  Spacing,
  Title,
  Text,
  PDFViewer,
  Row,
  Button,
  Column,
  Spinner,
  Description
} from 'components';
import { useEffect, useState } from 'react';
import { getCommercialApresentation, getSimulationPDF } from 'api/services/ApiCalls';
import axios from 'axios';
import { FaDownload } from 'react-icons/fa';
import styles from './styles.module.scss';
import fileIcon from '../../assets/icons/file-icon-grey.png';

function SimulationDownload() {
  const [file, setFile] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getPDFSimulation(data) {
    getSimulationPDF({ simulation_id: data.id })
      .then((response) => {
        axios.get(response.data.simulation_pdf).then((response) => console.log(response));
        setFile(response.data.simulation_pdf);
        setLoadingData(false);
      })
      .catch((error) => console.error(error));
  }

  function getPdf() {
    getCommercialApresentation()
      .then((response) => {
        const pdf = response.data.documentation[0];
        setUrl(pdf.documentation_path);
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  function InstitutionalInformationDownload() {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Informativo Institucional.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      getPDFSimulation(jwtParsed);
    }
  }

  function downloadFile() {
    setLoading(true);
    const url = file;
    setTimeout(() => {
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Simulação.pdf');
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      });
    }, 1000);
  }

  useEffect(() => {
    setLoadingData(true);
    getPdf();
    const haveParams = window.location.search;
    if (haveParams) {
      getParams();
    }
  }, []);

  return (
    <Layout navbar={true} goBackButton={false}>
      <Spacing size="medium" />
      {loadingData ? (
        <>
          <Row width="100%" align="center">
            <Column width="100%" position="centered">
              <Spinner show={true} />
              <Spacing size="xx-small" />
              <Description appearance="primary" bolder={true}>
                Carregando os dados
              </Description>
            </Column>
          </Row>
          <Spacing size="x-large" />
        </>
      ) : (
        <>
          <Title appearance="primary" bolder={true}>
            Simulação Plano de Saúde Sulmed
          </Title>
          <Spacing size="x-small" />
          <Text>
            Uma simulação personalizada foi gerada para melhor te atender. Caso tenha dúvidas, entre
            em contato com seu Consultor Sulmed.
          </Text>
          <Spacing size="xxx-large" />
          {width >= 700 ? (
            <PDFViewer url={file} protocol={1149} />
          ) : (
            <div className={styles.file}>
              <img src={fileIcon} alt="" />
              <h1>simulação.pdf</h1>
              <i>82 KB</i>
            </div>
          )}
          {width >= 700 && (
            <>
              {' '}
              <Spacing size="medium" />
              <Row position="end" width="99%">
                <Button
                  text="Informativo Institucional"
                  appearance="ghost"
                  iconPosition="left"
                  width="240px"
                  onClick={() => InstitutionalInformationDownload()}
                >
                  <FaDownload />
                </Button>
              </Row>
            </>
          )}
          <Spacing size="large" />
          <Row position="centered" width="99%">
            <Column position="centered" width="100%">
              <Button
                text="Download em PDF"
                iconPosition="left"
                width="240px"
                appearance={loading ? 'disabled' : 'primary'}
                onClick={() => {
                  if (!loading) {
                    downloadFile();
                  }
                }}
                loading={loading}
              />
              {width <= 700 && (
                <>
                  <Spacing size="medium" />
                  <Row position="end" width="99%">
                    <Button
                      text="Informativo Institucional"
                      appearance="ghost"
                      iconPosition="left"
                      width="240px"
                      onClick={() => InstitutionalInformationDownload()}
                    >
                      <FaDownload />
                    </Button>
                  </Row>
                </>
              )}
            </Column>
          </Row>
        </>
      )}
    </Layout>
  );
}

export default SimulationDownload;
