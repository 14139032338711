import {
  getContractByOrderID,
  getContractData,
  getOrderData,
  getPlanById,
  sendContractAuthToken
} from 'api/services/ApiCalls';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  Alert,
  BottomNavigation,
  Button,
  Checkbox,
  Column,
  Description,
  Layout,
  PDFViewer,
  Row,
  Spacing,
  Spinner,
  Text,
  Title
} from '../../components';
import styles from './styles.module.scss';

function Contract() {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState<any>([]);
  const [plans, setPlans] = useState<any>([]);
  const [order, setOrder] = useState();
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<any>({
    opened: false,
    title: '',
    type: '',
    message: ''
  });

  function handleNext() {
    setLoading(true);
    const accepts = [];
    contracts.map((mocked) => {
      accepts.push(mocked.accept);
    });
    if (!accepts.includes(false)) {
      const formData = new FormData();
      formData.append('order_id', order);
      sendContractAuthToken(formData)
        .then(() => {
          setLoading(false);
          navigate('/contrato/auth');
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
      setLoading(false);
    } else {
      setLoading(false);
      setAlertData({
        opened: true,
        title: 'Atenção!',
        type: 'warning',
        message: 'Todos os todos os termos dos contratos precisam ser aceitos para prosseguir.'
      });
      setTimeout(() => {
        setAlertData({
          opened: false
        });
      }, 5500);
    }
  }

  function downloadContract(contract, title) {
    axios({
      url: contract,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    });
  }

  function downloadMinuta(planId: number) {
    getContractData(planId)
      .then((response) => {
        axios({
          url: response.data.contract.draft_document,
          method: 'GET',
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf'
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Minuta.pdf');
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function downloadGuides(plans) {
    plans.map((plan) => {
      axios({
        url: plan.documentation_path,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Guia de Leitura - plano ${plan.type}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    });
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      return jwtParsed.order;
    }
  }

  function getPlansInfo(contracts) {
    contracts.map((contract) => {
      getPlanById(contract.plan_id).then((response) => {
        contract.title = `${response.data.plan[0].name} + ${response.data.plan[0].subtitle}`;
        contract.accept = false;
        setContracts([...contracts]);
      });
    });
    setLoadingPage(false);
  }

  useEffect(() => {
    const orderID = getParams();
    setOrder(orderID);
    sessionStorage.setItem('@orderID', orderID);

    getOrderData(orderID)
      .then((response) => {
        console.log(response.data.order_data.order.status);
        if (
          response.data.order_data.order.status !== 8 &&
          response.data.order_data.order.status !== 9
        ) {
          navigate('/contrato/sucesso');
        }
      })
      .catch((error) => console.error(error));

    getContractByOrderID(orderID)
      .then((response) => {
        getPlansInfo(response.data.contract);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Layout
      navbar={true}
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Contrato', status: 'inProgress' },
        { title: 'Cadastro Beneficiários', status: 'pending' },
        { title: 'Análise Beneficiários', status: 'pending' },
        { title: 'Implantação', status: 'pending' }
      ]}
    >
      {alertData.opened && (
        <Alert type={alertData.type} title={alertData.title} text={alertData.message} />
      )}
      <Spacing size="large" />

      {!loadingPage ? (
        <>
          <Title appearance="primary" bolder={true}>
            Contrato
          </Title>
          <Spacing size="x-small" />
          <Text>
            Para essa proposta foi gerado {contracts.length} contrato(s) de adesão. Antes de
            finalizar a contratação do plano, leia atentamente as informações de cada contrato
            abaixo e aceite os termos e condições.
          </Text>
          <Spacing size="xxx-large" />
          {contracts.map((contract, key) => (
            <>
              <Accordion
                key={key}
                width="98%"
                title={`Contrato de Adesão Sulmed ${contract.title}`}
                padding="16px"
                chip={true}
                chipText={contract.accept ? 'Concluído' : 'Pendente'}
                chipColor={contract.accept ? 'green' : 'grey'}
                opened={false}
              >
                <PDFViewer url={contract.contract_document} />
                <Spacing size="large" />
                <div className={styles.options}>
                  <Spacing size="xx-small" />
                  <Checkbox
                    id={`${contract.id}`}
                    label="Declaro que li e aceito os termos e condições contratuais"
                    checked={contract.accept}
                    setChecked={() => {
                      contract.accept = !contract.accept;
                      setContracts([...contracts]);
                    }}
                  />
                  <div className={styles.buttons}>
                    <Button
                      size="sm"
                      text="Download Contrato"
                      width="190px"
                      appearance="ghost-borded"
                      onClick={() =>
                        downloadContract(
                          contract.contract_document,
                          `Contrato de Adesão Sulmed ${contract.title}.pdf`
                        )
                      }
                    >
                      <FaDownload />
                    </Button>
                    <Spacing size="medium" />
                    <Button
                      size="sm"
                      text="Download Minuta"
                      width="190px"
                      appearance="ghost-borded"
                      onClick={() => {
                        downloadMinuta(contract.plan_id);
                      }}
                    >
                      <FaDownload />
                    </Button>
                  </div>
                </div>
              </Accordion>
              <Spacing size="medium" />
            </>
          ))}
          {/* <Row>
            <Button
              size="sm"
              text="Aprovar todos ✨"
              width="200px"
              appearance="primary"
              onClick={() => {
                contracts.map((contract) => {
                  contract.accept = true;
                });
                setContracts([...contracts]);
              }}
            />
          </Row> */}
          <Spacing size="medium" />
          <Row width="95%" position="end">
            <Button
              text="Baixe aqui o Guia de Leitura Contratual"
              width="320px"
              appearance="ghost"
              iconPosition="left"
              onClick={() => {
                downloadGuides(plans);
              }}
            >
              <FaDownload style={{ margin: '0px 10px' }} />
            </Button>
          </Row>
          <Spacing size="xxx-large" />
          <BottomNavigation
            width="95%"
            labelLeft="Voltar"
            appearanceLeft="ghost"
            actionLeft={() => navigate('/revisao/beneficiarios')}
            labelRight="Finalizar"
            appearanceRight={loading ? 'disabled' : 'primary'}
            actionRight={() => !loading && handleNext()}
            loadingRight={loading}
          />
        </>
      ) : (
        <Row width="100%" align="center">
          <Column width="100%" position="centered">
            <Spinner show={true} />
            <Spacing size="medium" />
            <Description appearance="primary" bolder={true}>
              Carregando os dados
            </Description>
          </Column>
        </Row>
      )}
    </Layout>
  );
}

export default Contract;
