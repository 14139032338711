import React from 'react';
import { Layout, Feedback } from 'components';
import { useNavigate } from 'react-router-dom';
import feedback from '../../assets/icons/v-icon.svg';

function DeclarationHealthSuccess() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="As informações declaradas foram salvas!"
        image={feedback}
        description="A seguir daremos continuidade ao processo de cadastro de documentação dos beneficiários."
        buttonText="Continuar"
        onClickButton={() => navigate('/envio-documentos')}
      />
    </Layout>
  );
}

export default DeclarationHealthSuccess;
