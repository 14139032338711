import { updateCPTDependent, updateCPTHolder } from 'api/services/ApiCalls';
import { Button, Column, Divider, Dropdown, Row, Select, Spacing, Spinner, Text } from 'components';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

function CPT({ cpt, type, questions, holder, orderID, handleCPT }) {
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

  function handleHolderCPT() {
    updateCPTHolder({
      order_id: orderID,
      holder_id: holder.id,
      description_cpt_id: selectedQuestion.id
    })
      .then((response) => {
        setSelectedQuestion(null);
        handleCPT();
      })
      .catch((error) => console.error(error));
  }

  function handleDependentCPT() {
    updateCPTDependent({
      order_id: orderID,
      dependent_id: holder.id,
      description_cpt_id: selectedQuestion.id
    })
      .then((response) => {
        setSelectedQuestion(null);
        handleCPT();
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    console.log(cpt);
  }, []);

  return (
    <Dropdown title="Cobertura Parcial Temporária">
      <Row>
        <Column position="start">
          {cpt && cpt.length > 0 ? (
            cpt.map((item, key) => (
              <div key={key} style={{ width: '100%', textAlign: 'start' }}>
                {key > 0 && <Divider />}
                <p className={styles.cptItem}>
                  <span>{key + 1})</span> {item}
                </p>
              </div>
            ))
          ) : (
            <>
              <Spacing size="small" />
              <Row align="center">
                <Text>
                  O {type === 'holder' ? 'titular' : 'dependente'} não gerou nenhuma Cobertura
                  Parcial Temporária
                </Text>
              </Row>
              <Spacing size="small" />
            </>
          )}
          {!cpt && (
            <Row width="100%" align="center">
              <Column width="100%" position="centered">
                <Spinner show={true} />
              </Column>
            </Row>
          )}
        </Column>
      </Row>
      <Spacing size="medium" />
      <Row position="start">
        <Select
          width="97%"
          label="Selecione..."
          value={selectedQuestion?.title}
          data={questions}
          onClick={(event: any) => setSelectedQuestion(event)}
        />
      </Row>
      <Row position="end">
        <Button
          width="150px"
          text="Incluir CPT"
          onClick={() => {
            if (type === 'holder') {
              handleHolderCPT();
            } else {
              handleDependentCPT();
            }
          }}
        />
      </Row>
    </Dropdown>
  );
}

export default CPT;
