import React from 'react';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-error-connection.png';

function ErrorConnection() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Parece que você está sem conexão"
        description="Verifique a sua internet e tente outra vez."
        buttonText="Tentar Novamente"
        onClickButton={() => window.location.reload()}
        image={feedback}
      />
    </Layout>
  );
}

export default ErrorConnection;
