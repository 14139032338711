/* eslint-disable react/self-closing-comp */
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Plan from '../Plan';

function Tabs({ width, data, onClickPlan }: any) {
  const [plans, setPlans] = useState([
    {
      title: 'Global',
      type: 'global',
      active: true,
      plans: []
    },
    {
      title: 'Ambulatorial',
      type: 'ambulatorial',
      active: false,
      plans: []
    },
    {
      title: 'Odontológico',
      type: 'odontologico',
      active: false,
      plans: []
    }
  ]);

  function changePlanActive(type) {
    plans.map((plan) => {
      plan.active = false;
    });
    const activePlan = plans.find((plan) => plan.type === type);
    activePlan.active = true;
    setPlans([...plans]);
  }

  useEffect(() => {
    const global = [];
    const ambulatorial = [];
    const odontologico = [];

    data.map((item) => {
      // eslint-disable-next-line default-case
      switch (item.type) {
        case 'Global':
          global.push(item);
          break;
        case 'Ambulatorial':
          ambulatorial.push(item);
          break;
        case 'Odontológico':
          odontologico.push(item);
          break;
      }
    });
    plans.map((plan) => {
      // eslint-disable-next-line default-case
      switch (plan.title) {
        case 'Global':
          plan.plans = global;
          break;
        case 'Ambulatorial':
          plan.plans = ambulatorial;
          break;
        case 'Odontológico':
          plan.plans = odontologico;
          break;
      }
    });
    setPlans([...plans]);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {plans.map((plan, key) => (
          <div
            key={key}
            className={`${styles.button} ${plan.active === true && styles.active} ${
              styles[plan.type]
            }`}
            onClick={() => changePlanActive(plan.type)}
          >
            <p>{plan.title}</p>
            <div className={styles.line}>.</div>
          </div>
        ))}
      </div>
      <div className={styles.plans}>
        {plans.map(
          (plan) =>
            plan.active && (
              <>
                {plan.plans.map((item, key) => (
                  <Plan plan={plan} item={item} key={key} width={width} onClickPlan={onClickPlan} />
                ))}
              </>
            )
        )}
      </div>
      <div className={styles.footer}>
        {plans.map((plan, key) => (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <div
            key={key}
            className={`${styles.item} ${styles[plan.type]} ${
              plan.active === true && styles.active
            }`}
            onMouseOver={() => changePlanActive(plan.type)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Tabs;
