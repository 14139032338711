import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProposalContext } from 'context/ProposalContext';
import { getCommercialApresentation, resendToken } from 'api/services/ApiCalls';
import { FaDownload } from 'react-icons/fa';
import axios from 'axios';
import styles from './styles.module.scss';
import { Layout, Spacing, Title, Text, Row, Button, PDFViewer } from '../../components';

function ProposalDownload() {
  const navigate = useNavigate();
  const { order, setOrder } = useProposalContext();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');

  function getPdf() {
    getCommercialApresentation()
      .then((response) => {
        const pdf = response.data.documentation[0];
        setUrl(pdf.documentation_path);
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function InstitutionalInformationDownload() {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Informativo Institucional.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }

  async function handleGenerateToken() {
    setLoading(true);
    const formData = new FormData();
    formData.append('order_id', order.id);

    await resendToken(formData)
      .then((response) => {
        navigate('/proposta/autenticacao');
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
    setLoading(false);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      setOrder(jwtParsed);
      sessionStorage.setItem('@orderData', JSON.stringify(jwtParsed));
      const user = {
        enterprise: jwtParsed.enterprise,
        cnpj: jwtParsed.cnpj
      };
      sessionStorage.setItem('@userData', JSON.stringify(user));
    }
  }

  function handleNextPage() {
    handleGenerateToken();
  }

  useEffect(() => {
    getPdf();
    getParams();
  }, []);

  return (
    <Layout navbar={true} goBackButton={false}>
      <Spacing size="medium" />
      <Title appearance="primary" bolder={true}>
        Proposta #{order.id}
      </Title>
      <Spacing size="x-small" />
      <Text>
        Leia com atenção as informações da proposta, antes de aceitá-la. Caso esteja fazendo essa
        proposta junto com um consultor, recomenda-se ter acesso ao e-mail e telefone de cadastro
        para realizar a autenticação.
      </Text>
      <Spacing size="xxx-large" />
      <PDFViewer url={order.prospect_contract_link} protocol={order.id} />
      <Spacing size="large" />
      <Row position="end" width="99%">
        <Button
          text="Informativo Institucional"
          appearance="ghost"
          iconPosition="left"
          width="240px"
          onClick={() => InstitutionalInformationDownload()}
        >
          <FaDownload />
        </Button>
      </Row>
      <Spacing size="xxx-large" />
      <Row position="centered">
        <Button
          appearance={!loading ? 'primary' : 'disabled'}
          text="Aceitar Proposta"
          width="187px"
          onClick={() => !loading && handleNextPage()}
          loading={loading}
        />
      </Row>
      <Spacing size="large" />
    </Layout>
  );
}

export default ProposalDownload;
