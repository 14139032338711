/* eslint-disable array-callback-return */
import axios from 'axios';
import {
  Button,
  Column,
  Description,
  Input,
  Modal,
  Row,
  Spacing,
  Text,
  Title,
  Undertitle
} from 'components';
import { useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FiActivity } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { maskCep, maskMoney } from 'utils/mask';
import layer from '../../assets/Layer 1.png';
import banner from '../../assets/happy-doctor-holding-clipboard-with-patients 1-1.png';
import errorIcon from '../../assets/icons/error-unenabled-location-icon.png';
import logo from '../../assets/logos/conexxa-logo.png';
import styles from './styles.module.scss';

const fetcher = (url) => axios.get(url).then((res) => res.data);

function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useSWR(`https://backoffice.sulmed.startaideia.dev/api/packages/${id}`, fetcher);
  const [packages, setPackages] = useState([]);
  const [modalLocation, setModalLocation] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [cep, setCep] = useState('');
  const storage = JSON.parse(sessionStorage.getItem('@data'));

  useEffect(() => {
    if (storage === null || storage.location.postCode === '') {
      setModalLocation(true);
    }
  }, []);

  return (
    data && (
      <div className={styles.container}>
        <Modal
          isOpen={modalLocation}
          title="Informe o CEP da sua região"
          description="Iremos filtrar os serviços de saúde associados a
          Conexxa disponíveis na sua região."
          footerButtonFirst="Cancelar"
          footerButtonFirstAppearance="ghost"
          footerButtonFirstWidth="74px"
          onClickFooterFirst={() => setModalLocation(false)}
          footerButtonSecond="Continuar"
          footerButtonSecondAppearance="primary"
          footerButtonSecondWidth="74px"
          onClickClose={() => setModalLocation(false)}
        >
          <Spacing size="large" />
          <Input
            name="cep"
            type="text"
            width="100%"
            maxLength={10}
            label="CEP"
            value={cep}
            onChange={(event) => {
              setCep(maskCep(event.target.value));
            }}
          />
          <Spacing size="large" />
        </Modal>

        <Modal
          isOpen={modalErrorIsOpen}
          image={errorIcon}
          imageWidth="70px"
          title="Serviço indisponível em sua região"
          description="Este pacote não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
          onClickClose={() => setModalErrorIsOpen(false)}
        >
          <Spacing size="xxx-large" />
          <Row>
            <Column position="centered">
              <Button
                text="Ver serviços disponíveis"
                width="195px"
                onClick={() => setModalErrorIsOpen(false)}
              />
              <Spacing size="medium" />
              <Button
                text="Mudar localização"
                appearance="ghost"
                width="165px"
                onClick={() => {
                  setModalErrorIsOpen(false);
                  setModalLocation(true);
                }}
                iconPosition="left"
              >
                <FaMapMarkerAlt />
              </Button>
            </Column>
          </Row>
        </Modal>

        <div className={styles.navbar}>
          <img src={logo} alt="logo conexx saúde" draggable="false" onClick={() => navigate('/')} />
          <ul>
            <li>Planos e Serviços</li>
            <li>Procedimentos</li>
            <li>Parceiros</li>
            <li>Perguntas Frequentes</li>
          </ul>
        </div>
        <div className={styles.banner}>
          <div className={styles.bannerFilter} />
          <img src={banner} className={styles.bannerBackground} draggable="false" />
          <div className={styles.bannerContent}>
            <h2>
              <FiActivity className={styles.icon} /> {data.categories.name}
            </h2>
          </div>
        </div>
        <div className={styles.about}>
          <div className={styles.aboutContent}>
            <Title bolder={true} appearance="primary" position="centered" width="100%">
              {data.categories.name}
            </Title>
            <Spacing size="medium" />
            <Row position="centered" width="100%">
              <Text position="centered" width="100%">
                {data.categories.description}
              </Text>
            </Row>
          </div>
        </div>
        <div className={styles.packages}>
          <Spacing size="xxx-large" />
          {packages.length > 0 ? (
            <div className={styles.packagesRow}>
              {packages.map((elem) => (
                <div className={styles.package} key={elem.name}>
                  <div className={styles.packageBanner}>
                    <img src={banner} draggable="false" className={styles.packageBannerImage} />
                    <img src={layer} draggable="false" className={styles.layer} />
                  </div>
                  <div className={styles.packageContent}>
                    <Spacing size="x-large" />
                    <Undertitle bolder={true} appearance="primary">
                      {elem.name}
                    </Undertitle>
                    <Spacing size="large" />
                    <ul className={styles.productDetails}>
                      {elem.exams.map((exam) => (
                        <li key={exam.name}> {exam.name}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <Row position="end" width="85%">
                      <Undertitle bolder={true} appearance="primary">
                        R$ {maskMoney(Number(elem.value))}
                      </Undertitle>
                    </Row>
                    <Row position="end" width="85%">
                      <Description>ou 2x no cartão sem juros</Description>
                    </Row>
                    <Spacing size="xxx-large" />
                    <Row>
                      <Button
                        text="Comprar"
                        width="84px"
                        onClick={() => navigate(`/detalhe/${elem.id}`)}
                      />
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <Text appearance="grey"> Nenhum pacote foi encontrado para a sua região </Text>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.footerContent}>
            <div className={`${styles.footerColumn} ${styles.centered}`}>
              <img src={logo} className={styles.logo} draggable="false" />
              <Description appearance="white">
                Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
                resolutividade e menos gastos para todos.
              </Description>
            </div>
            <div className={`${styles.footerColumn} ${styles.end}`}>
              <div>
                <p>Planos e Serviços</p>
                <Spacing size="medium" />
                <p>Procedimentos</p>
                <Spacing size="medium" />
                <p>Contato</p>
                <Spacing size="medium" />
                <p>Perguntas Frequentes</p>
              </div>
            </div>
            <div className={`${styles.footerColumn} ${styles.end}`}>
              <div>
                <p>Planos e Serviços</p>
                <Spacing size="medium" />
                <p>Procedimentos</p>
                <Spacing size="medium" />
                <p>Contato</p>
                <Spacing size="medium" />
                <p>Perguntas Frequentes</p>
              </div>
            </div>
          </div>
          <Spacing size="xx-large" />
          <Row>
            <Description appearance="white">
              Todos os direitos reservados - 2023. Desenvolvido por Starta Ideia
            </Description>
          </Row>
        </div>
      </div>
    )
  );
}

export default Product;
