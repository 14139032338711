import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from 'context/DataContext';
import ReactCodeInput from 'react-code-input';
import { verifyToken, resendTokenPurchase } from 'api/services/ApiCalls';
import styles from './styles.module.scss';
import { Layout, Title, Text, Spacing, Description, BottomNavigation, Row } from '../../components';

function Auth() {
  const navigate = useNavigate();
  const [code, setCode] = useState<any>('');
  const { context }: any = useContext(DataContext);

  const [userData, setUserData] = useState({
    telephone: '',
    email: ''
  });

  useEffect(() => {
    const data = sessionStorage.getItem('@userData');
    if (data) {
      const parsedData = JSON.parse(data);
      setUserData({
        telephone: parsedData.telephone,
        email: parsedData.email
      });
    }
  });

  function handleCode(event: any) {
    setCode(event);
  }

  async function handleAuthenticate() {
    const formData = new FormData();
    formData.append('token', code);
    formData.append('order_id', context.info.orderId);

    await verifyToken(formData)
      .then((response) => {
        navigate('/resumo');
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  async function resendCode() {
    const formData = new FormData();
    formData.append('purchase_id', context.info.orderId);

    await resendTokenPurchase(formData)
      .then((response) => {
        console.info(response.data);
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Title appearance="primary" bolder={true}>
        Informe o código de autenticação
      </Title>
      <Spacing size="x-small" />
      <Text>
        Para sua segurança lhe enviamos via SMS e E-mail um código de autenticação. Verifique sua
        caixa de entrada ou spam.
      </Text>
      <Spacing size="xxx-large" />
      <ReactCodeInput
        type="number"
        fields={6}
        value={code}
        name={code}
        autoFocus={true}
        inputMode="latin"
        onChange={(event: any) => {
          handleCode(event);
        }}
        inputStyle={{
          fontFamily: 'monospace',
          borderRadius: '20px',
          border: '1px solid #E8ECEE',
          margin: '4px 14px',
          width: '80px',
          height: '100px',
          fontSize: '62px',
          boxSizing: 'border-box',
          color: 'black',
          backgroundColor: '#FFFFFF',
          borderColor: '#E8ECEE',
          userSelect: 'none',
          outline: 'none',
          textAlign: 'center'
        }}
      />
      <Spacing size="xxx-large" />
      <Description appearance="grey">
        Não recebeu o código?{' '}
        <a href="#" onClick={resendCode} className={styles.link}>
          Clique Aqui
        </a>
      </Description>
      <Spacing size="x-large" />

      <Row width="70%" position="centered">
        <BottomNavigation
          labelLeft="Cancelar"
          appearanceLeft="ghost"
          actionLeft={() => navigate('/cadastro')}
          labelRight="Enviar"
          appearanceRight="primary"
          actionRight={handleAuthenticate}
        />
      </Row>
    </Layout>
  );
}

export default Auth;
