/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

export const ProposalContext = createContext({} as any);

export function ProposalProvider({ children }) {
  const [planValues, setPlanValues] = useState({
    underTotal: 0,
    discountPorcentage: 0,
    discountValue: 0,
    totalValue: 0
  });
  const [order, setOrder] = useState({
    cnpj: '',
    created_at: '',
    id: 0,
    phone: '',
    prospect_contract_link: '',
    status: 0,
    token: 0,
    total: 0,
    updated_at: ''
  });
  const [formPlans, setFormPlans] = useState([
    {
      age: '0 - 18',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '19 - 23',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '24 - 28',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '29 - 33',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '34 - 38',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '39 - 43',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '44 - 48',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '49 - 53',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '54 - 58',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    },
    {
      age: '59 - 75',
      plan: 'Open Ambulatorial',
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    }
  ]);

  function addNewForm() {
    const newPlan = {
      age: '',
      plan: '',
      plan_id: 0,
      owners: 0,
      dependents: 0,
      value: 0,
      totalValue: 0
    };
    setFormPlans((exitingPlan) => [...exitingPlan, newPlan]);
  }

  function orderByPlan(plan_id, plans) {
    const element = plans.filter((element) => element.id === plan_id);

    if (element.length > 0) {
      const newValue = [...formPlans];

      for (let i = 0; i < newValue.length; i++) {
        newValue[i].plan = element[0].title;
      }

      setFormPlans(newValue);
    }
  }

  function handleRemovePlan(id: number) {
    const newList = formPlans.filter((plan, index) => index !== id);
    setFormPlans(newList);
  }

  function handleSetDiscount(coupons, coupomName) {
    const validCupom = coupons.filter((coupon) => coupon.name === coupomName);
    if (validCupom[0].discount_value === null) {
      const number = Number(validCupom[0].discount_percentage);
      const calc = (number * Number(planValues.underTotal)) / 100;
      const totalValue = Number(planValues.underTotal) - calc;
      setPlanValues({
        ...planValues,
        discountValue: calc,
        totalValue
      });
    } else {
      const value_discount = Number(validCupom[0].discount_value);
      const totalValue = Number(planValues.underTotal) - value_discount;
      setPlanValues({
        ...planValues,
        discountValue: value_discount,
        totalValue
      });
    }
  }
  // const memoizedPlanValues = useMemo(() => ({ planValues }), [planValues]);

  return (
    <ProposalContext.Provider
      value={{
        setPlanValues,
        planValues,
        order,
        setOrder,
        handleRemovePlan,
        handleSetDiscount,
        addNewForm,
        formPlans,
        setFormPlans,
        orderByPlan
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
}

export function useProposalContext() {
  const context = useContext(ProposalContext);

  return context;
}
