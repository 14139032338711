import * as RadixDialog from '@radix-ui/react-dialog';
import { PropsWithChildren } from 'react';
import { BsXLg } from 'react-icons/bs';
import styles from './styles.module.scss';

type DialogProps = {
  content: React.ReactNode;
  title?: string;
  description?: string;
  showClose?: boolean;
} & PropsWithChildren;

function Dialog({ children, title, description, showClose, content }: DialogProps) {
  return (
    <RadixDialog.Root>
      <RadixDialog.Trigger className={styles.button}> {children} </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <RadixDialog.Overlay asChild>
          <div className={styles.overlay} />
        </RadixDialog.Overlay>
        <RadixDialog.Content>
          <div className={styles.container}>
            {showClose && (
              <RadixDialog.Close asChild>
                <button className={styles.close}>
                  <BsXLg size={16} />
                </button>
              </RadixDialog.Close>
            )}
            <RadixDialog.Title asChild>
              <h6 className={styles.title}>{title}</h6>
            </RadixDialog.Title>
            <RadixDialog.Description asChild>
              <p className={styles.description}>{description}</p>
            </RadixDialog.Description>
            {content}
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

export default Dialog;
