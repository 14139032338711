import * as RadixCheckbox from '@radix-ui/react-checkbox';
import styles from './styles.module.scss';

type CheckboxProps = {
  id: string;
  label: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

function Checkbox({ id, label, checked, setChecked }: CheckboxProps) {
  return (
    <div className={styles.container}>
      <RadixCheckbox.Root
        className={styles.root}
        id={id}
        checked={checked}
        onCheckedChange={() => setChecked(!checked)}
      >
        <RadixCheckbox.Indicator className={styles.indicator}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M2.5 6.5L4.5 8.5L9.5 3.5"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
