import { Layout, Feedback } from 'components';
import React from 'react';
import feedback from '../../assets/sulmed-feedback-success.png';

function EnterpriseDocumentSuccess() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Análise da empresa concluída com sucesso!"
        image={feedback}
        description="A etapa de envio e assinatura do contrato já pode ser iniciada."
      />
    </Layout>
  );
}

export default EnterpriseDocumentSuccess;
