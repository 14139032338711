import {
  deleteBeneficiaries,
  finishBeneficiariesRegister,
  getActivesPlans,
  getDocumentations,
  getOrderData,
  getOrdersPlan,
  getReletionships,
  getTableCommercial,
  postHolder,
  sendBeneficariesXls,
  updateBeneficiariesData
} from 'api/services/ApiCalls';
import axios from 'axios';
import { AlertState } from 'components/Alert';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { PiInfoBold, PiPencilSimple, PiTrash } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { PageSizeContext } from 'context/PageSizeContext';
import { maskCPF, maskCep, maskCnpj, maskPhone, maskReset } from 'utils/mask';
import { Tooltip, IconButton, Stack, Chip } from '@mui/material';
import {
  Alert,
  BottomNavigation,
  Button,
  Card,
  Column,
  Description,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Spacing,
  Spinner,
  Text,
  Title,
  DatePicker
} from '../../components';
import styles from './styles.module.scss';

function FormBeneficiaries() {
  const navigate = useNavigate();
  const { width }: any = useContext(PageSizeContext);
  const legalAge = dayjs().subtract(18, 'year');
  const [form, setForm] = useState<any>({
    id: 0,
    cpf: '',
    name: '',
    mother_name: '',
    birth_date: '',
    email: '',
    phone: '',
    sex: '',
    civil_status: '',
    cep: '',
    street: '',
    district: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    numberDependents: '',
    plan: '',
    plan_id: '',
    dependents: []
  });
  const [formValidations, setFormValidations] = useState<any>({
    cpf: {
      status: false,
      message: 'Campo Obrigatório'
    },
    birth_date: {
      status: false,
      message: 'Campo Obrigatório'
    },
    name: {
      status: false,
      message: 'Campo Obrigatório'
    },
    mothers_name: {
      status: false,
      message: 'Campo Obrigatório'
    },
    civil_status: {
      status: false,
      message: 'Campo Obrigatório'
    },
    gender: {
      status: false,
      message: 'Campo Obrigatório'
    },
    email: {
      status: false,
      message: 'Campo Obrigatório'
    },
    phone: {
      status: false,
      message: 'Campo Obrigatório'
    },
    plan: {
      status: false,
      message: 'Campo Obrigatório'
    },
    cep: {
      status: false,
      message: 'Campo Obrigatório'
    },
    street: {
      status: false,
      message: 'Campo Obrigatório'
    },
    district: {
      status: false,
      message: 'Campo Obrigatório'
    },
    number: {
      status: false,
      message: 'Campo Obrigatório'
    },
    city: {
      status: false,
      message: 'Campo Obrigatório'
    },
    state: {
      status: false,
      message: 'Campo Obrigatório'
    }
  });
  const [formEditing, setFormEditing] = useState<any>({});
  const [orderId, setOrderId] = useState(0);
  const [order, setOrder] = useState<any>();
  const [register, setRegister] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [modalImportDepenendents, setModalImportDepenendents] = useState(false);
  const [commertialTable, setCommertialTable] = useState([]);
  const [ownerPlans, setOwnerPlans] = useState([]);
  const [ownerDependents, setOwnerDependents] = useState([]);
  const [limitDependentsReached, setLimitDependentsReached] = useState(false);
  const [limitHoldersReached, setLimitHoldersReached] = useState(false);
  const [loadingImportDependents, setLoadingImportDependents] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csv, setCsv] = useState<any>(null);
  const [corporateInfo, setCorporateInfo] = useState({
    name: '',
    cnpj: ''
  });
  const [alert, setAlert] = useState<AlertState | null>(null);
  const [showForm, setShowForm] = useState(true);
  const [companyJourney, setCompanyJourney] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingRegisteringHolder, setLoadingRegisteringHolder] = useState(false);
  const [table, setTable] = useState([]);
  const [owners, setOwners] = useState([]);
  const [exempleXLS, setExampleXLS] = useState('');
  const [registered, setRegistered] = useState<any>();

  function resetDependents() {
    form.dependents = [];
    setForm(form);
  }

  function validateBeneficiariesDocument(document: string, type: string) {
    const documents = [];

    const { cpf, dependents } = form;

    // verifica o tipo passado como prop
    if (type === 'Títular') {
      // inclui todos os cpf de dependentes do formulário
      if (dependents.length > 0) {
        dependents.map((dependent) => {
          documents.push(dependent.cpf);
        });
      }
    } else {
      // inclui o cpf do titular no formulário
      documents.push(cpf);
    }

    // pega o cpf do titular e de todos os dependentes ja registrados anteriormente
    if (register.length > 0) {
      register.map((item) => {
        documents.push(item.cpf);

        if (item.dependents.length > 0) {
          item.dependents.map((dependent) => {
            documents.push(dependent.cpf && maskReset(dependent.cpf));
          });
        }
      });
    }

    return documents.includes(document);
  }

  function getAddress(cep: any) {
    axios
      .get(`https://viacep.com.br/ws/${maskReset(cep)}/json/`)
      .then((response) => {
        setForm({
          ...form,
          street: response.data.logradouro,
          district: response.data.bairro,
          complement: response.data.complemento,
          city: response.data.localidade,
          state: response.data.uf
        });
        setFormValidations({
          ...formValidations,
          street: {
            status: false,
            message: ''
          }
        });
        setFormValidations({
          ...formValidations,
          distric: {
            status: false,
            message: ''
          }
        });
        setFormValidations({
          ...formValidations,
          city: {
            status: false,
            message: ''
          }
        });
        setFormValidations({
          ...formValidations,
          state: {
            status: false,
            message: ''
          }
        });
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  function resetFormData() {
    setForm({
      id: 0,
      cpf: '',
      name: '',
      mother_name: '',
      birth_date: '',
      email: '',
      phone: '',
      sex: '',
      civil_status: '',
      cep: '',
      street: '',
      district: '',
      number: '',
      complement: '',
      city: '',
      state: '',
      numberDependents: '',
      plan: '',
      plan_id: '',
      dependents: []
    });
  }

  function resetAlert() {
    setAlert(null);
  }

  async function sendXls() {
    setLoadingImportDependents(true);
    try {
      const file = csv;
      const formData = new FormData();
      formData.append('beneficiaries', file);
      formData.append('order_id', `${orderId}`);
      const response = await sendBeneficariesXls(formData);
      if (response.data.beneficiaries.length > 0) {
        response.data.beneficiaries.map((beneficiariy) => {
          beneficiariy.birth_date = dayjs(beneficiariy.birth_date).format('DD/MM/YYYY');
        });
      }
      setRegister([...response.data.beneficiaries]);
      sessionStorage.setItem(
        '@formBeneficiaries',
        JSON.stringify([...response.data.beneficiaries])
      );
      setAlert({
        ...{
          show: true,
          type: 'success',
          title: 'Importado!',
          text: 'O seu arquivo foi importado com sucesso.'
        }
      });
      setTimeout(() => {
        resetAlert();
      }, 5500);
      setModalImportDepenendents(false);
      setCsv(null);
      setShowForm(false);
    } catch (error) {
      setAlert({
        ...{
          show: true,
          type: 'error',
          title: 'Atenção!',
          text: error.response.data.details
        }
      });
      setTimeout(() => {
        resetAlert();
      }, 5500);
      setCsv(null);
    } finally {
      setLoadingImportDependents(false);
    }
  }

  async function getPlans() {
    try {
      const response = await getActivesPlans();
      const formattedPlan = response.data.plans.map((plan) => {
        const formatPlan = {
          id: plan.id,
          title: `${plan.name} ${plan.subtitle}`,
          value: `${plan.name} ${plan.subtitle}`,
          coparticipation: plan.co_participation,
          status: false,
          attributes: plan.plan_attributes,
          type: plan.type
        };
        return formatPlan;
      });
      setPlans(formattedPlan);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  async function getRelationship() {
    try {
      const response = await getReletionships();
      const formattedRelation = response.data.client_relationships.map((relation) => {
        const formatData = {
          id: relation.id,
          title: relation.name,
          value: relation.name
        };
        return formatData;
      });
      setRelationships(formattedRelation);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  function handleDependents(amount) {
    for (let index = 1; index <= amount; index++) {
      form.dependents.push({
        id: index,
        document: '',
        mother_name: '',
        email: '',
        birth_date: null,
        name: '',
        sex: '',
        kinship: '',
        checkBirthdayValue: false,
        checkBirthdayValueMessage: 'Campo Obrigatório!',
        checkDocumentValue: false,
        checkDocumentValueMessage: 'Campo Obrigatório!'
      });
    }
    setForm({ ...form, numberDependents: amount });
  }

  function loadCsv(event: any) {
    setCsv(event.target.files[0]);
  }

  function handleFormDependents(field, value, id) {
    const item = form.dependents.find((row) => row.id === id);
    item[field] = value;
    setForm({ ...form });
  }

  function checkHoldersRegister(plan) {
    if (register.length > 0) {
      let count = 0;
      register.forEach((v) => v.plan_id === plan.plan_id && count++);
    }
  }

  function getOccurrence(array, value) {
    let count = 0;
    array.forEach((v) => v === value && count++);
    return count;
  }

  function validateHolderAge(birthdate: string) {
    const birthDate = dayjs(dayjs(birthdate).format('MM/DD/YYYY'));
    const currentYear = dayjs();
    const age = Math.floor(currentYear.diff(birthDate, 'year', true));
    form.birth_date = dayjs(birthdate).format('DD/MM/YYYY');
    form.numberDependents = '';
    form.plan = '';
    form.plan_id = '';
    form.age = age;
    setForm({ ...form });
    resetDependents();
    setFormValidations({ ...formValidations, birth_date: { status: false, message: '' } });

    if (isEditing || registered.registered_owners < registered.total_holders) {
      const relevantPlans = commertialTable
        .filter(
          (commercialItem) => age >= commercialItem.minimum_age && age <= commercialItem.maximum_age
        )
        .map((commercialItem) => ({
          ...commercialItem,
          title: `${commercialItem.plan.name} ${commercialItem.plan.subtitle}`
        }));

      const newArray = owners
        .filter((owner) => {
          if (owner.owners > 0) {
            if (owner.age === '59 +') {
              return age >= 59 && age <= 100;
            } else if (owner.age === '0 - 100') {
              const minAge = Number(owner.age.substring(0, 2));
              const maxAge = Number(owner.age.slice(-3));
              return age >= minAge && age <= maxAge;
            } else {
              const minAge = Number(owner.age.substring(0, 2));
              const maxAge = Number(owner.age.slice(-2));
              return age >= minAge && age <= maxAge;
            }
          }
        })
        .map((owner) => ({
          ...relevantPlans.find((item) => item.plan_id === owner.plan_id),
          ...owner
        }));

      if (newArray.length === 0) {
        setFormValidations({
          ...formValidations,
          birth_date: { status: true, message: 'Nenhum titular encontrado nesta faixa etária!' }
        });
      }
      setOwnerPlans(newArray);
    } else {
      setFormValidations({
        ...formValidations,
        birth_date: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
      setFormValidations({
        ...formValidations,
        birth_date: {
          status: true,
          message:
            'O limite máximo de titulares disponiveis para cadastro nesta proposta foi atingido!'
        }
      });
      setAlert({
        ...{
          show: true,
          type: 'error',
          title: 'Atenção!',
          text: 'O limite máximo de titulares disponiveis para cadastro nesta proposta foi atingido!'
        }
      });

      setTimeout(() => {
        resetAlert();
      }, 5500);
    }
  }

  function validateBeneficiarieAge(birthdate: string, dependentId) {
    const birthDate = dayjs(dayjs(birthdate).format('MM/DD/YYYY'));
    const currentYear = dayjs();
    const age = Math.floor(currentYear.diff(birthDate, 'year', true));
    const item = form.dependents.find((row) => row.id === dependentId);
    item.age = age;
    item.birth_date = dayjs(birthdate).format('DD/MM/YYYY');
    const check = [];
    registered.simulation.map((dependent) => {
      if (dependent.plan_id === form.plan_id) {
        if (dependent.age === '59 +') {
          if (age >= 59 && age <= 100) {
            if (dependent.dependents > 0) {
              check.push(true);
            }
          } else {
            check.push(false);
          }
        } else if (dependent.age === '0 - 100') {
          if (
            age >= Number(dependent.age.substring(0, 2)) &&
            age <= Number(dependent.age.slice(-3))
          ) {
            if (dependent.dependents > 0) {
              check.push(true);
            }
          } else {
            check.push(false);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (
            age >= Number(dependent.age.substring(0, 2)) &&
            age <= Number(dependent.age.slice(-2))
          ) {
            if (dependent.dependents > 0) {
              check.push(true);
            }
          } else {
            check.push(false);
          }
        }
      }
    });
    item.checkField = !check.includes(true);
    setForm({ ...form, dependents: [...form.dependents] });
  }

  function defineOwnerAge(birth_date) {
    const birthDate = dayjs(birth_date);
    const currentYear = dayjs();
    const age = Math.floor(currentYear.diff(birthDate, 'year', true));

    let selectedPlans = [];
    table.map((elem: any) => {
      selectedPlans = [...selectedPlans, ...Object.entries(elem.dependents).map((e) => e[1])];
    });

    const planos = [];
    commertialTable.map(async (commercialItem) => {
      if (age >= commercialItem.minimum_age && age <= commercialItem.maximum_age) {
        commercialItem.title = `${commercialItem.plan.name} ${commercialItem.plan.subtitle}`;
        planos.push(commercialItem);
      }
    });

    const selectedPlansArray = [];
    const checkHaveOwners = [];
    setFormValidations({
      ...formValidations,
      birth_date: {
        status: false,
        message: ''
      }
    });

    selectedPlans.map((selectedPlan: any) => {
      if (selectedPlan.age.substring(0, 1) === '0') {
        selectedPlan.minimum_age = Number(selectedPlan.age.substring(0, 1));
        selectedPlan.maximum_age = Number(selectedPlan.age.substring(3));
      }
      if (selectedPlan.age.substring(0, 2) !== '59') {
        selectedPlan.minimum_age = Number(selectedPlan.age.substring(0, 2));
        selectedPlan.maximum_age = Number(selectedPlan.age.substring(5));
      } else {
        selectedPlan.minimum_age = Number(selectedPlan.age.substring(0, 2));
        selectedPlan.maximum_age = 110;
      }
      if (selectedPlan.age === '0 - 100') {
        selectedPlan.minimum_age = 0;
        selectedPlan.maximum_age = 100;
      }
    });

    // Verifica a quantidade de titulares dentro de uma mesma faixa etária tem nos planos selecionados
    selectedPlans.map((seletedPlan: any) => {
      if (age >= seletedPlan.minimum_age && age <= seletedPlan.maximum_age) {
        if (seletedPlan.owners > 0) {
          planos.map((plano) => {
            if (plano.plan_id === seletedPlan.plan_id) {
              plano.dependents = seletedPlan.dependents;
              selectedPlansArray.push(plano);
              checkHoldersRegister(seletedPlan);
              checkHaveOwners.push(true);
            }
          });
        } else {
          checkHaveOwners.push(false);
        }
      }
    });
    setOwnerPlans(selectedPlansArray);

    if (!checkHaveOwners.includes(true)) {
      setFormValidations({
        ...formValidations,
        birth_date: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    }

    getActivesPlans().then((response) => {
      response.data.plans.map((plan: any) => {
        plan.title = `${plan.name} ${plan.subtitle}`;
      });
    });

    return checkHaveOwners.includes(true);
  }

  function checkDependentsBirthdayField(dependent: any, event: any) {
    const checks = [];
    const formDependentAges = [];

    // Check if dependent age combine with plan`s register
    table.map((item) => {
      if (form.plan === item.plan) {
        item.dependents.map((dependent) => {
          form.dependents.map((formDependent) => {
            if (formDependent.birth_date) {
              const dependentAge =
                dayjs().year() - Number(dayjs(formDependent.birth_date).format('YYYY'));
              if (dependentAge >= dependent.minimum_age && dependentAge <= dependent.maximum_age) {
                // Add deendent age to array to just permit register another if this age hasnt rached the limit
                formDependentAges.push(`${dependent.minimum_age} - ${dependent.maximum_age}`);
              }
            }
          });
        });
      }
    });

    // Verify if a age can be registered in the plan selected
    table.map((item) => {
      if (form.plan === item.plan) {
        item.dependents.map((dependent) => {
          const age = dayjs().year() - Number(dayjs(event).format('YYYY'));

          // check all plan dependents to pick the one closest to the stated age
          if (age >= dependent.minimum_age && age <= dependent.maximum_age) {
            checks.push(false);

            // Create variable to count number of dependents with same age
            let count = 0;
            register.map((item) => {
              if (form.plan === item.plan) {
                item.dependents.map((elem) => {
                  const ageDependent =
                    dayjs().year() - Number(dayjs(elem.birth_date).format('YYYY'));
                  if (
                    ageDependent >= dependent.minimum_age &&
                    ageDependent <= dependent.maximum_age
                  ) {
                    count += 1;
                  }
                });
              }
            });

            if (count === Number(dependent.dependent)) {
              checks.push(true);
            }

            if (form.dependents.length > 1) {
              form.dependents.map((formDependent) => {
                if (formDependent.birth_date) {
                  const dependentAge =
                    dayjs().year() - Number(dayjs(formDependent.birth_date).format('YYYY'));
                  if (
                    dependentAge >= dependent.minimum_age &&
                    dependentAge <= dependent.maximum_age
                  ) {
                    const count = getOccurrence(
                      formDependentAges,
                      `${dependent.minimum_age} - ${dependent.maximum_age}`
                    );

                    if (count <= Number(dependent.dependent)) {
                      checks.push(false);
                    } else {
                      checks.push(true);
                    }
                  }
                }
              });
            } else {
              checks.push(false);
            }
          }
        });
      }
    });

    if (checks.includes(true)) {
      dependent.checkBirthdayValue = true;
      dependent.checkBirthdayValueMessage = 'Campo obrigatório';
      setForm({ ...form });
    } else {
      dependent.checkBirthdayValue = false;
      dependent.checkBirthdayValueMessage = '';
      setForm({ ...form });
    }

    return checks.includes(true);
  }

  function checkRegisteredHolders() {
    let holder = 0;
    order.order_data.order.order_simulation.map((simulation) => {
      if (simulation.plan_id === form.plan_id) {
        holder += Number(simulation.owners);
      }
    });

    let registered = 0;
    register.map((item) => {
      if (item.plan_id === form.plan_id) {
        registered += 1;
      }
    });

    if (registered >= holder) {
      setLimitDependentsReached(true);
      setAlert({
        ...{
          show: true,
          type: 'warning',
          title: 'Atenção!',
          text: 'O Limite de Títulares foi alcançado para o plano selecionado!'
        }
      });

      setTimeout(() => {
        resetAlert();
      }, 5500);
    }

    return registered < holder;
  }

  function validateHolderDocument(document) {
    if (register.length > 0) {
      const allDocuments = [];
      register.map((item) => {
        if (form.plan !== '') {
          if (
            maskReset(document) === maskReset(item.cpf) &&
            form.plan === item.plan &&
            formEditing.id !== form.id
          ) {
            allDocuments.push(true);
          } else {
            allDocuments.push(false);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (maskReset(document) === maskReset(item.cpf)) {
            allDocuments.push(true);
          } else {
            allDocuments.push(false);
          }
        }
      });

      if (allDocuments.includes(true)) {
        setFormValidations({
          ...formValidations,
          cpf: {
            status: true,
            message: 'Você ja cadastrou outros beneficiarios com este CPF'
          }
        });
        return true;
      } else {
        return false;
      }
    }
  }

  function checkRequestedFields() {
    if (form.cpf === '' || form.cpf === undefined) {
      setFormValidations({
        ...formValidations,
        cpf: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        cpf: {
          status: false,
          message: ''
        }
      });
    }

    if (form.name === '' || form.name === undefined) {
      setFormValidations({
        ...formValidations,
        name: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        name: {
          status: false,
          message: ''
        }
      });
    }

    if (form.mother_name === '' || form.mother_name === undefined) {
      setFormValidations({
        ...formValidations,
        mothers_name: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        mothers_name: {
          status: false,
          message: ''
        }
      });
    }

    if (form.email === '' || form.email === undefined) {
      setFormValidations({
        ...formValidations,
        email: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        email: {
          status: false,
          message: ''
        }
      });
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
      setFormValidations({
        ...formValidations,
        email: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        email: {
          status: false,
          message: ''
        }
      });
    }

    if (form.birth_date === '' || form.birth_date === undefined) {
      setFormValidations({
        ...formValidations,
        birth_date: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        birth_date: {
          status: false,
          message: ''
        }
      });
    }

    if (form.phone === '' || form.phone === undefined) {
      setFormValidations({
        ...formValidations,
        phone: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        phone: {
          status: false,
          message: ''
        }
      });
    }

    if (form.plan === '' || form.plan === undefined) {
      setFormValidations({
        ...formValidations,
        plan: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        plan: {
          status: false,
          message: ''
        }
      });
    }

    if (form.cep === '' || form.cep === undefined) {
      setFormValidations({
        ...formValidations,
        cep: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        cep: {
          status: false,
          message: ''
        }
      });
    }

    if (form.street === '' || form.street === undefined) {
      setFormValidations({
        ...formValidations,
        street: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        street: {
          status: false,
          message: ''
        }
      });
    }

    if (form.district === '' || form.district === undefined) {
      setFormValidations({
        ...formValidations,
        disctrict: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        disctrict: {
          status: false,
          message: ''
        }
      });
    }

    if (form.number === '' || form.number === undefined) {
      setFormValidations({
        ...formValidations,
        number: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        number: {
          status: false,
          message: ''
        }
      });
    }

    if (form.city === '' || form.city === undefined) {
      setFormValidations({
        ...formValidations,
        city: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        city: {
          status: false,
          message: ''
        }
      });
    }

    if (form.state === '' || form.state === undefined) {
      setFormValidations({
        ...formValidations,
        state: {
          status: true,
          message: 'Campo Obrigatório'
        }
      });
    } else {
      setFormValidations({
        ...formValidations,
        state: {
          status: false,
          message: ''
        }
      });
    }

    if (
      form.cpf !== '' &&
      form.cpf !== undefined &&
      form.name !== '' &&
      form.name !== undefined &&
      form.mother_name !== '' &&
      form.mother_name !== undefined &&
      form.email !== '' &&
      form.email !== undefined &&
      form.birth_date !== '' &&
      form.birth_date !== undefined &&
      form.phone !== '' &&
      form.phone !== undefined &&
      form.plan !== '' &&
      form.plan !== undefined &&
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkDependentsRegistered() {
    let exist = 0;
    register.map((regItem) => {
      if (regItem.plan_id === form.plan_id) {
        exist +=
          regItem.numberDependents === 'Sem Dependentes' ? 0 : Number(regItem.numberDependents);
      }
    });

    let limit = 0;
    table.map((item) => {
      if (item.plan === form.plan) {
        item.dependents.map((dependent) => {
          limit += Number(dependent.dependent);
        });
      }
    });

    if (exist >= limit) {
      setLimitDependentsReached(true);
      setAlert({
        ...{
          show: true,
          type: 'warning',
          title: 'Atenção!',
          text: 'O Limite de Dependentes foi alcançado para o plano selecionado!'
        }
      });

      setTimeout(() => {
        resetAlert();
      }, 5500);
    }

    return exist < limit;
  }

  // temporary castoff
  function handleEditTitular() {
    updateBeneficiariesData({
      holder: {
        ...form,
        birth_date: dayjs(form.birth_date).format('DD/MM/YYYY'),
        phone: maskReset(form.phone),
        zip_code: form.cep,
        order_id: orderId
      },
      dependents: form.dependents,
      order_id: orderId
    })
      .then((response) => {
        setIsEditing(false);
        sessionStorage.setItem('@formBeneficiaries', JSON.stringify({ ...form }));
        setAlert({
          ...{
            show: true,
            type: 'success',
            title: 'Alterado!',
            text: 'Os dados dos beneficiários foram atualizados com sucesso.'
          }
        });
        setTimeout(() => {
          resetAlert();
        }, 5500);
      })
      .catch((error) => {
        setAlert({
          ...{
            show: true,
            type: 'error',
            title: 'Atenção!',
            text: 'Ocorreu um problema ao salvar os dados, revise os campos preenchidos'
          }
        });
        setTimeout(() => {
          resetAlert();
        }, 5500);
      });
  }

  async function handleForm() {
    setLoading(true);
    finishBeneficiariesRegister({
      order_id: orderId
    }).then(() => {
      setLoading(false);
      navigate('/formulario/beneficiarios/concluido');
    });
  }

  async function getCommertialTable() {
    const commercialTable: any = await getTableCommercial();
    setCommertialTable(commercialTable.data.table_commercial);
  }

  function checkHoldersRegistered(event: any) {
    let exist = 0;
    register.map((regItem) => {
      if (regItem.plan_id === event.plan_id) {
        exist++;
      }
    });

    let owners = 0;
    table.map((item) => {
      if (item.plan === event.title) {
        item.dependents.map((dependent) => {
          owners += dependent.owners;
        });
      }
    });

    if (exist === owners) {
      setLimitHoldersReached(true);

      setAlert({
        ...{
          show: true,
          type: 'warning',
          title: 'Atenção!',
          text: 'O Limite de cadastro de titulares para o plano selecionado foi atingido!'
        }
      });

      setTimeout(() => {
        resetAlert();
      }, 5500);
    }
  }

  function validateNumberOfDependentes(event) {
    setLimitHoldersReached(false);
    setForm({ ...form, plan: event.plan, plan_id: event.plan_id });
    setLimitDependentsReached(false);
    let dependents = 0;
    table.map((seletedPlan: any) => {
      if (event.plan === seletedPlan.plan) {
        seletedPlan.dependents.map((dependent) => {
          if (dependent.dependent > '0') {
            dependents += Number(dependent.dependent);
          }
        });
      }
    });

    let exist = 0;
    register.map((regItem) => {
      if (regItem.plan_id === event.plan_id) {
        exist +=
          regItem.numberDependents === 'Sem Dependentes' ? 0 : Number(regItem.numberDependents);
      }
    });

    const dependentsList = [];
    for (let index = 1; index <= dependents - exist; index++) {
      dependentsList.push({
        title: `${index}`,
        value: index
      });
    }
    setOwnerDependents(dependentsList);
  }

  function validateHoldersData(event) {
    setLimitHoldersReached(false);
    setForm({ ...form, plan: event.title, plan_id: event.plan_id });
    setLimitDependentsReached(false);

    let dependents = 0;
    registered.simulation.map((item) => {
      if (item.plan_id === event.plan_id) {
        dependents += item.dependents;
      }
    });

    const dependentsList = [];
    for (let index = 1; index <= dependents; index++) {
      dependentsList.push({
        title: `${index}`,
        value: index
      });
    }
    setOwnerDependents(dependentsList);

    if (register.length > 0) {
      checkDependentsRegistered();
      checkHoldersRegistered(event);
    }
  }

  function validateBeneficiarieDocument(type, id, document) {
    if (register.length > 0) {
      const dependentsDocuments = [];
      register.map((item) => {
        item.dependents.map((dependent) => {
          if (maskReset(document) === maskReset(form.cpf)) {
            dependentsDocuments.push(true);
          }
          if (maskReset(document) === maskReset(dependent.cpf) && form.plan === item.plan) {
            dependentsDocuments.push(true);
          } else {
            dependentsDocuments.push(false);
          }
        });
      });

      if (dependentsDocuments.includes(true)) {
        return true;
      } else {
        return false;
      }
    }
  }

  async function searchPlans() {
    // remove that api call after show to clients
    const plans: any = await getActivesPlans();
    plans.data.plans.map((plan: any) => {
      plan.title = `${plan.name} ${plan.subtitle}`;
    });
  }

  function showAlert(type, title, text) {
    setAlert({
      show: true,
      type,
      title,
      text
    });
    setTimeout(() => {
      resetAlert();
    }, 5500);
  }

  function updateRegisteredData() {
    registered.simulation.forEach((item) => {
      if (form.plan_id === item.plan_id) {
        if (item.owners > 0) {
          const minAge = item.age.includes('+') ? 0 : Number(item.age.substring(0, 2));
          const maxAge =
            item.age.includes('+') || item.age.includes('100') ? 100 : Number(item.age.slice(-2));

          if (form.age >= minAge && form.age <= maxAge) {
            item.owners -= 1;
            registered.registered_owners += 1;
          }
        }

        if (form.dependents.length > 0 && item.dependents > 0) {
          form.dependents.forEach((dependent) => {
            const minAge = item.age.includes('+') ? 0 : Number(item.age.substring(0, 2));
            const maxAge =
              item.age.includes('+') || item.age.includes('100') ? 100 : Number(item.age.slice(-2));

            if (dependent.age >= minAge && dependent.age <= maxAge) {
              item.dependents -= 1;
              registered.registered_dependents += 1;
            }
          });
        }
      }
    });
    setRegistered({ ...registered });
  }

  // Remover esta função depois de usar
  function devFunc(data: any) {
    const info = [];
    data.map((item) => {
      info.push({
        index: item.plan_id,
        plan: item.plan,
        age: item.age,
        owners: item.owners,
        dependents: item.dependents
      });
    });
    console.info('Segue abaixo tabela dos planos selecionados pelo usuário ✨');
    console.table(info);
  }

  function getOrderPlans(orderID: any) {
    const formData = new FormData();
    formData.append('order_id', orderID);
    getOrdersPlan(formData)
      .then((response) => {
        const plansOrganized = [];
        response.data.order_plans.map((plan) => {
          plansOrganized.push({
            attributes: plan.plan_attributes,
            dependents: plan.order_simulations,
            plan: `${plan.name} ${plan.subtitle}`
          });
        });
        setTable(plansOrganized);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getOrder(orderID: any) {
    getOrderData(orderID)
      .then((response) => {
        getOrderPlans(response.data.order_data.order.id);
        setCorporateInfo({
          name: response.data.order_data.order.company_name,
          cnpj: response.data.order_data.order.cnpj
        });
        if (response.data.order_data.order.holders.length > 0) {
          const final = [];

          // get the simulation array and organize information
          let holders = 0;
          let dependents = 0;
          response.data.order_data.order.order_simulation.map((item) => {
            holders += Number(item.owners);
            dependents += Number(item.dependents);
            if (item.owners !== '0' || item.dependents !== '0') {
              final.push({
                order_id: item.order_id,
                plan_id: item.plan_id,
                age: item.age,
                plan: item.plan,
                owners: Number(item.owners),
                dependents: Number(item.dependents)
              });
            }
          });

          // remove registered owners from final array
          response.data.order_data.order.holders.forEach((holder) => {
            final.forEach((item) => {
              const birthDate = dayjs(holder.birth_date);
              const currentYear = dayjs();
              const age = Math.floor(currentYear.diff(birthDate, 'year', true));

              if (holder.plan_id === item.plan_id) {
                if (item.owners > 0) {
                  if (item.age === '0 - 100') {
                    item.owners -= 1;
                  } else if (age >= item.age.substring(0, 2) && age <= item.age.slice(-2)) {
                    item.owners -= 1;
                  } else {
                    item.owners -= 1;
                  }
                }

                if (item.dependents > 0) {
                  if (holder.dependents.length > 0) {
                    holder.dependents.forEach((dependent) => {
                      const birthDate = dayjs(dependent.birth_date);
                      const currentYear = dayjs();
                      const dependentAge = Math.floor(currentYear.diff(birthDate, 'year', true));

                      if (item.age === '0 - 100') {
                        item.dependents -= 1;
                      } else if (
                        dependentAge >= item.age.substring(0, 2) &&
                        dependentAge <= item.age.slice(-2)
                      ) {
                        item.dependents -= 1;
                      } else {
                        item.dependents -= 1;
                      }
                    });
                  }
                }
              }
            });
          });
          setOwners(final);
          setRegistered({
            registered_owners: response.data.order_data.order.holders.length,
            registered_dependents: 0,
            total_holders: holders,
            total_dependents: dependents,
            simulation: final
          });
        } else {
          const final = [];
          let holders = 0;
          let dependents = 0;
          response.data.order_data.order.order_simulation.map((item) => {
            holders += Number(item.owners);
            dependents += Number(item.dependents);
            if (item.owners !== '0' || item.dependents !== '0') {
              final.push({
                order_id: item.order_id,
                plan_id: item.plan_id,
                age: item.age,
                plan: item.plan,
                owners: Number(item.owners),
                dependents: Number(item.dependents)
              });
            }
          });
          setOwners(final);
          setRegistered({
            registered_owners: 0,
            registered_dependents: 0,
            total_holders: holders,
            total_dependents: dependents,
            simulation: final
          });
        }
        if (response.data.order_data.order.holders.length > 0) {
          response.data.order_data.order.holders.map((holder) => {
            holder.birth_date = dayjs(holder.birth_date).format('DD/MM/YYYY');
            holder.sex = holder.sex === 'm' ? 'Masculino' : 'Feminino';
            holder.numberDependents =
              holder.dependents.length > 0 ? holder.dependents.length : 'Sem Dependentes';
            holder.dependents.map((dependent) => {
              dependent.birth_date = dayjs(dependent.birth_date).format('DD/MM/YYYY');
              dependent.sex = dependent.sex === 'm' ? 'Masculino' : 'Feminino';
            });
          });
          setRegister([...response.data.order_data.order.holders]);
        }
        devFunc(response.data.order_data.order.order_simulation);
        setOrder(response.data);
        setLoadingContent(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleAddTitular() {
    setLoadingRegisteringHolder(true);
    const check = checkRequestedFields();
    if (check && registered.registered_owners < registered.total_holders) {
      postHolder({
        holders: [
          {
            ...form,
            phone: maskReset(form.phone),
            zip_code: form.cep,
            order_id: orderId
          }
        ]
      })
        .then(() => {
          sessionStorage.setItem('@formBeneficiaries', JSON.stringify([{ ...form }]));
          updateRegisteredData();
          setFormValidations({
            ...formValidations,
            birth_date: {
              status: false,
              message: ''
            }
          });
          setAlert({
            ...{
              show: true,
              type: 'success',
              title: 'Cadastrado!',
              text: 'O cadastro de beneficiario foi salvo com sucesso.'
            }
          });
          setTimeout(() => {
            resetAlert();
          }, 5500);
          resetFormData();
          setLoadingRegisteringHolder(false);
          getOrder(orderId);
        })
        .catch((error) => {
          setAlert({
            ...{
              show: true,
              type: 'error',
              title: 'Atenção!',
              text: 'Ocorreu um problema ao salvar os dados, revise os campos preenchidos'
            }
          });
          setTimeout(() => {
            resetAlert();
          }, 5500);
          setLoading(false);
          setLoadingRegisteringHolder(false);
        });
    } else {
      let message = '';
      if (!check) {
        message = 'Campos obrigatórios não foram preenchidos corretamente';
      }
      if (registered.registered_owners === registered.total_holders) {
        message =
          'O limite máximo de titulares disponiveis para cadastro nesta proposta foi atingido!';
      }
      setAlert({
        ...{
          show: true,
          type: 'error',
          title: 'Atenção!',
          text: message
        }
      });
      setTimeout(() => {
        resetAlert();
      }, 5500);
      setLoadingRegisteringHolder(false);
    }
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      setOrderId(jwtParsed.id);
      getOrder(jwtParsed.id);
      setCompanyJourney(true);
    } else {
      setCompanyJourney(false);
    }
  }

  function getDocs() {
    getDocumentations()
      .then((response) => {
        response.data.documentation.map((documentation) => {
          if (documentation.type === 'Cadastro de Vidas') {
            setExampleXLS(documentation.documentation_path);
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function editBeneficiarie(elem: any) {
    setIsEditing(true);
    const item = register.find((row) => row.id === elem.id);
    if (item.zip_code) {
      item.cep = item.zip_code;
    }
    if (item.birth_date) {
      defineOwnerAge(form.birth_date);
      const plan = plans.find((plan) => plan.id === item.plan_id);
      item.plan = plan.title;
    }
    if (item.dependents.length === 0) {
      item.numberDependents = 'Sem Dependentes';
    }

    defineOwnerAge(item.birth_date);
    validateNumberOfDependentes({
      plan: item.plan,
      plan_id: item.plan_id
    });
    setForm({ ...item });
    setFormEditing({ ...item });
  }

  function handleRemoveTitular(id: string) {
    deleteBeneficiaries({ _method: 'delete', holder_id: id })
      .then((response) => {
        sessionStorage.removeItem('@formBeneficiaries');
        setRegister([]);
        getOrder(orderId);
        setAlert({
          ...{
            show: true,
            type: 'success',
            title: 'Sucesso!',
            text: response.data.message
          }
        });
        setTimeout(() => {
          resetAlert();
        }, 5500);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    setLoadingContent(true);
    getPlans();
    getRelationship();
    getCommertialTable();
    searchPlans();
    getDocs();

    getParams();

    const user = JSON.parse(sessionStorage.getItem('@userData'));
    if (user) {
      setCorporateInfo({
        name: user.enterprise,
        cnpj: user.cnpj
      });
    }

    const formBeneficiaries = JSON.parse(sessionStorage.getItem('@formBeneficiaries'));
    if (formBeneficiaries) {
      setRegister(formBeneficiaries);
    }
  }, []);

  return (
    <>
      {alert?.show && <Alert type={alert.type} title={alert.title} text={alert.text} />}

      <Modal
        isOpen={modalImportDepenendents}
        width="700px"
        height="490px"
        title="Importar Arquivo"
        description="Para importar as informações dos beneficiários, siga os passos abaixo:"
        onClickClose={() => {
          setModalImportDepenendents(false);
        }}
      >
        <div style={{ margin: '0px' }}>
          <Text appearance="grey" textAlign="start">
            <p style={{ margin: '0px' }}>
              1. Baixe o
              <span
                style={{ color: '#30A8E0', cursor: 'pointer', margin: '0px 3px' }}
                onClick={() => {
                  axios({
                    url: exempleXLS,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                      'Content-Type':
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                  }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Cadastro_de_Beneficiários_da_Empresa.xlsx');
                    document.body.appendChild(link);
                    link.click();
                  });
                }}
              >
                Modelo de Planilha
              </span>
              e preencha com as informações dos beneficiários.
            </p>
          </Text>
          <Spacing size="medium" />
          <Text appearance="grey" textAlign="start">
            2. Todas as colunas devem ser preenchidas corretamente com as informações solicitadas.
          </Text>
          <Spacing size="medium" />
          <Text appearance="grey" textAlign="start">
            3. Importe o Modelo de Planilha para que as informações sejam carregadas.
          </Text>
          <Spacing size="medium" />
          <Text appearance="grey" textAlign="start">
            4. A planilha possui um cadastro de exemplo, que deve ser excluído antes do envio, e
            informações adicionais em notas.
          </Text>
          <Spacing size="xx-large" />
          <label htmlFor="arquivo" className={styles.fileBox}>
            {csv === null ? (
              <>
                <input
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  value={csv}
                  onChange={(event) => {
                    loadCsv(event);
                  }}
                  className={styles.fileInput}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <div className={styles.fileUpload}>
                  <p>
                    <span>Clique aqui</span>
                  </p>
                  <p>e faça upload do seu arquivo</p>
                  <p>xls, xlsx</p>
                </div>
              </>
            ) : (
              <div className={`${styles.fileUpload} ${styles.uploaded}`}>
                <p>Arquivo anexado com sucesso!</p>
                <FaTrashAlt
                  onClick={() => {
                    setCsv(null);
                  }}
                />
              </div>
            )}
          </label>
        </div>
        <Spacing size="large" />
        <div className={styles.row}>
          <Button
            text="Voltar"
            appearance="ghost-borded"
            width={width >= 1000 ? '45%' : '90%'}
            onClick={() => setModalImportDepenendents(false)}
          />
          <Spacing size="medium" />
          <Button
            text="Importar"
            appearance={loadingImportDependents ? 'disabled' : 'primary'}
            width={width >= 1000 ? '45%' : '90%'}
            onClick={() => {
              if (!loadingImportDependents && csv) {
                sendXls();
              }
            }}
            loading={loadingImportDependents}
          />
        </div>
      </Modal>

      {loadingContent ? (
        <Layout
          navbar={true}
          verticalCentered={true}
          steps={[
            { title: 'Dados da empresa', status: 'concluded' },
            { title: 'Contrato', status: 'concluded' },
            { title: 'Cadastro Beneficiários', status: 'inProgress' },
            { title: 'Análise Beneficiários', status: 'pending' },
            { title: companyJourney ? 'Concluído' : 'Implantação', status: 'pending' }
          ]}
        >
          <Row width="100%" align="center">
            <Column width="100%" position="centered">
              <Spinner show={true} />
              <Spacing size="xx-small" />
              <Description appearance="primary" bolder={true}>
                Carregando os dados
              </Description>
            </Column>
          </Row>
        </Layout>
      ) : (
        <Layout
          navbar={true}
          steps={[
            { title: 'Dados da empresa', status: 'concluded' },
            { title: 'Contrato', status: 'concluded' },
            { title: 'Cadastro Beneficiários', status: 'inProgress' },
            { title: 'Análise Beneficiários', status: 'pending' },
            { title: companyJourney ? 'Concluído' : 'Implantação', status: 'pending' }
          ]}
        >
          <Spacing size="medium" />
          <Title appearance="primary" bolder={true}>
            Cadastro dos Beneficiários
          </Title>
          <Spacing size="x-small" />
          <Text>
            Preencha todas as informações do titular, ou se preferir, insira os dados de contato dos
            titulares e envie para que eles possam finalizar o cadastro
          </Text>
          <Spacing size="large" />
          <Row position="end" width="97%">
            <Button
              width="fit-content"
              appearance="ghost"
              onClick={() => setModalImportDepenendents(true)}
              iconPosition="left"
              text="Importar Arquivo"
            >
              <PiInfoBold size={18} />
            </Button>
          </Row>
          <Spacing size="large" />
          <Card appearance="ghost" width="97%">
            <Row position="start">
              <Text bolder={true} maxWidth="100%" textAlign="start">
                Informações da empresa
              </Text>
            </Row>
            <Spacing size="xx-large" />
            <Row>
              <Column>
                <Description textAlign="start">Razão Social</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true} textAlign="start">
                  {corporateInfo.name}
                </Text>
              </Column>
              {width <= 1000 && <Spacing size="small" />}
              <Column>
                <Description textAlign="start">CNPJ</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true}>{maskCnpj(corporateInfo.cnpj)}</Text>
              </Column>
            </Row>
          </Card>
          <Spacing size="large" />
          <Card appearance="primary" width="97%" textAlign="start">
            <Stack direction="row" spacing={1}>
              <Chip label="Versão Beta" size="small" color="primary" variant="outlined" />
            </Stack>
            <Spacing size="medium" />
            <p>
              Títulares:{' '}
              <b>
                {registered?.registered_owners} / {registered?.total_holders}
              </b>
            </p>
            <p>
              Dependentes:{' '}
              <b>
                {registered?.registered_dependents} / {registered?.total_dependents}
              </b>
            </p>
          </Card>
          <Spacing size="large" />
          {register && register.length > 0 && (
            <>
              <div className={styles.tableHeader}>
                <Column width="200px" position="start" justifyContent="flex-start">
                  <Text appearance="primary" maxWidth="fit-content" textAlign="start">
                    CPF
                  </Text>
                </Column>
                <Column
                  width={width >= 650 ? '200px' : '130px'}
                  position="start"
                  justifyContent="flex-start"
                >
                  <Text appearance="primary">Nome</Text>
                </Column>
                <Column
                  width={width >= 650 ? '180px' : '190px'}
                  position="centered"
                  justifyContent="center"
                >
                  <Text appearance="primary">Dependent.</Text>
                </Column>
                {width >= 650 && (
                  <Column
                    width="300px"
                    position="start"
                    justifyContent="flex-start"
                    paddingRight="0px"
                  >
                    <Text appearance="primary">Contato</Text>
                  </Column>
                )}
                <Column
                  width="80px"
                  position="start"
                  justifyContent="flex-start"
                  paddingRight="0px"
                >
                  <Text appearance="primary">Ações</Text>
                </Column>
              </div>
              {register.map((elem, key) => (
                <div className={styles.tableContent} key={key}>
                  <Column width="220px" position="start" justifyContent="flex-start">
                    <p className={styles.bolder} style={{ textAlign: 'start' }}>
                      {maskCPF(elem.cpf)}
                    </p>
                  </Column>
                  <Column width="250px" position="start">
                    <p className={styles.email} style={{ textAlign: 'start' }}>
                      {elem.name}
                    </p>
                  </Column>
                  <Column width={width >= 650 ? '170px' : '64px'} position="centered">
                    <p>{elem.dependents.length}</p>
                  </Column>
                  {width >= 650 && (
                    <Column
                      width={width >= 650 ? '300px' : '94px'}
                      position="start"
                      paddingRight="0px"
                    >
                      <p className={styles.email}>{elem.email}</p>
                    </Column>
                  )}
                  <Column
                    width={width >= 650 ? '120px' : '40px'}
                    position="start"
                    paddingRight="0px"
                  >
                    <Row>
                      <Tooltip title="Editar">
                        <IconButton>
                          <PiPencilSimple size={17} onClick={() => editBeneficiarie(elem)} />
                        </IconButton>
                      </Tooltip>
                      <Spacing size="xx-small" />
                      <Tooltip title="Deletar">
                        <IconButton>
                          <PiTrash size={17} onClick={() => handleRemoveTitular(elem.id)} />
                        </IconButton>
                      </Tooltip>
                    </Row>
                  </Column>
                </div>
              ))}

              <Spacing size="large" />
            </>
          )}
          {register.length <= 100 && (
            <Card appearance="ghost" width="97%">
              <Column width="100%" position="left">
                <Text bolder={true}>Informações do titular</Text>
              </Column>
              <Spacing size="large" />
              <div className={`${styles.row} ${styles.start}`}>
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Spacing size="x-small" />
                  <Input
                    name="holderDocument"
                    type="text"
                    width="100%"
                    label="CPF do titular"
                    maxLength={14}
                    value={maskCPF(form.cpf)}
                    error={formValidations.cpf.status}
                    errorMessage={formValidations.cpf.message}
                    onChange={(event) => {
                      if (event.target.value.length > 1) {
                        setFormValidations({
                          ...formValidations,
                          cpf: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                      if (isEditing) {
                        setForm({
                          ...form,
                          id: form.id,
                          cpf: maskReset(event.target.value)
                        });
                      } else {
                        setForm({
                          ...form,
                          id: register.length + 1,
                          cpf: maskReset(event.target.value)
                        });
                      }
                    }}
                    onBlur={(event) => {
                      if (isEditing) {
                        if (maskReset(form.cpf) !== formEditing.cpf) {
                          validateHolderDocument(event.target.value);
                        }
                      } else {
                        const validDocuments = validateBeneficiariesDocument(form.cpf, 'Títular');
                        setFormValidations({
                          ...formValidations,
                          cpf: {
                            status: validDocuments,
                            message: 'CPF informado já cadastrado anteriormente'
                          }
                        });
                        validateHolderDocument(event.target.value);
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="large" />}
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Spacing size="xxx-small" />
                  <DatePicker
                    disableFuture
                    maxDate={legalAge}
                    label="Data de Nascimento"
                    views={['year', 'month', 'day']}
                    value={form.birth_date !== '' ? dayjs(form.birth_date) : null}
                    onChange={(newValue) => {
                      // defineOwnerAge(dayjs(newValue).format('DD/MM/YYYY'));
                      validateHolderAge(newValue);
                    }}
                  />
                  {formValidations.birth_date.status && (
                    <i style={{ color: 'red', fontSize: '10px', textAlign: 'start' }}>
                      {formValidations.birth_date.message}
                    </i>
                  )}
                </Column>
              </div>
              {width >= 650 && <Spacing size="large" />}
              <div className={styles.row}>
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Input
                    name="holderName"
                    type="text"
                    width="100%"
                    label="Nome Completo"
                    value={form.name}
                    error={formValidations.name.status}
                    errorMessage={formValidations.name.message}
                    onChange={(event) => {
                      setForm({ ...form, name: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          name: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="large" />}
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Input
                    name="holderMothersName"
                    type="text"
                    width="100%"
                    label="Nome da Mãe"
                    value={form.mother_name}
                    error={formValidations.mothers_name.status}
                    errorMessage={formValidations.mothers_name.message}
                    onChange={(event) => {
                      setForm({ ...form, mother_name: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          mothers_name: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
              </div>
              {width >= 650 && <Spacing size="large" />}
              <div className={styles.row}>
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Select
                    label="Estado Civil"
                    width="100%"
                    data={[
                      {
                        title: 'Solteiro',
                        value: 'solteiro'
                      },
                      {
                        title: 'Casado',
                        value: 'casado'
                      },
                      {
                        title: 'Separado',
                        value: 'separado'
                      },
                      {
                        title: 'Divorciado',
                        value: 'divorciado'
                      },
                      {
                        title: 'Viúvo',
                        value: 'viúvo'
                      }
                    ]}
                    value={form.civil_status.charAt(0).toUpperCase() + form.civil_status.slice(1)}
                    onClick={(event) => {
                      setForm({ ...form, civil_status: event.value });
                    }}
                    error={formValidations.civil_status.status}
                    errorMessage={formValidations.civil_status.message}
                  />
                </Column>
                {width >= 650 && <Spacing size="large" />}
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Select
                    label="Sexo"
                    width="100%"
                    data={[
                      {
                        title: 'Masculino',
                        value: 'Masculino'
                      },
                      {
                        title: 'Feminino',
                        value: 'Feminino'
                      }
                    ]}
                    value={form.sex}
                    onClick={(event) => {
                      setForm({ ...form, sex: event.value });
                    }}
                    error={formValidations.gender.status}
                    errorMessage={formValidations.gender.message}
                  />
                </Column>
              </div>
              {width >= 650 && <Spacing size="large" />}
              <div className={styles.row}>
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Input
                    name="holderEmail"
                    type="text"
                    width="100%"
                    label="E-mail"
                    value={form.email}
                    onChange={(event) => {
                      if (event.target.value.length > 1) {
                        setFormValidations({
                          ...formValidations,
                          email: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                      setForm({ ...form, email: event.target.value });
                    }}
                    error={formValidations.email.status}
                    errorMessage={formValidations.email.message}
                  />
                </Column>
                {width >= 650 && <Spacing size="large" />}
                <Column width={width >= 650 ? '50%' : '100%'} paddingRight="0px">
                  <Input
                    name="holderPhone"
                    type="text"
                    width="100%"
                    maxLength={15}
                    label="Telefone"
                    value={maskPhone(form.phone)}
                    onChange={(event) => {
                      setForm({ ...form, phone: event.target.value });
                    }}
                    error={formValidations.phone.status}
                    errorMessage={formValidations.phone.message}
                  />
                </Column>
              </div>

              {isEditing ? (
                <>
                  <Spacing size="xx-large" />
                  <Column position="left">
                    <Text bolder={true}>Tipo de Plano</Text>
                  </Column>
                  <Spacing size="medium" />
                  <Row position="start">
                    <Column
                      width={width >= 1000 ? '47%' : '100%'}
                      paddingRight={width >= 1000 ? '10px' : '0px'}
                    >
                      <Select
                        width="100%"
                        label="Selecione o plano"
                        data={ownerPlans}
                        value={form.plan}
                        error={formValidations.plan.status}
                        errorMessage={formValidations.plan.message}
                        onClick={(event) => {
                          // validateNumberOfDependentes(event);
                          validateHolderDocument(form.cpf);
                        }}
                      />
                    </Column>
                  </Row>
                </>
              ) : (
                ownerPlans.length > 0 && (
                  <>
                    <Spacing size="xx-large" />
                    <Column position="left">
                      <Text bolder={true}>Tipo de Plano</Text>
                    </Column>
                    <Spacing size="medium" />
                    <Row position="start">
                      <Column
                        width={width >= 1000 ? '47%' : '100%'}
                        paddingRight={width >= 1000 ? '10px' : '0px'}
                      >
                        <Select
                          width="100%"
                          label="Selecione o plano"
                          data={ownerPlans}
                          value={form.plan}
                          error={formValidations.plan.status}
                          errorMessage={formValidations.plan.message}
                          onClick={(event: any) => {
                            form.plan_id = event.plan_id;
                            form.plan = event.title;
                            form.numberDependents = '';
                            validateHoldersData(event);
                            validateHolderDocument(form.cpf);
                            setForm({ ...form });
                            resetDependents();
                          }}
                        />
                      </Column>
                    </Row>
                  </>
                )
              )}
              <Spacing size="xx-large" />

              <Column width="100%" position="left">
                <Text bolder={true}>Endereço do titular</Text>
              </Column>

              <Spacing size="medium" />
              <Row>
                <Column paddingRight="0px">
                  <Input
                    name="holderCEP"
                    type="text"
                    width="100%"
                    maxLength={9}
                    label="CEP"
                    value={form.cep ? maskCep(form.cep) : form.cep}
                    error={formValidations.cep.status}
                    errorMessage={formValidations.cep.message}
                    onChange={(event) => {
                      setForm({ ...form, cep: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          cep: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                    onBlur={() => {
                      if (form.cep.length >= 8) {
                        getAddress(form.cep);
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="small" />}
                <Column paddingRight="0px">
                  <Input
                    name="holderStreet"
                    type="text"
                    width="100%"
                    label="Logradouro"
                    value={form.street}
                    error={formValidations.street.status}
                    errorMessage={formValidations.street.message}
                    onChange={(event) => {
                      setForm({ ...form, street: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          street: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="small" />}
                <Column paddingRight="0px">
                  <Input
                    name="holderDistrict"
                    type="text"
                    width="100%"
                    label="Bairro"
                    error={formValidations.district.status}
                    errorMessage={formValidations.district.message}
                    value={form.district}
                    onChange={(event) => {
                      setForm({ ...form, district: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          disctrict: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
              </Row>
              {width >= 650 && <Spacing size="medium" />}
              <Row>
                <Column paddingRight="0px">
                  <Input
                    name="holderNumber"
                    type="text"
                    width="100%"
                    maxLength={5}
                    label="Número"
                    error={formValidations.number.status}
                    errorMessage={formValidations.number.message}
                    value={form.number}
                    onChange={(event) => {
                      setForm({ ...form, number: event.target.value });

                      if (event.target.value.length > 1) {
                        setFormValidations({
                          ...formValidations,
                          number: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="small" />}
                <Column paddingRight="0px">
                  <Input
                    name="holderComplement"
                    type="text"
                    width="100%"
                    label="Complemento"
                    value={form.complement}
                    onChange={(event) => {
                      setForm({ ...form, complement: event.target.value });
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="small" />}
                <Column paddingRight="0px">
                  <Input
                    name="holderCity"
                    type="text"
                    width="100%"
                    label="Cidade"
                    value={form.city}
                    error={formValidations.city.status}
                    errorMessage={formValidations.city.message}
                    onChange={(event) => {
                      setForm({ ...form, city: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          city: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="small" />}
                <Column paddingRight="0px">
                  <Input
                    name="holderState"
                    type="text"
                    width="100%"
                    label="Estado"
                    value={form.state}
                    error={formValidations.state.status}
                    errorMessage={formValidations.state.message}
                    onChange={(event) => {
                      setForm({ ...form, state: event.target.value });

                      if (event.target.value.length > 3) {
                        setFormValidations({
                          ...formValidations,
                          state: {
                            status: false,
                            message: ''
                          }
                        });
                      }
                    }}
                  />
                </Column>
              </Row>
              {isEditing ? (
                <>
                  <Spacing size="xx-large" />
                  <Column width="100%" position="left">
                    <Text>Dependentes</Text>
                  </Column>
                  <Spacing size="medium" />
                  <Row position="left">
                    <Select
                      label="Número de dependentes"
                      width={width >= 1000 ? '47%' : '100%'}
                      data={[{ title: 'Sem Dependentes' }, ...ownerDependents]}
                      value={form.numberDependents}
                      onClick={(event) => {
                        resetDependents();
                        handleDependents(event.title);
                      }}
                    />
                  </Row>
                  <Spacing size="medium" />
                  {form.dependents.map((dependent, key) => (
                    <>
                      <Spacing size="medium" />
                      <Card width="100%" appearance="ghost" padding="10px 24px" key={key}>
                        <Spacing size="x-large" />
                        <Column width="100%" position="start">
                          <Description
                            maxWidth="100%"
                            bolder={true}
                            appearance="grey"
                            textAlign="start"
                          >
                            Dependente {dependent.id}
                          </Description>
                        </Column>
                        <Spacing size="medium" />
                        <Row>
                          <Column paddingRight="0px">
                            <Select
                              label="Grau de Parentesco"
                              width="100%"
                              data={relationships}
                              value={dependent.kinship}
                              onClick={(event) => {
                                handleFormDependents('kinship', event.value, dependent.id);
                              }}
                            />
                          </Column>
                          {width >= 650 && <Spacing size="medium" />}
                          <Column paddingRight="0px">
                            <Input
                              name="dependentDocument"
                              type="text"
                              width="100%"
                              label="CPF do Dependente"
                              maxLength={14}
                              value={maskCPF(dependent.cpf)}
                              error={dependent.checkDocumentValue}
                              errorMessage={dependent.checkDocumentValueMessage}
                              onChange={(event) => {
                                handleFormDependents('cpf', event.target.value, dependent.id);
                              }}
                              onBlur={(event) => {
                                const validDocuments = validateBeneficiariesDocument(
                                  dependent.cpf,
                                  'Dependente'
                                );
                                if (validDocuments) {
                                  dependent.checkDocumentValue = true;
                                  dependent.checkDocumentValueMessage =
                                    'Você ja cadastrou outros beneficiarios com este CPF';
                                  setForm({ ...form });
                                }
                              }}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column paddingRight="0px">
                            <Input
                              name="dependentName"
                              type="text"
                              width="100%"
                              label="Nome do Dependente"
                              value={dependent.name}
                              onChange={(event) => {
                                handleFormDependents('name', event.target.value, dependent.id);
                              }}
                            />
                          </Column>
                          {width >= 650 && <Spacing size="medium" />}
                          <Column paddingRight="0px">
                            <Input
                              name="dependentBirthdate"
                              type="text"
                              width="100%"
                              label="Data de Nascimento"
                              mask="99/99/9999"
                              maxLength={14}
                              value={dependent.birth_date}
                              error={dependent.checkBirthdayValue}
                              errorMessage={dependent.checkBirthdayValueMessage}
                              onChange={(event) => {
                                handleFormDependents(
                                  'birth_date',
                                  event.target.value,
                                  dependent.id
                                );
                              }}
                              // onBlur={(event) => {
                              //   checkDependentsBirthdayField(dependent, event);
                              // }}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column paddingRight="0px">
                            <Input
                              name="dependentMothersName"
                              type="text"
                              width="100%"
                              label="Nome da Mãe"
                              value={dependent.mother_name}
                              onChange={(event) => {
                                handleFormDependents(
                                  'mother_name',
                                  event.target.value,
                                  dependent.id
                                );
                              }}
                            />
                          </Column>
                          {width >= 650 && <Spacing size="medium" />}
                          <Column paddingRight="0px">
                            <Input
                              name="dependentMothersName"
                              type="text"
                              width="100%"
                              label="Nome da Mãe"
                              value={dependent.mother_name}
                              onChange={(event) => {
                                handleFormDependents(
                                  'mother_name',
                                  event.target.value,
                                  dependent.id
                                );
                              }}
                            />
                          </Column>
                        </Row>
                      </Card>
                    </>
                  ))}
                </>
              ) : (
                limitHoldersReached === false &&
                limitDependentsReached === false &&
                ownerPlans.length > 0 &&
                ownerDependents.length >= 1 && (
                  <>
                    <Spacing size="xx-large" />
                    <Column width="100%" position="left">
                      <Text>Dependentes</Text>
                    </Column>
                    <Spacing size="medium" />
                    <Row position="left">
                      <Select
                        label="Número de dependentes"
                        width={width >= 1000 ? '47%' : '100%'}
                        data={[{ title: 'Sem Dependentes' }, ...ownerDependents]}
                        value={form.numberDependents}
                        onClick={(event) => {
                          resetDependents();
                          handleDependents(event.title);
                        }}
                      />
                    </Row>
                    <Spacing size="medium" />
                    {form.dependents.map((dependent, key) => (
                      <>
                        <Spacing size="medium" />
                        <Card width="100%" appearance="ghost" padding="10px 24px" key={key}>
                          <Spacing size="x-large" />
                          <Column width="100%" position="start">
                            <Description
                              bolder={true}
                              appearance="grey"
                              maxWidth="100%"
                              textAlign="start"
                            >
                              Dependente {dependent.id}
                            </Description>
                          </Column>
                          <Spacing size="medium" />
                          <div className={styles.row}>
                            <Column paddingRight="0px" width={width >= 650 ? '50%' : '100%'}>
                              <Select
                                label="Grau de Parentesco"
                                width="100%"
                                data={relationships}
                                value={dependent.relationship}
                                onClick={(event) => {
                                  handleFormDependents('relationship', event.value, dependent.id);
                                }}
                              />
                            </Column>
                            {width >= 650 && <Spacing size="medium" />}
                            <Column paddingRight="0px" width={width >= 650 ? '50%' : '100%'}>
                              <Input
                                name="dependentDocument"
                                type="text"
                                width="100%"
                                label="CPF do Dependente"
                                maxLength={14}
                                value={dependent.cpf ? maskCPF(dependent.cpf) : ''}
                                error={dependent.checkDocumentValue}
                                errorMessage={dependent.checkDocumentValueMessage}
                                onChange={(event) => {
                                  handleFormDependents('cpf', event.target.value, dependent.id);
                                }}
                                // onBlur={(event) => {
                                //   const validDocuments = validateBeneficiariesDocument(
                                //     dependent.cpf,
                                //     'Dependente'
                                //   );
                                //   if (validDocuments) {
                                //     dependent.checkDocumentValue = true;
                                //     dependent.checkDocumentValueMessage =
                                //       'Você ja cadastrou outros beneficiarios com este CPF';
                                //     setForm({ ...form });
                                //   }
                                // }}
                              />
                            </Column>
                          </div>
                          <Row align="start">
                            <Column paddingRight="0px">
                              <Spacing size="x-small" />
                              <Input
                                name="dependentName"
                                type="text"
                                width="100%"
                                label="Nome do Dependente"
                                value={dependent.name}
                                onChange={(event) => {
                                  handleFormDependents('name', event.target.value, dependent.id);
                                }}
                              />
                            </Column>
                            {width >= 650 && <Spacing size="medium" />}
                            <Column paddingRight="0px">
                              <DatePicker
                                disableFuture
                                label="Data de Nascimento"
                                data={
                                  dependent.birth_date !== null ? dayjs(dependent.birth_date) : null
                                }
                                onChange={(newValue) => {
                                  validateBeneficiarieAge(newValue, dependent.id);
                                }}
                              />
                              {dependent.checkField && (
                                <i style={{ color: 'red', fontSize: '12px' }}>
                                  Nenhum dependente encontrado nesta faixa etária
                                </i>
                              )}
                            </Column>
                          </Row>
                          <Row>
                            <Column paddingRight="0px">
                              <Input
                                name="dependentMothersName"
                                type="text"
                                width="100%"
                                label="Nome da Mãe"
                                value={dependent.mother_name}
                                onChange={(event) => {
                                  handleFormDependents(
                                    'mother_name',
                                    event.target.value,
                                    dependent.id
                                  );
                                }}
                              />
                            </Column>
                            {width >= 650 && <Spacing size="medium" />}
                            <Column paddingRight="0px">
                              <Input
                                name="dependentEmail"
                                type="text"
                                width="100%"
                                disabled={
                                  dependent.birth_date === '' ||
                                  !dayjs(dependent.birth_date).isBefore(
                                    dayjs().subtract(18, 'year')
                                  )
                                }
                                label="Email (maiores de 18 anos)"
                                value={dependent.email}
                                onChange={(event) => {
                                  handleFormDependents('email', event.target.value, dependent.id);
                                }}
                              />
                            </Column>
                          </Row>
                        </Card>
                      </>
                    ))}
                  </>
                )
              )}
              <Spacing size="large" />
              <Row position="end" width="99%">
                <Button
                  text={isEditing ? 'Atualizar Beneficiários' : 'Salvar Beneficiários'}
                  width="200px"
                  appearance={!loadingRegisteringHolder ? 'primary' : 'disabled'}
                  // onClick={
                  //   journeyBlocked === false && limitHoldersReached === false
                  //     ? handleAddTitular
                  //     : null
                  // }
                  loading={loadingRegisteringHolder}
                  onClick={() => {
                    if (!loadingRegisteringHolder && !isEditing) {
                      handleAddTitular();
                    } else {
                      handleEditTitular();
                    }
                  }}
                />
              </Row>

              <Row position="end" width="99%">
                {limitHoldersReached && (
                  <>
                    <Spacing size="x-large" />
                    <Description bolder={true} textAlign="end">
                      Atenção! Limite de cadastro de titulares para o plano selecionado foi
                      atingido!
                    </Description>
                  </>
                )}
              </Row>
            </Card>
          )}

          {!showForm && csv && (
            <Row position="end" width="95%">
              <Button
                text="Adicionar Titular"
                width="166px"
                appearance="ghost"
                onClick={() => {
                  setShowForm(true);
                }}
                iconPosition="left"
              >
                <FaPlus />
              </Button>
            </Row>
          )}

          <Spacing size="x-large" />

          <Row width="97%">
            <BottomNavigation
              labelLeft="Voltar"
              appearanceLeft="ghost"
              actionLeft={() => navigate('/formulario/empresa')}
              labelRight="Finalizar Cadastro"
              appearanceRight={!loading && register.length > 0 ? 'primary' : 'disabled'}
              actionRight={() => {
                if (!loading && register.length > 0) {
                  handleForm();
                }
              }}
            />
          </Row>

          <Spacing size="x-large" />
        </Layout>
      )}
    </>
  );
}

export default FormBeneficiaries;
