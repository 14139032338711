import {
  acceptCompanyPolicy,
  getAllEnterpriseDocuments,
  getDocumentations,
  getOrderData,
  postOrder,
  updateProposalResposible,
  uploadCompanyDocument
} from 'api/services/ApiCalls';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { maskCPF, maskCep, maskCnpj } from 'utils/mask';
import JWT from 'expo-jwt';
import styles from './styles.module.scss';
import {
  Alert,
  BottomNavigation,
  Button,
  Card,
  Checkbox,
  Column,
  DocumentUpload,
  Input,
  Layout,
  Modal,
  Row,
  Spacing,
  Text,
  Title
} from '../../components';

function FormCompany() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    corporateName: '',
    id: 0,
    phone: '',
    email: '',
    cnpj: '',
    name: '',
    document: '',
    cep: '',
    street: '',
    district: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    firstResponsibleName: '',
    firstResponsibleDocument: '',
    firstResponsibleEmail: '',
    secondResponsibleName: '',
    secondResponsibleDocument: '',
    secondResponsibleEmail: ''
  });
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalPolicy, setModalPolicy] = useState(true);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [pdf, setPdf] = useState<any>({});
  const [checkNameField, setCheckNameField] = useState(false);
  const [checkDocumentField, setCheckDocumentField] = useState(false);
  const [alertData, setAlertData] = useState<any>({
    display: false,
    title: '',
    type: '',
    message: ''
  });
  const [order, setOrder] = useState<any>();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      setForm({
        ...form,
        corporateName: jwtParsed.enterprise,
        cnpj: jwtParsed.cnpj,
        cep: jwtParsed.zip_code,
        street: jwtParsed.street,
        district: jwtParsed.district,
        number: jwtParsed.number,
        complement: jwtParsed.complement,
        city: jwtParsed.city,
        state: jwtParsed.state,
        id: jwtParsed.id
      });
    }
  }

  function resetAlert() {
    setAlertData({
      display: false,
      type: '',
      title: '',
      message: ''
    });
  }

  function validateDocuments() {
    const validate = [];
    companyDocuments.map((document) => {
      validate.push(document.file !== '');
    });

    if (
      validate.includes(true) &&
      form.name !== '' &&
      form.document !== '' &&
      form.document.length <= 13
    ) {
      return true;
    } else {
      if (form.name === '') {
        setCheckNameField(true);
      }
      if (form.document === '' || form.document.length <= 13) {
        setCheckDocumentField(true);
      }
      return false;
    }
  }

  async function handleUploadDocument() {
    if (validateDocuments()) {
      setLoading(true);
      companyDocuments.map(async (document) => {
        if (document.file !== '') {
          const formData = new FormData();

          try {
            formData.append('order_id', JSON.stringify(form.id));
            formData.append('company_document_id', JSON.stringify(document.id));
            formData.append('company_document_file', document.file);

            const response = await uploadCompanyDocument(formData);
            if (response.data.success) {
              const body = {
                order_id: form.id,
                responsible_name: form.name,
                responsible_cpf: form.document
              };

              updateProposalResposible(body)
                .then((response) => {
                  navigate('/formulario/empresa/concluido');
                  setLoading(false);
                })
                .catch((error) => console.error(error));
            }
          } catch (error) {
            console.error('err', error);
            setLoading(false);
          }
        }
      });
    } else {
      setAlertData({
        display: true,
        type: 'error',
        title: 'Atenção!',
        message:
          'Necessário realizar o upload de ao menos um documento e informar os dados do representante.'
      });
      setTimeout(() => {
        resetAlert();
      }, 5500);
    }
  }

  function getPolicyDocuments() {
    const accept = localStorage.getItem('@acceptPolicy');
    if (accept) {
      setModalPolicy(false);
    } else {
      getDocumentations().then((response) => {
        const document = response.data.documentation.find(
          (doc) => doc.type === 'Política de Privacidade'
        );
        setPdf(document);
      });
    }
  }

  useEffect(() => {
    const haveParams = window.location.search;
    if (haveParams) {
      getParams();
    } else {
      const orderSession = JSON.parse(sessionStorage.getItem('@orderData'));
      getOrderData(orderSession.id)
        .then((response) => {
          setForm({
            ...form,
            id: response.data.order_data.order.id,
            phone: response.data.order_data.order.phone,
            email: response.data.order_data.order.email,
            corporateName: response.data.order_data.order.company_name,
            name: response.data.order_data.order.responsible_name,
            cnpj: response.data.order_data.order.cnpj,
            cep: response.data.order_data.order.zip_code,
            street: response.data.order_data.order.street,
            district: response.data.order_data.order.district,
            number: response.data.order_data.order.number,
            complement: response.data.order_data.order.complement,
            city: response.data.order_data.order.city,
            state: response.data.order_data.order.state
          });
        })
        .catch((error) => console.error(error));
      setOrder(orderSession);
    }
    getAllEnterpriseDocuments().then((response) => {
      const documents = response.data.documentation;
      documents.map((document) => {
        document.file = '';
      });
      setCompanyDocuments(documents);
    });
    getPolicyDocuments();
  }, []);

  return (
    <Layout
      navbar={true}
      steps={[
        { title: 'Dados da empresa', status: 'inProgress' },
        { title: 'Contrato', status: 'pending' },
        { title: 'Cadastro Beneficiários', status: 'pending' },
        { title: 'Análise Beneficiários', status: 'pending' },
        { title: 'Concluído', status: 'pending' }
      ]}
    >
      <Modal
        isOpen={modalPolicy}
        width="750px"
        height={width >= 1000 ? 'auto' : '720px'}
        title="Termos e Política de Privacidade"
        description="Para dar continuidade ao cadastro é necessário aceitar os Termos e Política de Privacidade 
abaixo. Leia atentamente e clique em concordo para aceitar e continuar o processo."
      >
        <iframe
          src={`${pdf.documentation_path}#toolbar=0&view=FitH`}
          className={styles.pdf}
          title="Cobertura Parcial Temporária"
        />
        <Spacing size="x-large" />
        <Checkbox
          id="temporaryCoverage"
          label="Li e estou de acordo com os termos e política de privacidade."
          checked={acceptPolicy}
          setChecked={() => setAcceptPolicy(!acceptPolicy)}
        />
        <Spacing size="xxx-large" />
        <div className={styles.row}>
          <Button
            text="Continuar"
            appearance={acceptPolicy ? 'primary' : 'disabled'}
            width={width >= 1000 ? '45%' : '90%'}
            onClick={() => {
              if (acceptPolicy) {
                setModalPolicy(false);
                localStorage.setItem('@acceptPolicy', JSON.stringify(true));
                acceptCompanyPolicy({
                  token: JWT.encode({ order_id: order.id }, 'sulmed')
                }).then((response) => console.info(response.data));
              } else {
                setAlertData({
                  display: true,
                  title: 'Atenção!',
                  type: 'warning',
                  message: 'Você precisa aceitar os Termos e Politica de Privacidade para continuar'
                });
                setTimeout(() => {
                  setAlertData({
                    display: false,
                    title: '',
                    type: '',
                    message: ''
                  });
                }, 5500);
              }
            }}
          />
        </div>
      </Modal>

      {alertData.display && (
        <Alert type={alertData.type} title={alertData.title} text={alertData.message} />
      )}

      <Spacing size="medium" />
      <Title appearance="primary" bolder={true}>
        Dados cadastrais da sua empresa
      </Title>
      <Spacing size="x-small" />
      <Text>Para dar continuidade nesta proposta, insira as informações da sua empresa</Text>
      <Spacing size="large" />
      <Card appearance="ghost" width="98%">
        <Spacing size="x-large" />
        <Row>
          <Column paddingRight="0px">
            <Input
              name="corporateName"
              type="text"
              width="100%"
              label="Razão Social"
              value={form.corporateName}
              disabled={true}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="cnpj"
              type="text"
              width="100%"
              label="CNPJ"
              value={maskCnpj(form.cnpj)}
              disabled={true}
            />
          </Column>
        </Row>
        <Spacing size="small" />
        <Row>
          <Column paddingRight="0px">
            <Input
              name="name"
              type="text"
              width="100%"
              label="Nome do Representante *"
              value={form.name}
              error={checkNameField}
              errorMessage="O campo é obrigatório"
              onChange={(event) => {
                setForm({
                  ...form,
                  name: event.target.value
                });
                if (event.target.value.length > 0) {
                  setCheckNameField(false);
                }
              }}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="document"
              type="text"
              width="100%"
              label="CPF do Representante *"
              value={maskCPF(form.document)}
              maxLength={14}
              error={checkDocumentField}
              errorMessage="O campo é obrigatório"
              onChange={(event) => {
                setForm({
                  ...form,
                  document: event.target.value
                });
                if (event.target.value.length > 0) {
                  setCheckDocumentField(false);
                }
              }}
            />
          </Column>
        </Row>
        <Spacing size="small" />
        <Row width="100%" position="start" align="start">
          <Text bolder={true} textAlign="start">
            Endereço da sua empresa
          </Text>
        </Row>
        <Spacing size="medium" />
        <Row>
          <Column paddingRight="0px">
            <Input
              name="cep"
              type="text"
              width="100%"
              label="CEP"
              value={form.cep ? maskCep(form.cep) : form.cep}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, cep: event.target.value });
              }}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="street"
              type="text"
              width="100%"
              label="Logradouro"
              value={form.street}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, street: event.target.value });
              }}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="district"
              type="text"
              width="100%"
              label="Bairro"
              value={form.district}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, district: event.target.value });
              }}
            />
          </Column>
        </Row>
        <Spacing size="small" />
        <Row>
          <Column paddingRight="0px">
            <Input
              name="number"
              type="text"
              width="100%"
              label="Número"
              value={form.number}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, number: event.target.value });
              }}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="complement"
              type="text"
              width="100%"
              label="Complemento"
              value={form.complement}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, complement: event.target.value });
              }}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="city"
              type="text"
              width="100%"
              label="Cidade"
              value={form.city}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, city: event.target.value });
              }}
            />
          </Column>
          <Spacing size="small" />
          <Column paddingRight="0px">
            <Input
              name="state"
              type="text"
              width="100%"
              label="Estado"
              value={form.state}
              disabled={true}
              onChange={(event) => {
                setForm({ ...form, state: event.target.value });
              }}
            />
          </Column>
        </Row>
        <Spacing size="xx-large" />
        <Row width="90%" position="start" align="start">
          <Text bolder={true}>Upload de documentação</Text>
        </Row>
        <Spacing size="large" />
        {companyDocuments.map((document) => (
          <>
            <Row width="100%">
              <DocumentUpload
                file={document.file}
                setFile={(event) => {
                  // eslint-disable-next-line prefer-destructuring
                  document.file = event.target.files[0];
                  setCompanyDocuments([...companyDocuments]);
                }}
                placeholder={
                  document.file ? `${document.type} carregado com sucesso` : document.description
                }
              />
            </Row>
            <Spacing size="x-large" />
          </>
        ))}
      </Card>
      <Spacing size="xxx-large" />

      <Row width="97%">
        <BottomNavigation
          labelRight="Continuar"
          appearanceRight={loading ? 'disabled' : 'primary'}
          actionRight={() => !loading && handleUploadDocument()}
          loadingRight={loading}
        />
      </Row>
    </Layout>
  );
}

export default FormCompany;
