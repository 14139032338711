/* eslint-disable array-callback-return */
import {
  Alert,
  Card,
  Column,
  Description,
  Navbar,
  Row,
  Spacing,
  Spinner,
  Text,
  Title
} from 'components';
import { useEffect, useState } from 'react';
import { maskReset } from 'utils/mask';
import { getActivesPlans } from 'api/services/ApiCalls';
import { AlertState } from 'components/Alert';
import { useNavigationContext } from 'context/NavigationContext';
import styles from './styles.module.scss';
import Tabs from './components/Tabs';

import banner from '../../assets/banner-background-sulmed.png';
import infoIcon from '../../assets/icons/info-icon.png';
import mouse from '../../assets/icons/mouse-sulmed.png';
import whatsapp from '../../assets/icons/whatsapp.png';
import Footer from './components/Footer';

function Home() {
  const { setParams, Broker } = useNavigationContext();
  const [alert, setAlert] = useState<AlertState | null>(null);
  const [plans, setPlans] = useState([]);
  const [form, setForm] = useState({
    email: '',
    phone: '',
    plans: [
      { id: 1, title: '', type: '', attributes: [], coparticipation: [], plan_id: 0, total: 0 },
      { id: 2, title: '', type: '', attributes: [], coparticipation: [], plan_id: 0, total: 0 },
      { id: 3, title: '', type: '', attributes: [], coparticipation: [], plan_id: 0, total: 0 }
    ]
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const brokerParamsName = urlParams.get('corretor');
      sessionStorage.setItem('@brokerParams', brokerParamsName);
      setParams(brokerParamsName);
    }
    return '';
  }

  async function getPlans() {
    try {
      const response = await getActivesPlans();
      const formattedPlan = response.data.plans.map((plan) => {
        const formatPlan = {
          id: plan.id,
          type: plan.type,
          title: plan.name,
          subtitle: plan.subtitle,
          value: `${plan.name} ${plan.subtitle}`,
          attributes: plan.plan_attributes,
          coparticipation: plan.co_participation
        };
        return formatPlan;
      });

      setPlans(formattedPlan);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  function choosePlan(plan: any) {
    const avaiblePlan = form.plans.find((plan) => plan.title === '');
    if (avaiblePlan) {
      avaiblePlan.plan_id = plan.id;
      avaiblePlan.title = plan.value;
      avaiblePlan.type = plan.type;
      avaiblePlan.attributes = plan.attributes;
      avaiblePlan.coparticipation = plan.coparticipation;
      setForm({ ...form });
    } else {
      setAlert({
        ...{
          show: true,
          type: 'warning',
          title: 'Atenção!',
          text: 'A quantidade máxima de planos para simulação foi atingida. Caso deseje alterar algum dos planos selecionados poderá realizar a alteracão diretamente no simulador.'
        }
      });
      setTimeout(() => {
        setAlert(null);
      }, 5500);
    }
  }

  useEffect(() => {
    sessionStorage.clear();
    getParams();
    getPlans();
  }, []);

  return (
    <div className={styles.container}>
      {alert?.show && <Alert type={alert.type} title={alert.title} text={alert.text} />}
      <Navbar />
      {Broker.broker && (
        <a
          href={`https://api.whatsapp.com/send?phone=${maskReset(Broker.broker.phone)}`}
          className={styles.whatsapp}
          target="__blank"
        >
          <img src={whatsapp} />
        </a>
      )}
      <div className={styles.banner}>
        <img src={banner} className={styles.bannerBackground} draggable="false" />
        <div className={styles.bannerContent}>
          <h1>Planos de Saúde Sulmed</h1>
          <img src={mouse} />
        </div>
      </div>
      <div className={styles.packages}>
        <div className={styles.packagesTitle}>
          <Title bolder={true} appearance="primary">
            Conheça os nossos planos disponíveis
          </Title>
          <Spacing size="x-small" />
          <Description appearance="grey">
            Com maiores benefícios e menores custos, saúde completa e acessível para todos
          </Description>
        </div>
        <Spacing size="xxx-large" />

        <Card appearance="fadedPrimary" width="80%">
          <div className={styles.row}>
            <img src={infoIcon} alt="" style={{ alignSelf: 'flex-start', marginTop: '4px' }} />
            <Spacing size="xx-small" />
            <Text appearance="primary" textAlign="left">
              A simulação será feita com base no plano selecionado. Caso queira alterar, você poderá
              selecionar outros planos no simulador
            </Text>
          </div>
        </Card>
        <Spacing size="xxx-large" />
        {plans.length > 0 ? (
          <Tabs width={width} data={plans} onClickPlan={(event) => choosePlan(event)} />
        ) : (
          <Row width="100%" align="center">
            <Column width="100%" position="centered">
              <Spinner show={true} />
              <Spacing size="medium" />
              <Description appearance="primary" bolder={true}>
                Carregando os dados
              </Description>
            </Column>
          </Row>
        )}
      </div>

      {/* <Element id="simulator-section" name="simulator-section">
        <div className={styles.simulator}>
          <div className={styles.column} style={{ width: width >= 1050 ? '40%' : '90%' }}>
            <h1 className={styles.title}>Encontre o plano de saúde ideal com o nosso simulador</h1>
            <p className={styles.text}>
              O Plano de Saúde que sua empresa precisa está aqui. Para simular, basta selecionar um
              ou mais planos e inserir a quantidade de vidas por faixa etária.
            </p>
            <p className={styles.text}>
              <b>
                Saiba na hora o valor e ainda compare com outros planos de forma simples e prática
              </b>
            </p>
          </div>
          <Column
            width={width >= 1050 ? '50%' : '90%'}
            position="centered"
            paddingRight={width >= 920 ? '10px' : '0px'}
          >
            <Simulator width={width} form={form} setForm={setForm} />
          </Column>
        </div>
      </Element> */}
      <Footer />
    </div>
  );
}

export default Home;
