import { getOrderData } from 'api/services/ApiCalls';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Billet,
  Card,
  Column,
  CreditCard,
  Layout,
  Pix,
  Spacing,
  Text,
  Title,
  Undertitle
} from '../../components';
import { maskMoney } from '../../utils/mask';
import styles from './styles.module.scss';

function Payment() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const [order, setOrder] = useState<any>([]);
  const [checks, setChecks] = useState<any>([
    {
      id: 1,
      status: false
    },
    {
      id: 2,
      status: false
    },
    {
      id: 3,
      status: true
    }
  ]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function handleChangeCheck(id: number) {
    checks.map((check) => {
      check.status = false;
    });
    const obj = checks.find((o) => o.id === id);
    obj.status = true;
    setChecks([...checks]);
  }

  function handlePix() {
    const formData = new FormData();
    const storage = JSON.parse(localStorage.getItem('@data'));
    formData.append('purchase_id', storage.info.purchase_id);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_LINK_API}/packages/payment/create/pix`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      window.open(response.data.payment.pix_link, '_blank', 'noreferrer');

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_LINK_API}/purchasesuccess/${storage.info.purchase_id}`,
        data: {},
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        navigate('/pagamento/aguardando');
      });
    });
  }

  function handleBillet() {
    const formData = new FormData();
    const storage = JSON.parse(localStorage.getItem('@data'));
    formData.append('purchase_id', storage.info.purchase_id);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_LINK_API}/packages/payment/create/billet`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      window.open(response.data.payment.ticket_link, '_blank', 'noreferrer');

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_LINK_API}/purchasesuccess/${storage.info.purchase_id}`,
        data: {},
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        navigate('/pagamento/aguardando');
      });
    });
  }

  useEffect(() => {
    const order = JSON.parse(sessionStorage.getItem('@orderData'));
    getOrderData('1027')
      .then((response) => {
        setOrder(response.data.order_data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Layout
      navbar={true}
      verticalCentered={true}
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Beneficiários', status: 'concluded' },
        { title: 'Análise', status: 'concluded' },
        { title: 'Contrato', status: 'concluded' },
        { title: 'Pagamento', status: 'inProgress' },
        { title: 'Conclusão', status: 'pending' }
      ]}
    >
      <Spacing size="xxx-large" />
      <div className={styles.content}>
        <Column width={width >= 650 ? '50%' : '99%'} paddingRight="0px">
          <Title bolder={true}>Método de pagamento</Title>
          <Spacing size="x-small" />
          <Text textAlign="start">
            Escolha o método de pagamento e insira as informações solicitadas para finalizar a
            compra.
          </Text>
          <Spacing size="medium" />
          <Pix
            width={width >= 650 ? '90%' : '100%'}
            check={checks[0].status}
            onChangeCheckbox={() => handleChangeCheck(checks[0].id)}
            onClick={handlePix}
          />
          <Spacing size="xx-small" />
          <Billet
            width={width >= 650 ? '90%' : '100%'}
            check={checks[1].status}
            onChangeCheckbox={() => handleChangeCheck(checks[1].id)}
            onClick={handleBillet}
          />
          <Spacing size="xx-small" />
          <CreditCard
            width={width >= 650 ? '90%' : '100%'}
            check={checks[2].status}
            onChangeCheckbox={() => handleChangeCheck(checks[2].id)}
          />
        </Column>
        <Spacing size="xxx-large" />
        <Column width={width <= 1000 ? '100%' : '40%'} paddingRight="0px">
          <Title bolder={true}>Resumo da Compra</Title>
          {width <= 1000 ? <Spacing size="medium" /> : <Spacing size="xxx-large" />}
          <Card appearance="ghost" width={width >= 650 ? '91%' : '100%'}>
            <div className={styles.row}>
              <Text position="start" bolder={true}>
                Subtotal
              </Text>
              <Text position="start">R$ {maskMoney(Number(order.subtotal))}</Text>
            </div>
            <Spacing size="xx-large" />
            {order.coupon_id && (
              <div className={styles.row}>
                <Text position="start">Desconto</Text>
                <Text position="start">
                  R$ {(Number(order.subtotal) * (Number(order.coupom_discount) / 100)).toFixed(2)}
                </Text>
              </div>
            )}

            <Spacing size="xx-large" />
            <div className={styles.row}>
              {width >= 350 ? (
                <>
                  <Title position="start" appearance="primary" bolder={true}>
                    Total a pagar
                  </Title>
                  <Title position="start" bolder={true}>
                    R$ {maskMoney(Number(order.total))}
                  </Title>
                </>
              ) : (
                <>
                  <Undertitle position="start" appearance="primary" bolder={true}>
                    Total a pagar
                  </Undertitle>
                  <Undertitle position="start" bolder={true}>
                    R$ {maskMoney(totalValue)}
                  </Undertitle>
                </>
              )}
            </div>
          </Card>
          {/* <Button text="Realizar pagamento" width="96%" appearance="primary" size="lg" /> */}
        </Column>
      </div>
    </Layout>
  );
}

export default Payment;
