import { api } from 'api/services';
import { Button, Description, Input, Spacing } from 'components';
import Alert, { AlertProps } from 'components/Alert';
import Chip from 'components/Chip';
import ListBox from 'components/ListBox';
import React, { useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GoIssueClosed } from 'react-icons/go';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import styles from '../styles.module.scss';
import { TQuestion, TQuestionAnswer } from '../types';

type QuestionProps = {
  questions: TQuestion[];
  questionsLength: number;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;

  orderId: number;
  id: number;

  setNotCompleted: React.Dispatch<React.SetStateAction<number>>;

  imcForm: TQuestionAnswer;
  setPending: React.Dispatch<React.SetStateAction<boolean>>;
  isDependent?: boolean;

  setPdf: React.Dispatch<React.SetStateAction<string>>;
};

function Question({
  questions,
  questionsLength,
  count,
  setCount,
  id,
  orderId,
  isDependent,
  setNotCompleted,
  imcForm,
  setPending,
  setPdf
}: QuestionProps) {
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const [answer, setAnswer] = useState<boolean | null>(null);
  const [subAnswer, setSubAnswer] = useState<string | number | null>(null);
  const [tags, setTags] = useState<string[]>([]);

  const otherInputRef = useRef<HTMLInputElement>(null);

  const [form, setForm] = useState<TQuestionAnswer>({
    ...(isDependent ? { dependent_id: id } : { holder_id: id }),
    order_id: orderId,
    answers: []
  });

  const getPdf = async () => {
    try {
      const form = {
        ...(isDependent ? { dependent_id: id } : { holder_id: id }),
        order_id: orderId
      };
      const res = await api.post(`${isDependent ? 'dependent' : 'holder'}/cpt/create`, form);
      setPdf(res.data.cpt_document);
    } catch (err) {
      setAlert({
        type: 'error',
        text: 'Erro ao buscar o PDF',
        title: 'Erro'
      });
    }
  };

  const cleanFields = () => {
    setAnswer(null);
    setSubAnswer(null);
    setTags([]);
  };

  const handleIncludeTag = () => {
    if (otherInputRef.current?.value) {
      setTags([...tags, otherInputRef.current.value]);
      otherInputRef.current.value = '';
    }
  };

  const handleNext = () => {
    if (answer === null)
      return setAlert({ type: 'warning', text: 'Selecione sim ou não', title: 'Atenção' });
    else if (answer && !tags.length)
      return setAlert({
        type: 'warning',
        text: 'Por favor, preencha todos os campos',
        title: 'Atenção'
      });

    const newAnswer = {
      answer_description: questions?.find((q) => q.id === count - 1)?.description,
      answer_flag: answer ? 1 : 0,
      disease: tags.join(', '),
      question_id: questions?.find((q) => q.id === count - 1)?.id,
      initial_question_id: ''
    };

    setForm((prev) => ({
      ...prev,
      answers: [...prev.answers, newAnswer]
    }));

    if (answer) setPending(true);

    setCount(count + 1);
    cleanFields();
  };

  const handleSave = async () => {
    handleNext();
    try {
      await api.post(`${isDependent ? 'dependent' : 'holder'}/dps-init/create`, imcForm);
      await api.post(`${isDependent ? 'dependent' : 'holder'}/dps/create`, form);
      setAlert({
        type: 'success',
        text: 'Declaração de saúde enviada com sucesso',
        title: 'Sucesso'
      });

      setNotCompleted((prev: number) => prev - 1);
    } catch (err) {
      setAlert({
        type: 'error',
        text: 'Erro ao enviar declaração de saúde',
        title: 'Erro'
      });
    }
    await getPdf();
  };

  return (
    <>
      <div className={styles.column}>
        {questions?.map(
          (question: TQuestion, i) =>
            count === question.id + 1 && (
              <React.Fragment key={question.id}>
                <div className={styles.wrapper}>
                  <span className={styles.info}>
                    Pergunta {i + 2} de {questionsLength}
                  </span>
                  <p className={styles.title}>{question.name}</p>
                  <p className={styles.info}>{question.description}</p>
                </div>

                <div className={styles.confirmButtons}>
                  <Button
                    appearance={`${answer ? 'hoved' : 'ghost'}`}
                    text="Sim"
                    width="fit-content"
                    iconPosition="left"
                    onClick={() => setAnswer(true)}
                  >
                    <GoIssueClosed />
                  </Button>
                  <Button
                    appearance={`${answer === false ? 'hoved' : 'ghost'}`}
                    text="Não"
                    width="fit-content"
                    iconPosition="left"
                    onClick={() => setAnswer(false)}
                  >
                    <AiOutlineCloseCircle />
                  </Button>
                </div>
                {answer && question.subanswers && (
                  <>
                    <div className={styles.tags}>
                      {tags?.map((tag) => (
                        <Chip
                          key={tag}
                          title={tag}
                          onClick={() => setTags(tags?.filter((t) => t !== tag))}
                        />
                      ))}
                    </div>
                    <ListBox
                      options={question.subanswers.map((subanswer) => ({
                        label: subanswer.name,
                        value: subanswer.id
                      }))}
                      onChange={(label) => {
                        setSubAnswer(label);
                        if (label !== 'Outros' && !tags.includes(label.toString()))
                          setTags([...tags, label.toString()]);
                      }}
                    />
                    {subAnswer === 'Outros' && (
                      <div className={styles.subquestion}>
                        <Input placeholder="Qual outro(a)?" ref={otherInputRef} />
                        <Description appearance="grey2">
                          O campo de descrever a doença, que passará por uma analise para inclusão
                          de CPT.
                        </Description>
                        <Spacing size="medium" />
                        <Button
                          appearance="primary"
                          width="120px"
                          text="Incluir"
                          onClick={handleIncludeTag}
                        />
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            )
        )}
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          appearance="ghost"
          text={count === questionsLength ? 'Salvar' : 'Próxima'}
          width="fit-content"
          onClick={count === questionsLength ? handleSave : handleNext}
        >
          <MdOutlineKeyboardArrowRight />
        </Button>
      </div>
      {alert && <Alert {...alert} />}
    </>
  );
}

export default Question;
