import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-bro.png';

function ProposalSentConsultant() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true} goBackButton={false}>
      <Feedback
        title="Proposta foi enviada para você!"
        description="Enviamos para o seu e-mail a proposta comercial. Se preferir, você pode visualizar a proposta e dar seguimento ao atendimento aqui pelo site"
        image={feedback}
        buttonText="Ver Proposta"
        onClickButton={() => navigate('/proposta/download')}
        underbuttonText="Simular novamente"
        onClickUnderbutton={() => {
          sessionStorage.clear();
          navigate('/');
        }}
      />
    </Layout>
  );
}

export default ProposalSentConsultant;
