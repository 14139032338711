import { Button } from 'components';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import styles from '../styles.module.scss';

type StartProps = {
  questionsLength: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

function Start({ questionsLength, setCount }: StartProps) {
  return (
    <>
      <div className={styles.column}>
        <div className={styles.wrapper}>
          <p className={styles.placeholder}>
            Responda a {questionsLength || ''} perguntas referentes a sua saúde
          </p>
          <p className={styles.info}> É fácil e rápido </p>
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button appearance="ghost" text="Próxima" width="fit-content" onClick={() => setCount(1)}>
          <MdOutlineKeyboardArrowRight />
        </Button>
      </div>
    </>
  );
}

export default Start;
