import {
  getCPTDescriptions,
  getDepedentByID,
  getDependentsCPT,
  getHoldDocuments,
  getHoldersCPT,
  getOrderData,
  getPlanById,
  getQuestionsDPS,
  updateBeneficiariesStatus
} from 'api/services/ApiCalls';
import {
  Alert,
  Button,
  Card,
  Column,
  Description,
  Divider,
  Dropdown,
  FileUpload,
  LabelledValue,
  Layout,
  Row,
  Spacing,
  Spinner,
  Text,
  Title,
  Undertitle
} from 'components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { maskCPF, maskCep, maskPhone } from 'utils/mask';
import { useNavigate } from 'react-router-dom';
import DocumentReviewer from './components/DocumentReviewer';
import CPT from './components/CPT';
import styles from './styles.module.scss';

import checkicon from '../../assets/icons/check-icon.png';
import clockicon from '../../assets/icons/clock-icon.png';
import warningicon from '../../assets/icons/warning-icon.png';
import erroricon from '../../assets/icons/error-icon.png';

dayjs().format();
dayjs.locale('pt-br');

dayjs().format();
dayjs.locale('pt-br');

function ReviewHolderConsultant() {
  const navigate = useNavigate();
  const [id, setId] = useState(sessionStorage.getItem('@holder'));
  const [loading, setLoading] = useState<any>(true);
  const [holder, setHolder] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);
  const [dependents, setDependents] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [cptHolder, setcptHolder] = useState<any>([]);
  const [cptDependents, setcptDependents] = useState<any>([]);
  const [alert, setAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [plan, setPlan] = useState<any>({});
  const [CPTDescription, setCPTDescription] = useState([]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function checkBeneficiariesDocuments(holder, dependents) {
    const checks = [];

    holder.documents.map((document: any) => {
      checks.push(document.status);
    });

    dependents.map((dependent: any) => {
      dependent.documents.map((document: any) => {
        checks.push(document.status);
      });
    });

    if (checks.includes(0)) {
      setAlert(true);
      setAlertTitle('Atenção!');
      setAlertMessage('Ainda há documentos à serem revisados!');
      setTimeout(() => {
        setAlert(false);
      }, 5500);
    } else if (checks.includes(2)) {
      const formData = new FormData();
      formData.append('holder_id', holder.id);
      formData.append('beneficiaries_status', '4');

      updateBeneficiariesStatus(formData)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const formData = new FormData();
      formData.append('holder_id', holder.id);
      formData.append('beneficiaries_status', '6');

      updateBeneficiariesStatus(formData)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async function getDPSQuestions() {
    await getQuestionsDPS()
      .then((response) => {
        const quest = [];
        response.data.question_answer?.map((question: any) => {
          question.subanswers?.map((subanswer: any) => {
            if (!cptHolder.some((item) => item.disease === subanswer.name)) {
              subanswer.title = subanswer.cpt_description;
              subanswer.value = subanswer.name;
              quest.push(subanswer);
            }
          });
        });
        setQuestions(quest);
      })
      .catch((error) => console.error(error));
  }

  function getAllDocuments(holder) {
    getHoldDocuments()
      .then((response) => {
        setDocuments([...response.data.documents]);
        holder.holder[0].documents.map((document) => {
          const test = response.data.documents.find((elem) => elem.id === document.document_id);
          document.type = test?.type;
          document.description = test?.description;
        });
        holder.dependents.map((dependent) => {
          dependent.documents.map((document) => {
            const test = response.data.documents.find((elem) => elem.id === document.document_id);
            document.type = test?.type;
            document.description = test?.description;
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function organizeDependentsCPT(dependents) {
    dependents?.map((dependent) => {
      let questionsGeral;
      getDependentsCPT(dependent.id)
        .then((response) => {
          getQuestionsDPS()
            .then((questions: any) => {
              questionsGeral = questions.data.question_answer;
            })
            .catch((error) => console.error(error))
            .finally(() => {
              questionsGeral.map((question) => {
                question.answer = false;
              });
              let dependentQuestions = [];
              dependentQuestions = questionsGeral;
              const cptItems = [];
              const cptInitialQuestions = [];
              const cptsDependent = [];

              response.data.dependent_answers.map((cpt) => {
                if (
                  cpt.answer_flag === '1' &&
                  cpt.question_id &&
                  cpt.dependent_id === dependent.id
                ) {
                  cptsDependent.push(cpt);
                }
                if (!cpt.question_id) {
                  cptInitialQuestions.push(cpt);
                }
              });

              cptsDependent.map((item) => {
                const index = dependentQuestions.findIndex((elem) => elem.id === item.question_id);
                dependentQuestions[index].answer = true;
                dependentQuestions[index].disease = item.disease;
                cptItems.push(item);
              });

              const index = dependents.findIndex((elem) => elem.id === dependent.id);
              dependents[index].dps = dependentQuestions;
              dependents[index].cpt = response.data.cpt_descriptions;

              let peso;
              if (cptInitialQuestions[0].initial_question_id) {
                peso = cptInitialQuestions.find((question) => question.initial_question_id === 2);
              } else {
                // eslint-disable-next-line prefer-destructuring
                peso = cptInitialQuestions[1];
              }
              console.log(peso);

              let altura;
              if (cptInitialQuestions[0].initial_question_id) {
                altura = cptInitialQuestions.find((question) => question.initial_question_id === 1);
              } else {
                // eslint-disable-next-line prefer-destructuring
                altura = cptInitialQuestions[0];
              }

              const imc =
                Number(peso.answer_description.replace(',', '.')) /
                (Number(altura.answer_description) * Number(altura.answer_description));
              dependents[index].imc = imc.toFixed(2);
              console.log('peso: ', Number(peso.answer_description.replace(',', '.')));
              console.log(imc);
              dependents[index].initial_questions = cptInitialQuestions;
              setDependents([...dependents]);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  function organizeHolderCPT(holder) {
    getQuestionsDPS()
      .then((questions) => {
        questions.data.question_answer.map((question) => {
          question.answer = false;
        });
        getHoldersCPT(holder?.id)
          .then((cpts) => {
            setcptHolder([...cpts.data.holder_answers]);
            const cptItems = [];
            const cptInitialQuestions = [];
            console.log(cpts);
            cpts.data.holder_answers.map((cpt) => {
              if (cpt.question_id) {
                const index = questions.data.question_answer.findIndex(
                  (elem) => elem.id === cpt.question_id
                );
                if (cpt.answer_flag === '1') {
                  questions.data.question_answer[index].answer = true;
                  questions.data.question_answer[index].disease = cpt.disease;
                  cptItems.push(cpt);
                }
              } else {
                cptInitialQuestions.push(cpt);
              }
            });
            holder.dps = questions.data.question_answer;
            holder.cpt = cpts.data.cpt_descriptions;
            const peso = cptInitialQuestions.find((question) => question.initial_question_id === 2);
            const altura = cptInitialQuestions.find(
              (question) => question.initial_question_id === 1
            );

            const imc =
              Number(peso.answer_description.replace(',', '.')) /
              (Number(altura.answer_description) * Number(altura.answer_description));
            holder.imc = imc.toFixed(2);
            holder.initial_questions = cptInitialQuestions;
            setHolder({ ...holder });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => console.error(error));
  }

  function getOrder(id) {
    getOrderData(id)
      .then((response) => {
        setOrder(response.data.order_data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getBeneficiaries() {
    getDepedentByID(id)
      .then((response) => {
        getOrder(response.data.holder[0].order_id);
        response.data.holder[0].cpt = [];
        setDependents(response.data.dependents);

        getPlanById(response.data.holder[0].plan_id).then((response) => {
          setPlan(response.data.plan[0]);
        });

        organizeHolderCPT(response.data.holder[0]);
        organizeDependentsCPT(response.data.dependents);

        response.data.holder[0].dependents.map((dependent: any) => {
          getDependentsCPT(dependent.id)
            .then((response) => {
              if (cptDependents.length > 0) {
                const index = cptDependents.findIndex((elem) => elem.id === dependent.id);
                cptDependents[index] = {
                  cpt: response.data.dependent_answers,
                  ...dependent
                };
                setcptDependents([...cptDependents]);
              } else {
                cptDependents.push({
                  cpt: response.data.dependent_answers,
                  ...dependent
                });
                setcptDependents([...cptDependents]);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
        getAllDocuments(response.data);
        setHolder(response.data.holder[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    getDPSQuestions();
  }

  useEffect(() => {
    getBeneficiaries();
    getCPTDescriptions()
      .then((response) => {
        response.data.cpt_descriptions.map((item) => {
          item.title = item.name;
        });
        setCPTDescription(response.data.cpt_descriptions);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Layout
      navbar={true}
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Contrato', status: 'concluded' },
        { title: 'Cadastro Beneficiários', status: 'concluded' },
        { title: 'Análise Beneficiários', status: 'inProgress' },
        { title: 'Implantação', status: 'pending' }
      ]}
    >
      <Spacing size="medium" />
      {alert && <Alert type="success" title={alertTitle} text={alertMessage} />}
      {!loading ? (
        <>
          <Row width="97%" position="start">
            <Title appearance="primary" bolder={true}>
              Analise as informações recebidas
            </Title>
          </Row>
          <Spacing size="x-small" />
          <Row width="97%" position="start">
            <Text position="start" maxWidth="100%">
              Analise as informações do titular abaixo. Caso haja alguma incosistência, você pode
              reenviar a documentação para correção.
            </Text>
          </Row>
          <Spacing size="large" />
          <Card appearance="ghost" width="97%">
            <Row position="start">
              <Column>
                <Description textAlign="start">Status</Description>
                <Spacing size="xxx-small" />
                <Row position="start" align="start">
                  {holder.status === 0 && (
                    <>
                      <img src={clockicon} className={styles.icon} />
                      <Description textAlign="start">Aguardando</Description>
                    </>
                  )}
                  {holder.status === 1 && (
                    <>
                      <img src={clockicon} className={styles.icon} />
                      <Description textAlign="start">DPS</Description>
                    </>
                  )}
                  {holder.status === 2 && (
                    <>
                      <img src={clockicon} className={styles.icon} />
                      <Description textAlign="start">Envio da Documentação</Description>
                    </>
                  )}
                  {holder.status === 3 && (
                    <>
                      <img src={warningicon} className={styles.icon} />
                      <Description textAlign="start">Em análise</Description>
                    </>
                  )}
                  {holder.status === 4 && (
                    <>
                      <img src={clockicon} className={styles.icon} />
                      <Description textAlign="start">Aguardando Reenvio</Description>
                    </>
                  )}
                  {holder.status === 5 && (
                    <>
                      <img src={clockicon} className={styles.icon} />
                      <Description textAlign="start">Reenviado</Description>
                    </>
                  )}
                  {holder.status === 6 && (
                    <>
                      <img src={checkicon} className={styles.icon} />
                      <Description textAlign="start">Aprovado</Description>
                    </>
                  )}
                  {holder.status === 7 && (
                    <>
                      <img src={erroricon} className={styles.icon} />
                      <Description textAlign="start">Cancelado</Description>
                    </>
                  )}
                </Row>
              </Column>
              <Column>
                <Description textAlign="start">CPF</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true} textAlign="start">
                  {maskCPF(holder.cpf)}
                </Text>
              </Column>
              <Column>
                <Description textAlign="start">Nome</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true} textAlign="start">
                  {holder.name}
                </Text>
              </Column>
            </Row>
          </Card>
          <Spacing size="large" />

          <Row width="96%" position="start">
            <Undertitle bolder={true}>Titular</Undertitle>
          </Row>
          <Spacing size="xx-small" />
          <Row width="96%" position="start">
            <Undertitle bolder={true} appearance="primary">
              {holder.name}
            </Undertitle>
          </Row>
          <Spacing size="small" />

          <Dropdown title="Informações do Titular">
            <Row width="97%">
              <Column width="100%">
                <LabelledValue align="start" label="CPF" value={maskCPF(holder.cpf)} />
                <Spacing size="small" />
                <LabelledValue align="start" label="Email" value={holder.email} />
                <Spacing size="small" />
                <LabelledValue align="start" label="CEP" value={maskCep(holder.zip_code)} />
                <Spacing size="small" />
                <LabelledValue align="start" label="Número" value={holder.number} />
                <Spacing size="small" />
                <LabelledValue align="start" label="Nome da Mãe" value={holder.mother_name} />
              </Column>
              <Column>
                <LabelledValue align="start" label="Nome" value={holder.name} />
                <Spacing size="small" />
                <LabelledValue align="start" label="Telefone" value={maskPhone(holder.phone)} />
                <Spacing size="small" />
                <LabelledValue align="start" label="Rua" value={holder.street} />
                <Spacing size="small" />
                <LabelledValue
                  align="start"
                  label="Complemento"
                  value={holder.complement ? holder.complement : ' - '}
                />
              </Column>
              <Column>
                <LabelledValue
                  align="start"
                  label="Data de Nascimento"
                  value={dayjs(holder.birth_date).format('DD/MM/YYYY')}
                />
                <Spacing size="small" />
                <LabelledValue align="start" label="Estado Civil" value={holder.civil_status} />
                <Spacing size="small" />
                <LabelledValue align="start" label="Bairro" value={holder.district} />
                <Spacing size="small" />
                <Row>
                  <Column position="start">
                    <LabelledValue align="start" label="Cidade" value={holder.city} />
                  </Column>
                  <Column position="start">
                    <LabelledValue align="start" label="UF" value={holder.state} />
                  </Column>
                </Row>
              </Column>
            </Row>
          </Dropdown>

          <Spacing size="small" />

          {plan.type !== 'Odontológico' && (
            <>
              <Dropdown title="Declaração de Saúde Pessoal do Titular">
                <Spacing size="small" />

                {holder.initial_questions && (
                  <>
                    <Card appearance="ghost">
                      <Row width="98%">
                        <Column>
                          <Undertitle bolder={true}>Pergunta Inicial</Undertitle>
                        </Column>
                      </Row>
                      <Spacing size="large" />
                      <Row width="100%">
                        <Column>
                          <Text>
                            <b>Altura:</b>
                            {holder.initial_questions[0]?.answer_description}
                          </Text>
                        </Column>
                        <Column>
                          <Text>
                            <b>Peso:</b>
                            {Number(
                              holder.initial_questions[1]?.answer_description.replace(',', '.')
                            ).toFixed(2)}
                          </Text>
                        </Column>
                        <Column>
                          <Text>
                            <b>IMC:</b> {holder.imc}
                          </Text>
                        </Column>
                      </Row>
                      <Spacing size="medium" />
                      <Card
                        padding="16px 8px"
                        appearance={
                          holder.initial_questions[0]?.answer_flag !== '1' ? 'error' : 'green'
                        }
                      >
                        <Row>
                          <Column>
                            <Row position="start">
                              <Text textAlign="start">Você concorda com o seu IMC?</Text>
                            </Row>
                          </Column>
                          <Column width="40%" position="centered">
                            <Text
                              appearance={
                                holder.initial_questions[0]?.answer_flag === '1'
                                  ? 'success'
                                  : 'disabled'
                              }
                              bolder={holder.initial_questions[0]?.answer_flag === '1'}
                            >
                              Sim
                            </Text>
                          </Column>
                          <Column width="40%" position="centered">
                            <Text
                              appearance={
                                holder.initial_questions[0]?.answer_flag !== '1'
                                  ? 'error'
                                  : 'disabled'
                              }
                              bolder={holder.initial_questions[0]?.answer_flag !== '1'}
                            >
                              Não
                            </Text>
                          </Column>
                        </Row>
                      </Card>
                    </Card>
                    <Spacing size="small" />
                  </>
                )}
                {holder.dps ? (
                  holder.dps.map((elem) => (
                    <>
                      <Spacing size="xx-small" />
                      <Card padding="16px 8px" appearance={elem.answer && 'error'}>
                        <Row align="start">
                          <Column width="2%" justifyContent="flex-start">
                            <Text>{elem.id}) </Text>
                          </Column>
                          <Spacing size="x-small" />
                          <Column>
                            <Row position="start">
                              <Text textAlign="start">{elem.name}</Text>
                            </Row>
                            {elem.disease && (
                              <>
                                <Spacing size="xx-small" />
                                <Row position="start">
                                  <Text textAlign="start">
                                    <b>Resposta: </b> {elem.disease}
                                  </Text>
                                </Row>
                              </>
                            )}
                          </Column>
                          <Column width="40%" position="centered" justifyContent="center">
                            <Text
                              appearance={elem.answer ? 'error' : 'disabled'}
                              bolder={elem.answer}
                            >
                              Sim
                            </Text>
                          </Column>
                          <Column width="40%" position="centered" justifyContent="center">
                            <Text
                              appearance={!elem.answer ? 'success' : 'disabled'}
                              bolder={!elem.answer}
                            >
                              Não
                            </Text>
                          </Column>
                        </Row>
                      </Card>
                      <Spacing size="xx-small" />
                      <Divider />
                      <Spacing size="xx-small" />
                    </>
                  ))
                ) : (
                  <>
                    <Spacing size="small" />
                    <Row align="center">
                      <Text>O titular ainda não respondeu a sua Declaração de Saúde Pessoal</Text>
                    </Row>
                    <Spacing size="small" />
                  </>
                )}
              </Dropdown>
              <Spacing size="small" />
            </>
          )}

          <Dropdown title="Documentos do Titular">
            <div className={styles.documents}>
              <div className={styles.row}>
                {holder.documents.length > 0 ? (
                  holder.documents.map((document, key) => (
                    <div className={styles.column} key={key}>
                      <DocumentReviewer
                        id={document.document_id}
                        width={width}
                        orderId={order.status}
                        status={document.status}
                        beneficiarieID={holder.id}
                        beneficiarieType="holder"
                        title={document.type}
                        description={document.description}
                        image={document.document_path}
                        updateData={getBeneficiaries}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    <Row align="center">
                      <Text>O titular ainda não realizou o upload da sua documentação</Text>
                    </Row>
                    <Spacing size="large" />
                  </>
                )}
              </div>
            </div>
          </Dropdown>

          <Spacing size="medium" />

          {dependents.lenght > 0 && <Divider />}

          {dependents &&
            dependents.map((dependent, key) => (
              <>
                <Spacing size="large" />
                <Row position="start" width="96%">
                  <Undertitle bolder={true} appearance="primary-light">
                    Dependente {key + 1}
                  </Undertitle>
                </Row>
                <Spacing size="xx-small" />
                <Row position="start" width="96%">
                  <Undertitle bolder={true} appearance="primary">
                    {dependent.name}
                  </Undertitle>
                </Row>

                <Spacing size="small" />

                <Dropdown title="Informações do Dependente">
                  <Row>
                    <Column width="100%">
                      <LabelledValue align="start" label="CPF" value={maskCPF(dependent.cpf)} />
                      <Spacing size="small" />
                      <LabelledValue align="start" label="Nome" value={dependent.name} />
                    </Column>
                    <Column>
                      <LabelledValue
                        align="start"
                        label="Data de Nascimento"
                        value={dayjs(dependent.birth_date).format('DD/MM/YYYY')}
                      />
                      <Spacing size="small" />
                      <LabelledValue
                        align="start"
                        label="Nome da mãe"
                        value={dependent.mother_name}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Spacing size="small" />
                      <LabelledValue
                        align="start"
                        label="Grau de Parentesco"
                        value={dependent.relationship}
                      />
                    </Column>
                  </Row>
                </Dropdown>

                <Spacing size="small" />

                {plan.type !== 'Odontológico' && (
                  <>
                    <Dropdown title="Declaração de Saúde Pessoal do Dependente">
                      <Spacing size="small" />

                      {dependent.initial_questions && (
                        <>
                          <Card appearance="ghost">
                            <Row width="98%">
                              <Column>
                                <Undertitle bolder={true}>Pergunta Inicial</Undertitle>
                              </Column>
                            </Row>
                            <Spacing size="large" />
                            <Row width="100%">
                              <Column>
                                <Text>
                                  <b>Altura:</b>
                                  {dependent.initial_questions[0]?.answer_description}
                                </Text>
                              </Column>
                              <Column>
                                <Text>
                                  <b>Peso:</b>
                                  {Number(dependent.initial_questions[1]?.answer_description)
                                    .toFixed(2)
                                    .replace(',', '.')}
                                </Text>
                              </Column>
                              <Column>
                                <Text>
                                  <b>IMC:</b> {dependent.imc}
                                </Text>
                              </Column>
                            </Row>
                            <Spacing size="medium" />
                            <Card
                              padding="16px 8px"
                              appearance={
                                dependent.initial_questions[0]?.answer_flag !== '1'
                                  ? 'error'
                                  : 'green'
                              }
                            >
                              <Row>
                                <Column>
                                  <Row position="start">
                                    <Text textAlign="start">Você concorda com o seu IMC?</Text>
                                  </Row>
                                </Column>
                                <Column width="40%" position="centered">
                                  <Text
                                    appearance={
                                      dependent.initial_questions[0]?.answer_flag === '1'
                                        ? 'success'
                                        : 'disabled'
                                    }
                                    bolder={dependent.initial_questions[0]?.answer_flag === '1'}
                                  >
                                    Sim
                                  </Text>
                                </Column>
                                <Column width="40%" position="centered">
                                  <Text
                                    appearance={
                                      dependent.initial_questions[0]?.answer_flag !== '1'
                                        ? 'error'
                                        : 'disabled'
                                    }
                                    bolder={dependent.initial_questions[0]?.answer_flag !== '1'}
                                  >
                                    Não
                                  </Text>
                                </Column>
                              </Row>
                            </Card>
                          </Card>
                          <Spacing size="small" />
                        </>
                      )}

                      {dependent.dps ? (
                        dependent.dps.map((elem) => (
                          <>
                            <Spacing size="xx-small" />
                            <Card padding="16px 8px" appearance={elem.answer && 'error'}>
                              <Row>
                                <Column width="2%" position="centered">
                                  <Text>{elem.id}) </Text>
                                </Column>
                                <Column>
                                  <Row position="start">
                                    <Text textAlign="start">{elem.name}</Text>
                                  </Row>
                                  <Spacing size="xx-small" />
                                  {elem.disease && (
                                    <Row position="start">
                                      <Text textAlign="start">
                                        <b>Resposta: </b> {elem.disease}
                                      </Text>
                                    </Row>
                                  )}
                                </Column>
                                <Column width="40%" position="centered">
                                  <Text
                                    appearance={elem.answer ? 'error' : 'disabled'}
                                    bolder={elem.answer}
                                  >
                                    Sim
                                  </Text>
                                </Column>
                                <Column width="40%" position="centered">
                                  <Text
                                    appearance={!elem.answer ? 'success' : 'disabled'}
                                    bolder={!elem.answer}
                                  >
                                    Não
                                  </Text>
                                </Column>
                              </Row>
                            </Card>
                            <Spacing size="xx-small" />
                          </>
                        ))
                      ) : (
                        <>
                          <Spacing size="small" />
                          <Row align="center">
                            <Text>
                              O dependente ainda não respondeu a sua Declaração de Saúde Pessoal
                            </Text>
                          </Row>
                          <Spacing size="small" />
                        </>
                      )}
                      <Spacing size="medium" />
                    </Dropdown>

                    <Spacing size="small" />
                  </>
                )}

                <Dropdown title="Documentos do Dependente">
                  <div className={styles.documents}>
                    <div className={styles.row}>
                      {dependent.documents.length > 0 ? (
                        dependent.documents.map((document, key) => (
                          <div className={styles.column} key={key}>
                            <DocumentReviewer
                              id={document.document_id}
                              width={width}
                              orderId={order.status}
                              status={document.status}
                              beneficiarieID={dependent.id}
                              beneficiarieType="dependent"
                              title={document.type}
                              description={document.description}
                              image={document.document_path}
                              updateData={getBeneficiaries}
                            />
                          </div>
                        ))
                      ) : (
                        <>
                          <Row align="center">
                            <Text>
                              O dependente ainda não realizou o upload da sua documentação
                            </Text>
                          </Row>
                          <Spacing size="large" />
                        </>
                      )}
                    </div>
                  </div>
                </Dropdown>
              </>
            ))}

          <Spacing size="xxx-large" />

          <Row width="97%" position="spaceBetween">
            <Button
              width={width >= 650 ? '250px' : '90%'}
              text="Voltar para o Painel"
              appearance="ghost"
              onClick={() => navigate(-1)}
            />
          </Row>
          <Spacing size="large" />
        </>
      ) : (
        <Row width="97%" align="center">
          <Column width="100%" position="centered">
            <Spinner show={true} />
            <Spacing size="medium" />
            <Description appearance="primary" bolder={true}>
              Carregando os dados
            </Description>
          </Column>
        </Row>
      )}
    </Layout>
  );
}

export default ReviewHolderConsultant;
