import { api } from 'api/services';
import JWT from 'expo-jwt';
import {
  acceptBeneficiaryPolicy,
  getDocumentations,
  senTokenDPS,
  updateBeneficiariesStatus
} from 'api/services/ApiCalls';
import { Button, Checkbox, Layout, Modal, Spacing } from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertState } from 'components/Alert';
import { useMount } from 'react-use';
import Box from './components/Box';
import styles from './styles.module.scss';

export default function DeclarationHealth() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const beneficiaryStorage = JSON.parse(sessionStorage.getItem('@beneficiary'));
  const [beneficiary, setBeneficiary] = useState<any>(beneficiaryStorage);
  const [dependents, setDependents] = useState<any>(beneficiaryStorage?.dependents || []);
  const [disabledNext, setDisabledNext] = useState<boolean>(false);
  const [notCompleted, setNotCompleted] = useState<any>((dependents?.length || 0) + 1);
  const [alert, setAlert] = useState<AlertState | null>(null);
  const [modalPolicy, setModalPolicy] = useState(true);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [pdf, setPdf] = useState<any>({});
  const [order, setOrder] = useState('');

  if (beneficiary.status === 1) navigate('/declaracao-saude/auth');
  if (beneficiary.status > 1) navigate('/conclusao');

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  async function getData() {
    try {
      const res = await api.get(`/holder-dependents/${beneficiary.id}`);
      const { data } = res;
      setOrder(data.holder[0].order_id);
      setBeneficiary(data.holder[0]);
      setDependents(data.dependents);
    } catch (err) {
      console.error(err);
    }
  }

  useMount(() => {
    getData();
  });

  async function handleGenerateToken() {
    const formData = new FormData();
    formData.append('holder_id', beneficiary.id);

    await senTokenDPS(formData)
      .then(() => {
        navigate('/declaracao-saude/auth');
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
    setLoading(false);
  }

  function updateStatus() {
    setLoading(true);
    const form = {
      holder_id: beneficiary.id,
      beneficiaries_status: 1
    };

    updateBeneficiariesStatus(form).then(() => {
      handleGenerateToken();
    });
  }

  function getPolicyDocuments() {
    const accept = localStorage.getItem('@acceptPolicy');
    if (accept) {
      setModalPolicy(false);
    } else {
      getDocumentations().then((response) => {
        const document = response.data.documentation.find(
          (doc) => doc.type === 'Política de Privacidade'
        );
        setPdf(document);
      });
    }
  }

  useEffect(() => {
    getPolicyDocuments();
  }, []);

  return (
    <Layout
      navbar
      verticalCentered
      steps={[
        { title: 'Beneficiários', status: 'concluded' },
        { title: 'Declaração de saúde', status: 'inProgress' },
        { title: 'Documentos', status: 'pending' },
        { title: 'Conclusão', status: 'pending' }
      ]}
    >
      <Modal
        isOpen={modalPolicy}
        width="750px"
        height={width >= 1000 ? 'auto' : '700px'}
        title="Termos e Política de Privacidade"
        description="Para dar continuidade ao cadastro é necessário aceitar os Termos e Política de Privacidade 
abaixo. Leia atentamente e clique em concordo para aceitar e continuar o processo."
      >
        <iframe
          src={`${pdf.documentation_path}#toolbar=0&view=FitH`}
          className={styles.pdf}
          title="Cobertura Parcial Temporária"
        />
        <Spacing size="x-large" />
        <Checkbox
          id="temporaryCoverage"
          label="Li e estou de acordo com os termos e política de privacidade."
          checked={acceptPolicy}
          setChecked={() => setAcceptPolicy(!acceptPolicy)}
        />
        <Spacing size="xxx-large" />
        <div className={styles.row}>
          <Button
            text="Concordo"
            appearance={acceptPolicy ? 'primary' : 'disabled'}
            width={width >= 1000 ? '45%' : '90%'}
            onClick={() => {
              if (acceptPolicy) {
                setModalPolicy(false);
                localStorage.setItem('@acceptPolicy', JSON.stringify(true));

                acceptBeneficiaryPolicy({
                  token: JWT.encode({ order_id: order }, 'sulmed')
                }).then((response) => console.info(response.data));
              } else {
                setAlert({
                  show: true,
                  title: 'Atenção!',
                  type: 'warning',
                  text: 'Você precisa aceitar os Termos e Politica de Privacidade para continuar'
                });
                setTimeout(() => {
                  setAlert(null);
                }, 5500);
              }
            }}
          />
        </div>
      </Modal>

      <div className={styles.container}>
        <div className={styles.title}>
          <h4>Declaração de Saúde</h4>
          <p>
            Responda um a Declaração Pessoal de Saúde para prosseguir para o envio da sua
            documentação
          </p>
        </div>

        <section className={styles.section}>
          <p className={`${styles.text} ${styles.bold}`}>Titular</p>
          <p className={styles.text}>{beneficiary.name}</p>
          <Box
            id={beneficiary.id}
            orderId={beneficiary.order_id}
            setNotCompleted={setNotCompleted}
            setDisabledNext={setDisabledNext}
          />
        </section>

        {dependents?.map((dependent, i) => (
          <section key={dependent.name} className={styles.section}>
            <p className={`${styles.text} ${styles.bold}`}>Dependente</p>
            <p className={styles.text}>{dependent.name}</p>
            <Box
              id={dependents[i].id}
              orderId={dependents[i].order_id}
              setNotCompleted={setNotCompleted}
              setDisabledNext={setDisabledNext}
              isDependent
            />
          </section>
        ))}

        <div className={styles.buttonsWrapper}>
          <Button
            appearance="ghost"
            text="Voltar ao Inicio"
            width="fit-content"
            onClick={() => navigate('/formulario/cadastro')}
          />

          <Button
            text="Continuar"
            width="fit-content"
            loading={loading}
            disabled={disabledNext || notCompleted !== 0}
            onClick={() => updateStatus()}
          />
        </div>
      </div>
    </Layout>
  );
}
