import React from 'react';
import styles from './styles.module.scss';

function BrokerLogged({ brokerName = '', brokerImg = '' }) {
  return (
    <div className={styles.brokerContainer}>
      <div className={styles.brokerImg}>
        <img src={brokerImg} alt="brokerImg" />
      </div>
      <div className={styles.brokerText}>
        <p className={styles.brokerName}>{brokerName}</p>
        <p className={styles.brokerDescription}>Consultor</p>
      </div>
    </div>
  );
}

export default BrokerLogged;
