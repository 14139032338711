import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-success.png';

function ProposalSuccess() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Parabéns por escolher a Sulmed!"
        image={feedback}
        description="Falta muito pouco para que sua empresa possa garantir saúde de qualidade para todos seus colaboradores! Vamos dar segmento no cadastro da sua empresa."
        buttonText="Iniciar Cadastro"
        onClickButton={() => navigate('/formulario/empresa')}
        underbuttonText="Ir para a Página Inicial"
        onClickUnderbutton={() => navigate('/')}
      />
    </Layout>
  );
}

export default ProposalSuccess;
