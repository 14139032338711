import {
  getDepedentByID,
  getHoldDocuments,
  saveDependentsDocuments,
  saveHolderDocuments,
  updateBeneficiariesStatus
} from 'api/services/ApiCalls';
import {
  Button,
  Column,
  Description,
  Divider,
  FileUpload,
  Layout,
  Row,
  Spacing,
  Spinner,
  Text,
  Title
} from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

export default function UploadReprovedDocuments() {
  const [holders, setHolders] = useState<any>({});
  const [dependents, setDependents] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [editDocuments, setEditDocuments] = useState<boolean>(false);
  const [mockedData, setMockedData] = useState<any>([
    {
      id: 1,
      name: 'Nome do Titular 1',
      documents: [],
      dependents: [
        {
          id: 1,
          name: 'Nome do Dependente 1',
          documents: []
        },
        {
          id: 2,
          name: 'Nome do Dependente 2',
          documents: []
        }
      ]
    },
    {
      id: 2,
      name: 'Nome do Titular 2',
      documents: [],
      dependents: [
        {
          id: 1,
          name: 'Nome do Dependente 1',
          documents: []
        },
        {
          id: 2,
          name: 'Nome do Dependente 2',
          documents: []
        }
      ]
    }
  ]);
  const navigate = useNavigate();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  async function sendHolderDocuments() {
    holders.documents.map((document: any) => {
      if (document?.document_path.substring(0, 5) !== 'https') {
        const formData = new FormData();
        formData.append('document_id', document.document_id);
        formData.append('holder_id', holders.id);
        formData.append('holder', document.document_image);
        saveHolderDocuments(formData);
      }
    });

    dependents.map((dependent: any) => {
      dependent.documents.map((document: any) => {
        if (document.document_path.substring(0, 5) !== 'https') {
          const formData = new FormData();
          formData.append('document_id', document.document_id);
          formData.append('dependent_id', dependent.id);
          formData.append('dependent', document.document_image);
          saveDependentsDocuments(formData);
        }
      });
    });

    const formData = new FormData();
    formData.append('holder_id', holders.id);
    formData.append('beneficiaries_status', '5');
    updateBeneficiariesStatus(formData).then(() => {
      navigate('/conclusao');
    });
  }

  function organizeHolderData(holder: any, dependents: any, documents: any) {
    const holderDocuments = [];

    holder.documents.map((document) => {
      if (document.status === 2) {
        const filteredDocument = documents.find((elem) => elem.id === document.document_id);
        document.type = filteredDocument.type;
        document.description = filteredDocument.description;
        holderDocuments.push(document);
      }
    });

    holder.documents = holderDocuments;

    dependents.map((dependent) => {
      const dependentDocuments = [];
      dependent.documents.map((document) => {
        if (document.status === 2) {
          const filteredDocument = documents.find((elem) => elem.id === document.document_id);
          document.type = filteredDocument.type;
          document.description = filteredDocument.description;
          dependentDocuments.push(document);
        }
      });
      dependent.documents = dependentDocuments;
    });

    setHolders({ ...holder });
    setLoading(false);
  }

  function getDocuments(holder, dependents) {
    getHoldDocuments().then((response) => {
      organizeHolderData(holder, dependents, response.data.documents);
    });
  }

  function getHolderData(id) {
    getDepedentByID(id)
      .then((response) => {
        setHolders({ ...response.data.holder[0] });
        setDependents([...response.data.dependents]);
        getDocuments(response.data.holder[0], response.data.dependents);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getParams() {
    const params = window.location.search;
    setEditDocuments(true);
    const urlParams = new URLSearchParams(params);
    const tk = urlParams.get('t');
    const jwtParsed = parseJwt(tk);
    getHolderData(jwtParsed.holder.id);
  }

  useEffect(() => {
    getParams();
  }, []);

  return (
    <Layout
      navbar={true}
      verticalCentered={true}
      steps={[
        { title: 'Beneficiários', status: 'concluded' },
        { title: 'Declaração de saúde', status: 'concluded' },
        { title: 'Documentos', status: 'inProgress' },
        { title: 'Conclusão', status: 'pending' }
      ]}
    >
      {!loading ? (
        <>
          <Column position="centered" width="80%">
            <Spacing size="medium" />
            <Title bolder appearance="primary">
              Reenvio de Documentos
            </Title>
            <Spacing size="small" />
            <Description>
              Clique para fazer o reenvio dos documentos solicitados abaixo. Os documentos devem
              estar legíveis. Caso haja alguma inconsistência, enviaremos uma mensagem solicitando
              um novo envio de documento.
            </Description>
          </Column>
          <Spacing size="xxx-large" />
          <div className={styles.container}>
            {holders && holders.documents.length > 0 && (
              <>
                <Row width="100%">
                  <Column paddingRight="0px">
                    <Text bolder>Titular</Text>
                    <Spacing size="xx-small" />
                    <Title bolder appearance="primary">
                      {holders.name}
                    </Title>

                    <Spacing size="small" />
                    <Divider />
                    <Spacing size="large" />

                    <div className={styles.documents}>
                      {holders.documents.map(
                        (document: any, index: number) => (
                          // document.status === 2 && (
                          <Column
                            width={width <= 600 ? '80%' : '45%'}
                            paddingRight="0px"
                            key={index}
                          >
                            <Text bolder={true} textAlign="left">
                              {document.type}
                            </Text>
                            <Spacing size="xxx-small" />
                            <Description appearance="grey2" bolder={true} textAlign="left">
                              {document.description}
                            </Description>
                            <Spacing size="medium" />
                            <FileUpload
                              file={document.document_path}
                              setFile={(event: any) => {
                                // eslint-disable-next-line prefer-destructuring
                                document.document_image = event ? event.target.files[0] : '';
                                document.document_path = event || '';
                                setMockedData([...mockedData]);
                                setHolders({ ...holders });
                              }}
                              setImage={(event: any) => {
                                document.document_path = event;
                                setMockedData([...mockedData]);
                                setHolders({ ...holders });
                              }}
                            />
                            <Spacing size="xx-small" />
                            {document.disapproval_justification !== '' && (
                              <>
                                <i className={styles.disapprovalJustification}>
                                  <b>Motivo:</b> {document.disapproval_justification}
                                </i>
                                <Spacing size="medium" />
                              </>
                            )}
                          </Column>
                        )
                        // )
                      )}
                    </div>
                    <Spacing name="xxx-large" />
                  </Column>
                </Row>
                <Spacing size="xxx-large" />
              </>
            )}
            {dependents.map((dependent, index) =>
              dependent.documents.length > 0 ? (
                <Row width="100%" key={index}>
                  <Column>
                    <Description bolder>Dependente {index + 1}</Description>
                    <Spacing size="small" />
                    <Title bolder appearance="primary">
                      {dependent.name}
                    </Title>

                    <Spacing size="small" />
                    <Divider />
                    <Spacing size="xx-large" />

                    <div className={styles.documents}>
                      {dependent.documents.map(
                        (document: any, index: number) => (
                          // document.status === 2 && (
                          <Column
                            width={width <= 600 ? '80%' : '45%'}
                            paddingRight="0px"
                            key={index}
                          >
                            <Text bolder={true} textAlign="left">
                              {document.type}
                            </Text>
                            <Spacing size="xxx-small" />
                            <Description appearance="grey2" bolder={true} textAlign="left">
                              {document.description}
                            </Description>
                            <Spacing size="medium" />
                            <FileUpload
                              file={document.document_path}
                              setFile={(event: any) => {
                                // eslint-disable-next-line prefer-destructuring
                                document.document_image = event ? event.target.files[0] : '';
                                document.document_path = event || '';
                                setMockedData([...mockedData]);
                                // setDependents({ ...dependents });
                              }}
                              setImage={(event: any) => {
                                document.document_path = event;
                                setMockedData([...mockedData]);
                                // setDependents({ ...dependents });
                              }}
                            />
                            <Spacing size="xx-small" />
                            {document.disapproval_justification !== '' && (
                              <>
                                <i className={styles.disapprovalJustification}>
                                  <b>Motivo:</b> {document.disapproval_justification}
                                </i>
                                <Spacing size="medium" />
                              </>
                            )}
                          </Column>
                        )
                        // )
                      )}
                    </div>
                    <Spacing size="large" />
                  </Column>
                </Row>
              ) : (
                ''
              )
            )}
          </div>
          <Row width="100%" position="spaceBetween">
            <Button
              text="Voltar"
              width=" 78px"
              appearance="ghost"
              onClick={() => navigate('/declaracao-saude/auth')}
            />
            <Button text="Enviar" width=" 78px" onClick={sendHolderDocuments} />
          </Row>
        </>
      ) : (
        <Row width="100%" align="center">
          <Column width="100%" position="centered">
            <Spinner show={true} />
            <Spacing size="medium" />
            <Description appearance="primary" bolder={true}>
              Carregando os dados
            </Description>
          </Column>
        </Row>
      )}
    </Layout>
  );
}
