import { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useNavigate } from 'react-router-dom';

import { senTokenDPS, verifyTokenDPS } from 'api/services/ApiCalls';
import styles from './styles.module.scss';

import { Alert, Button, Description, Layout, Spacing, Text, Title } from '../../components';

function DeclarationHealthAuth() {
  const navigate = useNavigate();
  const [code, setCode] = useState<any>('');
  const [holderId, setHolderId] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);

  async function resendCodeSMS() {
    try {
      await senTokenDPS({
        holder_id: holderId
      });
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  function handleCode(event: any) {
    setCode(event);
  }

  async function handleAuthenticate() {
    try {
      await verifyTokenDPS({
        token: code,
        order_id: orderId,
        holder_id: holderId
      }).then((event: any) => {
        try {
          if (event.data.success === true) {
            navigate('/declaracao-saude/sucesso');
          } else {
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
            }, 5500);
          }
        } catch (error) {
          console.error(error.response.data.error);
        }
      });
    } catch (error) {
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 5500);
    }
  }

  useEffect(() => {
    const beneficiary = JSON.parse(sessionStorage.getItem('@beneficiary'));
    setOrderId(beneficiary.order_id);
    setHolderId(beneficiary.id);
  }, []);

  return (
    <Layout navbar={true} verticalCentered={true} goBackButton={true} onClick={() => navigate('/')}>
      {errorAlert && <Alert type="error" title="Atenção!" text="O token informado é inválido!" />}
      <Title appearance="primary" bolder={true}>
        Informe o código de autenticação
      </Title>
      <Spacing size="x-small" />
      <Text>
        Para sua segurança lhe enviamos via SMS e E-mail um código de autenticação. Verifique sua
        caixa de entrada ou spam.
      </Text>
      <Spacing size="xxx-large" />
      <ReactCodeInput
        type="number"
        fields={6}
        value={code}
        name={code}
        autoFocus={true}
        inputMode="latin"
        onChange={(event: any) => {
          handleCode(event);
        }}
        inputStyle={{
          fontFamily: 'monospace',
          borderRadius: '20px',
          border: '1px solid #E8ECEE',
          margin: '4px 14px',
          width: '80px',
          height: '100px',
          fontSize: '62px',
          boxSizing: 'border-box',
          color: 'black',
          backgroundColor: '#FFFFFF',
          borderColor: '#E8ECEE',
          userSelect: 'none',
          outline: 'none',
          textAlign: 'center'
        }}
      />
      <Spacing size="xxx-large" />
      <Description appearance="grey">
        Não recebeu o código?{' '}
        <a href="#" onClick={resendCodeSMS} className={styles.link}>
          Clique Aqui
        </a>
      </Description>
      <Spacing size="xxx-large" />
      <Button width="130px" text="Enviar" onClick={handleAuthenticate} />
    </Layout>
  );
}

export default DeclarationHealthAuth;
