import React, { useEffect, useState } from 'react';
import { Column, Description, Divider, Spacing } from 'components';
import { maskMoneyPlans } from 'utils/mask';
import styles from './styles.module.scss';

function PlanMobile({ plans }) {
  useEffect(() => {
    plans.map((plan) => {
      let totalValue = 0;
      plan.dependents.map((dependent) => {
        totalValue += dependent.totalValue;
      });
      plan.total = totalValue;
    });
  }, []);

  return (
    <div className={styles.container}>
      {plans.map((plan) => (
        <>
          <Spacing size="medium" />
          <h1 className={styles.title}>{plan.plan}</h1>
          <Spacing size="xx-small" />
          <div className={styles.row}>
            <Column width="100%" justifyContent="flex-start" position="flex-start">
              <Description appearance="grey">Faixa etária/R$</Description>
            </Column>
            <Column width="50%" justifyContent="flex-start" position="flex-start">
              <Description appearance="grey">Tit.</Description>
            </Column>
            <Column width="50%" justifyContent="flex-start" position="flex-start">
              <Description appearance="grey">Dep.</Description>
            </Column>
            <Column width="100%" justifyContent="flex-start" position="flex-start">
              <Description appearance="grey">Total</Description>
            </Column>
          </div>
          <Spacing size="xxx-small" />
          <Divider />
          {plan.dependents.map((dependent) => (
            <>
              <Spacing size="xx-small" />
              <div className={styles.row}>
                <Column width="100%" justifyContent="flex-start" position="flex-start">
                  <p className={styles.left}>{dependent.age}</p>
                  <p className={styles.left}>R$ {maskMoneyPlans(Number(dependent.plan_value))}</p>
                </Column>
                <Column width="50%" justifyContent="flex-start" position="flex-start">
                  <p>{dependent.owners}</p>
                </Column>
                <Column width="50%" justifyContent="flex-start" position="flex-start">
                  <p>{dependent.dependents}</p>
                </Column>
                <Column width="100%" justifyContent="flex-start" position="flex-start">
                  <p>
                    <b>R$ {maskMoneyPlans(Number(dependent.totalValue))}</b>
                  </p>
                </Column>
              </div>
              <Spacing size="xx-small" />
              <Divider />
            </>
          ))}
        </>
      ))}
    </div>
  );
}

export default PlanMobile;
