import React from 'react';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-onboarding-bro.svg';

function FormBeneficiariesConcluded() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Cadastro de beneficiários concluído!"
        description="Os beneficiários cadastrados receberão um e-mail para envio das documentações necessárias, que posteriormente serão analisádas pela Sulmed."
        image={feedback}
      />
    </Layout>
  );
}

export default FormBeneficiariesConcluded;
