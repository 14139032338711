import React from 'react';
import { BsXLg } from 'react-icons/bs';
import styles from './styles.module.scss';
import { Title, Description, Spacing, BottomNavigation } from '..';

function Modal({
  isOpen = false,
  width = '500px',
  height,
  image,
  title,
  description,
  children,
  onClickClose,
  footerButtonFirst,
  footerButtonFirstAppearance = 'primary',
  onClickFooterFirst,
  footerButtonFirstLoading = false,
  footerButtonSecond,
  footerButtonSecondAppearance = 'ghost',
  onClickFooterSecond,
  footerButtonSecondLoading = false
}: any) {
  return (
    isOpen && (
      <div className={styles.container} onClick={onClickClose}>
        <div
          className={styles.modal}
          style={{ width: width && width, height: height && height }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header} style={{ height: '35px' }}>
            <BsXLg className={styles.close} onClick={onClickClose} />
          </div>
          <div className={styles.header}>
            {image && <img src={image} alt={title} className={styles.image} />}
            {/* <Spacing size="large" /> */}
            <Title bolder={true} appearance="primary">
              {title}
            </Title>
            <Spacing size="xx-small" />
            <div className={styles.description}>
              <Description>{description}</Description>
            </div>
          </div>
          <Spacing size="large" />
          <div className={styles.body}>{children}</div>
          {footerButtonFirst || footerButtonSecond ? (
            <div className={styles.footer}>
              <Spacing size="large" />
              <BottomNavigation
                width="90%"
                labelLeft={footerButtonFirst}
                appearanceLeft={footerButtonFirstAppearance}
                actionLeft={onClickFooterFirst}
                loadingLeft={footerButtonFirstLoading}
                labelRight={footerButtonSecond}
                appearanceRight={footerButtonSecondAppearance}
                actionRight={onClickFooterSecond}
                loadingRight={footerButtonSecondLoading}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  );
}

export default Modal;
