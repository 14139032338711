import { getOrderData, sendDocumentContract, uploadDocumentContract } from 'api/services/ApiCalls';
import {
  Alert,
  Button,
  Column,
  Description,
  Divider,
  DocumentUpload,
  Layout,
  RadioButton,
  Row,
  Spacing,
  Text,
  Title
} from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ContractUpload() {
  const navigate = useNavigate();
  const [contract, setContract] = useState<any>('');
  const [checkY, setCheckY] = useState<boolean>(false);
  const [checkN, setCheckN] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<any>('');
  const [userId, setUserId] = useState<any>('');
  const [aproved, setAproved] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingFinalize, setLoadingFinalize] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<any>({
    title: '',
    message: ''
  });

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      console.log(jwtParsed);
      setOrderId(jwtParsed.order_id);
      setUserId(jwtParsed.user_id);

      if (jwtParsed.contract !== null) {
        navigate('/contrato/upload/sucesso');
      }

      getOrderData(jwtParsed.order_id)
        .then((response) => {
          if (
            response.data.order_data.order.status !== 8 &&
            response.data.order_data.order.status !== 9
          ) {
            navigate('/contrato/upload/sucesso');
          }
        })
        .catch((error) => console.error(error));
    }
  }

  async function handleSendContract() {
    setLoadingUpload(true);
    try {
      const formData = new FormData();
      formData.append('order_id', orderId);
      formData.append('contract_file', contract);
      formData.append('contract_type', 'manual');
      await uploadDocumentContract(formData).then(() => {
        setAlertData({
          title: 'Sucesso!',
          type: 'success',
          message: 'Contrato enviado com sucesso.'
        });
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 5500);
        setAproved(true);
        setLoadingUpload(false);
      });
    } catch (error) {
      console.error(error);
      setAlertData({
        title: 'Atenção!',
        type: 'warning',
        message: 'Ocorreu um erro ao fazer o upload do documento, tente novamente mais tarde.'
      });
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 5500);
      setLoadingUpload(false);
    }
  }

  async function handleSendDocument() {
    setLoadingFinalize(true);
    try {
      const formData = new FormData();
      formData.append('order_id', orderId);
      formData.append('user_id', userId);
      await sendDocumentContract(formData).then(() => {
        setLoadingFinalize(false);
        navigate('/contrato/upload/sucesso');
      });
    } catch (error) {
      console.error(error);
      setLoadingFinalize(false);
    }
  }

  useEffect(() => {
    getParams();
  }, []);

  return (
    <Layout navbar>
      {alert && <Alert type={alertData.type} title={alertData.title} text={alertData.message} />}
      <Spacing size="xx-large" />
      <Title appearance="primary" bolder>
        Upload de Contrato
      </Title>
      <Spacing size="x-small" />
      <Text maxWidth="100%">
        Faça o upload do Contrato de Adesão assinado e clique em Enviar para concluir essa etapa.
      </Text>
      <Spacing size="xxx-large" />
      <Spacing size="xxx-large" />
      <Row width="90%" position="left">
        <Description maxWidth="fit-content" textAlign="left" bolder={true}>
          Upload de Contrato de Adesão
        </Description>
      </Row>
      <Spacing size="medium" />
      <Row width="90%">
        <Column>
          <DocumentUpload
            file={contract}
            setFile={(event) => {
              setContract(event.target.files[0]);
            }}
            placeholder={aproved ? 'Contrato de Adesão aprovado com sucesso' : 'Contrato de Adesão'}
          />
          <Spacing />
          <Button
            text="Salvar Documento"
            width="fit-content"
            loading={loadingUpload}
            appearance={!loadingUpload ? 'primary' : 'disabled'}
            onClick={() => {
              if (!loadingUpload) {
                handleSendContract();
              }
            }}
          />
        </Column>
      </Row>
      <Spacing size="large" />
      <Divider />
      <Spacing size="large" />
      <Row width="90%">
        <Column>
          <Text appearance="grey2"> O Contrato está assinado?</Text>
          <Column>
            <Spacing size="small" />
            <RadioButton
              label="Sim"
              color="#78909c"
              checked={checkY}
              onChange={() => {
                if (checkN) {
                  setCheckN(false);
                  setCheckY(true);
                } else {
                  setCheckY(true);
                }
              }}
            />
            <Spacing size="xx-small" />
            <RadioButton
              label="Não"
              color="#78909c"
              checked={checkN}
              onChange={() => {
                if (checkY) {
                  setCheckN(true);
                  setCheckY(false);
                } else {
                  setCheckN(true);
                }
              }}
            />
          </Column>
        </Column>
      </Row>
      <Spacing size="xxx-large" />
      <Spacing size="xxx-large" />
      <Spacing size="xxx-large" />
      <Spacing size="xxx-large" />
      <Row position="end" width="98%">
        <Button
          text="Finalizar"
          width="143px"
          loading={loadingFinalize}
          appearance={!loadingFinalize && aproved ? 'primary' : 'disabled'}
          onClick={() => {
            if (!loadingFinalize && aproved) {
              if (checkY) {
                handleSendDocument();
              } else {
                setAlertData({
                  title: 'Atenção!',
                  type: 'warning',
                  message: 'É necessário que o Contrato esteja Assinado para poder Enviar.'
                });
                setAlert(true);
                setTimeout(() => {
                  setAlert(false);
                }, 5500);
              }
            }
          }}
        />
      </Row>
      <Spacing size="xxx-large" />
    </Layout>
  );
}
