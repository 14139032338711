import React, { useEffect, useState } from 'react';
import { maskCnpj, maskMoney, maskMoneyPlans, maskPhone } from 'utils/mask';
import dayjs from 'dayjs';
import { getOrdersPlan, getPlanById, getSpecialDiscount } from 'api/services/ApiCalls';
import styles from './styles.module.scss';
import {
  Layout,
  Row,
  Spacing,
  Title,
  Text,
  Card,
  Column,
  Description,
  Undertitle
} from '../../components';
import check from '../../assets/icons/check-icon.png';
import { CoupomDivider } from './components';

function SpecialDiscountResume() {
  const [jwtOrder, setJwtOrder] = useState(null);
  const [jwtSpecialDiscount, setJwtSpecialDiscount] = useState(null);
  const [jwtPlans, setJwtPlans] = useState(null);
  const [orderSimulation, setOrderSimulation] = useState(null);
  const [discountValue, setDiscountValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [table, setTable] = useState({
    plan: '',
    coparticipation: [],
    attributes: []
  });
  const [seTotalBeneficiaries, setTotalBeneficiaries] = useState(0);

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  function calcTotalMembers(orderSimulation: any) {
    let count = 0;
    orderSimulation.map((elem) => {
      count = count + Number(elem.owners) + Number(elem.dependents);
    });
    setTotalBeneficiaries(count);
  }

  function calcDiscountValue(discount_percentage, value) {
    const calc = Number(value) * (Number(discount_percentage) / 100);
    setDiscountValue(calc);
  }

  function getPlansOrder(orderID: any) {
    const formData = new FormData();
    formData.append('order_id', orderID);
    getOrdersPlan(formData)
      .then((response) => {
        setJwtPlans(response.data.order_plans);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getParams() {
    const params = window.location.search;
    console.log(params);
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');

      const jwtParsed = parseJwt(tk);
      getSpecialDiscount({
        order_special_id: jwtParsed.order_special_id
      }).then((response) => {
        getPlansOrder(response.data.order.id);
        setJwtOrder(response.data.order);
        setOrderSimulation(response.data.order_simulation);
        setJwtSpecialDiscount(response.data.order_special_discount);

        getPlanById(response.data.order_simulation[0].plan_id)
          .then((response) => {
            table.plan = `${response.data.plan[0].name} ${response.data.plan[0].subtitle}`;
            table.coparticipation = [...response.data.plan[0].co_participation];
            table.attributes = [...response.data.plan[0].plan_attributes];
            setTable({ ...table });
          })
          .catch((error) => console.error(error));

        let total = 0;
        response.data.order_simulation.map((item) => {
          total += Number(item.totalValue);
        });
        setTotalValue(total);

        calcTotalMembers(response.data.order_simulation);
        calcDiscountValue(response.data.order_special_discount.value, response.data.order.total);
      });
    }
  }

  dayjs().format();
  dayjs.locale('pt-br');
  const currentDate = dayjs();
  const validDate = currentDate.add(20, 'day');

  useEffect(() => {
    getParams();
  }, []);

  return (
    <Layout navbar={true}>
      <Spacing size="medium" />
      <Title appearance="primary" bolder={true}>
        Resumo da Proposta
      </Title>
      <Spacing size="xx-small" />
      <Text>Confirme se todos os dados estão corretos antes de prosseguir</Text>
      <Spacing size="x-large" />
      <Card appearance="ghost" width="94%">
        <Row>
          <Column>
            <Title bolder={true}>Proposta Comercial</Title>
            <Spacing size="xxx-small" />
          </Column>
          <Column position="end">
            <Description>Data da proposta: {dayjs(currentDate).format('DD/MM/YYYY')}</Description>
            <Spacing size="xxx-small" />
            <Description>Proposta válida até: {dayjs(validDate).format('DD/MM/YYYY')}</Description>
          </Column>
        </Row>
        <Spacing size="medium" />
        <Card appearance="ghost">
          <Row position="start">
            <Undertitle bolder={true}>Informações da empresa</Undertitle>
          </Row>
          <Spacing size="medium" />
          <Row>
            <Column width="50%">
              <Description>CNPJ</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true}>{jwtOrder === null ? '' : maskCnpj(jwtOrder.cnpj)}</Text>
            </Column>
            <Column>
              <Description>Nome da Empresa</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true}>{jwtOrder === null ? '' : jwtOrder.company_name}</Text>
            </Column>
          </Row>
          <Spacing size="large" />
          <Row>
            {jwtOrder && jwtOrder.city !== null && (
              <Column>
                <Description>Município</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true}>{jwtOrder.city === null ? '' : jwtOrder.city}</Text>
              </Column>
            )}
            {jwtOrder && jwtOrder.district !== null && (
              <Column width="99%">
                <Description>Bairro</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true}>{jwtOrder.district === null ? '' : jwtOrder.district}</Text>
              </Column>
            )}
            {jwtOrder && jwtOrder.zip_code !== null && (
              <Column>
                <Description>CEP</Description>
                <Spacing size="xxx-small" />
                <Text bolder={true}>{jwtOrder.zip_code === null ? '' : jwtOrder.zip_code}</Text>
              </Column>
            )}
          </Row>
          <Spacing size="large" />
          <Row>
            <Column width="102%">
              <Description>Representante</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true}>{jwtOrder === null ? '' : jwtOrder.responsible_name}</Text>
            </Column>
            <Column>
              <Description>Telefone</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true}>{jwtOrder === null ? '' : maskPhone(jwtOrder.phone)}</Text>
            </Column>
            <Column width="102%">
              <Description>Email</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true}>{jwtOrder === null ? '' : jwtOrder.email}</Text>
            </Column>
          </Row>
        </Card>

        <Spacing size="medium" />

        <Card appearance="ghost">
          <Row position="start">
            <Undertitle bolder={true}>Dados da Simulação</Undertitle>
          </Row>
          <Spacing size="medium" />
          <Row>
            <Column width="120%">
              <Description appearance="grey" bolder={true}>
                Plano
              </Description>
            </Column>
            <Column width="70%" position="centered">
              <Description appearance="grey" bolder={true}>
                Faixa etária
              </Description>
            </Column>
            <Column width="60%" position="centered">
              <Description appearance="grey" bolder={true}>
                Qnt. Titular
              </Description>
            </Column>
            <Column position="centered">
              <Description appearance="grey" bolder={true}>
                Qnt. Dependente
              </Description>
            </Column>
            <Column position="centered">
              <Description appearance="grey" bolder={true}>
                Valor do plano
              </Description>
            </Column>
            <Column position="end">
              <Description appearance="grey" bolder={true}>
                Valor total
              </Description>
            </Column>
          </Row>
          <Spacing size="small" />
          {orderSimulation !== null
            ? orderSimulation.map((formPlan, index) => (
                <>
                  <Row key={index}>
                    <Column width="120%">
                      <Text textAlign="start">{formPlan.plan}</Text>
                    </Column>
                    <Column width="70%" position="centered">
                      <Text>{formPlan.age}</Text>
                    </Column>
                    <Column width="60%" position="centered">
                      <Text>{formPlan.owners}</Text>
                    </Column>
                    <Column position="centered">
                      <Text>{formPlan.dependents}</Text>
                    </Column>
                    <Column position="centered">
                      <Text>R$ {formPlan.value !== '0' ? maskMoney(formPlan.value) : '0,00'}</Text>
                    </Column>
                    <Column position="end">
                      <Text>
                        R$ {formPlan.totalValue !== '0' ? maskMoney(formPlan.totalValue) : '0,00'}
                      </Text>
                    </Column>
                  </Row>
                  <Spacing size="medium" />
                </>
              ))
            : null}
          <Spacing size="x-large" />
          {jwtSpecialDiscount !== null ? (
            <>
              <Row>
                <CoupomDivider
                  name=""
                  description={jwtSpecialDiscount.description}
                  discountValue={Number(jwtSpecialDiscount.value)}
                />
              </Row>
              <Spacing size="large" />
            </>
          ) : null}

          <Card appearance="card">
            <Row position="end">
              <Column width="60px" position="end">
                <Description appearance="grey">Subtotal</Description>
              </Column>
              <Column width="90px" position="end">
                <Description appearance="grey">
                  R$ {jwtOrder !== null ? maskMoneyPlans(totalValue) : '0,00'}
                </Description>
              </Column>
            </Row>
            <Spacing size="small" />
            {jwtSpecialDiscount && jwtOrder !== null ? (
              <Row position="end">
                <Column width="60px" position="end">
                  <Description appearance="grey">Desconto</Description>
                </Column>
                <Column width="90px" position="end">
                  <Description appearance="grey">
                    - R$ {maskMoneyPlans(Number(discountValue))}
                  </Description>
                </Column>
              </Row>
            ) : null}
            <Spacing size="small" />
            <Row position="end">
              <Column width="70px" position="end">
                <Description appearance="grey">Valor Total</Description>
              </Column>
              <Column width="90px" position="end">
                <Description bolder={true}>
                  R${' '}
                  {jwtOrder !== null ? maskMoneyPlans(Number(totalValue) - discountValue) : '0,00'}
                </Description>
              </Column>
            </Row>

            <Spacing size="large" />
            <Row position="end">
              <Description>
                Esta proposta possui um número mínimo de <b>{seTotalBeneficiaries} vidas</b> para
                serem cobradas.
              </Description>
            </Row>
          </Card>
        </Card>

        <Spacing size="medium" />

        <Card appearance="ghost">
          <Row position="start">
            <Undertitle appearance="grey3" bolder={true}>
              Plano(s) Selecionado(s)
            </Undertitle>
          </Row>
          <Spacing size="x-large" />
          <div className={styles.container}>
            {table && (
              <>
                <Card width="100%" appearance="ghost">
                  <div className={styles.plansAtributes}>
                    <Undertitle appearance="primary" padding="10px 0px" bolder={true}>
                      {table.plan}
                    </Undertitle>
                    <Spacing size="medium" />
                    <Row position="start" align="start">
                      <Column width="45%" justifyContent="start">
                        <Description bolder={true}>Coberturas do Plano</Description>
                        <Spacing size="x-small" />
                        {table.attributes.map(
                          (coparticipation, key) =>
                            coparticipation.status === 1 && (
                              <div className={styles.atributes} key={key}>
                                <Spacing size="x-small" />
                                <Text appearance="grey" textAlign="left">
                                  <img
                                    src={coparticipation.status === 1 && check}
                                    style={{
                                      width: '15px',
                                      marginRight: '8px',
                                      color: 'green',
                                      marginTop: '3px',
                                      alignSelf: 'flex-start'
                                    }}
                                  />

                                  {coparticipation.attribute_name}
                                </Text>
                                <Spacing size="xx-small" />
                              </div>
                            )
                        )}
                      </Column>

                      <Spacing size="x-large" />

                      <Column width="45%" justifyContent="start">
                        <Description bolder={true}>Coparticipações</Description>
                        <Spacing size="x-small" />
                        {table.coparticipation &&
                          table.coparticipation.map((coparticipation, key) => {
                            if (
                              coparticipation.status === 1 &&
                              coparticipation.type === 'coparticipacao'
                            ) {
                              return (
                                <div className={styles.atributes} key={key}>
                                  <Row width="100%" position="start" align="start">
                                    <Text
                                      appearance="grey"
                                      textAlign="start"
                                      position="start"
                                      padding="0px"
                                    >
                                      <img
                                        src={check}
                                        style={{
                                          width: '15px',
                                          marginRight: '8px',
                                          color: 'green',
                                          marginTop: '3px',
                                          alignSelf: 'flex-start'
                                        }}
                                      />
                                      {coparticipation.co_participation_name}
                                    </Text>
                                  </Row>
                                  <Spacing size="xx-small" />
                                </div>
                              );
                            }
                          })}
                      </Column>
                    </Row>
                  </div>
                </Card>
                <Spacing size="medium" />
              </>
            )}
          </div>
        </Card>

        <Spacing size="medium" />
      </Card>

      <Spacing size="xxx-large" />
    </Layout>
  );
}

export default SpecialDiscountResume;
