import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import { approveContract, sendContractAuthToken, verifyTokenContract } from 'api/services/ApiCalls';
import styles from './styles.module.scss';
import { Layout, Title, Text, Spacing, Description, Button, Alert } from '../../components';

function ContractAuth() {
  const navigate = useNavigate();
  const [code, setCode] = useState<any>('');
  const [orderId, setOrderId] = useState(JSON.parse(sessionStorage.getItem('@orderID')));
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  async function resendCodeSMS() {
    const formData = new FormData();
    formData.append('order_id', orderId);

    sendContractAuthToken(formData)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }

  function handleAuthenticate() {
    setLoading(true);
    const formData = new FormData();
    formData.append('token', code);
    formData.append('order_id', orderId);

    verifyTokenContract(formData)
      .then(() => {
        const formData = new FormData();
        formData.append('order_id', orderId);

        approveContract(formData)
          .then(() => {
            setLoading(false);
            navigate('/contrato/sucesso');
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 5500);
      });
  }

  return (
    <Layout navbar={true} verticalCentered={true} onClick={() => navigate('/')}>
      {errorAlert && <Alert type="error" title="Atenção!" text="O token informado é inválido!" />}
      <Title appearance="primary" bolder={true}>
        Informe o código de autenticação
      </Title>
      <Spacing size="x-small" />
      <Text>
        Para sua segurança lhe enviamos via SMS e E-mail um código de autenticação. Verifique sua
        caixa de entrada ou spam.
      </Text>
      <Spacing size="xxx-large" />
      <ReactCodeInput
        type="number"
        fields={6}
        value={code}
        name={code}
        autoFocus={true}
        inputMode="latin"
        onChange={(event: any) => {
          setCode(event);
        }}
        inputStyle={{
          fontFamily: 'monospace',
          borderRadius: '20px',
          border: '1px solid #E8ECEE',
          margin: '4px 14px',
          width: '80px',
          height: '100px',
          fontSize: '62px',
          boxSizing: 'border-box',
          color: 'black',
          backgroundColor: '#FFFFFF',
          borderColor: '#E8ECEE',
          userSelect: 'none',
          outline: 'none',
          textAlign: 'center'
        }}
      />
      <Spacing size="xxx-large" />
      <Description appearance="grey">
        Não recebeu o código?{' '}
        <a href="#" onClick={resendCodeSMS} className={styles.link}>
          Clique Aqui
        </a>
      </Description>
      <Spacing size="xxx-large" />
      <Button
        appearance={loading ? 'disabled' : 'primary'}
        width="130px"
        text="Enviar"
        onClick={() => !loading && handleAuthenticate()}
        loading={loading}
      />
    </Layout>
  );
}

export default ContractAuth;
