import styles from './styles.module.scss';

export function CoupomDivider({ name, description, discountValue }) {
  return (
    <div className={styles.container}>
      <h5>
        Cupom {name} adicionado, Desconto de {discountValue}%
      </h5>
      <p>
        <i>{description}</i>
      </p>
    </div>
  );
}
