import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-waiting-payment.png';

function WaitingPayment() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/pagamento/concluido');
    }, 5000);
  }, []);

  return (
    <Layout
      navbar={true}
      verticalCentered={true}
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Beneficiários', status: 'concluded' },
        { title: 'Análise', status: 'concluded' },
        { title: 'Contrato', status: 'concluded' },
        { title: 'Pagamento', status: 'concluded' },
        { title: 'Conclusão', status: 'inProgress' }
      ]}
    >
      <Feedback title="Aguarde enquanto confirmamos seu pagamento" image={feedback} />
    </Layout>
  );
}

export default WaitingPayment;
