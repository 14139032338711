import { getAllUploadedEnterpriseDocuments, getOrderData } from 'api/services/ApiCalls';
import {
  Card,
  Column,
  Description,
  Layout,
  Row,
  Spacing,
  Spinner,
  Text,
  Title,
  Undertitle
} from 'components';
import { useEffect, useState } from 'react';
import ImageView from './components/ImageView';

export default function EnterpriseDocumentCheckConsultant() {
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [documents, setDocuments] = useState([]);
  const [data, setData] = useState({
    city: '',
    company_name: '',
    street: '',
    phone: '',
    responsible_name: '',
    cnpj: '',
    district: '',
    zip_code: '',
    email: '',
    company_social_contract: '',
    company_gfip: '',
    company_cnpj_card: '',
    legal_responsible_document_front: '',
    legal_responsible_document_back: ''
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  async function getDocuments(id) {
    try {
      const response = await getAllUploadedEnterpriseDocuments(id);
      setDocuments(response.data.documentation);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function getAllData(id) {
    try {
      const response = await getOrderData(id);
      setData(response.data.order_data.order);
      getDocuments(id);
    } catch (error) {
      console.error(error);
    }
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    console.log(params);
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      setOrderId(jwtParsed.order_id);
      getAllData(jwtParsed.order_id);
    }
  }

  useEffect(() => {
    setLoading(true);
    getParams();
  }, []);

  return (
    <Layout navbar={true} goBackButton={false}>
      <Spacing size="x-large" />
      {loading ? (
        <Layout navbar={false} verticalCentered={true}>
          <Row width="100%" align="center">
            <Column width="100%" position="centered">
              <Spinner show={true} />
              <Spacing size="xx-small" />
              <Description appearance="primary" bolder={true}>
                Carregando os dados
              </Description>
            </Column>
          </Row>
          <Spacing size="x-large" />
        </Layout>
      ) : (
        <>
          <Title appearance="primary" bolder={true}>
            Análise de informações da empresa
          </Title>
          <Spacing size="xx-small" />
          <Text maxWidth="675px">
            Analise a documentação enviada pela empresa. Se estiver tudo certo, clique em Aprovar.
            Caso haja alguma inconsistência clique em Reprovar e informe o motivo para que os
            documentos possam ser reenviados.
          </Text>
          <Spacing size="x-large" />
          <Row position="start" width="98%">
            <Card appearance="ghost" width="100%">
              <Row position="start">
                <Undertitle appearance="grey3" bolder={true} textAlign="start">
                  Informações da empresa
                </Undertitle>
              </Row>
              <Spacing size="medium" />
              <Row>
                <Column width={width <= 650 ? '100%' : '33%'}>
                  <Description appearance="grey2" textAlign="start">
                    CNPJ
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.cnpj}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column width="67%">
                  <Description appearance="grey2" textAlign="start">
                    Nome da Empresa
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.company_name}
                  </Text>
                </Column>
              </Row>
              {width <= 650 ? <Spacing size="small" /> : <Spacing size="large" />}
              <Row>
                <Column>
                  <Description appearance="grey2" textAlign="start">
                    Município
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.city}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column width="99%">
                  <Description appearance="grey2" textAlign="start">
                    Bairro
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.district}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column>
                  <Description appearance="grey2" textAlign="start">
                    CEP
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.zip_code}
                  </Text>
                </Column>
              </Row>
              {width <= 650 ? <Spacing size="small" /> : <Spacing size="large" />}
              <Row>
                <Column width={width <= 650 ? '100%' : '102%'}>
                  <Description appearance="grey2" textAlign="start">
                    Representante
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.responsible_name}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column>
                  <Description appearance="grey2" textAlign="start">
                    Telefone
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.phone}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column width={width <= 650 ? '100%' : '102%'}>
                  <Description appearance="grey2" textAlign="start">
                    E-mail
                  </Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.email}
                  </Text>
                </Column>
              </Row>
            </Card>
          </Row>
          <Spacing size="x-large" />
          {documents.map((document) => (
            <>
              <ImageView
                orderID={orderId}
                docID={document.document_data.company_document_id}
                img={document.document_data.document_path}
                name={document.document_description}
                url={document.document_data.document_path}
                onClick={() => getAllData(orderId)}
              />
              <Spacing size="x-large" />
            </>
          ))}
          <Spacing size="x-large" />
        </>
      )}
    </Layout>
  );
}
