import { useEffect } from 'react';

import { Button, Layout, PDFViewer, Row, Spacing, Text, Title } from '../../components';

function EnterpriseAgreement() {
  useEffect(() => {}, []);

  return (
    <Layout
      navbar
      goBackButton
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Contrato', status: 'inProgress' },
        { title: 'Cadastro Beneficiários', status: 'pending' },
        { title: 'Análise Beneficiários', status: 'pending' },
        { title: 'Implantação', status: 'pending' }
      ]}
    >
      <Spacing size="medium" />
      <Title appearance="primary" bolder={true}>
        Contrato #
      </Title>
      <Spacing size="x-small" />
      <Text>
        Antes de finalizar a contratação do plano, leia atentamente as informações do contrato
        abaixo e aceite os termos e condições.
      </Text>
      <Spacing size="xxx-large" />
      <PDFViewer url="" protocol="9999" />
      <Spacing size="xxx-large" />
      <Row position="centered">
        <Button text="Assinatura Digital" width="187px" />
      </Row>
    </Layout>
  );
}

export default EnterpriseAgreement;
