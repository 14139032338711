/* eslint-disable array-callback-return */
import { getActivePackagesById } from 'api/services/ApiCalls';
import {
  BottomNavigation,
  Description,
  Divider,
  Input,
  Layout,
  Modal,
  Row,
  Spacing,
  Text,
  Title
} from 'components';
import { DataContext } from 'context/DataContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { maskMoney } from 'utils/mask';
import productImage from '../../assets/Drag and drop.png';
import ticket from '../../assets/icons/ticket-icon.png';
import Product from './components/Product';
import styles from './styles.module.scss';

function Summary() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const { context, handleData, deleteProduct }: any = useContext(DataContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [coupon, setCoupon] = useState('');

  function updateTotalValue() {
    let amount: any = 0;
    context.products.map((elem) => {
      amount += parseFloat(elem.value) * elem.amount;
    });
    setTotalValue(amount);
  }

  function checkProductsValue() {
    context.products.map(async (product) => {
      await getActivePackagesById(product.id)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
          // eslint-disable-next-line no-param-reassign
          product.totalValue = parseFloat(resp.data.packages[0].value);
        })
        .catch((error) => console.error(error.response.data.error))
        .finally(() => {
          handleData(context);
        });
    });
  }

  function addProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount += 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });

    updateTotalValue();
  }

  function subsProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount -= 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });

    updateTotalValue();
  }

  useEffect(() => {
    checkProductsValue();

    let amount: any = 0;
    const storage = JSON.parse(sessionStorage.getItem('@data'));
    storage.products.map((elem) => {
      amount += parseFloat(elem.value) * elem.amount;
    });
    setTotalValue(amount);
  }, []);

  return (
    <Layout navbar={true}>
      <Modal
        isOpen={modalIsOpen}
        title="Insira um Cupom"
        description="Cada produto pode ter apenas um cupom aplicado."
        footerButtonFirst="Cancelar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="74px"
        onClickFooterFirst={() => setModalIsOpen(false)}
        footerButtonSecond="Aplicar"
        footerButtonSecondAppearance="primary"
        footerButtonSecondWidth="74px"
        onClickFooterSecond={() => setModalIsOpen(false)}
        onClickClose={() => setModalIsOpen(false)}
      >
        <Spacing size="large" />
        <Input
          name="coupon"
          type="text"
          width="100%"
          label="Inserir Código do cupom"
          value={coupon}
          onChange={(event) => {
            setCoupon(event.target.value);
          }}
        />
        <Spacing size="large" />
      </Modal>
      <Spacing size="xx-large" />
      <div className={styles.container}>
        <Title bolder={true} appearance="primary">
          Resumo de sua compra
        </Title>
        <Spacing size="x-small" />
        <Text>Verifique os itens selecionados, antes de finalizar a compra</Text>
        <Spacing size="x-large" />
        {context.products.length > 0 ? (
          context.products.map((product) => (
            <>
              <Spacing size="large" />
              <Product
                image={productImage}
                title={product.title}
                description={product.description}
                productValue={product.value}
                amount={product.amount}
                totalValue={product.totalValue}
                onClickDelete={() => deleteProduct(product.id)}
                addProduct={(event) => addProduct(product.id, event)}
                subsProduct={(event) => subsProduct(product.id, event)}
              />
            </>
          ))
        ) : (
          <>
            <Spacing size="large" />
            <Row>
              <Description appearance="grey">Nenhum produto foi adicionado ao carrinho</Description>
            </Row>
          </>
        )}
        <Spacing size="xxx-large" />
        <Row position="start">
          <p className={styles.ticket} onClick={() => setModalIsOpen(true)}>
            <img src={ticket} /> Inserir código do cupom
          </p>
        </Row>
        <Spacing size="x-small" />
        <Divider />
        <Spacing size="small" />
        <div className={styles.value}>
          <h1>Total</h1>
          <h1>R$ {maskMoney(totalValue)}</h1>
        </div>
      </div>

      <Spacing size="xxx-large" />

      <BottomNavigation
        width="148px"
        labelLeft="Voltar"
        appearanceLeft="ghost"
        actionLeft={() => navigate('/autenticacao')}
        labelRight="Finalizar Compra"
        appearanceRight={context.products.length > 0 ? 'primary' : 'disabled'}
        actionRight={() => (context.products.length > 0 ? navigate('/pagamento') : '')}
      />
    </Layout>
  );
}

export default Summary;
