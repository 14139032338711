import { Description, Input, Row, Spacing, Text } from 'components';
import styles from './styles.module.scss';

function Table({ width, ages, onChangeHolder, onChangeDependent }) {
  return (
    <div className={styles.container}>
      <Row position="centered" align="center">
        <Text>Informe a quantidade de vidas por idade</Text>
      </Row>
      <Spacing size="medium" />
      <div className={styles.column}>
        <div>
          <div className={styles.rowPlans} style={{ height: '20px' }}>
            <div className={`${styles.column} ${styles.large}`} style={{ alignItems: 'start' }}>
              <Description>Faixa Etária</Description>
            </div>
            <Spacing size="xx-small" />
            <div className={`${styles.column} ${styles.small}`} style={{ alignItems: 'start' }}>
              <Description>Titular</Description>
            </div>
            <Spacing size="xx-small" />
            <div className={`${styles.column} ${styles.small}`} style={{ alignItems: 'start' }}>
              <Description>Dependente</Description>
            </div>
          </div>
          <Spacing size="xx-small" />
          {ages.map(
            (age, key) =>
              key <= 4 && (
                <div className={styles.rowPlans} key={age.id}>
                  <div className={`${styles.column} ${styles.large}`}>
                    <div className={styles.age}>{age.title}</div>
                  </div>
                  <Spacing size="xx-small" />
                  <div className={`${styles.column} ${styles.small}`}>
                    <Input
                      name="holder"
                      type="text"
                      width="100%"
                      value={age.holder}
                      position="center"
                      onChange={(event) => {
                        if (event.target.value.charAt(0) === '0') {
                          event.target.value = event.target.value.substring(1);
                        }
                        if (event.target.value === '') {
                          event.target.value = '0';
                        }
                        if (Number(event.target.value) >= 0 && Number(event.target.value) <= 100) {
                          onChangeHolder(age.id, parseInt(event.target.value, 10));
                        }
                      }}
                    />
                  </div>
                  <Spacing size="xx-small" />
                  <div className={`${styles.column} ${styles.small}`}>
                    <Input
                      name="dependents"
                      type="text"
                      width="100%"
                      value={age.dependent}
                      position="center"
                      onChange={(event) => {
                        if (event.target.value.charAt(0) === '0') {
                          event.target.value = event.target.value.substring(1);
                        }
                        if (event.target.value === '') {
                          event.target.value = '0';
                        }
                        if (Number(event.target.value) >= 0 && Number(event.target.value) <= 100) {
                          onChangeDependent(age.id, parseInt(event.target.value, 10));
                        }
                      }}
                    />
                  </div>
                </div>
              )
          )}
        </div>

        <div>
          <Spacing size="xx-small" />
          {ages.map(
            (age, key) =>
              key > 4 && (
                <div className={styles.rowPlans} key={age.id}>
                  <div className={`${styles.column} ${styles.large}`}>
                    <div className={styles.age}>{age.title}</div>
                  </div>
                  <Spacing size="xx-small" />
                  <div className={`${styles.column} ${styles.small}`}>
                    <Input
                      name="holder"
                      type="text"
                      width="100%"
                      value={age.holder}
                      position="center"
                      onChange={(event) => {
                        if (event.target.value.charAt(0) === '0') {
                          event.target.value = event.target.value.substring(1);
                        }
                        if (event.target.value === '') {
                          event.target.value = '0';
                        }
                        if (Number(event.target.value) >= 0 && Number(event.target.value) <= 100) {
                          onChangeHolder(age.id, parseInt(event.target.value, 10));
                        }
                      }}
                    />
                  </div>
                  <Spacing size="xx-small" />
                  <div className={`${styles.column} ${styles.small}`}>
                    <Input
                      name="dependent"
                      type="text"
                      width="100%"
                      value={age.dependent}
                      position="center"
                      onChange={(event) => {
                        if (event.target.value.charAt(0) === '0') {
                          event.target.value = event.target.value.substring(1);
                        }
                        if (event.target.value === '') {
                          event.target.value = '0';
                        }
                        if (Number(event.target.value) >= 0 && Number(event.target.value) <= 100) {
                          onChangeDependent(age.id, parseInt(event.target.value, 10));
                        }
                      }}
                    />
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      {/* <Spacing size="xxx-large" /> */}
    </div>
  );
}

export default Table;
