import React, { useState } from 'react';
import {
  Button,
  Card,
  Column,
  Input,
  Modal,
  Row,
  Spacing,
  Text,
  Title,
  Undertitle
} from 'components';
import { useNavigate } from 'react-router-dom';
import { maskCnpj, maskPhone, maskReset } from 'utils/mask';
import { getCnpj } from 'api/services/ApiCalls';
import * as Scroll from 'react-scroll';
import Alert, { AlertState } from 'components/Alert';
import styles from './styles.module.scss';

import check from '../../../../assets/icons/check-icon.png';
import chevron from '../../../../assets/icons/chevron-left-icon.png';

const ScrollLink = Scroll.Link;

function Plan({ plan, item, key, width, onClickPlan }) {
  const navigate = useNavigate();
  const [modalAttributesIsOpen, setModalAttributesIsOpen] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertState | null>(null);
  const [form, setForm] = useState({
    cnpj: '',
    enterprise: '',
    email: '',
    phone: ''
  });
  const [checkFields, setCheckFields] = useState({
    cnpj: false,
    email: false,
    phone: false
  });
  const [modalPlanSelected, setModalPlanSelected] = useState<any>({
    coparticipation: []
  });

  function cleanModalFields() {
    setForm({
      cnpj: '',
      enterprise: '',
      email: '',
      phone: ''
    });
    setCheckFields({
      cnpj: false,
      email: false,
      phone: false
    });
  }

  function getId(id) {
    sessionStorage.setItem('@plan_id', id);
  }

  function resetAlert() {
    setAlert(null);
  }

  async function getCnpjData(cnpj: string) {
    try {
      const response = await getCnpj(cnpj);
      setForm({ ...form, enterprise: response.data.cnpj_data.name, cnpj });
      const user = JSON.parse(sessionStorage.getItem('@userData'));
      const userData = {
        city: response.data.cnpj_data.city,
        complement: response.data.cnpj_data.complement,
        district: response.data.cnpj_data.district,
        number: response.data.cnpj_data.number,
        state: response.data.cnpj_data.state,
        street: response.data.cnpj_data.street,
        zip_code: response.data.cnpj_data.zip_code
      };
      sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
    } catch (error) {
      console.error(error);

      setAlert({
        ...{
          show: true,
          type: 'error',
          title: 'Atenção!',
          text: 'Ocorreu um problema ao salvar os dados, revise os campos preenchidos'
        }
      });
      setTimeout(() => {
        resetAlert();
      }, 5500);
    }
  }

  function checkRequestedFields() {
    if (form.cnpj === '' || form.cnpj.length > 14) {
      setCheckFields({ ...checkFields, cnpj: true });
    } else {
      setCheckFields({ ...checkFields, cnpj: false });
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
      setCheckFields({ ...checkFields, email: true });
    }

    if (form.email === '') {
      setCheckFields({ ...checkFields, email: true });
    }

    if (form.email !== '' && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
      setCheckFields({ ...checkFields, email: false });
    }

    if (form.phone === '' || form.phone.length <= 13) {
      setCheckFields({ ...checkFields, phone: true });
    } else {
      setCheckFields({ ...checkFields, phone: false });
    }

    if (
      form.cnpj !== '' &&
      form.email !== '' &&
      form.phone !== '' &&
      form.phone.length > 13 &&
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function saveUserData() {
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    const userData = {
      cnpj: form.cnpj,
      telephone: form.phone,
      email: form.email,
      enterprise: form.enterprise
    };
    sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
  }

  async function handleNextPage() {
    const checkRequestFields = checkRequestedFields();
    if (checkRequestFields) {
      const response = await getCnpj(form.cnpj);
      if (response.data.success !== false) {
        saveUserData();
        const user = JSON.parse(sessionStorage.getItem('@userData'));
        const userData = {
          city: response.data.cnpj_data.city,
          complement: response.data.cnpj_data.complement,
          district: response.data.cnpj_data.district,
          number: response.data.cnpj_data.number,
          state: response.data.cnpj_data.state,
          street: response.data.cnpj_data.street,
          zip_code: response.data.cnpj_data.zip_code,
          enterprise: response.data.cnpj_data.name
        };
        sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
        navigate('/proposta');
      } else {
        setCheckFields({ ...checkFields, cnpj: true });
      }
    }
  }

  return (
    <>
      {alert?.show && <Alert type={alert.type} title={alert.title} text={alert.text} />}

      <Modal
        isOpen={modalAttributesIsOpen}
        title=""
        height="90%"
        width="70%"
        description=""
        onClickClose={() => {
          setModalAttributesIsOpen(false);
        }}
      >
        <Row position="start">
          <Title appearance="primary" bolder={true} textAlign="start">
            {modalPlanSelected.value}
          </Title>
        </Row>
        <Spacing size="x-large" />
        <Card appearance="ghost" padding="24px 15px">
          <Row align="start">
            <Column width={width >= 1000 ? '50%' : '100%'}>
              <Row position="start">
                <Column>
                  <Undertitle appearance="primary" bolder={true}>
                    Coberturas do Plano
                  </Undertitle>
                </Column>
              </Row>
              <Spacing size="x-large" />
              {modalPlanSelected &&
                modalPlanSelected.coparticipation.map((coparticipation) => {
                  if (coparticipation.status === 1 && coparticipation.type === 'cobertura') {
                    return (
                      <>
                        <Row width="100%" position="start" align="start">
                          <img
                            src={check}
                            style={{
                              width: '15px',
                              marginRight: '8px',
                              color: 'green',
                              marginTop: '2px'
                            }}
                          />
                          <Text appearance="grey" textAlign="start" position="start" padding="0px">
                            {coparticipation.co_participation_name}
                          </Text>
                        </Row>
                        <Spacing size="xx-small" />
                      </>
                    );
                  }
                })}
            </Column>
            <Spacing size="xxx-large" />
            <Column width={width >= 1000 ? '50%' : '100%'}>
              <Row position="start">
                <Column>
                  <Undertitle appearance="primary" bolder={true}>
                    Coparticipações
                  </Undertitle>
                </Column>
              </Row>
              <Spacing size="x-large" />
              {modalPlanSelected &&
                modalPlanSelected.coparticipation.map((coparticipation) => {
                  if (coparticipation.status === 1 && coparticipation.type === 'coparticipacao') {
                    return (
                      <>
                        <Row width="100%" position="start" align="start">
                          <img
                            src={check}
                            style={{
                              width: '15px',
                              marginRight: '8px',
                              color: 'green',
                              marginTop: '2px'
                            }}
                          />
                          <Text appearance="grey" textAlign="start" position="start" padding="0px">
                            {coparticipation.co_participation_name}
                          </Text>
                        </Row>
                        <Spacing size="xx-small" />
                      </>
                    );
                  }
                })}
            </Column>
          </Row>
        </Card>
        <Spacing size="xxx-large" />
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        title="Olá, bem-vindo(a)!"
        height="380px"
        width="600px"
        description="Preencha os campos abaixo, para que possamos dar continuidade ao processo de simulação de Planos."
        onClickClose={() => {
          setModalIsOpen(false);
          cleanModalFields();
        }}
      >
        <Spacing size="xxx-large" />
        <div className={styles.modal}>
          <Column width="100%" paddingRight="0px">
            <Input
              name="cnpj"
              type="text"
              width="100%"
              label="CNPJ *"
              value={maskCnpj(form.cnpj)}
              error={checkFields.cnpj}
              errorMessage="Campo obrigatório"
              onChange={(event) => {
                if (event.target.value.length > 1) {
                  setCheckFields({ ...checkFields, cnpj: false });
                }
                if (event.target.value.length > 13) {
                  getCnpjData(maskReset(event.target.value));
                }
                setForm({ ...form, cnpj: event.target.value });
              }}
            />
          </Column>
          {width > 600 && <Spacing size="x-small" />}
          <Column width="100%" paddingRight="0px">
            <Input
              name="phone"
              type="text"
              width="100%"
              label="Telefone *"
              error={checkFields.phone}
              value={form.phone}
              maxLength={15}
              onChange={(event) => {
                if (event.target.value.length > 1) {
                  setCheckFields({ ...checkFields, phone: false });
                }
                setForm({ ...form, phone: maskPhone(event.target.value) });
              }}
            />
          </Column>
        </div>
        <Row width="98%">
          <Input
            name="email"
            type="text"
            width="100%"
            label="E-mail *"
            error={checkFields.email}
            errorMessage="Campo obrigatório"
            value={form.email}
            onChange={(event) => {
              if (event.target.value.length > 1) {
                setCheckFields({ ...checkFields, email: false });
              }
              setForm({ ...form, email: event.target.value });
            }}
          />
        </Row>
        <Row>
          <Button text="Continuar" width="200px" onClick={handleNextPage} />
        </Row>
      </Modal>

      <div key={key} className={`${styles.plan}  ${styles[plan.type]}`}>
        <div className={styles.header}>
          <p className={styles.type}>{item.type}</p>
          <h3 className={styles.title}>{item.title}</h3>
          <p className={styles.subtitle}>{item.subtitle}</p>
        </div>
        <div className={styles.body}>
          {item.attributes.map((atribute, key) => (
            <div className={styles.atributes} key={key}>
              <img
                src={check}
                style={{
                  width: '15px',
                  marginRight: '8px',
                  color: 'green'
                }}
              />
              <p>{atribute.attribute_name}</p>
            </div>
          ))}
        </div>
        <Spacing size="small" />
        <Row width="90%" position="end">
          <Text
            appearance="grey"
            bolder={true}
            pointer={true}
            textAlign="end"
            onClick={() => {
              setModalAttributesIsOpen(true);
              setModalPlanSelected(item);
            }}
          >
            Mais Detalhes
            <img src={chevron} alt="" style={{ marginLeft: '5px' }} />
          </Text>
        </Row>
        <Spacing size="large" />
        <Row position="centered">
          <Column position="centered" paddingRight="0px">
            <Button
              text="Contratar"
              width="85%"
              onClick={() => {
                setModalIsOpen(true);
                getId(item.id);
              }}
            />
            {/* <Spacing size="small" />
            <ScrollLink to="simulator-section" spy={true} smooth={true} duration={500}>
              <Button
                text="Simular Plano"
                width="190px"
                appearance="ghost-borded"
                onClick={() => {
                  onClickPlan(item);
                }}
              />
            </ScrollLink> */}
          </Column>
        </Row>
      </div>
    </>
  );
}

export default Plan;
