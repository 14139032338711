import {
  getDepedentByID,
  getHoldDocuments,
  saveDependentsDocuments,
  saveHolderDocuments,
  updateBeneficiariesStatus
} from 'api/services/ApiCalls';
import {
  Button,
  Column,
  Description,
  Divider,
  FileUpload,
  Layout,
  Row,
  Spacing,
  Spinner,
  Text,
  Title
} from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

export default function UploadDocuments() {
  const [holders, setHolders] = useState<any>({});
  const [dependents, setDependents] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [editDocuments, setEditDocuments] = useState<boolean>(false);
  const [mockedData, setMockedData] = useState<any>([
    {
      id: 1,
      name: 'Nome do Titular 1',
      documents: [],
      dependents: [
        {
          id: 1,
          name: 'Nome do Dependente 1',
          documents: []
        },
        {
          id: 2,
          name: 'Nome do Dependente 2',
          documents: []
        }
      ]
    },
    {
      id: 2,
      name: 'Nome do Titular 2',
      documents: [],
      dependents: [
        {
          id: 1,
          name: 'Nome do Dependente 1',
          documents: []
        },
        {
          id: 2,
          name: 'Nome do Dependente 2',
          documents: []
        }
      ]
    }
  ]);
  const navigate = useNavigate();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  async function sendHolderDocuments() {
    holders.documents.map((document: any) => {
      if (document?.document_path.substring(0, 5) !== 'https') {
        const formData = new FormData();
        formData.append('document_id', document.document_id);
        formData.append('holder_id', holders.id);
        formData.append('holder', document.document_image);
        saveHolderDocuments(formData);
      }
    });

    dependents.map((dependent: any) => {
      dependent.documents.map((document: any) => {
        if (document.document_path.substring(0, 5) !== 'https') {
          const formData = new FormData();
          formData.append('document_id', document.document_id);
          formData.append('dependent_id', dependent.id);
          formData.append('dependent', document.document_image);
          saveDependentsDocuments(formData);
        }
      });
    });

    const formData = new FormData();
    formData.append('holder_id', holders.id);
    formData.append('beneficiaries_status', '3');
    updateBeneficiariesStatus(formData).then(() => {
      navigate('/conclusao');
    });
  }

  function organizeHolderData(holder: any, dependents: any, documents: any) {
    if (!holder.documents) {
      holder.documents = [];
    }

    documents.map((document: any) => {
      const index = holder?.documents.findIndex((elem) => elem.document_id === document.id);
      if (index === -1) {
        holder.documents.push({
          document_id: document.id,
          type: document.type,
          status: document.status ? document.status : 0,
          description: document.description,
          document_path: document.document_path || '',
          document_image: document.document_path || ''
        });
      } else {
        holder.documents[index].description = document.description;
        holder.documents[index].document_image = document.document_path;
        holder.documents[index].type = document.type;
      }
    });

    setHolders({ ...holder });

    dependents.map((dependent: any) => {
      if (dependent.documents.length === 0) {
        dependent.documents = [];
      }

      documents.map((document: any) => {
        dependents.map((depedent: any) => {
          const index = depedent?.documents.findIndex((elem) => elem.document_id === document.id);
          if (index < 0) {
            depedent.documents.push({
              document_id: document.id,
              status: document.status,
              type: document.type,
              description: document.description,
              document_path: document.document_path || '',
              document_image: document.document_path || ''
            });
          } else {
            depedent.documents[index].description = document.description;
            depedent.documents[index].document_image = document.document_path;
            depedent.documents[index].type = document.type;
          }
        });
      });
      setDependents([...dependents]);
    });

    setLoading(false);
  }

  function getDocuments(holder, dependents) {
    getHoldDocuments().then((response) => {
      organizeHolderData(holder, dependents, response.data.documents);
    });
  }

  function getHolderData(id) {
    getDepedentByID(id)
      .then((response) => {
        setHolders({ ...response.data.holder[0] });
        setDependents([...response.data.dependents]);
        getDocuments(response.data.holder[0], response.data.dependents);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      setEditDocuments(true);
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      getHolderData(jwtParsed.holder.id);
    } else {
      const data = JSON.parse(sessionStorage.getItem('@beneficiary'));
      getHolderData(data.id);
    }
  }

  useEffect(() => {
    getParams();
  }, []);

  if (holders.status > 3) navigate('/conclusao');

  return (
    <Layout
      navbar={true}
      verticalCentered={true}
      steps={[
        { title: 'Beneficiários', status: 'concluded' },
        { title: 'Declaração de saúde', status: 'concluded' },
        { title: 'Documentos', status: 'inProgress' },
        { title: 'Conclusão', status: 'pending' }
      ]}
    >
      {!loading ? (
        <>
          <Column position="centered" width="80%">
            <Spacing size="medium" />
            <Title bolder appearance="primary">
              Upload de Documentos
            </Title>
            <Spacing size="small" />
            <Description>
              Clique para fazer o upload dos documentos digitalizados abaixo. Os documentos devem
              estar legíveis. Caso haja alguma inconsistência, enviaremos uma mensagem solicitando
              um novo envio de documento.
            </Description>
          </Column>
          <Spacing size="xxx-large" />
          <div className={styles.container}>
            {holders && (
              <>
                <Row width="100%">
                  <Column paddingRight="0px">
                    <Text bolder>Titular</Text>
                    <Spacing size="xx-small" />
                    <Title bolder appearance="primary">
                      {holders.name}
                    </Title>

                    <Spacing size="small" />
                    <Divider />
                    <Spacing size="xx-large" />

                    <div className={styles.documents}>
                      {holders.documents.length > 0 &&
                        holders.documents.map((document: any, index: number) => (
                          <Column
                            width={width <= 600 ? '80%' : '45%'}
                            paddingRight="0px"
                            key={index}
                          >
                            <Text bolder={true} textAlign="left">
                              {document.type}
                            </Text>
                            <Spacing size="xxx-small" />
                            <Description appearance="grey2" bolder={true} textAlign="left">
                              {document.description}
                            </Description>
                            <Spacing size="medium" />
                            <FileUpload
                              file={document.document_path}
                              setFile={(event: any) => {
                                // eslint-disable-next-line prefer-destructuring
                                document.document_image = event ? event.target.files[0] : '';
                                document.document_path = event || '';
                                setMockedData([...mockedData]);
                                setHolders({ ...holders });
                              }}
                              setImage={(event: any) => {
                                document.document_path = event;
                                setMockedData([...mockedData]);
                                setHolders({ ...holders });
                              }}
                            />
                            <Spacing size="medium" />
                          </Column>
                        ))}
                    </div>
                    <Spacing name="xxx-large" />
                  </Column>
                </Row>
                {dependents.length > 0 &&
                  dependents.map((dependent, index) => (
                    <Row width="100%" key={index}>
                      <Column>
                        <Description bolder>Dependente {index + 1}</Description>
                        <Spacing size="small" />
                        <Title bolder appearance="primary">
                          {dependent.name}
                        </Title>

                        <Spacing size="small" />
                        <Divider />
                        <Spacing size="xx-large" />

                        <div className={styles.documents}>
                          {dependent.documents.length > 0 &&
                            dependent.documents.map((document: any, index: number) => (
                              <Column
                                width={width <= 600 ? '80%' : '45%'}
                                paddingRight="0px"
                                key={index}
                              >
                                <Text bolder={true} textAlign="left">
                                  {document.type}
                                </Text>
                                <Spacing size="xxx-small" />
                                <Description appearance="grey2" bolder={true} textAlign="left">
                                  {document.description}
                                </Description>
                                <Spacing size="medium" />
                                <FileUpload
                                  file={document.document_path}
                                  setFile={(event: any) => {
                                    // eslint-disable-next-line prefer-destructuring
                                    document.document_image = event ? event.target.files[0] : '';
                                    document.document_path = event || '';
                                    setMockedData([...mockedData]);
                                    // setDependents({ ...dependents });
                                  }}
                                  setImage={(event: any) => {
                                    document.document_path = event;
                                    setMockedData([...mockedData]);
                                    // setDependents({ ...dependents });
                                  }}
                                />
                                <Spacing size="medium" />
                              </Column>
                            ))}
                        </div>
                        <Spacing size="large" />
                      </Column>
                    </Row>
                  ))}
                <Spacing size="xxx-large" />
              </>
            )}
          </div>
          <Row width="100%" position="spaceBetween">
            <Button
              text="Voltar"
              width=" 78px"
              appearance="ghost"
              onClick={() => {
                const formData = new FormData();
                formData.append('holder_id', holders[0].id.toString());
                formData.append('beneficiaries_status', '0');
                updateBeneficiariesStatus(formData).then(() => {
                  navigate('/declaracao-saude/auth');
                });
              }}
            />
            <Spacing size="small" />
            <Button text="Enviar" width="78px" onClick={sendHolderDocuments} />
          </Row>
        </>
      ) : (
        <Row width="100%" align="center">
          <Column width="100%" position="centered">
            <Spinner show={true} />
            <Spacing size="medium" />
            <Description appearance="primary" bolder={true}>
              Carregando os dados
            </Description>
          </Column>
        </Row>
      )}
    </Layout>
  );
}
