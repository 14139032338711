import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import { verifyToken, resendToken } from 'api/services/ApiCalls';
import styles from './styles.module.scss';
import { Layout, Title, Text, Spacing, Description, Button, Alert, Row } from '../../components';

function ProposalAuth() {
  const navigate = useNavigate();
  const [code, setCode] = useState<any>('');
  const [order, setOrder] = useState<any>(JSON.parse(sessionStorage.getItem('@orderData')));
  const [alert, setAlert] = useState<any>({
    show: false,
    title: '',
    type: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  function resetAlertParams() {
    setAlert({
      show: false,
      title: '',
      type: '',
      message: ''
    });
  }

  async function resendCodeSMS() {
    resendToken({
      order_id: order.id
    })
      .then(() => {
        setAlert({
          show: true,
          title: 'Sucesso',
          type: 'success',
          message: 'Um novo token foi enviado para você!'
        });
        setTimeout(() => {
          resetAlertParams();
        }, 5500);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleCode(event: any) {
    setCode(event);
  }

  function handleAuthenticate() {
    setLoading(true);
    verifyToken({
      token: code,
      order_id: order.id
    })
      .then(() => {
        navigate('/proposta/concluido');
      })
      .catch((error) => {
        console.error(error);
        setAlert({
          show: true,
          title: 'Atenção',
          type: 'error',
          message: 'O token informado é inválido!'
        });
        setTimeout(() => {
          resetAlertParams();
        }, 5500);
      });
    setLoading(false);
  }

  return (
    <Layout navbar={true} verticalCentered={true} goBackButton={true} onClick={() => navigate('/')}>
      {alert.show && <Alert type={alert.type} title={alert.title} text={alert.message} />}
      <Spacing size="medium" />
      <Title appearance="primary" bolder={true}>
        Informe o código de autenticação
      </Title>
      <Spacing size="x-small" />
      <Text>
        Para sua segurança lhe enviamos via SMS e E-mail um código de autenticação. Verifique sua
        caixa de entrada ou spam.
      </Text>
      <Spacing size="xxx-large" />
      <Row width="90%" position="centered">
        <ReactCodeInput
          type="number"
          fields={6}
          value={code}
          name={code}
          autoFocus={true}
          inputMode="latin"
          onChange={(event: any) => {
            handleCode(event);
          }}
          inputStyle={{
            fontFamily: 'monospace',
            borderRadius: '20px',
            border: '1px solid #E8ECEE',
            margin: '4px 14px',
            width: '80px',
            height: '100px',
            fontSize: '62px',
            boxSizing: 'border-box',
            color: 'black',
            backgroundColor: '#FFFFFF',
            borderColor: '#E8ECEE',
            outline: 'none',
            textAlign: 'center',
            userSelect: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none'
          }}
        />
      </Row>
      <Spacing size="xxx-large" />
      <Description appearance="grey">
        Não recebeu o código?{' '}
        <a href="#" onClick={resendCodeSMS} className={styles.link}>
          Clique Aqui
        </a>
      </Description>
      <Spacing size="xxx-large" />
      <Button
        appearance={!loading ? 'primary' : 'disabled'}
        width="130px"
        text="Enviar"
        onClick={() => !loading && handleAuthenticate()}
        loading={loading}
      />
    </Layout>
  );
}

export default ProposalAuth;
