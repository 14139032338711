import { api } from 'api/services';
import Alert, { AlertProps } from 'components/Alert';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import Done from './components/Done';
import Imc from './components/Imc';
import Pending from './components/Pending';
import Questions from './components/Questions';
import StartQuestions from './components/Start';
import styles from './styles.module.scss';
import { TQuestion, TQuestionAnswer } from './types';

type BoxProps = {
  orderId: number;
  id: number;
  setNotCompleted: React.Dispatch<React.SetStateAction<number>>;
  setDisabledNext: React.Dispatch<React.SetStateAction<boolean>>;
  isDependent?: boolean;
};

export function Box({ orderId, id, setNotCompleted, setDisabledNext, isDependent }: BoxProps) {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [questions, setQuestions] = useState<TQuestion[] | null>(null);
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const [pdf, setPdf] = useState('');

  const [imcForm, setImcForm] = useState<TQuestionAnswer>({
    ...(isDependent ? { dependent_id: id } : { holder_id: id }),
    order_id: orderId,
    answers: []
  });

  const [pending, setPending] = useState(false);

  const getQuestions = async () => {
    try {
      setLoading(true);
      const res = await api.get('dps/questions-answers');
      setQuestions(res.data.question_answer);
    } catch (err) {
      setAlert({
        type: 'error',
        text: 'Erro ao buscar as perguntas',
        title: 'Erro'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const questionsLength = questions?.length + 1;

  if (loading)
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.container}>
      {count === 0 && <StartQuestions questionsLength={questionsLength} setCount={setCount} />}
      {count === 1 && (
        <Imc
          questionsLength={questionsLength}
          setCount={setCount}
          setImcForm={setImcForm}
          setPending={setPending}
        />
      )}
      {count > 1 && count <= questionsLength && (
        <Questions
          questions={questions}
          questionsLength={questionsLength}
          count={count}
          setCount={setCount}
          id={id}
          orderId={orderId}
          imcForm={imcForm}
          setPending={setPending}
          setNotCompleted={setNotCompleted}
          isDependent={isDependent}
          setPdf={setPdf}
        />
      )}
      {count > questionsLength &&
        (pending ? (
          <Pending setDisabledNext={setDisabledNext} pdf={pdf} setPending={setPending} />
        ) : (
          <Done />
        ))}
      {alert && <Alert {...alert} />}
    </div>
  );
}

export default Box;
