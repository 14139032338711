import React from 'react';
import { Spacing, Undertitle } from 'components';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import styles from './styles.module.scss';

function Amount({ amount, addAmount, subsAmount }) {
  return (
    <div className={styles.container}>
      <p
        onClick={subsAmount}
        style={{ color: amount === 1 ? 'grey' : 'var(--primary-color-main)' }}
      >
        <AiOutlineMinus />
      </p>
      <Spacing size="x-small" />
      <Undertitle appearance="primary">{amount}</Undertitle>
      <Spacing size="x-small" />
      <p
        onClick={addAmount}
        style={{ color: amount === 13 ? 'grey' : 'var(--primary-color-main)' }}
      >
        <AiOutlinePlus />
      </p>
    </div>
  );
}

export default Amount;
