import { PropsWithChildren, useEffect } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Step } from 'types/step';
import { Button, Navbar, Row, Spacing, Steps } from '..';
import styles from './styles.module.scss';

type LayoutProps = {
  steps?: Step[];
  onClick?: () => void;
  goBackButton?: boolean;
  navbar?: boolean;
  verticalCentered?: boolean;
} & PropsWithChildren;

function Layout({
  children,
  steps,
  onClick,
  goBackButton = false,
  navbar = false,
  verticalCentered = false
}: LayoutProps) {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, []);

  return (
    <>
      {navbar && <Navbar />}
      <div className={styles.container}>
        {steps && (
          <>
            <Spacing size="medium" />
            <Row position="centered">
              <Steps steps={steps} />
            </Row>
          </>
        )}
        {goBackButton && (
          <>
            <Spacing size="medium" />
            <Row position="start">
              <Button
                width="188px"
                text="Voltar para o Painel"
                appearance="ghost"
                iconPosition="left"
                onClick={onClick}
              >
                <FaChevronLeft />
              </Button>
            </Row>
          </>
        )}
        <Spacing size="small" />
        <div
          className={styles.content}
          style={{ justifyContent: verticalCentered ? 'center' : 'start' }}
        >
          {children}
        </div>
        <Spacing size="x-large" />
      </div>
    </>
  );
}

export default Layout;
