import { Listbox } from '@headlessui/react';
import { useState } from 'react';
import styles from './styles.module.scss';

export type Option = {
  label: string;
  value: string | number;
};

type ListBoxProps = {
  options: Option[];
  placeholder?: string;
  onChange?: (value: string | number) => void;
};

function ListBox({ options, onChange, placeholder = 'Selecione' }: ListBoxProps) {
  const [selected, setSelected] = useState<Option | null>(null);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className={styles.container}>
        <Listbox.Button className={styles.button}>
          {selected?.label || placeholder}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
          >
            <path
              d="M12.8334 1.5L7.00008 7.33333L1.16675 1.5"
              stroke="#78909C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Listbox.Button>
        <Listbox.Options className={styles.options}>
          {options.map((option) => (
            <Listbox.Option
              key={option.value}
              value={option}
              className={styles.option}
              onClick={() => onChange?.(option.label)}
            >
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}

export default ListBox;
