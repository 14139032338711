import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import styles from './styles.module.scss';

function DatePicker({ ...props }: any) {
  return (
    <div className={styles.datepicker}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <MUIDatePicker {...props} format="DD/MM/YYYY" />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}

export default DatePicker;
