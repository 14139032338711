import React from 'react';
import styles from './styles.module.scss';

function AppEnvironment({ environment }: any) {
  console.log(environment);
  if (environment.toLowerCase() !== 'production') {
    const environmentStyle = {
      backgroundColor: `var(--enviroment-${environment.toLowerCase()}, --basic-white)`
    };

    return (
      <div className={styles.appEnvironment} style={environmentStyle}>
        <span>{environment}</span>
      </div>
    );
  }

  return null;
}

export default AppEnvironment;
