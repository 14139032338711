import {
  Button,
  Column,
  Description,
  Input,
  Modal,
  Row,
  Spacing,
  Text,
  Title,
  Undertitle
} from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { maskPhone } from 'utils/mask';
import consultant from '../../assets/consultantImage.png';
import check from '../../assets/icons/check-icon.png';
import error from '../../assets/icons/error-icon.png';
import icon from '../../assets/icons/mouse-icon.png';
import logo from '../../assets/logos/sulmed-logo-white.svg';
import header from '../../assets/plan-item-header.png';
import banner from '../../assets/sulmed-banner.png';
import styles from './styles.module.scss';

function SitePlans() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [form, setForm] = useState({
    cnpj: '',
    phone: '',
    email: ''
  });
  const mockedData = [
    {
      id: 1,
      title: 'Sulmed Open',
      type: 'Empresarial',
      code: 'UNIAMB / ANS 460786094',
      benefits: [
        {
          id: 1,
          title: 'Consultas',
          haveIt: true
        },
        {
          id: 2,
          title: 'Exames simples',
          haveIt: true
        },
        {
          id: 3,
          title: 'Exames especiais',
          haveIt: true
        },
        {
          id: 4,
          title: 'Atendimento ambulatorial',
          haveIt: true
        },
        {
          id: 5,
          title: 'Sem acomodação',
          haveIt: true
        },
        {
          id: 6,
          title: 'Abrangência regional',
          haveIt: false
        },
        {
          id: 7,
          title: 'Coparticipações via fatura',
          haveIt: false
        }
      ]
    },
    {
      id: 2,
      title: 'Sulmed Open',
      type: 'Empresarial',
      code: 'UNIAMB / ANS 460786094',
      benefits: [
        {
          id: 1,
          title: 'Consultas',
          haveIt: true
        },
        {
          id: 2,
          title: 'Exames simples',
          haveIt: true
        },
        {
          id: 3,
          title: 'Exames especiais',
          haveIt: true
        },
        {
          id: 4,
          title: 'Atendimento ambulatorial',
          haveIt: true
        },
        {
          id: 5,
          title: 'Sem acomodação',
          haveIt: true
        },
        {
          id: 6,
          title: 'Abrangência regional',
          haveIt: false
        },
        {
          id: 7,
          title: 'Coparticipações via fatura',
          haveIt: false
        }
      ]
    },
    {
      id: 3,
      title: 'Sulmed Open',
      type: 'Empresarial',
      code: 'UNIAMB / ANS 460786094',
      benefits: [
        {
          id: 1,
          title: 'Consultas',
          haveIt: true
        },
        {
          id: 2,
          title: 'Exames simples',
          haveIt: true
        },
        {
          id: 3,
          title: 'Exames especiais',
          haveIt: true
        },
        {
          id: 4,
          title: 'Atendimento ambulatorial',
          haveIt: true
        },
        {
          id: 5,
          title: 'Sem acomodação',
          haveIt: true
        },
        {
          id: 6,
          title: 'Abrangência regional',
          haveIt: false
        },
        {
          id: 7,
          title: 'Coparticipações via fatura',
          haveIt: false
        }
      ]
    }
  ];
  const isConsultant = true;

  function handleModal() {}

  return (
    <div className={styles.container}>
      <Modal
        isOpen={modalIsOpen}
        width="600px"
        title="Olá, bem-vindo(a)!"
        description="Preencha os campos abaixo, para que possamos dar continuidade ao processo de simulação de Planos."
        onClickClose={() => setModalIsOpen(false)}
      >
        <Row>
          <Column>
            <Input
              name="cnpj"
              type="text"
              width="100%"
              label="CNPJ"
              value={form.cnpj}
              onChange={(event) => {
                setForm({ ...form, cnpj: event.target.value });
              }}
            />
          </Column>
          <Column>
            <Input
              name="phone"
              type="text"
              width="100%"
              label="Telefone"
              value={maskPhone(form.phone)}
              onChange={(event) => {
                setForm({ ...form, phone: event.target.value });
              }}
            />
          </Column>
        </Row>
        <Spacing size="xxx-large" />
        <Row position="start" width="104%">
          <Input
            name="email"
            type="text"
            width="100%"
            label="E-mail"
            value={form.email}
            onChange={(event) => {
              setForm({ ...form, email: event.target.value });
            }}
          />
        </Row>
        <Spacing size="xxx-large" />
        <Row>
          <Column position="start">
            <Button
              text="Cancelar"
              appearance="ghost"
              width="83px"
              onClick={() => setModalIsOpen(false)}
            />
          </Column>
          <Column position="end">
            <Button text="Continuar" width="83px" onClick={handleModal} />
          </Column>
        </Row>
      </Modal>
      <div className={styles.contact}>
        <Column>
          <Row position="start">
            <Description appearance="white" bolder={true}>
              (51) 3220.1000 | Central 24h: 4042.1329
            </Description>
          </Row>
        </Column>
        <Column>
          <Row position="end">
            <Description appearance="white" bolder={true}>
              Rede Credenciada • Área do Colaborador • Área do Prestador • Área do Cliente
            </Description>
          </Row>
        </Column>
      </div>
      <div className={styles.navbar}>
        <img src={logo} className={styles.logo} />
        <ul>
          <li>
            <Undertitle bolder={true}>A Sulmed</Undertitle>
          </li>
          <li>
            <Undertitle bolder={true}>Planos de Saúde</Undertitle>
          </li>
          <li>
            <Undertitle bolder={true}>Plano Odontológico</Undertitle>
          </li>
          <li>
            <Undertitle bolder={true}>Saúde e Segurança do Trabalho</Undertitle>
          </li>
          <li>
            <Undertitle bolder={true}>Fale Conosco</Undertitle>
          </li>
          <li>
            <Undertitle bolder={true}>Blog</Undertitle>
          </li>
        </ul>
        {isConsultant ? (
          <div className={styles.consultantID}>
            <Row>
              <div className={styles.consultantImage}>
                <img src={consultant} />
              </div>
              <Column>
                <p className={styles.consultantName}>Fernando Holmes Holmes Holmes</p>
                <p className={styles.consultantRole}>Seu Consultor</p>
              </Column>
            </Row>
          </div>
        ) : (
          <div className={styles.button}>
            <Undertitle appearance="white" bolder={true}>
              Pronto Atendimento Digital
            </Undertitle>
          </div>
        )}
      </div>
      <div className={styles.banner}>
        <h1 className={styles.bannerTitle}>Planos de Saúde Sulmed</h1>
        <img src={icon} className={styles.bannerIcon} />
        <img src={banner} className={styles.bannerBackground} />
      </div>
      <div className={styles.content}>
        <Spacing size="xxx-large" />
        <Title>Conheça os nossos planos disponíveis</Title>
        <Spacing size="xxx-small" />
        <Description>
          Com maiores benefícios e menores custos, saúde completa e acessível para todos
        </Description>
        <Spacing size="x-large" />
        <Row position="centered">
          {mockedData.map((data, key) => (
            <Column key={key}>
              <div className={styles.plan}>
                <div className={styles.planHeader}>
                  <div className={styles.planHeaderTitle}>
                    <Undertitle appearance="white">{data.type}</Undertitle>
                    <Title appearance="white" bolder={true}>
                      {data.title}
                    </Title>
                    <Spacing size="medium" />
                    <p className={styles.description}>{data.code}</p>
                  </div>
                  <img src={header} className={styles.planHeaderBackground} />
                </div>
                <div className={styles.planBody}>
                  {data.benefits.map((benefit) => (
                    <>
                      <Row position="start">
                        <img src={benefit.haveIt ? check : error} className={styles.planIconBody} />
                        <Text>{benefit.title}</Text>
                      </Row>
                      <Spacing size="small" />
                    </>
                  ))}
                </div>
                <Row position="centered">
                  <Button text="Simular Plano" width="170px" onClick={() => setModalIsOpen(true)} />
                </Row>
                <Spacing size="medium" />
              </div>
            </Column>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default SitePlans;
