import React from 'react';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-success.png';

function ProposalEditSent() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Proposta Reenviada!"
        image={feedback}
        description="Seu cliente receberá a Proposta Editada no e-mail informado. Aguarde e auxilie ele nos próximos passos para concluir o contrato."
      />
    </Layout>
  );
}

export default ProposalEditSent;
