import {
  aproveEnterpriseDocument,
  createContract,
  getAllUploadedEnterpriseDocuments,
  getOrderData,
  reproveEnterpriseDocument
} from 'api/services/ApiCalls';
import {
  Alert,
  Button,
  Card,
  Column,
  Description,
  Input,
  Layout,
  RadioButton,
  Row,
  Spacing,
  Spinner,
  Text,
  Textarea,
  Title,
  Undertitle
} from 'components';
import { useEffect, useState } from 'react';
import { BsCheck, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import error from '../../assets/icons/error-icon@2x.png';
import check from '../../assets/icons/green_check@2x.png';
import ImageView from './components/ImageView';
import styles from './styles.module.scss';

import untik from '../../assets/icons/untik-point.png';
import tik from '../../assets/icons/tik-poin.png';

export default function EnterpriseDocument() {
  const navigate = useNavigate();
  const [checkY, setCheckY] = useState(false);
  const [checkN, setCheckN] = useState(false);
  const [checkDoc, setCheckDoc] = useState(false);
  const [checkFin, setCheckFin] = useState(false);
  const [firstStep, setFirstStep] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [aproved, setAproved] = useState(false);
  const [repproved, setRepproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [orderId, setOrderId] = useState('');
  const [documents, setDocuments] = useState([]);
  const [data, setData] = useState({
    city: '',
    company_name: '',
    street: '',
    phone: '',
    responsible_name: '',
    cnpj: '',
    district: '',
    zip_code: '',
    email: '',
    company_social_contract: '',
    company_gfip: '',
    company_cnpj_card: '',
    legal_responsible_document_front: '',
    legal_responsible_document_back: ''
  });
  const [checks, setChecks] = useState([false, false]);
  const [alert, setAlert] = useState<any>({
    show: false,
    title: '',
    type: '',
    message: ''
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  async function handleAproveDocument() {
    try {
      const formData = new FormData();
      formData.append('order_id', orderId);
      await aproveEnterpriseDocument(formData);
      setFirstStep(false);
      setAproved(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleRepovedocument() {
    setFirstStep(false);
    // setRepproved(true);
    try {
      const formData = new FormData();
      formData.append('order_id', orderId);
      if (checkDoc) {
        formData.append('disapproval_justification', 'Documentação');
      } else if (checkFin) {
        formData.append('disapproval_justification', 'Financeiro');
      }
      formData.append('disapproval_description', description);
      await reproveEnterpriseDocument(formData).then(() => {
        navigate('/analise/documento/empresa/sucesso');
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getDocuments(id) {
    try {
      const response = await getAllUploadedEnterpriseDocuments(id);
      setDocuments(response.data.documentation);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function getAllData(id) {
    try {
      const response = await getOrderData(id);
      setData(response.data.order_data.order);
      getDocuments(id);
    } catch (error) {
      console.error(error);
    }
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      setOrderId(tk);
      getAllData(tk);
    }
  }

  function handleFinalizeContract() {
    if (checks.includes(true)) {
      if (checks[0] === true) {
        const plansIDs: any = [];

        getOrderData(orderId)
          .then((response) => {
            response.data.order_data.plans_data.map((plan) => {
              if (!plansIDs.includes(plan.plan_id)) {
                plansIDs.push(plan.plan_id);
              }
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            createContract({
              order_id: orderId,
              plan_ids: plansIDs,
              plan_type: '',
              contract_type: 'auto'
            })
              .then((response) => console.log(response.data))
              .catch((error) => console.error(error))
              .finally(() => navigate('/analise/documento/empresa/sucesso'));
          });
      } else {
        navigate('/analise/documento/empresa/sucesso');
      }
    } else {
      setAlert({
        show: true,
        title: 'Atenção!',
        type: 'warning',
        message: 'Obrigatório informar como o contrato deve ser gerado.'
      });
      setTimeout(() => {
        setAlert({
          show: false,
          title: '',
          type: '',
          message: ''
        });
      }, 5500);
    }
  }

  useEffect(() => {
    setLoading(true);
    getParams();
  }, []);

  return (
    <Layout navbar={true} goBackButton={false}>
      {alert.show && <Alert type={alert.type} title={alert.title} text={alert.message} />}
      <Spacing size="x-large" />
      {loading ? (
        <Layout navbar={false} verticalCentered={true}>
          <Row width="100%" align="center">
            <Column width="100%" position="centered">
              <Spinner show={true} />
              <Spacing size="xx-small" />
              <Description appearance="primary" bolder={true}>
                Carregando os dados
              </Description>
            </Column>
          </Row>
          <Spacing size="x-large" />
        </Layout>
      ) : (
        <>
          <Title appearance="primary" bolder={true}>
            Análise de informações da empresa
          </Title>
          <Spacing size="xx-small" />
          <Text maxWidth="675px">
            Analise a documentação enviada pela empresa. Se estiver tudo certo, clique em Aprovar.
            Caso haja alguma inconsistência clique em Reprovar e informe o motivo para que os
            documentos possam ser reenviados.
          </Text>
          <Spacing size="x-large" />
          <Row position="start" width="98%">
            <Card appearance="ghost" width="100%">
              <Row position="start">
                <Undertitle appearance="grey3" bolder={true}>
                  Informações da empresa
                </Undertitle>
              </Row>
              <Spacing size="medium" />
              <Row>
                <Column width={width <= 650 ? '100%' : '100%'}>
                  <Description appearance="grey2">CNPJ</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.cnpj}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column>
                  <Description appearance="grey2">Nome da Empresa</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.company_name}
                  </Text>
                </Column>
              </Row>
              {width <= 650 ? <Spacing size="small" /> : <Spacing size="large" />}
              <Row>
                <Column>
                  <Description appearance="grey2">Município</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.city}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column width="99%">
                  <Description appearance="grey2">Bairro</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.district}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column>
                  <Description appearance="grey2">CEP</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.zip_code}
                  </Text>
                </Column>
              </Row>
              {width <= 650 ? <Spacing size="small" /> : <Spacing size="large" />}
              <Row>
                <Column width={width <= 650 ? '100%' : '102%'}>
                  <Description appearance="grey2">Representante</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.responsible_name}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column>
                  <Description appearance="grey2">Telefone</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.phone}
                  </Text>
                </Column>
                {width <= 650 && <Spacing size="small" />}
                <Column width={width <= 650 ? '100%' : '102%'}>
                  <Description appearance="grey2">E-mail</Description>
                  <Spacing size="xxx-small" />
                  <Text appearance="grey3" bolder={true}>
                    {data.email}
                  </Text>
                </Column>
              </Row>
            </Card>
          </Row>
          <Spacing size="x-large" />
          {firstStep && (
            <>
              {documents.map((document) => (
                <>
                  <ImageView
                    orderID={orderId}
                    docID={document.document_data.company_document_id}
                    img={document.document_data.document_path}
                    name={document.document_description}
                    url={document.document_data.document_path}
                    onClick={() => getAllData(orderId)}
                  />
                  <Spacing size="x-large" />
                </>
              ))}
              <Row width="98%">
                <Column>
                  <Text appearance="grey2">Foi realizado a verificação da empresa no Serasa</Text>
                  <Column>
                    <Spacing size="small" />
                    <RadioButton
                      label="Sim"
                      color="#78909c"
                      checked={checkY}
                      onChange={() => {
                        if (checkN) {
                          setCheckN(false);
                          setCheckY(true);
                        } else {
                          setCheckY(true);
                        }
                        setAnswered(true);
                      }}
                    />
                    <Spacing size="xx-small" />
                    <RadioButton
                      label="Não"
                      color="#78909c"
                      checked={checkN}
                      onChange={() => {
                        if (checkY) {
                          setCheckN(true);
                          setCheckY(false);
                        } else {
                          setCheckN(true);
                        }
                        setAnswered(true);
                      }}
                    />
                  </Column>
                </Column>
              </Row>
              <Spacing size="xx-large" />
              {checkN && (
                <Card appearance="warning" width="100%">
                  <Row>
                    <Text maxWidth="100%" appearance="warning">
                      Antes de finalizar a Análise, é necessário a verificação da situação
                      financeira da empresa no Serasa.
                    </Text>
                  </Row>
                </Card>
              )}
              <Spacing size="xx-large" />
              <Row position="end">
                <Button
                  text="Aprovar"
                  width="134px"
                  appearance={!answered || (!checkY && checkN) ? 'disabled' : 'primary'}
                  onClick={() => {
                    if (!answered || (checkY && !checkN)) {
                      handleAproveDocument();
                    }
                  }}
                >
                  <BsCheck color="white" />
                </Button>
                <Spacing size="xx-small" />
                <Button
                  text="Reprovar"
                  width="134px"
                  appearance={!answered || (!checkY && checkN) ? 'disabled' : 'ghost'}
                  onClick={() => {
                    if (!answered || (checkY && !checkN)) {
                      setFirstStep(false);
                      setRepproved(true);
                    }
                  }}
                >
                  <BsX />
                </Button>
              </Row>
            </>
          )}

          {aproved && (
            <>
              <Card width="98%" appearance="green" textAlign="center'">
                <Row position="centered">
                  <Column position="centered">
                    <img src={check} style={{ width: '50px' }} alt="green check icon" />
                    <Spacing size="x-small" />
                    <Text appearance="success">Documentos aprovados</Text>
                  </Column>
                </Row>
              </Card>
              <Spacing size="xxx-large" />
              <Row width="97%" position="start">
                <Text bolder={true}>Como será gerado o contrato?</Text>
              </Row>
              <Spacing size="small" />
              <Row width="97%" position="start">
                {checks[0] === false ? (
                  <img
                    src={untik}
                    className={styles.checkbox}
                    alt=""
                    onClick={() => {
                      checks[0] = true;
                      checks[1] = false;
                      setChecks([...checks]);
                    }}
                  />
                ) : (
                  <img
                    src={tik}
                    className={styles.checkbox}
                    alt=""
                    onClick={() => {
                      checks[0] = false;
                      setChecks([...checks]);
                    }}
                  />
                )}
                <Spacing size="xxx-small" />
                <Text>Gerar Contrato Via Sistema (Automático)</Text>
              </Row>
              <Spacing size="small" />
              <Row width="97%" position="start">
                {checks[1] === false ? (
                  <img
                    src={untik}
                    className={styles.checkbox}
                    alt=""
                    onClick={() => {
                      checks[1] = true;
                      checks[0] = false;
                      setChecks([...checks]);
                    }}
                  />
                ) : (
                  <img
                    src={tik}
                    className={styles.checkbox}
                    alt=""
                    onClick={() => {
                      checks[1] = false;
                      setChecks([...checks]);
                    }}
                  />
                )}
                <Spacing size="xxx-small" />
                <Text>Gerar Contrato Manualmente</Text>
              </Row>
              <Spacing size="xxx-large" />
              <Row width="98%" position="spaceBetween">
                <Button
                  text="Cancelar"
                  width="120px"
                  appearance="ghost"
                  onClick={() => {
                    setFirstStep(true);
                    if (aproved) {
                      setAproved(false);
                    }
                    if (repproved) {
                      setRepproved(false);
                    }
                  }}
                />
                <Button
                  text="Finalizar Análise"
                  width="160px"
                  onClick={() => {
                    handleFinalizeContract();
                  }}
                />
              </Row>
            </>
          )}
          {repproved && (
            <>
              <Card width="87%" appearance="error" textAlign="center'">
                <Row>
                  <img src={error} style={{ width: '50px' }} alt="red error icon" />
                </Row>
                <Spacing size="medium" />
                <Row position="centered">
                  <Text appearance="error">
                    Documentação Reprovada. Informe abaixo o motivo da recusa dos documentos
                  </Text>
                  <Spacing size="medium" />
                </Row>
              </Card>
              <Spacing size="x-large" />
              <Row width="90%">
                <Column>
                  <Text appearance="grey2" bolder>
                    Selecione o motivo da documentação ser reprovada
                  </Text>
                  <Column>
                    <Spacing size="small" />
                    <RadioButton
                      label="Financeiro"
                      color="#78909c"
                      checked={checkFin}
                      onChange={() => {
                        if (checkDoc) {
                          setCheckDoc(false);
                          setCheckFin(true);
                        } else {
                          setCheckFin(true);
                        }
                      }}
                    />
                    <Spacing size="xx-small" />
                    <RadioButton
                      label="Documentação"
                      color="#78909c"
                      checked={checkDoc}
                      onChange={() => {
                        if (checkFin) {
                          setCheckFin(false);
                          setCheckDoc(true);
                        } else {
                          setCheckDoc(true);
                        }
                      }}
                    />
                  </Column>
                  <Spacing size="large" />
                  <Text appearance="grey2" bolder>
                    Informe com mais detalhes a recusa da documentação
                  </Text>
                  <Spacing />
                  <Textarea
                    placeholder="Justificativa"
                    value={description}
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                  />
                </Column>
              </Row>
              <Spacing size="xxx-large" />
              <Spacing size="x-large" />
              <Row position="spaceBetween" width="98%">
                <Button
                  text="Cancelar"
                  width="95px"
                  appearance="ghost"
                  onClick={() => {
                    setFirstStep(true);
                    if (aproved) {
                      setAproved(false);
                    }
                    if (repproved) {
                      setRepproved(false);
                    }
                  }}
                />
                <Button
                  text="Finalizar Análise"
                  width="160px"
                  onClick={() => {
                    if (repproved) {
                      handleRepovedocument();
                    }
                  }}
                />
              </Row>
            </>
          )}
          <Spacing size="x-large" />
        </>
      )}
    </Layout>
  );
}
