import RadioButton from 'components/RadioButton';
import { Button, Card, Column, Description, Row, Spacing } from '..';

function Billet({ width, check, onChangeCheckbox, onClick }) {
  return (
    <Card appearance="ghost" width={width}>
      <RadioButton label="Boleto Bancário" checked={check} onChange={onChangeCheckbox} />
      {check && (
        <Row>
          <Column>
            <Spacing size="medium" />
            <Description textAlign="start">1. Clique no botão "Gerar Boleto";</Description>
            <Spacing size="xx-small" />
            <Description textAlign="start">
              2. Você sera direcionado para a tela de pagamento por Boleto;
            </Description>
            <Spacing size="xx-small" />
            <Description textAlign="start">3. Escolha a melhor forma de pagamento;</Description>
            <Spacing size="xx-small" />
            <Description textAlign="start">
              4. Após o pagamento você receberá um e-mail de confirmação e com detalhes do exame.
            </Description>

            <Spacing size="large" />
            <Button text="Gerar Boleto" appearance="primary" width="117px" onClick={onClick} />

            {/* <Spacing size="xx-small" /> */}
            {/* <Card appearance="ghost" width="100%">
            <div className={styles.copyCode}>
              <Text>00000000000000000000000</Text>
              <Text appearance="error" bolder={true} pointer={true}>
                Copiar <FaRegCopy className={styles.icon} />
              </Text>
            </div>
          </Card> */}
          </Column>

          {/* IMAGEM DO CODIGO DE BARRAS */}
          {/* <Column position="centered">
            <Spacing size="medium" />
            <img src={codebar} />
          </Column> */}
        </Row>
      )}
    </Card>
  );
}

export default Billet;
