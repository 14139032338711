import { getPlanById } from 'api/services/ApiCalls';
import { Alert, Feedback, Layout } from 'components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import feedback from '../../assets/sulmed-feedback-success.png';

export default function StartBeneficiariesJourney() {
  const navigate = useNavigate();

  async function getPlanData(id: number) {
    await getPlanById(id)
      .then((response) => {
        sessionStorage.setItem(
          '@plan_name',
          JSON.stringify(`${response.data.plan[0].name} ${response.data.plan[0].subtitle}`)
        );
      })
      .catch((error) => console.error(error));
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      getPlanData(jwtParsed.beneficiary.plan_id);
      sessionStorage.setItem('@beneficiary', JSON.stringify(jwtParsed.beneficiary));
      sessionStorage.setItem('@type', JSON.stringify(jwtParsed.type));
      sessionStorage.setItem('@dependents', JSON.stringify(jwtParsed.beneficiary.dependents));
    }
  }

  useEffect(() => {
    getParams();
  }, []);

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Parabéns por escolher a Sulmed!"
        image={feedback}
        description="Leva menos de 5 minutos para completar o cadastro das suas informações para aderir ao Plano de Saúde Sulmed e ter uma vida mais tranquila"
        buttonText="Iniciar Cadastro"
        onClickButton={() => navigate('/formulario/cadastro/beneficiario')}
      />
      <Alert
        text="Esse link é pessoal e não deve ser compartilhado"
        title="ATENÇÃO"
        type="warning"
      />
    </Layout>
  );
}
