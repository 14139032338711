import styles from './styles.module.scss';

import tik from '../../assets/icons/tik-poin.png';
import untik from '../../assets/icons/untik-point.png';

function RadioButton({ label, checked, onChange, color }: any) {
  return (
    <div className={styles.row}>
      {checked ? (
        <img src={tik} alt="" onClick={onChange} style={{ cursor: 'pointer' }} draggable="false" />
      ) : (
        <img
          src={untik}
          alt=""
          onClick={onChange}
          style={{ cursor: 'pointer' }}
          draggable="false"
        />
      )}
      <p className={styles.title} style={{ color: color || 'black' }}>
        {label}
      </p>
    </div>
  );
}

export default RadioButton;
