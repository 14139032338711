import {
  createSimulationData,
  getActivesPlans,
  getCommercialApresentation,
  getSimulationPDF,
  getTableCommercialByPlanId,
  sendSimulationData
} from 'api/services/ApiCalls';
import axios from 'axios';
import {
  Alert,
  Button,
  Column,
  Description,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Spacing,
  Spinner,
  Text,
  Title
} from 'components';
import { useNavigationContext } from 'context/NavigationContext';
import { useEffect, useState } from 'react';
import { FaDownload, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { maskMoneyPlans, maskPhone, maskReset } from 'utils/mask';
import Footer from './Components/Footer';
import Table from './Components/Table';
import styles from './styles.module.scss';

import check from '../../assets/icons/check-icon.png';
import checkBlue from '../../assets/icons/check.svg';
import chevronDown from '../../assets/icons/chevron-down-icon.svg';
import chevron from '../../assets/icons/chevron-left-icon.svg';
import download from '../../assets/icons/download.svg';
import checked from '../../assets/icons/icon-checkbox-checked.png';
import unchecked from '../../assets/icons/icon-checkbox-unchecked.png';

function SimulationConsultant() {
  const navigate = useNavigate();
  const { setParams, Broker } = useNavigationContext();
  const [plans, setPlans] = useState<any>([]);
  const [concluded, setConcluded] = useState<boolean>(false);
  const [modalContact, setModalContact] = useState<boolean>(false);
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [url, setUrl] = useState('');
  const [alertData, setAlertData] = useState<any>({
    show: false,
    type: '',
    title: '',
    message: ''
  });
  const contact = [
    {
      id: 1,
      title: 'Whatsapp',
      value: 'Whatsapp'
    },
    {
      id: 2,
      title: 'E-mail',
      value: 'E-mail'
    }
  ];
  const [form, setForm] = useState({
    email: '',
    phone: '',
    contact: '',
    plans: [
      {
        id: 1,
        checked: false,
        title: '',
        subtitle: '',
        plan_id: 0,
        type: '',
        value: '',
        attributes: [],
        coparticipation: [],
        values: [],
        total: 0
      }
    ]
  });
  const [table, setTable] = useState([
    {
      id: 1,
      title: '0 - 18',
      holder: 0,
      dependent: 0
    },
    {
      id: 2,
      title: '19 - 23',
      holder: 0,
      dependent: 0
    },
    {
      id: 3,
      title: '24 - 28',
      holder: 0,
      dependent: 0
    },
    {
      id: 4,
      title: '29 - 33',
      holder: 0,
      dependent: 0
    },
    {
      id: 5,
      title: '34 - 38',
      holder: 0,
      dependent: 0
    },
    {
      id: 6,
      title: '39 - 43',
      holder: 0,
      dependent: 0
    },
    {
      id: 7,
      title: '44 - 48',
      holder: 0,
      dependent: 0
    },
    {
      id: 8,
      title: '49 - 53',
      holder: 0,
      dependent: 0
    },
    {
      id: 9,
      title: '54 - 58',
      holder: 0,
      dependent: 0
    },
    {
      id: 10,
      title: '59 +',
      holder: 0,
      dependent: 0
    }
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSendSimulation, setLoadingSendSimulation] = useState<boolean>(false);
  const [beneficiaries, setBeneficiaries] = useState(0);
  const [downloadLink, setdownloadLink] = useState('');

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  async function getPlans() {
    try {
      const response = await getActivesPlans();
      const formattedPlan = response.data.plans.map((plan) => {
        const formatPlan = {
          id: plan.id,
          title: `${plan.name} ${plan.subtitle}`,
          subtitle: plan.subtitle,
          value: plan.name,
          values: plan.table_commercial,
          coparticipation: plan.co_participation,
          status: false,
          type: plan.type.toLowerCase(),
          attributes: plan.plan_attributes
        };

        return formatPlan;
      });
      setPlans(formattedPlan);
      const table = JSON.parse(sessionStorage.getItem('@table'));
      if (table) {
        table.map((item) => {
          const index = formattedPlan.map((e) => e.value).indexOf(item.plan);
          formattedPlan[index].status = true;
        });
      }
      setPlans(formattedPlan);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  function getPdf() {
    getCommercialApresentation()
      .then((response) => {
        const pdf = response.data.documentation[0];
        setUrl(pdf.documentation_path);
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  function InstitutionalInformationDownload() {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Informativo Institucional.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }

  function resetSimulation() {
    setForm({
      email: '',
      phone: '',
      contact: '',
      plans: [
        {
          id: 1,
          checked: false,
          title: '',
          subtitle: '',
          plan_id: 0,
          type: '',
          value: '',
          values: [],
          attributes: [],
          coparticipation: [],
          total: 0
        }
      ]
    });
    setTable([
      {
        id: 1,
        title: '0 - 18',
        holder: 0,
        dependent: 0
      },
      {
        id: 2,
        title: '19 - 23',
        holder: 0,
        dependent: 0
      },
      {
        id: 3,
        title: '24 - 28',
        holder: 0,
        dependent: 0
      },
      {
        id: 4,
        title: '29 - 33',
        holder: 0,
        dependent: 0
      },
      {
        id: 5,
        title: '34 - 38',
        holder: 0,
        dependent: 0
      },
      {
        id: 6,
        title: '39 - 43',
        holder: 0,
        dependent: 0
      },
      {
        id: 7,
        title: '44 - 48',
        holder: 0,
        dependent: 0
      },
      {
        id: 8,
        title: '49 - 53',
        holder: 0,
        dependent: 0
      },
      {
        id: 9,
        title: '54 - 58',
        holder: 0,
        dependent: 0
      },
      {
        id: 10,
        title: '59 +',
        holder: 0,
        dependent: 0
      }
    ]);
    getPlans();
    setConcluded(false);
  }

  function checkTableData(table: any) {
    const check = [];
    table.map((item) => {
      check.push(item.holder >= 1);
    });
    return check.includes(true);
  }

  function checkPlansData() {
    const check = [];

    form.plans.map((plan) => {
      check.push(plan.title !== '');
    });

    return check.includes(true);
  }

  function filterPlansData() {
    const finalPlans = [];
    form.plans.map((plan) => {
      if (plan.title) {
        finalPlans.push(plan);
      }
    });
    return finalPlans;
  }

  function handleSimulation() {
    if (checkTableData(table) && checkPlansData()) {
      form.plans = filterPlansData();
      setLoading(true);
      form.plans.map((plan) => {
        if (plan.title !== '') {
          getTableCommercialByPlanId(plan.plan_id.toString()).then((response) =>
            response.data.table_commercial.map((commercial) => {
              table.map((item) => {
                if (item.holder > 0 || item.dependent > 0) {
                  if (commercial.minimum_age === Number(item.title.substring(0, 2))) {
                    const count =
                      plan.total + Number(commercial.plan_value) * (item.holder + item.dependent);
                    plan.total = Number(count.toFixed(2));
                    plan.value = commercial.plan_value;
                  } else if (item.title === '0 - 100') {
                    const count =
                      plan.total + Number(commercial.plan_value) * (item.holder + item.dependent);
                    plan.total = Number(count.toFixed(2));
                    plan.value = commercial.plan_value;
                  } else {
                    // eslint-disable-next-line no-lonely-if
                    if (item.title === `${commercial.minimum_age} - ${commercial.maximum_age}`) {
                      const count =
                        plan.total + Number(commercial.plan_value) * (item.holder + item.dependent);
                      plan.total = Number(count.toFixed(2));
                      plan.value = commercial.plan_value;
                    }
                  }
                }
              });
            })
          );
        }

        document.documentElement.scrollTo({
          top: 0,
          left: 0
        });

        setTimeout(() => {
          setLoading(false);
        }, 3500);
      });

      let beneficiarie = 0;
      table.map((item) => {
        beneficiarie += item.holder + item.dependent;
      });
      setBeneficiaries(beneficiarie);

      setConcluded(true);
    } else {
      setAlertData({
        show: true,
        title: 'Atenção!',
        type: 'warning',
        message: 'Para realizar a simulação é necessário selecionar ao menos um plano e um titular'
      });
      setTimeout(() => {
        setAlertData({
          show: false
        });
      }, 5500);
    }
  }

  function checkPlansChecked() {
    const checkedPlans = [];
    form.plans.map((plan) => {
      if (plan.checked) {
        checkedPlans.push(plan);
      }
    });

    return checkedPlans.length === 0;
  }

  function saveSimulation() {
    if (form.email !== '' || form.phone !== '') {
      setLoadingSendSimulation(true);
      const plansItems = [];

      form.plans.map((plan) => {
        table.map((item) => {
          if (plan.checked) {
            if (item.holder !== 0 || item.dependent !== 0) {
              plansItems.push({
                age: item.title,
                plan: plan.title,
                owners: item.holder,
                dependents: item.dependent,
                value: plan.value,
                totalValue: plan.total,
                plan_id: plan.plan_id
              });
            }
          }
        });
      });

      const plansValues = [];
      form.plans.map((plan) => {
        const values = [];
        if (plan.checked) {
          plan.values.map((value) => {
            if (value.minimum_age >= 59) {
              const item = table.find((item) => item.title === `59 +`);
              if (item) {
                values.push({
                  age_id: value.id,
                  plan_id: value.plan_id,
                  age: `${value.minimum_age} - ${value.maximum_age}`,
                  plan_value: value.plan_value,
                  owners: item.holder,
                  dependents: item.dependent
                });
              }
            } else {
              const item = table.find(
                (item) => item.title === `${value.minimum_age} - ${value.maximum_age}`
              );
              if (item) {
                values.push({
                  age_id: value.id,
                  plan_id: value.plan_id,
                  age: `${value.minimum_age} - ${value.maximum_age}`,
                  plan_value: value.plan_value,
                  owners: item.holder,
                  dependents: item.dependent
                });
              }
            }
          });

          plansValues.push({
            id: plan.plan_id,
            title: plan.title,
            total_value: plan.total,
            plan_values: values
          });
        }
      });

      const data = {
        email: form.email,
        phone: form.phone,
        simulation_type: 'broker',
        broker_slug: Broker.broker.slug,
        plan_values: plansValues
      };

      createSimulationData(data)
        .then((response) => {
          if (form.contact === 'E-mail') {
            sendSimulationData({
              simulation_id: response.data.order.id,
              email: response.data.order.email
            });
          } else {
            setdownloadLink(response.data.order.jwt_token);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setModalContact(false);
          setModalSuccess(true);
          setLoadingSendSimulation(false);
        });
    } else {
      setAlertData({
        show: true,
        title: 'Atenção!',
        type: 'warning',
        message: `O campo de ${
          form.contact === 'Whatsapp' ? ' Whatsapp' : ' e-mail'
        } deve ser preenchido para prosseguir com a simulação.`
      });
      setTimeout(() => {
        setAlertData({
          show: false
        });
      }, 5500);
    }
  }

  function downloadSimulation() {
    if (!checkPlansChecked()) {
      setLoadingSendSimulation(true);
      const plansItems = [];

      form.plans.map((plan) => {
        table.map((item) => {
          if (plan.checked) {
            if (item.holder !== 0 || item.dependent !== 0) {
              plansItems.push({
                age: item.title,
                plan: plan.title,
                owners: item.holder,
                dependents: item.dependent,
                value: plan.value,
                totalValue: plan.total,
                plan_id: plan.plan_id
              });
            }
          }
        });
      });

      console.log('table: ', table);

      const plansValues = [];
      form.plans.map((plan) => {
        if (plan.checked) {
          const values = [];
          plan.values.map((value) => {
            if (value.minimum_age >= 59) {
              const item = table.find((item) => item.title === `59 +`);
              if (item) {
                values.push({
                  age_id: value.id,
                  plan_id: value.plan_id,
                  age: `${value.minimum_age} - ${value.maximum_age}`,
                  plan_value: value.plan_value,
                  owners: item.holder,
                  dependents: item.dependent
                });
              }
            } else {
              const item = table.find(
                (item) => item.title === `${value.minimum_age} - ${value.maximum_age}`
              );
              if (item) {
                values.push({
                  age_id: value.id,
                  plan_id: value.plan_id,
                  age: `${value.minimum_age} - ${value.maximum_age}`,
                  plan_value: value.plan_value,
                  owners: item.holder,
                  dependents: item.dependent
                });
              }
            }
          });

          plansValues.push({
            id: plan.plan_id,
            title: plan.title,
            total_value: plan.total,
            plan_values: values
          });
        }
      });

      const data = {
        email: form.email,
        phone: form.phone,
        simulation_type: 'broker',
        broker_slug: Broker.broker.slug,
        plan_values: plansValues
      };

      createSimulationData(data).then((response) => {
        getSimulationPDF({
          simulation_id: response.data.order.id
        })
          .then((response) => {
            const url = response.data.simulation_pdf;
            axios({
              url,
              method: 'GET',
              responseType: 'blob',
              headers: {
                'Content-Type': 'application/pdf'
              }
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'Simulação.pdf');
              document.body.appendChild(link);
              link.click();
              setLoadingSendSimulation(false);
            });
          })
          .catch((error) => console.error(error));
      });
    } else {
      setAlertData({
        show: true,
        title: 'Atenção!',
        type: 'warning',
        message: 'Você deve selecionar ao menos um plano para gerar uma simulação.'
      });
      setTimeout(() => {
        setAlertData({
          show: false
        });
      }, 5500);
    }
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const brokerParamsName = urlParams.get('corretor');
      sessionStorage.setItem('@brokerParams', brokerParamsName);
      sessionStorage.setItem('@brokerRoute', 'criar pedido');
      setParams(brokerParamsName);
    }
    return '';
  }

  function handleInitProposal() {
    const plansItems = [];

    form.plans.map((plan) => {
      if (plan.checked) {
        plansItems.push(plan);
      }
    });

    sessionStorage.setItem('@simulationTable', JSON.stringify(table));
    sessionStorage.setItem('@simulationPlans', JSON.stringify(plansItems));
    navigate(`/proposta/home?corretor=${Broker.broker.slug}`);
  }

  useEffect(() => {
    sessionStorage.clear();
    getPlans();
    getParams();
    getPdf();
  }, []);

  return (
    <>
      <Layout
        navbar={true}
        onClick={() => {
          sessionStorage.clear();
          navigate('/');
        }}
      >
        {alertData.show && (
          <Alert type={alertData.type} title={alertData.title} text={alertData.message} />
        )}

        <Modal
          isOpen={modalContact}
          title="Enviar simulação em PDF"
          description="Escolha uma das opções abaixo para enviar a simulação em PDF"
          height="355px"
          width="670px"
          onClickClose={() => {
            setModalContact(false);
          }}
        >
          <Spacing size="xxx-large" />
          <Row width="100%" position="centered">
            <Select
              width="100%"
              label="Selecione uma opção"
              data={contact}
              value={form.contact}
              onClick={(event) => {
                setForm({ ...form, contact: event.value });
              }}
            />
          </Row>
          <Spacing size="xxx-small" />
          {form.contact && (
            <Row width="100%" position="centered">
              {form.contact === 'Whatsapp' ? (
                <Input
                  name="whatsapp"
                  type="text"
                  width="100%"
                  value={form.phone}
                  label="Whatsapp"
                  maxLength={15}
                  onChange={(event) => {
                    setForm({ ...form, phone: maskPhone(event.target.value) });
                  }}
                />
              ) : (
                <Input
                  name="email"
                  type="text"
                  width="100%"
                  value={form.email}
                  label="E-mail"
                  onChange={(event) => {
                    setForm({ ...form, email: event.target.value });
                  }}
                />
              )}
            </Row>
          )}
          <Row>
            <Button
              width="250px"
              text="Enviar simulação em PDF"
              loading={loadingSendSimulation}
              appearance={form.contact || !loadingSendSimulation ? 'primary' : 'disabled'}
              onClick={() => {
                if (form.contact || !loadingSendSimulation) {
                  saveSimulation();
                }
              }}
            />
          </Row>
        </Modal>

        <Modal
          isOpen={modalSuccess}
          title=""
          description=""
          width="670px"
          onClickClose={() => setModalSuccess(false)}
        >
          <Row width="100%" position="centered">
            <Column width="100%" position="centered" justifyContent="center" paddingRight="0px">
              <img src={checkBlue} style={{ width: '70px' }} alt="" />
              <Spacing size="xx-small" />
              <Title appearance="primary" bolder={true}>
                {form.contact === 'Whatsapp' ? 'Simulação criada!' : 'Simulação enviada!'}
              </Title>
              <Spacing size="xx-small" />
              <Text>
                {form.contact === 'Whatsapp' ? (
                  <Row position="centered">
                    <Spacing size="small" />
                    <a
                      href={`https://wa.me//55${maskReset(
                        form.phone
                      )}?text=Olá,%20Segue%20o%20link%20da%20sua%20simulação:%20${downloadLink}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button width="fit-content" text="Enviar simulação" />
                    </a>
                  </Row>
                ) : (
                  'Uma simulação detalhada foi enviada para o e-mail informado.'
                )}
              </Text>
              <Spacing size="xxx-large" />
            </Column>
          </Row>
          <Spacing size="xxx-large" />
        </Modal>

        <Spacing size="medium" />
        <Title appearance="primary" bolder={true}>
          Encontre o plano de saúde ideal com o nosso simulador
        </Title>
        <Spacing size="xxx-small" />
        <Text maxWidth="98%">
          Para simular, basta selecionar um ou mais planos e inserir a quantidade de vidas por faixa
          etária.
        </Text>
        <Text bolder={true} maxWidth="98%">
          Saiba na hora o valor e ainda compare com outros planos de forma simples e prática
        </Text>
        <Spacing size="small" />
        <div className={styles.card}>
          {/* <Row width="100%" position="centered">
            <Title bolder={true}>Simulador de Plano</Title>
          </Row>
          <Spacing size="x-small" /> */}
          {!concluded ? (
            <>
              <Row width="98%" position="start" align="start">
                <Text>Selecione até 8 planos para esta simulação</Text>
              </Row>
              <Spacing size="xx-small" />
              <div className={styles.row}>
                {form.plans.length > 0 &&
                  form.plans.map((plan, key) => (
                    <div key={key} className={`${styles.column} ${styles.plans}`}>
                      <div className={styles.field}>
                        <select
                          name="plans"
                          id="plans"
                          value={plan.title}
                          className={styles.select}
                          onChange={(event: any) => {
                            const selected = JSON.parse(event.target.value);

                            let ambulatorial = 0;
                            let global = 0;
                            let odonto = 0;
                            form.plans.map((plan) => {
                              // eslint-disable-next-line default-case
                              switch (plan.type) {
                                case 'ambulatorial':
                                  ambulatorial += 1;
                                  break;
                                case 'global':
                                  global += 1;
                                  break;
                                case 'odontológico':
                                  odonto += 1;
                                  break;
                              }
                            });

                            if (selected.type === 'ambulatorial' && ambulatorial === 3) {
                              setAlertData({
                                show: true,
                                title: 'Atenção!',
                                type: 'warning',
                                message:
                                  'A quantidade máxima de planos do tipo Ambulatorial foi atingida, por favor selecione um plano de outro tipo ou altere algum dos já selecionados'
                              });
                              setTimeout(() => {
                                setAlertData({
                                  show: false
                                });
                              }, 5500);
                            } else if (selected.type === 'global' && global === 3) {
                              setAlertData({
                                show: true,
                                title: 'Atenção!',
                                type: 'warning',
                                message:
                                  'A quantidade máxima de planos do tipo Global foi atingida, por favor selecione um plano de outro tipo ou altere algum dos já selecionados'
                              });
                              setTimeout(() => {
                                setAlertData({
                                  show: false
                                });
                              }, 5500);
                            } else if (selected.type === 'odontológico' && odonto === 3) {
                              setAlertData({
                                show: true,
                                title: 'Atenção!',
                                type: 'warning',
                                message:
                                  'A quantidade máxima de planos do tipo Odontológico foi atingida, por favor selecione um plano de outro tipo ou altere algum dos já selecionados'
                              });
                              setTimeout(() => {
                                setAlertData({
                                  show: false
                                });
                              }, 5500);
                            } else {
                              form.plans.map((elem) => {
                                if (elem.id === plan.id) {
                                  elem.title = selected.title;
                                  elem.plan_id = selected.id;
                                  elem.attributes = selected.attributes;
                                  elem.type = selected.type;
                                  elem.coparticipation = selected.coparticipation;
                                  elem.values = selected.values;
                                }
                              });
                              setForm({ ...form });

                              const selectedPlansId = [];
                              form.plans.map((plan) => {
                                selectedPlansId.push(plan.plan_id);
                              });

                              plans.map((plan) => {
                                if (selectedPlansId.includes(plan.id)) {
                                  plan.status = true;
                                } else {
                                  plan.status = false;
                                }
                              });
                              setPlans(plans);
                            }
                          }}
                        >
                          <option value="" disabled>
                            {plan.title === '' ? 'Selecione um plano' : plan.title}
                          </option>
                          {plan.title !== '' && <option value={plan.title}>{plan.title}</option>}
                          {plans.map((plan, key) => {
                            if (plan.status === false) {
                              return (
                                <option key={key} value={JSON.stringify(plan)}>
                                  {plan.title}
                                </option>
                              );
                            }
                          })}
                        </select>
                        <img src={chevronDown} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
              <Spacing size="x-small" />
              <div className={`${styles.row} ${styles.end}`}>
                <Button
                  width="fit-content"
                  size="sm"
                  appearance={form.plans.length < 8 ? 'ghost' : 'disabled'}
                  text="Adicionar mais planos"
                  onClick={() => {
                    if (form.plans.length < 8) {
                      form.plans.push({
                        id: form.plans.length + 1,
                        checked: false,
                        title: '',
                        subtitle: '',
                        plan_id: 0,
                        type: '',
                        value: '',
                        attributes: [],
                        values: [],
                        coparticipation: [],
                        total: 0
                      });
                      setForm({ ...form });
                    }
                  }}
                  iconPosition="left"
                >
                  <FaPlus />
                </Button>
              </div>
              <Spacing size="medium" />
              <Table
                width={width}
                ages={table}
                onChangeHolder={(id, value) => {
                  const age = table.find((item) => item.id === id);
                  age.holder = value;
                  setTable([...table]);
                }}
                onChangeDependent={(id, value) => {
                  const age = table.find((item) => item.id === id);
                  age.dependent = value;
                  setTable([...table]);
                }}
              />
              <Spacing size="medium" />
              <Row width="98%" position="centered">
                <Button
                  width={width >= 500 ? '350px' : '80%'}
                  text="Simular"
                  onClick={() => handleSimulation()}
                />
              </Row>
            </>
          ) : loading ? (
            <>
              <Row width="100%" align="center">
                <Column width="100%" position="centered">
                  <Spinner show={true} />
                  <Spacing size="xx-small" />
                  <Description appearance="primary" bolder={true}>
                    Carregando os dados
                  </Description>
                </Column>
              </Row>
              <Spacing size="x-large" />
            </>
          ) : (
            <>
              <div className={styles.row}>
                <Button
                  width="230px"
                  appearance="ghost"
                  text="Gerar nova simulação"
                  onClick={resetSimulation}
                  iconPosition="left"
                >
                  <img src={chevron} alt="" />
                </Button>
              </div>
              <Spacing size="xxx-large" />
              <Row width="98%" position="start" align="start">
                <Text bolder={true}>Simulação de Planos</Text>
              </Row>
              <Spacing size="x-small" />
              <Row width="98%" position="start" align="start">
                <Text>Selecione abaixo os planos para compor a simulação:</Text>
              </Row>
              <Spacing size="large" />
              <div className={styles.plans}>
                {form.plans.map((plan, key) => (
                  <div className={`${styles.plan} ${styles[plan.type]}`} key={key}>
                    <div className={styles.header}>
                      {plan.checked ? (
                        <img
                          src={checked}
                          className={styles.toggle}
                          alt="checked"
                          onClick={() => {
                            plan.checked = !plan.checked;
                            setForm({ ...form });
                          }}
                        />
                      ) : (
                        <img
                          src={unchecked}
                          className={styles.toggle}
                          alt="unchecked"
                          onClick={() => {
                            plan.checked = !plan.checked;
                            setForm({ ...form });
                          }}
                        />
                      )}
                      <div>
                        <p className={styles.title}>{plan.title}</p>
                        <p className={styles.subtitle}>{plan.subtitle}</p>
                      </div>
                    </div>

                    <div className={styles.body}>
                      {plan.attributes.map((atribute, key) => (
                        <div className={styles.atributes} key={key}>
                          <img
                            src={check}
                            style={{
                              width: '15px',
                              marginRight: '8px',
                              color: 'green'
                            }}
                          />
                          <p>{atribute.attribute_name}</p>
                        </div>
                      ))}
                    </div>

                    <div className={styles.footer}>
                      <p className={styles.ticket}>
                        <i>Ticket Médio R$ {maskMoneyPlans(plan.total / beneficiaries)}</i>
                      </p>
                      <Spacing size="small" />
                      <p className={styles.total}>Total R$ {maskMoneyPlans(plan.total)}</p>
                    </div>
                  </div>
                ))}
              </div>
              <Spacing size="xxx-large" />
              <Spacing size="xxx-large" />
              <Row width="100%" position="centered">
                <Button
                  text="Enviar simulação"
                  onClick={() => {
                    if (checkPlansChecked()) {
                      setAlertData({
                        show: true,
                        title: 'Atenção!',
                        type: 'warning',
                        message: 'Você deve selecionar ao menos um plano para fazer a simulação.'
                      });
                      setTimeout(() => {
                        setAlertData({
                          show: false
                        });
                      }, 5500);
                    } else {
                      setModalContact(true);
                    }
                  }}
                />
              </Row>
              <Spacing size="medium" />
              <Row width="100%" position="centered">
                <Button
                  appearance={loadingSendSimulation ? 'disabled' : 'ghost-borded'}
                  text="Baixar Simulação em PDF"
                  loading={loadingSendSimulation}
                  onClick={() => {
                    if (!loadingSendSimulation) {
                      downloadSimulation();
                    }
                  }}
                >
                  <img src={download} alt="" />
                </Button>
              </Row>
              <Spacing size="medium" />
              <Row width="100%" position="centered">
                <Button
                  appearance="ghost-borded"
                  text="Gerar uma proposta"
                  onClick={() => {
                    if (checkPlansChecked()) {
                      setAlertData({
                        show: true,
                        title: 'Atenção!',
                        type: 'warning',
                        message: 'Você deve selecionar ao menos um plano para fazer a simulação.'
                      });
                      setTimeout(() => {
                        setAlertData({
                          show: false
                        });
                      }, 5500);
                    } else {
                      handleInitProposal();
                    }
                  }}
                />
              </Row>
              <Spacing size="medium" />
              <Row position="end" width="99%">
                <Button
                  text="Informativo Institucional"
                  appearance="ghost"
                  iconPosition="left"
                  width="240px"
                  onClick={() => InstitutionalInformationDownload()}
                >
                  <FaDownload />
                </Button>
              </Row>
            </>
          )}
        </div>
        {/* <Spacing size="large" /> */}
      </Layout>
      {/* <Footer /> */}
    </>
  );
}

export default SimulationConsultant;
