import { Column, Input, Spacing } from 'components';
import { useProposalContext } from 'context/ProposalContext';
import { useEffect, useState } from 'react';
import { maskMoneyPlans } from 'utils/mask';
import styles from './styles.module.scss';

function Table({
  content,
  age,
  owners,
  dependents,
  index,
  onChangeOwners,
  onChangeDependents,
  plan,
  planValue,
  totalValue
}: any) {
  const { formPlans } = useProposalContext();
  const [hasHolders, setHasHolders] = useState(false);

  function correctPlanId() {
    if (content) {
      const result = content.find(({ value }) => value === plan);
      return result;
    } else {
      return 0;
    }
  }

  function maskMoneyPlansLocal(value) {
    if (value) {
      return `R$ ${value}`;
    }
  }

  function getTablePlans() {
    const table = JSON.parse(sessionStorage.getItem('@table'));
    if (table) {
      const result = table.find((item) => item.plan === plan);
      if (result) {
        let holder = 0;
        result.dependents.map((dependent) => {
          holder += dependent.owners;
        });
        if (holder >= 1) {
          setHasHolders(false);
        }
      }
    }
  }

  useEffect(() => {
    const newValue = [...formPlans];
    const planId = correctPlanId();
    // getTablePlans();
    newValue[index].plan_id = planId?.id;
  }, [owners, age, plan, dependents, totalValue]);

  return (
    <div className={styles.container} id={`plan_row_${plan}`}>
      <div className={styles.content}>
        <div className={styles.row}>
          <Column>
            <Input name="plan" type="text" width="300px" value={plan} disabled />
          </Column>
          <Column width="100px">
            <Input name="age" type="text" width="90px" value={age} disabled />
          </Column>
          <Column width="50%">
            <Input
              name="owners"
              type="number"
              width="100%"
              position="center"
              value={owners}
              onChange={(event) => {
                if (event.target.value.charAt(0) === '0') {
                  event.target.value = event.target.value.substring(1);
                }
                if (event.target.value === '') {
                  event.target.value = '0';
                }
                if (Number(event.target.value) >= 0 && Number(event.target.value) <= 1000) {
                  onChangeOwners(parseInt(event.target.value, 10));
                }
              }}
            />
          </Column>
          <Column width="50%">
            <Input
              name="dependents"
              type="number"
              width="100%"
              position="center"
              value={dependents}
              disabled={hasHolders}
              onChange={(event) => {
                if (event.target.value.charAt(0) === '0') {
                  event.target.value = event.target.value.substring(1);
                }
                if (event.target.value === '') {
                  event.target.value = '0';
                }
                if (Number(event.target.value) >= 0 && Number(event.target.value) <= 1000) {
                  onChangeDependents(parseInt(event.target.value, 10));
                }
              }}
            />
          </Column>
          <Column>
            <Input
              name="planValue"
              type="text"
              width="100%"
              placeholder={maskMoneyPlansLocal(planValue)}
              disabled={true}
            />
          </Column>
          <Column>
            <Input
              name="totalValue"
              type="text"
              width="100%"
              placeholder={maskMoneyPlansLocal(maskMoneyPlans(Number(totalValue)))}
              disabled={true}
            />
          </Column>
        </div>
      </div>
    </div>
  );
}

export default Table;
