/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-constructed-context-values */
import { getBroker } from 'api/services/ApiCalls';
import { Alert } from 'components';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const NavigationContext = createContext({} as any);

export function NavigationProvider({ children }) {
  const [broker, setBroker] = useState(null);
  const [params, setParams] = useState('');
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  async function getBrokerByParams(brokerP: string) {
    try {
      const response = await getBroker(brokerP);
      setBroker(response.data.broker);
    } catch (error) {
      navigate('/');
      setError(true);
    }
  }

  useEffect(() => {
    const brokerParams = sessionStorage.getItem('@brokerParams');
    if (brokerParams) {
      getBrokerByParams(brokerParams);
    }
    if (params !== '') {
      getBrokerByParams(params);
    }
  }, [params]);
  const Broker = useMemo(() => ({ broker }), [broker]);

  return (
    <NavigationContext.Provider value={{ Broker, setParams }}>
      {children}
      {error && (
        <Alert
          title="Erro ao buscar corretor"
          text="O corretor não foi encontrado em nosso banco de dados, por favor verifique o link e tente novamente."
          type="error"
        />
      )}
    </NavigationContext.Provider>
  );
}

export function useNavigationContext() {
  const context = useContext(NavigationContext);

  return context;
}
