import React from 'react';
import { Card, Row, Column, Description } from '..';
import styles from './styles.module.scss';

function Table({ width = '95%', columns, children, appearance = 'grey', bolder = false }: any) {
  return (
    <Card appearance="ghost" width={width} padding="0px">
      <div className={styles.header}>
        <Row>
          {columns.map((elem, index) => (
            <Column key={index} width={elem.size} position={elem.position}>
              <Description appearance="primary" bolder={true}>
                {elem.title}
              </Description>
            </Column>
          ))}
        </Row>
      </div>
      <div className={styles.body}>{children}</div>
    </Card>
  );
}

export default Table;
