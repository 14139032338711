import { useEffect, useRef, useState } from 'react';
import Column from 'components/Column';
import Row from 'components/Row';
import Button from 'components/Button';
import uploadIcon from '../../assets/icons/upload-blue.svg';
import styles from './styles.module.scss';

export default function DocumentUpload({ file, setFile, placeholder = '' }) {
  const hiddenFileInput = useRef(null);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <Row width="100%" position="start" align="start">
      <label className={styles.fileBox}>
        <Column position="start" width="100%" htmlFor="arquivo" paddingRight="0px">
          <input
            type="file"
            // value={file}
            onChange={setFile}
            className={styles.fileInput}
          />
          {!file ? (
            <p className={styles.placeholder}>{placeholder}</p>
          ) : (
            <p className={styles.placeholder}>
              <b>{placeholder}</b>
            </p>
          )}
        </Column>
        <div style={{ width: '20px', backgroundColor: 'white' }} />
        <Button
          text="Upload"
          iconPosition="left"
          width={width >= 1000 ? '246px' : '100%'}
          appearance="ghost-borded"
          onClick={handleClick}
        >
          <img src={uploadIcon} />
        </Button>
        <input type="file" onChange={setFile} ref={hiddenFileInput} style={{ display: 'none' }} />
      </label>
    </Row>
  );
}
