import { useEffect, useState } from 'react';
import { getOrderData } from 'api/services/ApiCalls';
import { maskCPF, maskCnpj } from 'utils/mask';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import {
  Card,
  Column,
  Description,
  Feedback,
  Layout,
  Row,
  Spacing,
  Spinner,
  Text,
  Title
} from '../../components';
import styles from './styles.module.scss';
import feedback from '../../assets/conclusion-feedback.png';

import checkicon from '../../assets/icons/check-icon.png';
import clockicon from '../../assets/icons/clock-icon.png';
import warningicon from '../../assets/icons/warning-icon.png';
import erroricon from '../../assets/icons/error-icon.png';

function ReviewBeneficiariesConsultant() {
  const navigate = useNavigate();
  const [order, setOrder] = useState<any>();
  const [holders, setHolders] = useState([]);
  const [holdersWaiting, setHoldersWaiting] = useState([]);

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getData(orderData) {
    getOrderData(orderData.order_id)
      .then((response) => {
        console.log('response: ', response.data);
        setOrder(response.data.order_data.order);
        const holdersWaiting = [];
        const holdersProcessing = [];
        response.data.order_data.order?.holders.map((holder) => {
          if (holder.status === 0 || holder.status === 1) {
            holdersWaiting.push(holder);
          } else {
            holdersProcessing.push(holder);
          }
        });
        setHoldersWaiting(holdersWaiting);
        setHolders(holdersProcessing);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);
      console.log(jwtParsed);
      getData(jwtParsed);
    }
  }, []);

  return order ? (
    order.status === 15 || order.status === 6 ? (
      <Layout
        navbar={true}
        verticalCentered
        steps={[
          { title: 'Dados da empresa', status: 'concluded' },
          { title: 'Contrato', status: 'concluded' },
          { title: 'Cadastro Beneficiários', status: 'concluded' },
          { title: 'Análise Beneficiários', status: 'inProgress' },
          { title: 'Implantação', status: 'pending' }
        ]}
      >
        <Spacing size="medium" />
        <Title appearance="primary" bolder={true}>
          Analise as informações recebidas
        </Title>
        <Spacing size="x-small" />
        <Text>
          Acompanhe aqui o envio de documentação dos beneficiários. Para analisar a documentação,
          basta clicar em “visualizar informações”.
        </Text>
        <Spacing size="xx-large" />
        <Card appearance="ghost" width="97%">
          <Row position="start">
            <Text bolder={true}>Informações da empresa</Text>
          </Row>
          <Spacing size="medium" />
          <Row width="100%">
            <Column>
              <Description textAlign="start">Razão Social</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true} textAlign="start">
                {order.company_name}
              </Text>
            </Column>
            <Column>
              <Description textAlign="start">CNPJ</Description>
              <Spacing size="xxx-small" />
              <Text bolder={true} textAlign="start">
                {maskCnpj(order.cnpj)}
              </Text>
            </Column>
          </Row>
        </Card>

        <Spacing size="xx-large" />
        <Row width="97%" position="start">
          <Text bolder={true} padding="0px 6px">
            Cadastros em Análise
          </Text>
        </Row>
        <Spacing size="x-small" />
        <Card appearance="ghost" width="97%" padding="0px">
          <div className={styles.header}>
            <Column width="150px">
              <Description textAlign="start" appearance="primaryDark">
                Documentação
              </Description>
            </Column>
            <Column width="200px">
              <Description textAlign="start" appearance="primaryDark">
                CPF
              </Description>
            </Column>
            <Column width="200px">
              <Description textAlign="start" appearance="primaryDark">
                Nome
              </Description>
            </Column>
            <Column width="250px">
              <Description textAlign="start" appearance="primaryDark">
                Contato
              </Description>
            </Column>
            <Column width="230px">
              <Description textAlign="start" appearance="primaryDark">
                Ações
              </Description>
            </Column>
          </div>
          {holders.length > 0 ? (
            holders.map((elem, key) => (
              <div className={styles.body} key={key}>
                <Column width="150px">
                  <div className={styles.documentation}>
                    {elem.status === 0 && (
                      <>
                        <img src={warningicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Aguardando</Description>
                      </>
                    )}
                    {elem.status === 1 && (
                      <>
                        <img src={clockicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">DPS</Description>
                      </>
                    )}
                    {elem.status === 2 && (
                      <>
                        <img src={clockicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Envio da Documentação</Description>
                      </>
                    )}
                    {elem.status === 3 && (
                      <>
                        <img src={warningicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Em Análise</Description>
                      </>
                    )}
                    {elem.status === 4 && (
                      <>
                        <img src={clockicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Aguardando Reenvio</Description>
                      </>
                    )}
                    {elem.status === 5 && (
                      <>
                        <img src={clockicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Reenviado</Description>
                      </>
                    )}
                    {elem.status === 6 && (
                      <>
                        <img src={checkicon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Aprovado</Description>
                      </>
                    )}
                    {elem.status === 7 && (
                      <>
                        <img src={erroricon} />
                        <Spacing size="xxx-small" />
                        <Description textAlign="start">Cancelado</Description>
                      </>
                    )}
                  </div>
                </Column>
                <Column width="200px">
                  <Text textAlign="start" appearance="grey">
                    {maskCPF(elem.cpf)}
                  </Text>
                </Column>
                <Column width="200px" paddingRight="0px">
                  <Text textAlign="start">{elem.name}</Text>
                </Column>
                <Column width="250px" justifyContent="start" position="start">
                  <p className={styles.beneficiarieEmail}>{elem.email}</p>
                </Column>
                <Column width="230px">
                  <Text
                    textAlign="start"
                    bolder={true}
                    pointer={true}
                    appearance="primary"
                    onClick={() => {
                      sessionStorage.setItem('@holder', elem.id);
                      navigate('/revisao/titular/consultor');
                    }}
                  >
                    Visualizar Informações <FaChevronRight style={{ marginLeft: '7px' }} />
                  </Text>
                </Column>
              </div>
            ))
          ) : (
            <Card appearance="ghost" width="100%">
              <Row>
                <Text maxWidth="240px" textAlign="center">
                  Você ainda não possui documentos para análise
                </Text>
              </Row>
            </Card>
          )}
        </Card>
        <Spacing size="xx-large" />

        <Row width="97%" position="start">
          <Text bolder={true} padding="0px 6px">
            Cadastros Pendentes
          </Text>
        </Row>
        <Spacing size="x-small" />
        <Card appearance="ghost" width="97%" padding="0px">
          <div className={styles.header}>
            <Column width="190px">
              <Description textAlign="start" appearance="primaryDark">
                CPF
              </Description>
            </Column>
            <Column width="200px">
              <Description textAlign="start" appearance="primaryDark">
                Nome
              </Description>
            </Column>
            <Column width="190px">
              <Description textAlign="start" appearance="primaryDark">
                Dependentes
              </Description>
            </Column>
            <Column width="240px">
              <Description textAlign="start" appearance="primaryDark">
                Contato
              </Description>
            </Column>
            <Column width="270px" position="end">
              <Description textAlign="start" appearance="primaryDark">
                Documentação
              </Description>
            </Column>
          </div>
          {holdersWaiting.length > 0 ? (
            holdersWaiting.map((elem, key) => (
              <div className={styles.body} key={key}>
                <Column width="190px">
                  <Text textAlign="start" bolder={true}>
                    {elem.cpf}
                  </Text>
                </Column>
                <Column width="260px">
                  <p className={styles.beneficiarieEmail}>{elem.name}</p>
                </Column>
                <Column width="130px">
                  <Text textAlign="start">{elem?.dependents ? elem?.dependents?.length : 0}</Text>
                </Column>
                <Column width="300px" justifyContent="start" position="start">
                  <p className={styles.beneficiarieEmail}>{elem.email}</p>
                </Column>
                <Column width="200px">
                  <div className={styles.documentation}>
                    <img src={warningicon} alt="warning icon" />
                    <Description>Aguardando envio de documentação</Description>
                  </div>
                </Column>
              </div>
            ))
          ) : (
            <Card appearance="ghost" width="100%">
              <Row>
                <Text maxWidth="240px" textAlign="center">
                  Você não possui cadastros pendentes
                </Text>
              </Row>
            </Card>
          )}
        </Card>

        <Spacing size="xxx-large" />
      </Layout>
    ) : (
      <Layout navbar={true} verticalCentered>
        <Feedback
          title="Não há Beneficiários Cadastrados!"
          image={feedback}
          description="Os beneficiários desta proposta ainda não foram cadastrados."
        />
      </Layout>
    )
  ) : (
    <Row width="97%" align="center">
      <Spacing size="xxx-large" />
      <Column width="100%" position="centered">
        <Spacing size="xxx-large" />
        <Spinner show={true} />
        <Spacing size="medium" />
        <Description appearance="primary" bolder={true}>
          Carregando os dados
        </Description>
      </Column>
    </Row>
  );
}

export default ReviewBeneficiariesConsultant;
