/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import RadioButton from 'components/RadioButton';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Column, Input, Row, Spacing } from '..';
import { maskCep, maskCreditCard, maskDate } from '../../utils/mask';
import styles from './styles.module.scss';

import logo from '../../assets/credit-card-flags.png';
import info from '../../assets/icons/icon-info-grey.png';
import americanExpress from '../../assets/logos/flags/logo-credit-card-flag-american-express.png';
import aura from '../../assets/logos/flags/logo-credit-card-flag-aura.png';
import diners from '../../assets/logos/flags/logo-credit-card-flag-diners.png';
import discover from '../../assets/logos/flags/logo-credit-card-flag-discover.png';
import elo from '../../assets/logos/flags/logo-credit-card-flag-elo.png';
import hipercard from '../../assets/logos/flags/logo-credit-card-flag-hipercard.png';
import jcb from '../../assets/logos/flags/logo-credit-card-flag-jcb.png';
import mastercard from '../../assets/logos/flags/logo-credit-card-flag-mastercard.png';
import visa from '../../assets/logos/flags/logo-credit-card-flag-visa.png';

function CreditCard({ width, check, onChangeCheckbox }) {
  const navigate = useNavigate();
  const [errorDate, setErrorDate] = useState(false);
  const [showModalFlags, setShowModalFlags] = useState(false);
  const [creditCardFlag, setCreditCardFlag] = useState<any>();
  const [form, setForm] = useState({
    number: '',
    name: '',
    valid_date: '',
    document: '',
    address: '',
    zip_code: '',
    city: '',
    uf: '',
    code: ''
  });

  function checkDateField(value) {
    if (value !== '' && /^(0?[1-9]|1[012])[\/\-]\d{2}$/.test(maskDate(value))) {
      setErrorDate(false);
    } else {
      setErrorDate(true);
    }
  }

  function handlePayment() {
    console.info(form);
    navigate('/pagamento/aguardando');
  }

  function getCardFlag(cardnumber: any) {
    const number = cardnumber.replace(/[^0-9]+/g, '');

    const cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const flag in cards) {
      if (cards[flag].test(number)) {
        return flag;
      }
    }

    return false;
  }

  return (
    <div className={styles.container} style={{ width }}>
      <div className={styles.row}>
        <div className={styles.title}>
          <RadioButton label="Cartão de Crédito" checked={check} onChange={onChangeCheckbox} />
          <div
            className={styles.infoButton}
            onMouseOver={() => {
              setShowModalFlags(true);
            }}
            onMouseLeave={() => {
              setShowModalFlags(false);
            }}
          >
            <img src={info} alt="" />
            {showModalFlags && (
              <div className={styles.modalFlags}>
                <img
                  src={visa}
                  alt="Bandeira Cartão de Crédito Visa"
                  className={styles.creditCardFlag}
                />
                <img
                  src={mastercard}
                  alt="Bandeira Cartão de Crédito Mastercard"
                  className={styles.creditCardFlag}
                />
                <img
                  src={americanExpress}
                  alt="Bandeira Cartão de Crédito American Express"
                  className={styles.creditCardFlag}
                />
                <img
                  src={aura}
                  alt="Bandeira Cartão de Crédito Aura"
                  className={styles.creditCardFlag}
                />
                <img
                  src={diners}
                  alt="Bandeira Cartão de Crédito Diner"
                  className={styles.creditCardFlag}
                />
                <img
                  src={discover}
                  alt="Bandeira Cartão de Crédito Discover"
                  className={styles.creditCardFlag}
                />
                <img
                  src={elo}
                  alt="Bandeira Cartão de Crédito Elo"
                  className={styles.creditCardFlag}
                />
                <img
                  src={hipercard}
                  alt="Bandeira Cartão de Crédito Hipercard"
                  className={styles.creditCardFlag}
                />
                <img
                  src={jcb}
                  alt="Bandeira Cartão de Crédito JBC"
                  className={styles.creditCardFlag}
                />
              </div>
            )}
          </div>
        </div>
        <img src={logo} alt="" className={styles.flags} />
      </div>
      {check === true && (
        <>
          <Spacing size="xxx-large" />
          <div className={styles.row}>
            <Spacing size="small" />
            <Column paddingRight={width >= 650 ? '10px' : '0px'}>
              <div className={styles.creditCardField}>
                <Input
                  name="number"
                  type="text"
                  width={width >= 650 ? '90%' : '100%'}
                  label="Número do cartão"
                  placeholder="0000 0000 0000 000"
                  maxLength={19}
                  value={maskCreditCard(form.number)}
                  onChange={(event) => {
                    setForm({ ...form, number: event.target.value });
                  }}
                  onBlur={() => {
                    const flag = getCardFlag(form.number);
                    setCreditCardFlag(flag);
                  }}
                />
                {creditCardFlag === 'visa' && (
                  <img
                    src={visa}
                    alt="Bandeira Cartão de Crédito Visa"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'mastercard' && (
                  <img
                    src={mastercard}
                    alt="Bandeira Cartão de Crédito Mastercard"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'diners' && (
                  <img
                    src={diners}
                    alt="Bandeira Cartão de Crédito Diners"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'amex' && (
                  <img
                    src={americanExpress}
                    alt="Bandeira Cartão de Crédito american Express"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'discover' && (
                  <img
                    src={discover}
                    alt="Bandeira Cartão de Crédito Discover"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'hipercard' && (
                  <img
                    src={hipercard}
                    alt="Bandeira Cartão de Crédito Hipercard"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'jcb' && (
                  <img
                    src={jcb}
                    alt="Bandeira Cartão de Crédito JBC"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'aura' && (
                  <img
                    src={aura}
                    alt="Bandeira Cartão de Crédito Aura"
                    className={styles.creditCardFlag}
                  />
                )}
                {creditCardFlag === 'elo' && (
                  <img
                    src={elo}
                    alt="Bandeira Cartão de Crédito Elo"
                    className={styles.creditCardFlag}
                  />
                )}
              </div>
              <Input
                name="name"
                type="text"
                width={width >= 650 ? '90%' : '100%'}
                label="Nome Impresso no Cartão"
                placeholder="Nome no cartão"
                value={form.name}
                onChange={(event) => {
                  setForm({ ...form, name: event.target.value });
                }}
              />
              <div className={styles.row}>
                <Column
                  width={width >= 650 ? '50%' : '100%'}
                  paddingRight={width >= 650 ? '10px' : '0px'}
                >
                  <Input
                    name="validDate"
                    type="text"
                    width="100%"
                    placeholder="Mês / Ano"
                    label="Expira em"
                    maxLength={5}
                    value={maskDate(form.valid_date)}
                    error={errorDate}
                    onChange={(event) => {
                      setForm({ ...form, valid_date: event.target.value });
                      if (event.target.value.length >= 4) {
                        checkDateField(event.target.value);
                      }
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="medium" />}
                <Column
                  width={width >= 650 ? '50%' : '100%'}
                  paddingRight={width >= 650 ? '10px' : '0px'}
                >
                  <Input
                    name="code"
                    type="text"
                    width={width >= 650 ? '82%' : '100%'}
                    placeholder="CVV"
                    label="Código de segurança"
                    value={form.code}
                    onChange={(event) => {
                      setForm({ ...form, code: event.target.value });
                    }}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className={styles.row}>
            {width >= 650 && <Spacing size="small" />}
            <Column>
              <Input
                name="document"
                type="number"
                width={width >= 650 ? '90%' : '100%'}
                label="CPF / CNPJ"
                placeholder="CPF / CNPJ"
                value={form.document}
                onChange={(event) => {
                  setForm({ ...form, document: event.target.value });
                }}
              />
            </Column>
          </div>
          <div className={styles.row}>
            {width >= 650 && <Spacing size="small" />}
            <Column>
              <div className={styles.row}>
                <Column width={width >= 650 ? '33%' : '100%'}>
                  <Input
                    name="zip_code"
                    type="text"
                    width={width >= 650 ? '90%' : '100%'}
                    label="CEP"
                    placeholder="00000-000"
                    value={maskCep(form.zip_code)}
                    onChange={(event) => {
                      setForm({ ...form, zip_code: event.target.value });
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="medium" />}
                <Column width={width >= 650 ? '33%' : '100%'}>
                  <Input
                    name="city"
                    type="text"
                    width="100%"
                    label="Cidade"
                    placeholder="Cidade"
                    value={form.city}
                    onChange={(event) => {
                      setForm({ ...form, city: event.target.value });
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="medium" />}
                <Column width={width >= 650 ? '33%' : '100%'}>
                  <Input
                    name="uf"
                    type="text"
                    width={width >= 650 ? '71%' : '100%'}
                    label="UF"
                    placeholder="UF"
                    value={form.uf}
                    onChange={(event) => {
                      setForm({ ...form, uf: event.target.value });
                    }}
                  />
                </Column>
              </div>
              <div className={styles.row}>
                <Column width={width >= 650 ? '80%' : '100%'}>
                  <Input
                    name="address"
                    type="text"
                    width="100%"
                    label="Endereço"
                    placeholder="Endereço"
                    value={form.address}
                    onChange={(event) => {
                      setForm({ ...form, address: event.target.value });
                    }}
                  />
                </Column>
                {width >= 650 && <Spacing size="medium" />}
                <Column width={width >= 650 ? '33%' : '100%'}>
                  <Input
                    name="address"
                    type="text"
                    width={width >= 650 ? '70%' : '100%'}
                    label="Número"
                    placeholder="Nª"
                    value={form.address}
                    onChange={(event) => {
                      setForm({ ...form, address: event.target.value });
                    }}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <Spacing size="x-large" />
          <Row position="end">
            <Button text="Realizar Pagamento" width="180px" onClick={handlePayment} />
          </Row>
        </>
      )}
    </div>
  );
}

export default CreditCard;
