import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback, Card, Row, Spacing } from '../../components';
import feedback from '../../assets/sulmed-feedback-sorry.png';
import errorIcon from '../../assets/icons/excplamation-icon.png';

function ErrorPayment() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Spacing size="x-large" />
      <Card width="100%" appearance="error" textAlign="start">
        <Row position="start">
          <img src={errorIcon} style={{ marginRight: '10px' }} />
          Falha ao efetuar o pagamento, revise as informações e tente novamente
        </Row>
      </Card>
      <Spacing size="large" />
      <Feedback
        title="Erro ao realizar pagamento"
        description="Houve um erro ao processar o seu cartão, pois o emissor do cartão não autorizou o pagamento. Tente novamente mais tarde."
        image={feedback}
        buttonText="Tentar novamente"
        onClickButton={() => navigate('/')}
        underbuttonText="Ir para a Página Inicial"
        onClickUnderbutton={() => navigate('/')}
      />
    </Layout>
  );
}

export default ErrorPayment;
