import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-bro.png';

function ProposalSent() {
  const navigate = useNavigate();
  const [broker, setBroker] = useState('');

  useEffect(() => {
    const broker = sessionStorage.getItem('@brokerParams');
    setBroker(broker);
  }, []);

  return (
    <Layout navbar={true} verticalCentered={true} goBackButton={false}>
      <Feedback
        title="Proposta enviada!"
        description="Seu cliente receberá a Proposta no e-mail informado. Aguarde e auxilie ele nos próximos passos para concluir o contrato."
        image={feedback}
        buttonText="Nova Cotação"
        onClickButton={() => {
          sessionStorage.clear();
          navigate(`/proposta/home?corretor=${broker}`);
        }}
      />
    </Layout>
  );
}

export default ProposalSent;
