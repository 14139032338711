import { Feedback, Layout } from 'components';
import feedback from '../../assets/conclusion-feedback.png';

export default function ReviewBeneficiariesSuccess() {
  return (
    <Layout
      navbar
      verticalCentered
      steps={[
        { title: 'Dados da empresa', status: 'concluded' },
        { title: 'Contrato', status: 'concluded' },
        { title: 'Cadastro Beneficiários', status: 'concluded' },
        { title: 'Análise Beneficiários', status: 'concluded' },
        { title: 'Implantação', status: 'inProgress' }
      ]}
    >
      <Feedback
        title="Análise Concluída!"
        image={feedback}
        description="As informações dos beneficiarios foram analisadas e concluídas. Agora entraremos na etapa de implantação."
      />
    </Layout>
  );
}
