/* eslint-disable array-callback-return */
/* eslint-disable react/no-danger */
import { getActivePackagesById } from 'api/services/ApiCalls';
import axios from 'axios';
import {
  Button,
  Card,
  Column,
  Description,
  Row,
  SearchField,
  Select,
  Spacing,
  Text,
  Undertitle
} from 'components';
import { DataContext } from 'context/DataContext';
import { useContext, useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaPlus } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { maskMoney } from 'utils/mask';
import banner from '../../assets/detail-product-banner.png';
import conexxa from '../../assets/logos/conexxa-logo-blue.png';
import logo from '../../assets/logos/conexxa-logo.png';
import unimed from '../../assets/logos/unimed-logo-green.png';
import styles from './styles.module.scss';

function fetcher(url) {
  return axios.get(url).then((res) => res.data);
}

function Detail() {
  const { id } = useParams();
  const { data, error } = useSWR(getActivePackagesById(id), fetcher);
  const navigate = useNavigate();
  const [product, setProduct] = useState({ amount: 0 });
  const [city, setCity] = useState('Porto Alegre');

  const { context, addProduct, handleData }: any = useContext(DataContext);

  function updateProductData() {
    if (data && data.packages.length > 0) {
      const index = context.products.findIndex((element) => element.id === data.packages[0].id);
      // eslint-disable-next-line no-param-reassign
      setProduct(context.products[index]);
    }
  }

  function checkProductsValue() {
    context.products.map(async (product) => {
      await getActivePackagesById(product.id)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
        })
        .catch((error) => console.error(error.response.data.error));
    });
    handleData(context);
    updateProductData();
  }

  function addProductCart() {
    if (product.amount < 13) {
      addProduct(data.packages[0]);
      updateProductData();
    }
  }

  useEffect(() => {
    if (context.products.length > 0) {
      checkProductsValue();
    }
  }, []);
  return (
    <div className={styles.container}>
      {data && data.packages ? (
        <>
          <div className={styles.navbar}>
            <img
              src={logo}
              alt="logo conexx saúde"
              draggable="false"
              onClick={() => navigate('/')}
            />
            <ul>
              <li>Planos e Serviços</li>
              <li>Procedimentos</li>
              <li>Parceiros</li>
              <li>Perguntas Frequentes</li>
            </ul>
          </div>
          <SearchField city={city} />
          <div className={styles.content}>
            <div className={styles.contentColumn}>
              <h1>{data.packages[0].name}</h1>
              <Description appearance="grey">Cód: {data.packages[0].code}</Description>
              <Spacing size="xx-small" />
              <p
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: data.packages[0].description
                }}
              />
              <Spacing size="small" />
              <Undertitle bolder={true} appearance="grey">
                Para quem é indicado e onde devo fazer?
              </Undertitle>
              <p
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: data.packages[0].indication
                }}
              />
              <Spacing size="small" />
              <Undertitle bolder={true} appearance="grey">
                Orientações gerais
              </Undertitle>
              <div dangerouslySetInnerHTML={{ __html: data.packages[0].guideline }} />
              <Spacing size="small" />
              <Undertitle bolder={true} appearance="grey">
                Onde realizar o exame?
              </Undertitle>
              <div className={styles.partners}>
                <img src={conexxa} />
                <Undertitle bolder={true}>+</Undertitle>
                <img src={unimed} />
              </div>
              <p className={styles.text}>
                A compra do teste pelo Site ou Conexxa Saúde garante a você a realização do serviço,
                que deverá ser feito presencialmente em uma das clínicas credenciadas a rede Unimed
                de Saúde.
              </p>
              <p className={styles.text}>Confira passo a passo:</p>
              <ol>
                <li>
                  Após clicar no botão Comprar, informe seu CPF, e-mail e telefone para ser
                  encaminhado para a página de pagamento.
                </li>
                <li>
                  Em seguida, faça autenticação com o código enviado para seu e-mail e via SMS.
                </li>
                <li>Escolha entre as opções de pagamento cartão de crédito, PIX ou boleto.</li>
                <li>
                  Após receber a confirmação da compra você receberá um e-mail com todas as
                  instruções para agendar a sua consulta.
                </li>
                <li>Utilize o código que será enviado por e-mail para agendar a sua consulta.</li>
              </ol>
            </div>
            <div className={`${styles.contentColumn} ${styles.resume}`}>
              <Card appearance="ghost" width="95%">
                <img src={banner} className={styles.banner} />
                <Spacing size="small" />
                <Row>
                  <Column>
                    <h2 className={styles.title}>{data.packages[0].name}</h2>
                  </Column>
                </Row>
                <Spacing size="x-small" />
                <Text appearance="grey">Cód: {data.packages[0].code}</Text>
                <Spacing size="xx-large" />
                <ul className={styles.productDetails}>
                  {data.packages[0].exams.map((exam) => (
                    <li key={exam.name}> {exam.name}</li>
                  ))}
                </ul>
                <Spacing size="xx-large" />
                <h1>R$ {maskMoney(Number(data.packages[0].value))}</h1>
                <Undertitle position="start" bolder={true}>
                  ou 2x no cartão sem juros
                </Undertitle>
                <Spacing size="xx-large" />
                <p className={styles.location}>
                  <span>
                    <FaMapMarkerAlt />
                  </span>
                  Este preço é referente a região de
                  <Select
                    label=""
                    width="25%"
                    data={[
                      {
                        title: 'Porto Alegre',
                        value: 'Porto Alegre'
                      },
                      {
                        title: 'São Leopoldo',
                        value: 'São Leopoldo'
                      },
                      {
                        title: 'Canoas',
                        value: 'Canoa'
                      },
                      {
                        title: 'Novo Hamburgo',
                        value: 'Novo Hamburgo'
                      }
                    ]}
                    value={city}
                    onClick={(data) => setCity(data.title)}
                  />
                </p>
                <Spacing size="xx-large" />
                <Button
                  text="Comprar agora"
                  width="372px"
                  appearance="primary"
                  onClick={() => {
                    addProductCart();
                    navigate('/cadastro');
                  }}
                />
                <Spacing size="large" />
                <Button
                  text="Adicionar ao carrinho"
                  width="372px"
                  appearance={product.amount < 13 ? 'ghost' : 'disabled'}
                  iconPosition="left"
                  onClick={addProductCart}
                >
                  <FaPlus />
                </Button>
              </Card>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.footerContent}>
              <div className={`${styles.footerColumn} ${styles.centered}`}>
                <img src={logo} className={styles.logo} draggable="false" />
                <Description appearance="white">
                  Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
                  resolutividade e menos gastos para todos.
                </Description>
              </div>
              <div className={`${styles.footerColumn} ${styles.end}`}>
                <div>
                  <p>Planos e Serviços</p>
                  <Spacing size="medium" />
                  <p>Procedimentos</p>
                  <Spacing size="medium" />
                  <p>Contato</p>
                  <Spacing size="medium" />
                  <p>Perguntas Frequentes</p>
                </div>
              </div>
              <div className={`${styles.footerColumn} ${styles.end}`}>
                <div>
                  <p>Planos e Serviços</p>
                  <Spacing size="medium" />
                  <p>Procedimentos</p>
                  <Spacing size="medium" />
                  <p>Contato</p>
                  <Spacing size="medium" />
                  <p>Perguntas Frequentes</p>
                </div>
              </div>
            </div>
            <Spacing size="xx-large" />
            <Row>
              <Description appearance="white">
                Todos os direitos reservados - 2023. Desenvolvido por Starta Ideia
              </Description>
            </Row>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default Detail;
