import React, { useEffect, useState } from 'react';
import { Button, Description, Spacing, Undertitle } from 'components';
import { FaRegTrashAlt } from 'react-icons/fa';
import { maskMoney } from 'utils/mask';
import Amount from '../Amount';
import styles from './styles.module.scss';

function Product({
  image,
  title,
  amount,
  description,
  productValue,
  totalValue,
  onClickDelete,
  addProduct,
  subsProduct
}) {
  const [amounts, setAmount] = useState(amount);
  const [value, setValue] = useState<any>(totalValue);

  useEffect(() => {
    setValue(parseFloat(productValue) * amounts);
  }, []);

  function addAmount() {
    if (amounts < 13) {
      const calc = parseFloat(productValue) * (amounts + 1);
      setValue(calc);
      setAmount(amounts + 1);
      addProduct(calc);
    }
  }

  function subsAmount() {
    if (amounts > 1) {
      const calc = parseFloat(productValue) * (amounts - 1);
      setValue(calc);
      setAmount(amounts - 1);
      subsProduct(calc);
    } else {
      setValue(parseFloat(productValue));
      setAmount(1);
    }
  }

  return (
    <div className={styles.container}>
      <img src={image} alt={title} />
      <div className={styles.column}>
        <Undertitle bolder={true}>{title}</Undertitle>
        <Spacing size="xx-small" />
        <Description textAlign="start">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Description>
        <Spacing size="xx-small" />
        <div className={styles.buttons}>
          <Button
            text="Excluir"
            width="90px"
            appearance="ghost"
            iconPosition="left"
            onClick={onClickDelete}
          >
            <FaRegTrashAlt />
          </Button>
          <Spacing size="medium" />
        </div>
      </div>
      <div className={styles.value}>
        <Undertitle bolder={true} appearance="primary">
          R${maskMoney(parseFloat(value))}
        </Undertitle>
        <Spacing size="xx-small" />
        <Amount amount={amounts} addAmount={addAmount} subsAmount={subsAmount} />
      </div>
    </div>
  );
}

export default Product;
