import styles from './styles.module.scss';

export function CoupomDivider({ name, description }) {
  return (
    <div className={styles.container}>
      <h5>Cupom {name} adicionado</h5>
      <p>
        <i>{description}</i>
      </p>
    </div>
  );
}
