import { Feedback, Layout } from 'components';
import feedback from '../../assets/conclusion-feedback.png';

export default function Conclusion() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Seu cadastro foi concluído!"
        image={feedback}
        description="As informações de cadastro foram enviadas para análise. Caso haja alguma inconsistências, entraremos em contato."
      />
    </Layout>
  );
}
