import { Layout, Spacing, Title, Text, Row, Feedback } from 'components';
import React from 'react';
import company_final from '../../assets/company_final.png';

export default function EnterpriseDocumentFinal() {
  return (
    <Layout navbar verticalCentered={true}>
      <Spacing size="xxx-large" />
      <Feedback
        title="Documentos enviados para análise!"
        image={company_final}
        description="A documentação da sua empresa foi enviada para análise. Em caso de inconsistência,
          entraremos em contato para novo envio. Caso tudo esteja certo, a próxima etapa será a
          Assinatura do Contrato."
      />
    </Layout>
  );
}
