import { Button, Input } from 'components';
import { useRef, useState } from 'react';

import Alert, { AlertProps } from 'components/Alert';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GoIssueClosed } from 'react-icons/go';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import styles from '../styles.module.scss';
import { TQuestionAnswer } from '../types';

type ImcProps = {
  questionsLength: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setImcForm: React.Dispatch<React.SetStateAction<TQuestionAnswer>>;
  setPending: React.Dispatch<React.SetStateAction<boolean>>;
};

function Imc({ questionsLength, setCount, setImcForm, setPending }: ImcProps) {
  const [answer, setAnswer] = useState<boolean | null>(null);
  const [imc, setImc] = useState(0);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const heightRef = useRef<HTMLInputElement>(null);
  const weightRef = useRef<HTMLInputElement>(null);

  const calcIMC = () => {
    const height = parseFloat(heightRef.current?.value || '0');
    const weight = parseFloat(weightRef.current?.value || '0');

    const imc = weight / (height * height);
    const imcRounded = Math.round(imc * 100) / 100;

    setImc(imcRounded);
    if (imcRounded > 30) setPending(true);
  };

  const handleNext = () => {
    if (!imc || imc === 0)
      return setAlert({
        type: 'warning',
        text: 'Preencha os campos',
        title: 'Atenção'
      });

    if (answer === null)
      return setAlert({ type: 'warning', text: 'Selecione uma opção', title: 'Atenção' });

    setImcForm((prev) => ({
      ...prev,
      answers: [
        {
          answer_flag: answer ? 1 : 0,
          disease: '',
          answer_description: heightRef.current?.value || '0',
          question_id: '',
          initial_question_id: 1
        },
        {
          answer_flag: answer ? 1 : 0,
          disease: '',
          answer_description: weightRef.current?.value || '0',
          question_id: '',
          initial_question_id: 2
        }
      ]
    }));
    return setCount(2);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <span className={styles.info}>Pergunta 1 de {questionsLength}</span>
        <p className={styles.title}>Índice de Massa Corpórea IMC</p>
        <p className={styles.info}>
          Parâmetro adotado pela Organização Mundial de Saúde para calcular o peso ideal de cada
          pessoa.
        </p>
        <div className={styles.inputs}>
          <Input placeholder="Altura(m)" ref={heightRef} onChange={() => calcIMC()} mask="9.99" />
          <Input placeholder="Peso(kg)" ref={weightRef} onChange={() => calcIMC()} />
          <Input placeholder="Valor de IMC" disabled value={imc} />
        </div>
        <p className={styles.confirmText}>Você concorda com o resultado do seu IMC?</p>
        <div className={styles.confirmButtons}>
          <Button
            appearance={`${answer ? 'hoved' : 'ghost'}`}
            text="Sim"
            width="fit-content"
            iconPosition="left"
            onClick={() => setAnswer(true)}
          >
            <GoIssueClosed />
          </Button>
          <Button
            appearance={`${answer === false ? 'hoved' : 'ghost'}`}
            text="Não"
            width="fit-content"
            iconPosition="left"
            onClick={() => setAnswer(false)}
          >
            <AiOutlineCloseCircle />
          </Button>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button appearance="ghost" text="Próxima" width="fit-content" onClick={handleNext}>
          <MdOutlineKeyboardArrowRight />
        </Button>
      </div>
      {alert && <Alert {...alert} />}
    </>
  );
}

export default Imc;
