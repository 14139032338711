import { Row, Spacing, Button, Text, Card, Modal, Column, Alert, Spinner } from 'components';
import React, { useState } from 'react';
import { FaDownload, FaEye } from 'react-icons/fa';
import axios from 'axios';
import { BiCloudUpload } from 'react-icons/bi';
import { resendCompanyDocument } from 'api/services/ApiCalls';
import styles from './styles.module.scss';
import file from '../../../../assets/logos/file_logo_black.png';

export default function ImageView({ orderID, docID, name, img, url, onClick }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAPDF, setIsAPDF] = useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<any>('');
  const [alert, setAlert] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<any>({
    title: '',
    type: '',
    message: ''
  });

  return (
    <>
      {alert && <Alert type={alertData.type} title={alertData.title} text={alertData.message} />}
      <Row position="start" width="98%">
        <Text appearance="grey3" bolder={true} maxWidth="100%">
          {name}
        </Text>
      </Row>
      <Spacing size="xx-small" />
      <Card width="98%" appearance="fadedGrey">
        <img src={file} style={{ width: '80px' }} />
      </Card>
      <Spacing size="small" />
      <Row position="end" width="98%">
        <Column htmlFor="arquivo">
          <div className={styles.button}>
            {loading && <Spinner show={true} color="#373373" width="25px" height="25px" />}
            <p style={{ color: '#373373' }}>
              Upload
              <BiCloudUpload style={{ marginLeft: '5px' }} />
            </p>
            <input
              type="file"
              name="arquivo"
              id="arquivo"
              className={styles.teste}
              onChange={(event: any) => {
                if (event) {
                  const reader = new FileReader();
                  reader.addEventListener('load', (e) => {
                    const readerTarget = e.target;
                    setDocument(readerTarget.result);
                  });
                  reader.readAsDataURL(event.target.files[0]);
                  setLoading(true);

                  const formData = new FormData();
                  formData.append('order_id', orderID);
                  formData.append('company_document_id', docID);
                  formData.append('company_document_file', event.target.files[0]);
                  resendCompanyDocument(formData)
                    .then(() => {
                      onClick();
                      setAlertData({
                        title: 'Sucesso!',
                        type: 'success',
                        message: 'Documento enviado com sucesso.'
                      });
                      setAlert(true);
                      setTimeout(() => {
                        setLoading(false);
                        setAlert(false);
                        setDocument('');
                      }, 5500);
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.error(error);
                    });
                }
              }}
            />
          </div>
        </Column>
        <Spacing size="xx-small" />
        <a
          id="downloadLink"
          href={url}
          target="_blank"
          type="application/octet-stream"
          download="yourpdf.pdf"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button text="Download" width="140px" appearance="ghost" loading={loading}>
            <FaDownload style={{ marginLeft: '7px' }} />
          </Button>
        </a>
        <Spacing size="small" />
        <Button
          text="Visualizar"
          width="140px"
          appearance="ghost"
          loading={loading}
          onClick={() => {
            axios.get(url).then((response) => {
              if (response.headers['content-type'] === 'application/pdf') {
                setIsAPDF(true);
                setIsOpen(true);
              } else {
                setIsOpen(true);
              }
            });
          }}
        >
          <FaEye style={{ marginLeft: '7px' }} />
        </Button>
      </Row>
      <Modal
        width="800px"
        height="600px"
        isOpen={isOpen}
        title=""
        description=""
        onClickClose={() => {
          setIsOpen(false);
        }}
      >
        <div className={styles.container}>
          <Row width="100%">
            {isAPDF ? (
              <object data={url} type="text/html" className={styles.viewer}>
                <p>Alternative text - include a link </p>
                <param name="data" value={url} />
              </object>
            ) : (
              <img src={img} className={styles.modalImage} />
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
}
