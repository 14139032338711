import React from 'react';
import { Description, Text, Spacing } from '..';

function LabelledValue({ label, value, align }: any) {
  return (
    <>
      <Description textAlign={align} appearance="grey">
        {label}
      </Description>
      <Spacing size="xxx-small" />
      <Text bolder={true} textAlign={align}>
        {value}
      </Text>
    </>
  );
}

export default LabelledValue;
