import { getDepedentByID, getOrderData, getPlanById } from 'api/services/ApiCalls';
import { Button, Card, Column, Description, Input, Layout, Row, Spacing, Text } from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertDate } from 'utils/convertDate';
import { maskCPF, maskCep, maskPhone } from 'utils/mask';

const gender = {
  M: 'Masculino',
  F: 'Feminino'
};

export default function ConfirmPersonalData() {
  const navigate = useNavigate();
  const [formDependent, setFormDependent] = useState(null);
  const [holder, setHolder] = useState(null);
  const [plan, setPlan] = useState<any>();
  const [beneficiariesAmount, setBeneficiariesAmount] = useState<any>(0);
  const [form, setForm] = useState({
    cpf: '012.234.456-00',
    name: 'João da Silva Santos',
    birthDate: '1995-11-23',
    email: 'mail@mail.com',
    telephone: '55 11 99999-8888',
    maritalStatus: 'Solteiro(a)',
    mothersName: 'Maria da Silva Santos',
    gender: 'Masculino',
    planType: 'Selecione o plano',
    dependents: '1',
    cep: '',
    street: '',
    district: '',
    number: '',
    complement: '',
    city: '',
    state: ''
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  async function getHolderData(holder) {
    await getPlanById(holder.plan_id).then((response) => {
      setPlan(response.data.plan[0]);
    });
    getOrderData(holder.order_id).then((response) =>
      setBeneficiariesAmount(response.data.order_data.order.total_beneficiaries)
    );
    getDepedentByID(holder.id)
      .then((response) => {
        setHolder(response.data.holder[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const beneficiary = JSON.parse(sessionStorage.getItem('@beneficiary'));
    getHolderData(beneficiary);
    const dependents = JSON.parse(sessionStorage.getItem('@dependents'));
    const plan_name = JSON.parse(sessionStorage.getItem('@plan_name'));
    setFormDependent(dependents);
    setForm({
      birthDate: beneficiary.birth_date,
      city: beneficiary.city,
      complement: beneficiary.complement,
      district: beneficiary.district,
      email: beneficiary.email,
      cep: beneficiary.zip_code,
      cpf: beneficiary.cpf,
      dependents: '1',
      maritalStatus: beneficiary.civil_status,
      mothersName: beneficiary.mother_name,
      gender: beneficiary.sex,
      name: beneficiary.name,
      number: beneficiary.number,
      planType: plan_name,
      state: beneficiary.state,
      street: beneficiary.street,
      telephone: beneficiary.phone
    });
  }, []);

  return (
    <Layout
      navbar={true}
      steps={[
        { title: 'Beneficiários', status: 'inProgress' },
        { title: 'Declaração de saúde', status: 'pending' },
        { title: 'Documentos', status: 'pending' },
        { title: 'Conclusão', status: 'pending' }
      ]}
    >
      <Card appearance="ghost">
        <Row align="start">
          <Description bolder={true}>Informações do titular</Description>
        </Row>

        <div style={{ paddingBottom: 24 }} />

        <Row>
          <Column>
            <Input name="cpf" type="text" value={maskCPF(form.cpf)} label="CPF" disabled={true} />
          </Column>
          <Column>
            <Input name="name" type="text" label="Nome" value={form.name} disabled={true} />
          </Column>
          <Column>
            <Input
              name="birthDate"
              type="text"
              label="Data de Nascimento"
              value={convertDate(form.birthDate)}
              disabled={true}
            />
          </Column>
        </Row>
        <Spacing size="medium" />
        <Row>
          <Column>
            <Input name="email" type="text" value={form.email} label="E-mail" disabled={true} />
          </Column>
          <Column>
            <Input
              name="telephone"
              type="text"
              label="Celular"
              value={maskPhone(form.telephone)}
              onChange={(event) => {
                setForm({ ...form, telephone: maskPhone(event.target.value) });
              }}
            />
          </Column>
          <Column>
            <Input
              name="maritalStatus"
              type="text"
              label="Estado Civil"
              value={form.maritalStatus}
            />
          </Column>
        </Row>
        <Spacing size="medium" />
        <Row>
          <Column>
            <Input
              name="mothersName"
              type="text"
              label="Nome da mãe"
              value={form.mothersName}
              disabled
            />
          </Column>
          <Column>
            <Input
              name="gender"
              type="text"
              label="Sexo"
              value={gender[form.gender.toUpperCase()]}
              disabled
            />
          </Column>
        </Row>

        <Row align="start">
          <Description bolder={true}>Tipo de Plano</Description>
        </Row>

        <Spacing size="medium" />

        <Row>
          <Column>
            <Input
              name="plantType"
              type="text"
              width={width <= 1000 ? '100%' : '63%'}
              disabled
              value={form.planType}
            />
          </Column>
        </Row>
        <div style={{ paddingBottom: 24 }} />

        <Row align="start">
          <Description bolder={true}>Endereço do titular</Description>
        </Row>

        <Spacing size="medium" />
        <Row>
          <Column>
            <Input
              name="cep"
              type="text"
              label="CEP"
              value={maskCep(form.cep)}
              onChange={(event) => {
                setForm({ ...form, cep: event.target.value });
              }}
            />
          </Column>
          <Column>
            <Input
              name="street"
              type="text"
              label="Logradouro"
              value={form.street}
              onChange={(event) => {
                setForm({ ...form, street: event.target.value });
              }}
            />
          </Column>
          <Column>
            <Input
              name="district"
              type="text"
              label="Bairro"
              value={form.district}
              onChange={(event) => {
                setForm({ ...form, district: event.target.value });
              }}
            />
          </Column>
        </Row>
        <Spacing size="small" />
        <Row>
          <Column>
            <Input
              name="number"
              type="text"
              label="Número"
              value={form.number}
              onChange={(event) => {
                setForm({ ...form, number: event.target.value });
              }}
            />
          </Column>
          <Column>
            <Input
              name="complement"
              type="text"
              label="Complemento"
              value={form.complement}
              onChange={(event) => {
                setForm({ ...form, complement: event.target.value });
              }}
            />
          </Column>
          <Column>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                name="city"
                type="text"
                label="Cidade"
                value={form.city}
                onChange={(event) => {
                  setForm({ ...form, city: event.target.value });
                }}
              />
              <div style={{ width: '70px' }} />
              <Input
                name="state"
                type="text"
                label="Estado"
                value={form.state}
                onChange={(event) => {
                  setForm({ ...form, state: event.target.value });
                }}
              />
            </div>
          </Column>
        </Row>
        <Spacing size="large" />

        {formDependent !== null && formDependent.length > 0 && (
          <>
            <Row position="left">
              <Text bolder={true}>Dependentes</Text>
            </Row>

            <Spacing size="xx-large" />
            {formDependent.map((dependente) => (
              <Column key={dependente.id}>
                <Row align="start">
                  <Text bolder={true}>{dependente.name}</Text>
                </Row>
                <Spacing size="medium" />
                <Row width="100%" position="start">
                  <Column>
                    <Input
                      name="cpf"
                      type="text"
                      value={maskCPF(dependente.cpf)}
                      label="CPF"
                      disabled={true}
                    />
                  </Column>
                  <Column>
                    <Input
                      name="dependent_birthDate"
                      type="text"
                      value={convertDate(dependente.birth_date)}
                      label="Data de Nascimento"
                      disabled={true}
                    />
                  </Column>
                  <Column>
                    <Input
                      name="dependent_name"
                      type="text"
                      value={dependente.name}
                      label="Nome"
                      disabled={true}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Input
                      name="dependent_relationship"
                      type="text"
                      value={dependente.relationship}
                      label="Relacionamento com o titular"
                      disabled={true}
                    />
                  </Column>
                </Row>
                <Spacing size="small" />
              </Column>
            ))}
          </>
        )}

        <Row>
          <Column position="end">
            <Button
              text="Continuar"
              size="sm"
              width="102px"
              onClick={() => {
                if (plan.type === 'Odontológico' || beneficiariesAmount >= 30) {
                  navigate('/envio-documentos');
                } else if (holder.status === 4) {
                  navigate('/conclusao');
                } else {
                  navigate('/declaracao-saude');
                }
              }}
            />
          </Column>
        </Row>
      </Card>
    </Layout>
  );
}
