import { Button, Row, Spacing } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

export default function Jornadas() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Spacing size="medium" />
      <Row position="start">
        <Spacing size="medium" />
        <Button appearance="primary" text="Sulmed Jornada Proposta" onClick={() => navigate('/')} />
      </Row>
      <Spacing size="xx-small" />
      <Row position="start">
        <Spacing size="medium" />
        <Button
          appearance="primary"
          text="Sulmed Jornada Beneficiario"
          onClick={() => navigate('/formulario/cadastro')}
        />
      </Row>
      <Spacing size="xx-small" />
    </div>
  );
}
