import { useState } from 'react';
import { BiXCircle } from 'react-icons/bi';
import styles from './styles.module.scss';

type ChipProps = {
  title: string;
  onClick: () => void;
};

function Chip({ title, onClick }: ChipProps) {
  const [showClose, setShowClose] = useState(false);

  return (
    <div className={styles.container}>
      <p
        onClick={onClick}
        onMouseEnter={() => setShowClose(true)}
        onMouseLeave={() => setShowClose(false)}
      >
        {title} {showClose && <BiXCircle className={styles.icon} />}
      </p>
    </div>
  );
}

export default Chip;
