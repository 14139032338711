import feedback from '../../assets/sulmed-feedback-onboarding-bro.svg';
import { Feedback, Layout } from '../../components';

function ContractSuccess() {
  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Contrato de adesão foi assinado!"
        description={
          <>
            Falta muito pouco para finalizar o processo de contratação do seu Plano de Saúde Sulmed.{' '}
            <br /> Em instantes você receberá as instruções com os próximos passos.
          </>
        }
        image={feedback}
      />
    </Layout>
  );
}

export default ContractSuccess;
