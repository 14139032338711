import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useNavigationContext } from 'context/NavigationContext';
import BrokerLogged from 'components/BrokerLogged';

import styles from './styles.module.scss';
import sulmedLogo from '../../assets/logos/sulmed-logo-white.svg';

function Navbar() {
  const { Broker } = useNavigationContext();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img src={sulmedLogo} className={styles.logo} alt="Logo" onClick={() => navigate('/')} />
      <div className={styles.separator}>
        {Broker.broker !== null && (
          <BrokerLogged brokerImg={Broker.broker.image} brokerName={Broker.broker.name} />
        )}
      </div>
    </div>
  );
}

export default Navbar;
