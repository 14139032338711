/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  getActiveCoupons,
  getActivesPlans,
  getCnpj,
  getLinkedCNPJ,
  getOrderData,
  getOrdersPlan,
  getPlanById,
  getTableCommercialByPlanId
} from 'api/services/ApiCalls';
import { useProposalContext } from 'context/ProposalContext';
import { Fragment, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import check from '../../assets/icons/check-icon.png';
import error from '../../assets/icons/error-icon.png';
import infoIcon from '../../assets/icons/info-icon.png';
import {
  Accordion,
  Alert,
  BottomNavigation,
  Button,
  Card,
  Column,
  Description,
  Input,
  Layout,
  Modal,
  Row,
  Spacing,
  Spinner,
  Text,
  Title,
  Undertitle
} from '../../components';
import { maskCnpj, maskMoneyPlans, maskPhone, maskReset } from '../../utils/mask';
import Table from './components/Table';
import styles from './components/Table/styles.module.scss';
import TableMobile from './components/TableMobile';

function Proposal() {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [att, setAtt] = useState(0);
  const [plan, setPlan] = useState();
  const [checkEInputCNPJ, setCheckInputCNPJ] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [emailExtra1, setEmailExtra1] = useState<string>('');
  const [emailExtra2, setEmailExtra2] = useState<string>('');
  const [checkEInputEmail, setCheckInputEmail] = useState(false);
  const [enterprise, setEnterprise] = useState<string>('');
  const [agent, setAgent] = useState<string>('');
  const [office, setOffice] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [checkEInputTelephone, setCheckInputTelephone] = useState(false);
  const [totalBeneficiaries, seTotalBeneficiaries] = useState<number>(0);
  const [modalProposeExists, setModalProposeExists] = useState(false);
  const [modalInfoIsOpen, setModalInfoIsOpen] = useState(false);
  const [showExtraEmails, setShowExtraEmails] = useState(false);
  const [checkInputAgent, setCheckInputAgent] = useState(false);
  const [checkEInputEnterprise, setCheckEInputEnterprise] = useState(false);
  const [coupomInvalid, setCoupomInvalid] = useState(false);
  const [checkProposalExpiration, setCheckProposalExpiration] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [subtotal, setSubtotal] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectPlans, setSelectPlans] = useState([]);
  const [coupomName, setCoupomName] = useState('');
  const [proposalExpiration, setProposalExpiration] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [coupomInformed, setCoupomInformed] = useState('');
  const [coupomDescription, setCoupomDescription] = useState('');
  const [coupomDiscount, setCoupomDiscount] = useState('');
  const [proposalValidity, setProposalValidity] = useState('');
  const [table, setTable] = useState<any>([]);
  const {
    planValues,
    handleRemovePlan,
    handleSetDiscount,
    formPlans,
    setFormPlans,
    setPlanValues,
    orderByPlan
  } = useProposalContext();
  const corretor = sessionStorage.getItem('@brokerParams');
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [alert, setAlert] = useState<any>({
    show: false,
    title: '',
    type: '',
    message: ''
  });

  async function getCoupons() {
    try {
      const response = await getActiveCoupons();
      const { coupons } = response.data;
      setCoupons(coupons);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  function getInitialPlan(initialPlan, plans) {
    const plan = plans.find(
      (elem: any) => elem.value === `${initialPlan.name} ${initialPlan.subtitle}`
    );
    getTableCommercialByPlanId(plan.id).then((response) => {
      response.data.table_commercial.map((item: any) => {
        let age = '';
        if (item.minimum_age !== 59) {
          age = `${item.minimum_age} - ${item.maximum_age}`;
        } else {
          age = `${item.minimum_age} +`;
        }
        item.age = age;
        item.plan = `${initialPlan.name} ${initialPlan.subtitle}`;
        item.owners = 0;
        item.dependents = 0;
        item.totalValue = 0;
        item.plan_id = plan.id;
      });
      setTable([
        {
          plan: plan.value,
          attributes: plan.attributes,
          coparticipation: plan.coparticipation,
          dependents: response.data.table_commercial
        }
      ]);
      const index = plans.map((e) => e.value).indexOf(plan.value);
      plans[index].status = true;
      plans[index].plan = plans[index].value;
      plans[index].dependents = response.data.table_commercial;
      setSelectPlans([...plans]);
      sessionStorage.setItem('@table', JSON.stringify([plans[index]]));
    });
  }

  function getPlans() {
    getActivesPlans()
      .then((response) => {
        const formattedPlan = response.data.plans.map((plan) => {
          const formatPlan = {
            id: plan.id,
            title: plan.name,
            value: `${plan.name} ${plan.subtitle}`,
            coparticipation: plan.co_participation,
            status: false,
            attributes: plan.plan_attributes
          };
          return formatPlan;
        });
        setPlans(formattedPlan);
        const table = JSON.parse(sessionStorage.getItem('@table'));
        if (table) {
          table.map((item) => {
            const index = formattedPlan.map((e) => e.value).indexOf(item.plan);
            formattedPlan[index].status = true;
          });
        }
        setSelectPlans(formattedPlan);
        formPlans.map((formPlan: any) => {
          formPlan.plan = formattedPlan[0]?.title;
        });
        setFormPlans(formPlans);

        const initialPlan = sessionStorage.getItem('@plan_id');
        const tableData = sessionStorage.getItem('@table');
        if (initialPlan && !tableData) {
          getPlanById(initialPlan).then((response) => {
            getInitialPlan(response.data.plan[0], formattedPlan);
          });
        }
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  async function getCnpjData(cnpj: string) {
    const response = await getCnpj(cnpj);
    setEnterprise(response.data.cnpj_data.name);
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    const userData = {
      city: response.data.cnpj_data.city,
      complement: response.data.cnpj_data.complement,
      district: response.data.cnpj_data.district,
      number: response.data.cnpj_data.number,
      state: response.data.cnpj_data.state,
      street: response.data.cnpj_data.street,
      zip_code: response.data.cnpj_data.zip_code,
      enterprise: response.data.cnpj_data.name
    };
    sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
  }

  function checkRequestedFields() {
    if (cnpj === '' || cnpj === undefined) {
      setCheckInputCNPJ(true);
    } else {
      setCheckInputCNPJ(false);
    }

    if (email === '' || email === undefined) {
      setCheckInputEmail(true);
    } else {
      setCheckInputEmail(false);
    }

    if (proposalExpiration === '' || proposalExpiration === undefined) {
      setCheckProposalExpiration(true);
    } else {
      setCheckProposalExpiration(false);
    }

    if (agent === '' || agent === undefined || agent.length < 4) {
      setCheckInputAgent(true);
    } else {
      setCheckInputAgent(false);
    }

    if (telephone === '' || telephone === undefined) {
      setCheckInputTelephone(true);
    } else {
      setCheckInputTelephone(false);
    }

    if (enterprise === '' || enterprise === undefined) {
      setCheckEInputEnterprise(true);
    } else {
      setCheckEInputEnterprise(false);
    }

    if (
      cnpj !== '' &&
      email !== '' &&
      proposalExpiration !== '' &&
      proposalExpiration !== undefined &&
      telephone !== '' &&
      telephone !== undefined &&
      agent !== '' &&
      agent !== undefined &&
      agent.length > 3 &&
      enterprise !== '' &&
      enterprise !== undefined &&
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function saveUserData() {
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    const userData = {
      cnpj,
      name,
      telephone,
      email,
      agent,
      office,
      enterprise,
      proposalExpiration,
      coupomName: user.coupomName,
      coupomDescription: user.coupomDescription,
      coupomDiscount,
      validity: proposalValidity,
      emailExtra1,
      emailExtra2,
      totalBeneficiaries,
      subtotal
    };
    sessionStorage.setItem('@userData', JSON.stringify({ ...user, ...userData }));
    sessionStorage.setItem('@plan_attributes', JSON.stringify(plans));
  }

  function handleNextPage(): void {
    const result = JSON.parse(sessionStorage.getItem('@table'));
    const verify = [];
    if (result) {
      result.map((elem) => {
        let count = 0;
        elem.dependents.map((item) => {
          count += item.owners;
        });
        verify.push(count > 0);
      });
    }

    if (!verify.includes(false)) {
      setLoading(true);
      const checkRequestFields = checkRequestedFields();
      if (checkRequestFields) {
        saveUserData();
        const plansData = JSON.parse(sessionStorage.getItem('@plansData'));
        sessionStorage.setItem('@plansData', JSON.stringify({ ...plansData, ...formPlans }));
        navigate('/proposta/resumo');
      }
      document.documentElement.scrollTo({
        top: 0,
        left: 0
      });
      setLoading(false);
    } else {
      setAlert({
        show: true,
        title: 'Atenção!',
        type: 'warning',
        message: 'Necessário informar ao menos um titular por plano para prosseguir'
      });
      setTimeout(() => {
        setAlert({
          show: false,
          title: '',
          type: '',
          message: ''
        });
      }, 5500);
    }
  }

  async function fillFields() {
    const user = JSON.parse(sessionStorage.getItem('@userData'));
    if (user) {
      setCnpj(user.cnpj);
      setEmail(user.email);
      setEmailExtra1(user.emailExtra1);
      setEmailExtra2(user.emailExtra2);
      setProposalExpiration(user.proposalExpiration);
      setEnterprise(user.enterprise);
      setAgent(user.agent);
      setOffice(user.office);
      setTelephone(user.telephone);
    }
    const teste = await getLinkedCNPJ(maskReset(user.cnpj));
    const edit = sessionStorage.getItem('@editToken');
    if (teste.data.total_orders > 0 && edit === null) {
      setModalProposeExists(true);
    }
  }

  function calcTotalMembers() {
    const result = JSON.parse(sessionStorage.getItem('@table'));
    if (result) {
      let count = 0;
      result.map((elem) => {
        elem.dependents.map((item) => {
          count = count + item.owners + item.dependents;
        });
      });
      seTotalBeneficiaries(count);
    }
  }

  function calcTotal() {
    const result = JSON.parse(sessionStorage.getItem('@table'));
    if (result) {
      let totalValues = 0;
      result.map((elem) => {
        elem.dependents.map((item) => {
          totalValues += item.totalValue;
        });
      });
      setPlanValues({
        ...planValues,
        underTotal: totalValues,
        totalValue: totalValues
      });
      sessionStorage.setItem('@totalValue', totalValues.toFixed(2).toString());
    }
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getParams() {
    const params = window.location.search;
    if (params) {
      const urlParams = new URLSearchParams(params);
      const tk = urlParams.get('t');
      const jwtParsed = parseJwt(tk);

      getOrderData(jwtParsed.order.id)
        .then((response) => {
          getActiveCoupons().then((coupon) => {
            const coupom = coupon.data.coupons.find(
              (event) => event.id === response.data.order_data.order.coupon_id
            );
            if (coupom) {
              setCoupomDescription(coupom.coupon_description);
              handleSetDiscount(coupon.data.coupons, coupom.name);

              if (coupom.type === 'V') {
                setCoupomDiscount(coupom.discount_value);
              } else {
                const discount =
                  Number(response.data.order_data.order.subtotal) *
                  (Number(coupom.discount_percentage) / 100);
                setCoupomDiscount(discount.toFixed(2));
              }
              setCoupomInvalid(false);

              const user = JSON.parse(sessionStorage.getItem('@userData'));
              sessionStorage.setItem(
                '@userData',
                JSON.stringify({
                  ...user,
                  coupomName: coupom?.name,
                  coupomDescription: coupom?.coupon_description,
                  coupomDiscount
                })
              );
            }
          });
          setCnpj(response.data.order_data.order.cnpj);
          setEnterprise(response.data.order_data.order.company_name);
          setEnterprise(response.data.order_data.order.company_name);
          setAgent(response.data.order_data.order.responsible_name);
          setOffice(response.data.order_data.order.responsible_office);
          setEmail(response.data.order_data.order.email);
          setTelephone(response.data.order_data.order.phone);
          setProposalExpiration(response.data.order_data.order.invoice_due_date);

          const userData = {
            cnpj: response.data.order_data.order.cnpj,
            name: response.data.order_data.order.company_name,
            telephone: response.data.order_data.order.phone,
            email: response.data.order_data.order.email,
            agent: response.data.order_data.order.responsible_name,
            enterprise: response.data.order_data.order.company_name,
            proposalExpiration: response.data.order_data.order.invoice_due_date,
            coupomDiscount: response.data.order_data.order.coupom_discount,
            totalBeneficiaries: response.data.order_data.order.total_beneficiaries,
            subtotal: response.data.order_data.order.subtotal,
            city: response.data.order_data.order.city,
            street: response.data.order_data.order.street,
            number: response.data.order_data.order.number,
            complement: response.data.order_data.order.complement,
            state: response.data.order_data.order.state,
            zip_code: response.data.order_data.order.zip_code,
            district: response.data.order_data.order.district
          };
          sessionStorage.setItem('@userData', JSON.stringify({ ...userData }));
          sessionStorage.setItem('@editToken', jwtParsed.order.edit_token);
        })
        .catch((error) => console.error(error));

      sessionStorage.setItem('@orderId', jwtParsed.order.id);
      const tableData = JSON.parse(sessionStorage.getItem('@table'));
      if (tableData) {
        setTable(tableData);
      } else {
        const finalPlans = [];
        const formData = new FormData();
        formData.append('order_id', jwtParsed.order.id);
        getOrdersPlan(formData)
          .then((response) => {
            response.data.order_plans.map((plan) => {
              getPlanById(plan.id)
                .then((response) => {
                  plan.order_simulations.map((simulation) => {
                    simulation.dependents = simulation.dependent;
                    simulation.plan_value = simulation.value;
                    simulation.totalValue = Number(simulation.totalvalue);
                  });
                  finalPlans.push({
                    id: response.data.plan[0].id,
                    plan: `${response.data.plan[0].name} ${response.data.plan[0].subtitle}`,
                    coparticipation: response.data.plan[0].co_participation,
                    attributes: response.data.plan[0].plan_attributes,
                    dependents: plan.order_simulations
                  });
                })
                .catch((error) => console.error(error));
            });
          })
          .catch((error) => console.error(error));

        setTimeout(() => {
          setTable(finalPlans);
          sessionStorage.removeItem('@table');
          sessionStorage.setItem('@table', JSON.stringify(finalPlans));
          calcTotalMembers();
          calcTotal();
        }, 2500);
      }
    }
  }

  useEffect(() => {
    setLoadingContent(true);
    getCoupons();
    getParams();
    getPlans();
    fillFields();

    const storageTotal = sessionStorage.getItem('@totalValue');
    if (storageTotal) {
      setPlanValues({
        ...planValues,
        underTotal: storageTotal,
        totalValue: storageTotal
      });
    } else {
      setPlanValues({
        ...planValues,
        underTotal: 0,
        totalValue: 0
      });
    }

    const result = JSON.parse(sessionStorage.getItem('@table'));
    if (result) {
      setTable(result);
    }

    if (att < 2) {
      const pland_id = JSON.parse(sessionStorage.getItem('@plan_id'));
      orderByPlan(pland_id, plans);
      setAtt(att + 1);
    }

    const user = JSON.parse(sessionStorage.getItem('@userData'));
    if (user && user.coupomDiscount) {
      setEnterprise(user.enterprise);
      setCoupomDiscount(user.coupomDiscount);
    }

    calcTotalMembers();

    setTimeout(() => {
      setLoadingContent(false);
    }, 2500);
  }, [att]);

  return (
    <>
      {alert.show && <Alert type={alert.type} title={alert.title} text={alert.message} />}

      <Modal
        isOpen={modalProposeExists}
        height="346px"
        title=""
        description=""
        onClickClose={() => {
          setModalProposeExists(false);
          sessionStorage.clear();
          navigate(corretor ? `/?corretor=${corretor}` : '/');
        }}
        footerButtonSecond="Voltar para Home"
        onClickFooterSecond={() => {
          setModalProposeExists(false);
          sessionStorage.clear();
          navigate(corretor ? `/?corretor=${corretor}` : '/');
        }}
      >
        <div
          style={{
            height: '156px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <svg
            width="62"
            height="63"
            viewBox="0 0 62 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31 19V31.5M31 44H31.0313M59.125 31.5C59.125 47.033 46.533 59.625 31 59.625C15.467 59.625 2.875 47.033 2.875 31.5C2.875 15.967 15.467 3.375 31 3.375C46.533 3.375 59.125 15.967 59.125 31.5Z"
              stroke="#FCC332"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Spacing size="large" />
          <Title bolder={true} appearance="primary">
            Essa empresa já possui <br /> proposta vigente.
          </Title>
          <Spacing size="xx-small" />
          <Text>
            Para mais detalhes, entre em contato com
            {corretor ? 'o consultor' : 'a área comercial da operadora'}.
          </Text>
        </div>
      </Modal>

      <Modal
        width="900px"
        height="90%"
        isOpen={modalInfoIsOpen}
        title="Planos de Saúde Empresarial Sulmed"
        description="Veja abaixo as informacões das Coberturas e Coparticipações de cada plano."
        onClickClose={() => setModalInfoIsOpen(false)}
      >
        {plans &&
          plans.map((plan, key) => (
            <div key={key}>
              <Accordion width="100%" title={plan.value} padding="15px 24px" deleteButton={false}>
                <Row>
                  {/* <Card appearance="ghost" width="100%"> */}
                  <Column width="100%" paddingRight="0px">
                    <Undertitle bolder={true} appearance="primary">
                      {plan.value}
                    </Undertitle>
                    <Spacing size="x-small" />
                    <Card width="100%" appearance="ghost" padding="24px 15px">
                      <Row position="start" align="start">
                        <Text appearance="primary" textAlign="start" bolder={true}>
                          Coberturas do Plano
                        </Text>
                      </Row>
                      <Spacing size="x-large" />
                      {plan.attributes.map((attribute, key) => (
                        <>
                          <div className={`${styles.row} ${styles.start}`} key={key}>
                            <Column width="4%">
                              <img
                                src={attribute.status === 1 ? check : error}
                                style={{
                                  width: '15px',
                                  marginRight: '8px',
                                  color: attribute.status === 1 ? 'green' : 'red'
                                }}
                              />
                            </Column>
                            <Spacing size="xx-small" />
                            <Column>
                              <Description appearance="grey" textAlign="start">
                                {attribute.attribute_name}
                              </Description>
                            </Column>
                          </div>
                          <Spacing size="x-small" />
                        </>
                      ))}
                    </Card>

                    <Spacing size="x-small" />
                    <Card width="100%" appearance="ghost" padding="24px 15px">
                      <Row position="start" align="start">
                        <Text appearance="primary" bolder={true}>
                          Coparticipações
                        </Text>
                      </Row>
                      <Spacing size="x-large" />
                      <Column>
                        {plan.coparticipation.map((coop) => {
                          if (coop.status === 1) {
                            return (
                              <>
                                <div className={`${styles.row} ${styles.start}`}>
                                  <Column width="4%">
                                    <img
                                      src={check}
                                      style={{
                                        width: '15px',
                                        marginRight: '8px',
                                        color: 'green'
                                      }}
                                    />
                                  </Column>
                                  <Spacing size="xx-small" />
                                  <Column>
                                    <Description
                                      appearance="grey"
                                      textAlign="start"
                                      position="start"
                                      padding="0px"
                                    >
                                      {coop.co_participation_name}
                                    </Description>
                                  </Column>
                                </div>
                                <Spacing size="xx-small" />
                              </>
                            );
                          }
                        })}
                      </Column>
                    </Card>
                  </Column>
                  {/* </Card> */}
                </Row>
              </Accordion>
              <Spacing size="large" />
            </div>
          ))}
      </Modal>

      {loadingContent ? (
        <Layout navbar={true} verticalCentered={true}>
          <Row width="100%" align="center">
            <Column width="100%" position="centered">
              <Spinner show={true} />
              <Spacing size="xx-small" />
              <Description appearance="primary" bolder={true}>
                Carregando os dados
              </Description>
            </Column>
          </Row>
          <Spacing size="x-large" />
        </Layout>
      ) : (
        <Layout navbar={true} goBackButton={false}>
          <Spacing size="medium" />
          <Title appearance="primary" bolder={true}>
            Simulação do Plano Empresarial
          </Title>
          <Spacing size="xx-small" />
          <Text>
            Você pode selecionar o plano, a faixa de idade e inserir a quantidade de titulares e
            dependentes.
          </Text>
          <Spacing size="xx-large" />
          <div className={`${styles.row} ${styles.end}`}>
            <Spacing size="small" />
            <img src={infoIcon} alt="" />
            <Spacing size="x-small" />
            <Text
              bolder={true}
              maxWidth="360px"
              textAlign="start"
              appearance="primary"
              pointer={true}
              onClick={() => setModalInfoIsOpen(true)}
            >
              Dúvida de qual planos escolher? Saiba mais aqui
            </Text>
          </div>
          {width >= 1000 ? <Spacing size="xxx-large" /> : <Spacing size="large" />}
          <Row align="start" width="95%">
            <Text bolder={true} appearance="grey">
              Informações da empresa
            </Text>
          </Row>
          <Spacing size="medium" />
          <Row width="97%" align="start">
            <Column position="start" width="100%">
              <Input
                name="cnpj"
                type="text"
                width="100%"
                label="CNPJ *"
                disabled
                value={maskCnpj(cnpj)}
                error={checkEInputCNPJ}
                onChange={async (event) => {
                  setCnpj(maskReset(event.target.value));
                  if (event.target.value.length > 1) {
                    setCheckInputCNPJ(false);
                  }
                  if (event.target.value.length >= 14) {
                    getCnpjData(maskReset(event.target.value));
                    const teste = await getLinkedCNPJ(maskReset(event.target.value));
                    if (teste.data.total_orders > 0) {
                      setModalProposeExists(true);
                    }
                  }
                }}
              />
            </Column>
            {width >= 1000 && <Spacing size="small" />}
            <Column position="end">
              <Input
                name="enterprise"
                type="text"
                width="100%"
                value={enterprise}
                error={checkEInputEnterprise}
                errorMessage="Campo obrigatório"
                label="Nome da empresa"
                disabled
              />
            </Column>
          </Row>
          {width >= 1000 && <Spacing size="xxx-small" />}
          <Row width="97%">
            <Column>
              <Input
                name="agent"
                type="text"
                width="100%"
                label="Nome Representante Legal *"
                error={checkInputAgent}
                value={agent}
                onChange={(event) => {
                  if (event.target.value.length > 1) {
                    setCheckInputAgent(false);
                  }
                  setAgent(event.target.value);
                }}
                onBlur={() => {
                  const user = JSON.parse(sessionStorage.getItem('@userData'));
                  sessionStorage.setItem('@userData', JSON.stringify({ ...user, agent }));
                }}
              />
            </Column>
            {width >= 1000 && <Spacing size="small" />}
            <Column position="end">
              <Input
                name="office"
                type="text"
                width="100%"
                label="Cargo"
                value={office}
                onChange={(event) => {
                  setOffice(event.target.value);
                }}
                onBlur={() => {
                  const user = JSON.parse(sessionStorage.getItem('@userData'));
                  sessionStorage.setItem('@userData', JSON.stringify({ ...user, office }));
                }}
              />
            </Column>
          </Row>
          {width >= 1000 && <Spacing size="xxx-small" />}
          <Row width="97%">
            <Column>
              <Input
                name="email"
                type="text"
                width="100%"
                label="E-mail *"
                error={checkEInputEmail}
                value={email}
                onChange={(event) => {
                  if (event.target.value.length > 1) {
                    setCheckInputEmail(false);
                  }
                  setEmail(event.target.value);
                }}
              />
            </Column>
            {width >= 1000 && <Spacing size="small" />}
            <Column position="end">
              <Input
                name="phone"
                type="text"
                width="100%"
                label="Telefone *"
                error={checkEInputTelephone}
                value={maskPhone(telephone)}
                maxLength={15}
                onChange={(event) => {
                  if (event.target.value.length > 1) {
                    setCheckInputTelephone(false);
                  }
                  setTelephone(maskPhone(event.target.value));
                }}
              />
            </Column>
          </Row>
          {showExtraEmails && (
            <>
              <Spacing size="xxx-small" />
              <Row width="97%">
                <Column>
                  <Input
                    name="emailExtra1"
                    type="text"
                    width="100%"
                    label="E-mail (opcional)"
                    value={emailExtra1}
                    onChange={(event) => {
                      setEmailExtra1(event.target.value);
                    }}
                  />
                </Column>
                <Spacing size="small" />
                <Column position="end">
                  <Input
                    name="emailExtra2"
                    type="text"
                    width="100%"
                    label="E-mail (opcional)"
                    value={emailExtra2}
                    onChange={(event) => {
                      setEmailExtra2(event.target.value);
                    }}
                  />
                </Column>
              </Row>
            </>
          )}
          <Row position="end" width="95%">
            <Button
              text="Adicionar outros e-mails"
              width="240px"
              appearance={showExtraEmails ? 'disabled' : 'ghost'}
              onClick={() => {
                setShowExtraEmails(true);
              }}
              iconPosition="left"
            >
              <FaPlus style={{ color: '#fff', marginRight: '10px' }} />
            </Button>
          </Row>
          {width >= 1000 ? <Spacing size="xxx-large" /> : <Spacing size="small" />}
          <Card appearance="ghost" width="97%">
            <Title appearance="primary" textAlign="start" bolder={true}>
              Adicionar Plano
            </Title>
            <Spacing size="xxx-large" />

            <Row position="start">
              <Text bolder={true} appearance="grey">
                Selecione o Plano
              </Text>
            </Row>
            <Spacing size="medium" />
            <select
              name="plans"
              id="plans"
              className={styles.select}
              value={selectedPlan}
              onChange={(event: any) => {
                setSelectedPlan(event.target.value);
              }}
            >
              <option value="" disabled>
                Selecione um plano
              </option>
              {selectPlans.map((plan, key) => {
                if (plan.status === false) {
                  return (
                    <option key={key} value={plan.value}>
                      {plan.value}
                    </option>
                  );
                }
              })}
            </select>
            <Spacing size="xxx-large" />
            <Row position="end">
              <Button
                text="Adicionar Plano"
                width="170px"
                appearance={selectedPlan === '' ? 'disabled' : 'primary'}
                onClick={async () => {
                  const plan = selectPlans.find((elem: any) => elem.value === selectedPlan);
                  const response = await getTableCommercialByPlanId(`${plan.id}`);
                  if (selectedPlan !== '') {
                    response.data.table_commercial.map((item: any) => {
                      let age = '';
                      if (item.minimum_age !== 59) {
                        age = `${item.minimum_age} - ${item.maximum_age}`;
                      } else {
                        age = `${item.minimum_age} +`;
                      }
                      item.age = age;
                      item.plan = selectedPlan;
                      item.owners = 0;
                      item.dependents = 0;
                      item.totalValue = 0;
                      item.plan_id = plan.id;
                    });
                    table.unshift({
                      plan: selectedPlan,
                      attributes: plan.attributes,
                      coparticipation: plan.coparticipation,
                      dependents: response.data.table_commercial
                    });
                    setTable(table);
                    const index = selectPlans.map((e) => e.value).indexOf(selectedPlan);
                    selectPlans[index].status = true;
                    setSelectPlans([...selectPlans]);
                    setSelectedPlan('');
                  }
                }}
              />
            </Row>
          </Card>
          {width >= 1000 ? <Spacing size="x-large" /> : <Spacing size="medium" />}
          {table &&
            table.length > 0 &&
            table.map((elem, key) => (
              <Fragment key={key}>
                <Accordion
                  title={elem.plan}
                  deleteButton={true}
                  onClickDelete={() => {
                    const index = selectPlans.map((e) => e.value).indexOf(elem.plan);
                    selectPlans[index].status = false;
                    setSelectPlans([...selectPlans]);

                    const index2 = table.map((e) => e.plan).indexOf(elem.plan);
                    table.splice(index2, 1);
                    setTable(table);
                    sessionStorage.setItem('@table', JSON.stringify(table));

                    calcTotalMembers();
                    calcTotal();
                  }}
                  opened={key === 0}
                >
                  <TableMobile
                    width={width}
                    content={plans}
                    plans={elem.dependents}
                    plan={elem.plan}
                    onClickDelete={() => {
                      const index = selectPlans.map((e) => e.value).indexOf(elem.plan);
                      selectPlans[index].status = false;
                      setSelectPlans([...selectPlans]);

                      const index2 = table.map((e) => e.plan).indexOf(elem.plan);
                      table.splice(index2, 1);
                      setTable(table);
                      sessionStorage.setItem('@table', JSON.stringify(table));
                    }}
                    onChangeOwners={(data, id, plan_value, dependents) => {
                      // Busca os dados à serem alterados
                      const index = table.map((e) => e.plan).indexOf(elem.plan);
                      const indexDependents = table[index].dependents.map((e) => e.id).indexOf(id);

                      // Altera os dados informados
                      table[index].dependents[indexDependents].owners =
                        data === 0 || Number.isNaN(data) ? 0 : data;
                      table[index].dependents[indexDependents].totalValue =
                        Number(plan_value) *
                        (Number(Number.isNaN(data) ? 0 : data) + Number(dependents));
                      table[index].id = table[index].dependents[indexDependents].plan_id;

                      // Seta os novos valores no state Table
                      setTable([...table]);
                      sessionStorage.setItem('@table', JSON.stringify(table));

                      // Recalcula a quantidade total de membros e o valor total da proposta
                      calcTotalMembers();
                      calcTotal();
                    }}
                    onChangeDependents={(data, id, plan_value, owners) => {
                      // Busca os dados à serem alterados
                      const index = table.map((e) => e.plan).indexOf(elem.plan);
                      const indexDependents = table[index].dependents.map((e) => e.id).indexOf(id);

                      // Altera os dados informados
                      table[index].dependents[indexDependents].dependents =
                        data === 0 || Number.isNaN(data) ? 0 : data;
                      table[index].dependents[indexDependents].totalValue =
                        Number(plan_value) *
                        (Number(owners) + Number(Number.isNaN(data) ? 0 : data));
                      table[index].id = table[index].dependents[indexDependents].plan_id;

                      // Seta os novos valores no state Table
                      setTable([...table]);
                      sessionStorage.setItem('@table', JSON.stringify(table));

                      // Recalcula a quantidade total de membros e o valor total da proposta
                      calcTotalMembers();
                      calcTotal();
                    }}
                  />
                  <Spacing size="x-large" />
                  {width >= 800 && (
                    <div className={`${styles.row} ${styles.header}`} style={{ width: '900px' }}>
                      <Column width="230%">
                        <Description appearance="grey">Plano Selecionado</Description>
                      </Column>
                      <Column width="75%">
                        <Description appearance="grey">Idade do titular</Description>
                      </Column>
                      <Column width="60%">
                        <Description appearance="grey">Qnt. Titular</Description>
                      </Column>
                      <Column width="78%">
                        <Description appearance="grey">Qnt. Dependente</Description>
                      </Column>
                      <Column width="100%">
                        <Description appearance="grey">Valor do plano</Description>
                      </Column>
                      <Column width="100%">
                        <Description appearance="grey">Valor total</Description>
                      </Column>
                    </div>
                  )}
                  {plans &&
                    plans.length > 0 &&
                    elem.dependents.map((formPlan: any, index: number) => (
                      <Table
                        width={width}
                        content={plans}
                        key={index}
                        removePlan={() => handleRemovePlan(index)}
                        onChangeAge={(data) => {
                          const newValue = [...elem.dependents];
                          formPlan[index].age = data.title;
                          setFormPlans(newValue);
                        }}
                        onChangePlan={(data) => {
                          const newValue = [...elem.dependents];
                          newValue[index].plan = data.title;
                          setFormPlans(newValue);
                        }}
                        onChangeOwners={(data) => {
                          // Altera os dados informados
                          console.log(typeof formPlan.dependents);
                          formPlan.owners = data;
                          formPlan.totalValue =
                            Number(formPlan.plan_value) * (data + Number(formPlan.dependents));
                          elem.id = formPlan.plan_id;

                          // Seta os novos valores no state Table
                          setTable(table);
                          sessionStorage.setItem('@table', JSON.stringify(table));

                          // Recalcula a quantidade total de membros e o valor total da proposta
                          calcTotalMembers();
                          calcTotal();
                        }}
                        onChangeDependents={(data) => {
                          // Altera os dados informados
                          formPlan.dependents = data;
                          formPlan.totalValue =
                            Number(formPlan.plan_value) * (Number(formPlan.owners) + data);
                          elem.id = formPlan.plan_id;

                          // Seta os novos valores no state Table
                          setTable(table);
                          sessionStorage.setItem('@table', JSON.stringify(table));

                          // Recalcula a quantidade total de membros e o valor total da proposta
                          calcTotalMembers();
                          calcTotal();
                        }}
                        owners={formPlan.owners}
                        dependents={formPlan.dependents}
                        index={index}
                        age={formPlan.age}
                        plan={elem.plan}
                        planValue={formPlan.plan_value}
                        totalValue={formPlan.totalValue}
                      />
                    ))}
                </Accordion>
                <Spacing size="xx-small" />
              </Fragment>
            ))}
          {width >= 1000 ? <Spacing size="xxx-large" /> : <Spacing size="small" />}
          <Row width="97%">
            <Column paddingRight="0px">
              <Card appearance="ghost" width="100%" textAlign="start">
                <Title appearance="primary" textAlign="start" bolder={true}>
                  Vencimento da Fatura
                </Title>
                <Spacing size="xxx-large" />
                <div className={`${styles.row} ${styles.start}`}>
                  <select
                    name="proposalExpiration"
                    id="proposalExpiration"
                    style={{ border: checkProposalExpiration ? '2px solid red' : 'none' }}
                    className={styles.select}
                    value={proposalExpiration}
                    onChange={(event: any) => {
                      setCheckProposalExpiration(false);
                      setProposalExpiration(event.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Selecione o dia para vencimento da fatura
                    </option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                  {checkProposalExpiration ? (
                    <i className={styles.error}>Campo Obrigatório!</i>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
            </Column>
            {width >= 1000 ? <Spacing size="x-small" /> : <Spacing size="large" />}
            <Column paddingRight="0px">
              <Card appearance="ghost" width="100%" textAlign="start">
                <Title appearance="primary" textAlign="start" bolder={true}>
                  Data de Vigência
                </Title>
                <Spacing size="xxx-large" />
                <div className={`${styles.row} ${styles.start}`}>
                  <div className={styles.datepicker}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={dayjs(proposalValidity)}
                          disablePast
                          views={['year', 'month', 'day']}
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setProposalValidity(dayjs(newValue).format('YYYY-MM-DD'));
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </Card>
            </Column>
          </Row>

          {width >= 1000 ? <Spacing size="xxx-large" /> : <Spacing size="small" />}
          <Card appearance="primary" width="95%">
            <Row>
              <Column position="start" paddingRight="0px">
                <Text bolder={true} appearance="grey" textAlign="start">
                  Você possui um cupom de desconto? (opcional)
                </Text>
                <Row position="start">
                  <Column paddingRight="0px" position="start">
                    <Spacing size="large" />
                    <Input
                      name="discount"
                      type="text"
                      width="98%"
                      label="Cupom de desconto"
                      value={coupomInformed}
                      error={coupomInvalid}
                      errorMessage="Cumpom Inválido"
                      onChange={(event) => {
                        setCoupomInformed(event.target.value);
                      }}
                    />
                  </Column>
                  <Column paddingRight="0px">
                    <Button
                      text="Adicionar Cupom"
                      width={width >= 1000 ? '180px' : '98%'}
                      size="sm"
                      onClick={() => {
                        if (coupons.find((event) => event.name === coupomInformed)) {
                          const coupom = coupons.find((event) => event.name === coupomInformed);
                          setCoupomDescription(coupom.coupon_description);
                          handleSetDiscount(coupons, coupomInformed);

                          if (coupom.type === 'V') {
                            setCoupomDiscount(coupom.discount_value);
                          } else {
                            const discount =
                              Number(planValues.underTotal) *
                              (Number(coupom.discount_percentage) / 100);
                            setCoupomDiscount(discount.toFixed(2));
                          }
                          setCoupomInvalid(false);
                          const user = JSON.parse(sessionStorage.getItem('@userData'));
                          sessionStorage.setItem(
                            '@userData',
                            JSON.stringify({
                              ...user,
                              coupomName: coupom.name,
                              coupomDescription: coupom.coupon_description,
                              coupomDiscount
                            })
                          );
                        } else {
                          setCoupomInvalid(true);
                        }
                      }}
                    />
                  </Column>
                </Row>
              </Column>

              {width <= 1000 && <Spacing size="xxx-large" />}

              <Column position="end" paddingRight="0px">
                <Spacing size="medium" />
                <div className={styles.row} style={{ width: width >= 1000 ? '290px' : '100%' }}>
                  <div
                    className={styles.column}
                    style={{
                      width: width >= 1000 ? '140px' : '50%',
                      alignItems: width >= 1000 ? 'end' : 'start'
                    }}
                  >
                    <Text appearance="grey" textAlign="end">
                      Subtotal
                    </Text>
                  </div>
                  <div
                    className={styles.column}
                    style={{
                      width: width >= 1000 ? '190px' : '50%',
                      alignItems: 'flex-end',
                      textAlign: 'end'
                    }}
                  >
                    <Undertitle appearance="grey">
                      R$ {maskMoneyPlans(Number(planValues.underTotal))}
                    </Undertitle>
                  </div>
                </div>
                <Spacing size="xx-small" />
                {coupomDiscount && (
                  <>
                    <div className={styles.row} style={{ width: width >= 1000 ? '280px' : '100%' }}>
                      <Column position="end" width={width >= 1000 ? '80px' : '50%'}>
                        <Text appearance="grey">Desconto</Text>
                      </Column>
                      <Column
                        position="end"
                        width={width >= 1000 ? '200px' : '50%'}
                        paddingRight="0px"
                      >
                        <Undertitle appearance="grey">
                          - R$ {maskMoneyPlans(Number(coupomDiscount))}
                        </Undertitle>
                      </Column>
                    </div>
                    <Spacing size="xx-small" />
                  </>
                )}
                <div className={styles.row} style={{ width: width >= 1000 ? '320px' : '100%' }}>
                  <div
                    className={styles.column}
                    style={{
                      width: width >= 1000 ? '140px' : '50%',
                      alignItems: width >= 1000 ? 'end' : 'start',
                      textAlign: width >= 1000 ? 'center' : 'start'
                    }}
                  >
                    <Undertitle appearance="grey" bolder={true}>
                      Valor Total
                    </Undertitle>
                  </div>
                  <div
                    className={styles.column}
                    style={{
                      width: width >= 1000 ? '190px' : '50%',
                      alignItems: 'end',
                      textAlign: 'end'
                    }}
                  >
                    <Title bolder={true} appearance="primary" textAlign="end">
                      R$
                      {Number(planValues.underTotal) - Number(coupomDiscount) < 0
                        ? ' 0,00'
                        : maskMoneyPlans(Number(planValues.underTotal) - Number(coupomDiscount))}
                    </Title>
                  </div>
                </div>
              </Column>
            </Row>
          </Card>
          {width >= 1000 ? <Spacing size="xxx-large" /> : <Spacing size="small" />}
          <Row width="95%">
            <BottomNavigation
              labelRight="Continuar"
              appearanceRight={totalBeneficiaries <= 0 ? 'disabled' : 'primary'}
              actionRight={() => {
                if (totalBeneficiaries > 0) {
                  handleNextPage();
                }
              }}
              loadingRight={loading}
            />
          </Row>
        </Layout>
      )}
    </>
  );
}

export default Proposal;
