/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { getActivePackagesById, getPackagesByRegions } from 'api/services/ApiCalls';
import axios from 'axios';
import { Button, Column, Description, Input, Modal, Row, Spacing, Text } from 'components';
import { DataContext } from 'context/DataContext';
import { useContext, useEffect, useState } from 'react';
import { FaChevronDown, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { maskCep, maskReset } from 'utils/mask';
import Product from 'views/Summary/components/Product';
import productImage from '../../assets/Drag and drop.png';
import cart from '../../assets/icons/cart-icon.png';
import error from '../../assets/icons/error-unenabled-location-icon.png';
import target from '../../assets/icons/location-target-icon.png';
import styles from './styles.module.scss';

export default function SearchField({ city, onClick }: any) {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [userCity, setUserCity] = useState(city || 'Porto Alegre');
  const [modalCart, setModalCart] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [cep, setCep] = useState('');
  const { context, handleData, updatePackages, updateLocation, deleteProduct }: any =
    useContext(DataContext);

  function checkProductsValue() {
    context.products.map(async (product) => {
      await getActivePackagesById(product.id)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
        })
        .catch((error) => console.error(error))
        .finally(() => {
          handleData(context);
        });
    });
  }

  function getUserCity() {
    axios
      .get(`https://viacep.com.br/ws/${maskReset(cep)}/json/`)
      .then((response) => {
        setUserCity(response.data.localidade);
        updateLocation({
          postCode: maskReset(response.data.cep),
          city: response.data.localidade
        });
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  useEffect(() => {
    const storage = JSON.parse(sessionStorage.getItem('@data'));
    if (storage === null || storage.location.postCode === '') {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition((location) => {
          if (location) {
            const KEY = 'c59a89c750b543d18d6ce18e2bdbafda';
            const URL = `https://api.geoapify.com/v1/geocode/reverse?lat=${location.coords.latitude}&lon=${location.coords.longitude}&format=json&apiKey=${KEY}`;

            fetch(URL)
              .then((response) => response.json())
              .then((data) => {
                setUserCity(data.results[0].city);
                updateLocation({
                  postCode: maskReset(data.results[0].postcode),
                  city: data.results[0].city
                });
              })
              .catch((error) => {
                console.warn(error.message);
              });
          }
        });
      }
    } else {
      setUserCity(storage.location.city);
    }

    checkProductsValue();
  }, []);

  function addProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount += 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });
  }

  function subsProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount -= 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });
  }

  async function searchUserLocation() {
    await getPackagesByRegions(maskReset(cep))
      .then((response) => {
        if (response.data.packages.length === 0) {
          setModalErrorIsOpen(true);
        } else {
          getUserCity();
          updatePackages(response.data.packages);
          onClick(cep);
        }
      })
      .catch((error) => {
        console.error(error.response.data.error);
      })
      .finally(() => {
        setModalLocation(false);
        setCep('');
      });
  }

  return (
    <>
      <Modal
        isOpen={modalCart}
        title="Carrinho de compras"
        description=""
        width="800px"
        footerButtonFirst="Voltar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="140px"
        onClickFooterFirst={() => setModalCart(false)}
        footerButtonSecond="Finalizar Compra"
        footerButtonSecondAppearance={context.products.length > 0 ? 'primary' : 'disabled'}
        footerButtonSecondWidth="140px"
        onClickFooterSecond={() => (context.products.length > 0 ? navigate('/cadastro') : '')}
        onClickClose={() => setModalCart(false)}
      >
        <div className={styles.modalContent}>
          <Spacing size="large" />
          {context.products.length > 0 ? (
            <>
              <Spacing size="x-large" />
              {context.products.map((product) => (
                <Product
                  key={product.title}
                  image={productImage}
                  title={product.title}
                  description={product.description}
                  productValue={product.value}
                  totalValue={product.totalValue}
                  amount={product.amount}
                  onClickDelete={() => deleteProduct(product.id)}
                  addProduct={(event) => addProduct(product.id, event)}
                  subsProduct={(event) => subsProduct(product.id, event)}
                />
              ))}
              <Spacing size="xxx-large" />
            </>
          ) : (
            <Row>
              <Description appearance="grey">Nenhum produto foi adicionado ao carrinho</Description>
            </Row>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={modalLocation}
        title="Informe o CEP da sua região"
        description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
        footerButtonFirst="Cancelar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="74px"
        onClickFooterFirst={() => setModalLocation(false)}
        footerButtonSecond="Continuar"
        footerButtonSecondAppearance="primary"
        footerButtonSecondWidth="74px"
        onClickFooterSecond={() => searchUserLocation()}
        onClickClose={() => setModalLocation(false)}
      >
        <Spacing size="large" />
        <Input
          name="cep"
          type="text"
          width="100%"
          maxLength={10}
          label="CEP"
          value={cep}
          onChange={(event) => {
            setCep(maskCep(event.target.value));
          }}
        />
        <Spacing size="large" />
      </Modal>

      <Modal
        isOpen={modalErrorIsOpen}
        image={error}
        imageWidth="70px"
        title="Serviço indisponível em sua região"
        description="Este pacote não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
        onClickClose={() => setModalErrorIsOpen(false)}
      >
        <Spacing size="xxx-large" />
        <Row>
          <Column position="centered">
            <Button
              text="Ver serviços disponíveis"
              width="195px"
              onClick={() => setModalErrorIsOpen(false)}
            />
            <Spacing size="medium" />
            <Button
              text="Mudar localização"
              appearance="ghost"
              width="165px"
              onClick={() => {
                setModalErrorIsOpen(false);
                setModalLocation(true);
              }}
              iconPosition="left"
            >
              <FaMapMarkerAlt />
            </Button>
          </Column>
        </Row>
      </Modal>

      <div className={styles.searchField}>
        <Column>
          <div className={styles.location}>
            <img src={target} draggable="false" />
            <Spacing size="xx-small" />
            <Text
              appearance="primary"
              pointer={true}
              onClick={() => setModalLocation(true)}
              position="centered"
            >
              {userCity}
              <Spacing size="xxx-small" />
              <FaChevronDown />
            </Text>
          </div>
        </Column>
        <Column position="center">
          <Input
            name="search"
            type="text"
            width="100%"
            icon={<FaSearch />}
            placeholder="O que você precisa hoje?"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Column>
        <div className={styles.icons}>
          <Row>
            <div className={styles.cart} onClick={() => setModalCart(true)}>
              <img src={cart} className={styles.icon} />
              <div className={styles.notification}>{context.products.length}</div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}
