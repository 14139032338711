import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Feedback } from '../../components';
import feedback from '../../assets/sulmed-feedback-error-404.png';

function Error404() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Erro 404 - Página não encontrada"
        description="Desculpa. Parece que a página que você está procurando, não existe nesse site."
        buttonText="Ir para a Página Inicial"
        onClickButton={() => navigate('/')}
        image={feedback}
      />
    </Layout>
  );
}

export default Error404;
